let scrollPosition;
export const toggleBodyScroll = lock => {
  if (lock) {
    scrollPosition = document.documentElement.scrollTop;
    document.body.classList.add('body-scroll-lock');
    document.body.style.top = `-${scrollPosition}px`;
  } else {
    document.body.classList.remove('body-scroll-lock');
    document.body.style.top = '';
    window.scrollTo(0, scrollPosition);
  }
};
