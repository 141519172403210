import { API, APIRoutes } from '@app/api';
import { makeAutoObservable, runInAction } from 'mobx';

export class InstrumentationsStore {
  constructor() {
    makeAutoObservable(this);
  }

  instrumentations = {
    isLoading: false,
    data: [],
    error: null,
  };

  getInstrumentations = async () => {
    try {
      this.instrumentations.isLoading = true;

      const {
        data: { instrumentations },
      } = await API(APIRoutes.INSTRUMENTATIONS);

      runInAction(() => {
        this.instrumentations.data = instrumentations;
      });
    } catch (error) {
      runInAction(() => {
        this.instrumentations.error = error;
      });
    } finally {
      runInAction(() => {
        this.instrumentations.isLoading = false;
      });
    }
  };
}

export default new InstrumentationsStore();
