import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import { useTranslation } from 'react-i18next';
import TextField, { MARGINS } from '@components/Form/TextField';
import Select from '@components/Form/Select';
import SectionTitle from '@components/SectionTitle';
import Checkbox from '@components/Form/Checkbox';

const PaymentsTabs = ({ register, errors, control, countries }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box width={1 / 2} pr={3} mt={3}>
        <SectionTitle>{t('Bank information')}</SectionTitle>
      </Box>

      <Flex flexWrap="wrap" mx={-3}>
        <Box width={[1, 1, 1, 1 / 2]} px={3}>
          <Box width={1}>
            <TextField
              name="account_owner"
              label={t('Account owner')}
              placeholder={t('Account owner')}
              register={register}
              required
              errors={errors}
            />
            <Select
              control={control}
              isSearchable
              name="country_of_residence"
              label={t('Country of residence')}
              options={countries}
              errors={errors}
            />
            <Checkbox
              name="vat_registered"
              label={t('VAT registered')}
              register={register}
              errors={errors}
            />
            <TextField
              name="vat_number"
              label={t('VAT number')}
              placeholder={t('VAT number')}
              register={register}
              errors={errors}
            />
          </Box>
        </Box>
        <Box width={[1, 1, 1, 1 / 2]} px={3}>
          <Box width={1}>
            <TextField
              name="bank_name"
              label={t('Bank name')}
              placeholder={t('Bank name')}
              register={register}
              required
              errors={errors}
            />
            <Select
              control={control}
              isSearchable
              name="bank_country"
              label={t('Bank country')}
              options={countries}
              errors={errors}
              required
            />
            <TextField
              name="bank_account_no"
              label={t('Account number')}
              placeholder={t('Account number')}
              register={register}
              errors={errors}
              required
            />
            <TextField
              name="bank_blz"
              label={t('Bank blz')}
              placeholder={t('Bank blz')}
              register={register}
              errors={errors}
            />
            <TextField
              name="bank_iban"
              label={t('Bank IBAN')}
              placeholder={t('Bank IBAN')}
              register={register}
              required
              errors={errors}
            />
            <TextField
              name="bank_swift"
              label={t('Bank SWIFT')}
              placeholder={t('Bank SWIFT')}
              register={register}
              errors={errors}
              mb={MARGINS.BIG}
            />
          </Box>
        </Box>
      </Flex>
    </>
  );
};

PaymentsTabs.defaultProps = {
  errors: {},
  countries: [],
};

PaymentsTabs.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  control: PropTypes.object.isRequired,
  countries: PropTypes.array,
};

export default PaymentsTabs;
