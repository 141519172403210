import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Box, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router';
import TextField from '@components/Form/TextField';
import Header from '@components/Header';
import { MainContent, StyledLink } from '@styles/globalStyles';
import Preloader from '@components/Preloader';
import Select from '@components/Form/Select';
import routes from '@routes';
import FormButton from '@components/Form/FormButton';
import { VARIANTS as BTN_VARIANTS } from '@components/Button';
import Card from '@components/Card';
import { BUSINESS_TYPES } from '@root/stores/registrationStore';

const OAuthForm = ({
  authStore: {
    oAuth: { body, provider },
    oAuthSignIn,
    signInStatus: { isLoading, formNotification },
  },
  registrationStore: { getCountries, countries, userCountry },
}) => {
  const {
    reset,
    register,
    handleSubmit,
    control,
    errors: validationErrors,
    watch,
  } = useForm({
    defaultValues: {
      ...body,
    },
  });
  const { t } = useTranslation();

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (userCountry) {
      reset({
        ...body,
        country: userCountry,
      });
    }
  }, [userCountry]);

  const errors = {
    ...validationErrors,
    ...formNotification,
  };

  const onSubmit = data => {
    const country = data.country.value;
    oAuthSignIn(
      {
        ...body,
        ...data,
        business_type: data.business_type?.value,
        country,
      },
      provider,
      true,
    );
  };

  const isOtherBusiness = watch('business_type')?.value === 'other';

  if (!body) {
    return <Redirect to={routes.base} />;
  }

  return (
    <MainContent>
      <Header title={t('Provide more data')} />

      {isLoading && <Preloader center />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="hidden"
          name="confirm_success_url"
          value={`${window.location.origin}${routes.auth.signIn}`}
          ref={register}
        />
        <Flex
          width={1}
          alignItems="center"
          flexDirection="column"
          style={{ width: '50%' }}
        >
          <Card>
            <Box width={1}>
              <TextField
                name="first_name"
                label={t('First name')}
                placeholder={t('First name')}
                type="text"
                register={register}
                required
                errors={errors}
              />
              <TextField
                name="last_name"
                label={t('Last name')}
                placeholder={t('Last name')}
                type="text"
                register={register}
                required
                errors={errors}
              />
              <Select
                control={control}
                isSearchable
                name="business_type"
                label={t('Business type')}
                placeholder={t('Select business type')}
                options={BUSINESS_TYPES}
                errors={errors}
                required
              />
              <Box display={isOtherBusiness ? 'block' : 'none'}>
                <TextField
                  type="text"
                  register={register}
                  errors={errors}
                  name="business_type_other"
                  label={t('If ‘other’, please specify')}
                  placeholder={t('Details')}
                  required={isOtherBusiness}
                />
              </Box>
              <Select
                control={control}
                isSearchable
                name="country"
                label={t('Country')}
                placeholder={t('Select country')}
                options={countries.data}
                errors={errors}
                required
              />
              <TextField
                name="phone_no"
                label={t('Phone number')}
                placeholder={t('Phone number')}
                type="text"
                register={register}
                errors={errors}
              />
              <TextField
                name="email"
                label={t('E-mail')}
                placeholder={t('E-mail')}
                type="text"
                register={register}
                required
                errors={errors}
              />
            </Box>

            <Flex width={1} mb={3} justifyContent="space-between">
              <StyledLink to={routes.agb}>
                {t(
                  'By proceeding to create your account and use Intervox you are agreeing to our Terms of Use and Privacy Policy',
                )}
              </StyledLink>
            </Flex>
            <Box my={2} textAlign="center">
              <FormButton
                isLoading={isLoading}
                type="submit"
                variant={BTN_VARIANTS.GREEN}
                label={t('Sign up')}
              />
            </Box>
          </Card>
        </Flex>
      </form>
    </MainContent>
  );
};

OAuthForm.propTypes = {
  authStore: PropTypes.object.isRequired,
  registrationStore: PropTypes.object.isRequired,
};

export default inject('authStore', 'registrationStore')(observer(OAuthForm));
