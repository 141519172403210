const STORAGE_KEY_PREFFIX = 'intervox:';

export const KEYS = {
  AUTH_TOKEN: 'AUTH_TOKEN',
  AUTH_CLIENT: 'AUTH_CLIENT',
  AUTH_UID: 'AUTH_UID',
  AUTH_EXPIRY: 'AUTH_EXPIRY',
  RECENT_SEARCHES: 'RECENT_SEARCHES',
  SIDEBAR_COLLAPSED: 'SIDEBAR_COLLAPSED',
  WELCOME_CLOSED: 'WELCOME_CLOSED',
  DISABLE_PERFORMANCE_COOKIES: 'DISABLE_PERFORMANCE_COOKIES',
};

const MAP_KEYS_NAMES = {
  [KEYS.AUTH_TOKEN]: `${STORAGE_KEY_PREFFIX}authToken`,
  [KEYS.AUTH_CLIENT]: `${STORAGE_KEY_PREFFIX}authClient`,
  [KEYS.AUTH_UID]: `${STORAGE_KEY_PREFFIX}authUid`,
  [KEYS.AUTH_EXPIRY]: `${STORAGE_KEY_PREFFIX}authExpiry`,
  [KEYS.RECENT_SEARCHES]: `${STORAGE_KEY_PREFFIX}recentSearches`,
  [KEYS.SIDEBAR_COLLAPSED]: `${STORAGE_KEY_PREFFIX}sidebarCollapsed`,
  [KEYS.WELCOME_CLOSED]: `${STORAGE_KEY_PREFFIX}welcomeClosed`,
  [KEYS.DISABLE_PERFORMANCE_COOKIES]: `${STORAGE_KEY_PREFFIX}disablePerformanceCookies`,
};

export const setItem = (key, value) =>
  localStorage.setItem(MAP_KEYS_NAMES[key], value);

export const getItem = key => localStorage.getItem(MAP_KEYS_NAMES[key]);

export const removeItem = key => localStorage.removeItem(MAP_KEYS_NAMES[key]);

export const clear = () => {
  const lsKeys = Object.keys(localStorage);
  lsKeys.forEach(key => {
    if (key.indexOf(STORAGE_KEY_PREFFIX) !== -1) {
      localStorage.removeItem(key);
    }
  });
};
