import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Flex, Box } from 'rebass';
import { Helmet } from 'react-helmet';
import PasswordResetForm from '@containers/PasswordReset/components/PasswordResetForm';
import Header from '@components/Header';
import { MainContent } from '@styles/globalStyles';
import Card, { VARIANTS as CARD_VARIANTS } from '@components/Card';
import Text, { VARIANTS as TEXT_VARIANTS } from '@components/Text';

const PasswordReset = ({
  passwordStore: { resetPassword, passwordErrors },
}) => {
  const { t } = useTranslation();

  return (
    <MainContent>
      <Helmet>
        <title>{t('Password reset')}</title>
      </Helmet>
      <Header title={t('Password reset')} withSeparator />

      <Flex flexWrap="wrap" mx={-3} mt={3} mb={[0, 0, 0, 6]}>
        <Box width={[1, 1, 1, 1 / 2]} px={3} mb={[5, 5, 5, 0]}>
          <Card variant={CARD_VARIANTS.TRANSPARENT}>
            <Box mb={3}>
              <Text
                variant={TEXT_VARIANTS.H4}
                style={{ textTransform: 'initial' }}
              >
                {t('Please enter a new password')}
              </Text>
            </Box>

            <Text variant={TEXT_VARIANTS.BODY}>
              {t(
                'Include a minimum twelve characters. Please also include one upper case character and one special character.',
              )}
            </Text>
            <PasswordResetForm
              onSubmit={resetPassword}
              passwordErrors={passwordErrors}
            />
          </Card>
        </Box>
      </Flex>
    </MainContent>
  );
};

PasswordReset.propTypes = {
  passwordStore: PropTypes.object.isRequired,
};

export default inject('passwordStore')(observer(PasswordReset));
