import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash-es';
import { Error, FieldWrapper } from '@components/Form/style';
import {
  StyledInput,
  InputWrapper,
  Checkmark,
} from '@components/Form/Radio/style';
import i18n from '@utils/i18n';
import { MARGINS } from '@styles/globalStyles';

const Radio = ({
  options,
  defaultChecked,
  name,
  register,
  required,
  style,
  errors,
  mb,
}) => {
  const fieldId = useMemo(() => uniqueId(`input_${name}_`), []);

  return (
    <FieldWrapper style={style} mb={mb}>
      <InputWrapper withError={errors[name] ? 1 : 0}>
        {options.map(({ value, label }, index) => (
          <StyledInput
            key={`${fieldId}-${index}`}
            htmlFor={`${fieldId}-${index}`}
          >
            {label}
            <input
              name={name}
              type="radio"
              ref={register({
                required: {
                  value: required,
                  message: i18n.t('Required'),
                },
              })}
              id={`${fieldId}-${index}`}
              value={value}
              defaultChecked={index === defaultChecked}
            />
            <Checkmark />
          </StyledInput>
        ))}
      </InputWrapper>

      {errors[name] ? <Error>{errors[name].message}</Error> : null}
    </FieldWrapper>
  );
};

Radio.defaultProps = {
  required: false,
  style: {},
  errors: {},
  mb: MARGINS.SMALL,
};

Radio.defaultProps = {
  defaultChecked: 0,
};

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  defaultChecked: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  register: PropTypes.func.isRequired,
  required: PropTypes.bool,
  style: PropTypes.object,
  errors: PropTypes.object,
  mb: PropTypes.oneOf(Object.values(MARGINS)),
};

export default Radio;
