/* eslint-disable no-console */
import { makeAutoObservable, runInAction } from 'mobx';
import { API, APIRoutes } from '@app/api';
import i18n from '@utils/i18n';
import DetailsTab from '@containers/Account/components/DetailsTab';
import PasswordTab from '@containers/Account/components/PasswordTab';
import PaymentsTab from '@containers/Account/components/PaymentsTab';
import ComposersTab from '@containers/Account/components/ComposersTab';
import { TYPES } from '@components/Form/Notification/style';
import { rollbarStore, cookiesStore, blockDownloadStore } from '@stores';

export const CUSTOMER_TABS = [
  {
    id: 'password',
    name: i18n.t('Password'),
    Component: PasswordTab,
  },
  {
    id: 'details',
    name: i18n.t('Details'),
    Component: DetailsTab,
  },
];

export const COMPOSER_TABS = [
  {
    id: 'composers',
    name: i18n.t('Composers'),
    Component: ComposersTab,
  },
  {
    id: 'payment',
    name: i18n.t('Payment'),
    Component: PaymentsTab,
  },
];

export const PRODUCER_TABS = [
  {
    id: 'payment',
    name: i18n.t('Payment'),
    Component: PaymentsTab,
  },
];

const DEFAULT_CUSTOMER_FORM = {
  isLoading: false,
  errors: {},
  activeTab: 1,
  formNotification: null,
};

export class CustomerStore {
  constructor() {
    makeAutoObservable(this);
  }

  customerForm = {
    ...DEFAULT_CUSTOMER_FORM,
  };

  customer = {
    isLoading: false,
    data: null,
    error: null,
  };

  getCustomer = async (setIsAuthenticated = null) => {
    try {
      this.customer.isLoading = true;

      const {
        data: { customer },
      } = await API(APIRoutes.CUSTOMER);
      runInAction(() => {
        if (customer) {
          this.customer.data = customer;
          if (setIsAuthenticated) setIsAuthenticated(true);
          blockDownloadStore.setDownloadBlocking(
            customer.fake_account,
            customer.block_download,
          );

          cookiesStore.onChange(customer.cookie_performance_analytics);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.customer.error = error;
        if (setIsAuthenticated) setIsAuthenticated(false);
      });
    } finally {
      runInAction(() => {
        this.customer.isLoading = false;
      });
    }
  };

  setCustomerData = data => {
    this.customer.data = data;

    if (data) {
      blockDownloadStore.setDownloadBlocking(
        data.fake_account,
        data.block_download,
      );
    }

    if (!data) {
      return rollbarStore.configureRollbar(null);
    }
    return rollbarStore.configureRollbar(data.email);
  };

  onUpdate = async formData => {
    this.customerForm.isLoading = true;

    try {
      const {
        data: { customer },
      } = await API.put(APIRoutes.SIGN_UP, {
        ...formData,
        country: formData.country?.value,
        country_of_residence: formData.country_of_residence?.value,
        bank_country: formData.bank_country?.value,
        business_type: formData.business_type?.value,
        mro: formData.mro?.value,
        pro: formData.pro?.value,
      });
      runInAction(() => {
        this.customer.data = customer;
        this.customerForm.errors = null;
        this.handleSuccess();
      });
    } catch (e) {
      const formErrors = { ...e.errors };
      Object.keys(formErrors).forEach(key => {
        formErrors[key] = { message: formErrors[key] };
      });

      runInAction(() => {
        this.customerForm.errors = formErrors;
      });
    } finally {
      runInAction(() => {
        this.customerForm.isLoading = false;
      });
    }

    return null;
  };

  setActiveTab = id => {
    this.customerForm.activeTab = id;
    this.customerForm.errors = {};
    this.customerForm.formNotification = null;
  };

  clearForm = () => {
    this.customerForm = {
      ...DEFAULT_CUSTOMER_FORM,
    };
  };

  handleSuccess = () => {
    this.customerForm.formNotification = {
      type: TYPES.SUCCESS,
      message: i18n.t('Account has been updated.'),
    };
  };

  updateCookies = async allowCookies => {
    if (this.customer.data) {
      try {
        const {
          data: { customer },
        } = await API.put(APIRoutes.SIGN_UP, {
          cookie_performance_analytics: allowCookies,
        });
        runInAction(() => {
          this.customer.data = customer;
        });
      } catch (e) {
        console.warn(e);
      } finally {
        runInAction(() => {});
      }
    }
  };
}

export default new CustomerStore();
