import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { NavLink } from 'react-router-dom';

import ExtraForYouButton from '@assets/images/icons/extra-for-you-button.svg';

export const VARIANTS = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  TERTIARY: 'TERTIARY',
  GREEN: 'GREEN',
  QUATERNARY: 'QUATERNARY',
  QUINARY: 'QUINARY',
};

const PRIMARY = theme => css`
  background: ${theme.buttons.primary.background};
  color: ${theme.buttons.primary.color};

  &:hover {
    background: ${theme.buttons.secondary.background};
    color: ${theme.buttons.secondary.color};
  }
`;

// blue
const SECONDARY = theme => css`
  background: ${theme.buttons.secondary.background};
  color: ${theme.buttons.secondary.color};

  &:hover {
    background: ${theme.colors.quaternary};
  }
`;

const GREEN = theme => css`
  background: ${theme.buttons.green.background};
  color: ${theme.buttons.green.color};

  &:hover {
    background: ${theme.buttons.green.hoverBackground};
  }
`;

const QUINARY = theme => css`
  background: ${theme.buttons.secondary.background};
`;

const VARIANT = {
  [VARIANTS.PRIMARY]: PRIMARY,
  [VARIANTS.SECONDARY]: SECONDARY,
  [VARIANTS.GREEN]: GREEN,
  [VARIANTS.QUINARY]: QUINARY,
};

export const Btn = styled.button`
  border: none;
  font-family: ${({ theme }) => theme.buttons.fontFamily};
  font-size: 13px;
  line-height: 17px;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  background: inherit;
  padding: 10px 20px;
  border-radius: 22.5px;
  cursor: pointer;
  white-space: nowrap;
  display: inline-block;
  position: relative;
  overflow: ${({ loading }) => (loading ? 'hidden' : 'visible')};

  white-space: nowrap;
  text-overflow: ellipsis;

  &:focus {
    outline: none;
  }

  transition: all 0.1s;

  ${({ variant, theme }) => {
    if (variant) {
      return VARIANT[variant](theme);
    }
    return null;
  }}
`;

export const Extra4You = styled(NavLink)`
  background: #00c4d9 url(${ExtraForYouButton}) no-repeat 100% 0;
  display: block;
  margin-right: 2px;
  position: relative;
  height: 42px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 5px;
`;
