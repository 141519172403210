import { makeAutoObservable } from 'mobx';

export class BlockDownloadStore {
  constructor() {
    makeAutoObservable(this);
  }

  isDownloadBlocked = false;

  isFakeAccount = false;

  isGeoBlocking = false;

  isFakeAccountModalOpen = false;

  isGeoBlockingModalOpen = false;

  setDownloadBlocking = (isFakeAccount, isGeoBlocking) => {
    this.isDownloadBlocked = isFakeAccount || isGeoBlocking;
    this.isFakeAccount = isFakeAccount;
    this.isGeoBlocking = isGeoBlocking;
  };

  openBlockedDownloadModal = () => {
    if (this.isGeoBlocking) {
      this.isGeoBlockingModalOpen = true;
    } else if (this.isFakeAccount) {
      this.isFakeAccountModalOpen = true;
    }
  };

  closeFakeAccountModal = () => {
    this.isFakeAccountModalOpen = false;
  };

  closeGeoBlockingModal = () => {
    this.isGeoBlockingModalOpen = false;
  };
}

export default new BlockDownloadStore();
