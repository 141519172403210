import { makeAutoObservable, runInAction } from 'mobx';
import { API, APIRoutes } from '@app/api';

const PER_PAGE = 24;
const PER_PAGE_TRACKS = 10;

export class ComposersWebsiteStore {
  constructor() {
    makeAutoObservable(this);
  }

  composers = {
    isLoading: false,
    data: [],
    meta: {},
    error: null,
  };

  composer = {
    isLoading: false,
    data: [],
    error: null,
  };

  composerTracks = {
    expandedTrack: null,
    expandedAlternatives: false,
    isLoading: false,
    data: [],
    error: null,
    meta: {},
  };

  getComposers = async () => {
    try {
      this.composers.isLoading = true;

      const {
        data: { composer_websites, meta },
      } = await API(APIRoutes.COMPOSER_WEBSITES, {
        params: {
          page: 1,
          per: PER_PAGE,
        },
      });
      runInAction(() => {
        this.composers.data = composer_websites;
        this.composers.meta = meta;
      });
    } catch (error) {
      runInAction(() => {
        this.composers.error = error;
      });
    } finally {
      runInAction(() => {
        this.composers.isLoading = false;
      });
    }
  };

  getMoreComposers = async page => {
    try {
      this.composers.isLoading = true;

      const {
        data: { composer_websites, meta },
      } = await API(APIRoutes.COMPOSER_WEBSITES, {
        params: {
          page,
          per: PER_PAGE,
        },
      });
      runInAction(() => {
        this.composers.data = [...this.composers.data, ...composer_websites];
        this.composers.meta = meta;
      });
    } catch (error) {
      runInAction(() => {
        this.composers.error = error;
      });
    } finally {
      runInAction(() => {
        this.composers.isLoading = false;
      });
    }
  };

  getComposer = async id => {
    try {
      this.composer.isLoading = true;

      const {
        data: { composer_website },
      } = await API(APIRoutes.COMPOSER_WEBSITE(id));
      runInAction(() => {
        this.composer.data = composer_website;
      });
    } catch (error) {
      runInAction(() => {
        this.composer.error = error;
      });
    } finally {
      runInAction(() => {
        this.composer.isLoading = false;
      });
    }
  };

  getComposerTracks = async (id, page = 1) => {
    try {
      this.composerTracks.isLoading = true;

      const {
        data: { tracks, meta },
      } = await API(APIRoutes.COMPOSER_WEBSITE_TRACKS(id), {
        params: {
          page,
          per: PER_PAGE_TRACKS,
        },
      });
      runInAction(() => {
        this.composerTracks.data = tracks;
        this.composerTracks.meta = { ...meta, per: PER_PAGE_TRACKS };
      });
    } catch (error) {
      runInAction(() => {
        this.composerTracks.error = error;
      });
    } finally {
      runInAction(() => {
        this.composerTracks.isLoading = false;
      });
    }
  };

  toggleTrack = trackId => {
    const { expandedTrack } = this.composerTracks;
    this.composerTracks.expandedTrack =
      expandedTrack === trackId ? null : trackId;
    this.composerTracks.expandedAlternatives = false;
  };

  toggleTrackAlternatives = trackId => {
    const { expandedTrack, expandedAlternatives } = this.composerTracks;

    if (expandedTrack === trackId) {
      if (expandedAlternatives) {
        this.composerTracks.expandedAlternatives = false;
      } else {
        this.composerTracks.expandedAlternatives = true;
        this.getTrackAlternatives(trackId);
      }
    } else {
      this.composerTracks.expandedTrack = trackId;
      this.composerTracks.expandedAlternatives = true;
      this.getTrackAlternatives(trackId);
    }
  };

  getTrackAlternatives = async trackId => {
    const track = this.composerTracks.data.find(({ id }) => id === trackId);

    if (!track.alternatives) {
      try {
        track.alternatives = { isLoading: true };

        const {
          data: { tracks },
        } = await API(APIRoutes.TRACK_ALTERNATIVES(trackId));

        const tracksWithAlbum = tracks.map(t => ({ ...t, album: track.album }));

        runInAction(() => {
          track.alternatives.data = tracksWithAlbum;
        });
      } catch (error) {
        runInAction(() => {
          track.alternatives.error = error;
        });
      } finally {
        runInAction(() => {
          track.alternatives.isLoading = false;
        });
      }
    }
  };
}

export default new ComposersWebsiteStore();
