import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import { uniqueId } from 'lodash-es';
import Preloader from '@components/Preloader';
import { MainContent, Header } from '@styles/globalStyles';
import GridList from '@components/GridList';
import Topic from '@components/Topic';

const Topics = ({ topicsStore: { topics, getTopics } }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (topics.data.length === 0) {
      getTopics();
    }
  }, []);

  return (
    <MainContent>
      <Header>{t('Topics')}</Header>
      {topics.isLoading && <Preloader />}
      <GridList>
        {topics.data.map(
          ({ id, name, image, uuid }) =>
            id !== 'all' && (
              <li key={uniqueId()}>
                <Topic id={uuid} name={name} image={image.url} />
              </li>
            ),
        )}
      </GridList>
    </MainContent>
  );
};

const TopicsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
);

Topics.propTypes = {
  topicsStore: PropTypes.shape({
    getTopics: PropTypes.func,
    setCurrentTopic: PropTypes.func,
    topics: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: TopicsPropTypes,
    }),
  }).isRequired,
};

export default inject('topicsStore')(observer(Topics));
