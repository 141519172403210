import styled from '@emotion/styled';

import {
  Header as GlobalHeader,
  Separator as GlobalSeparator,
} from '@styles/globalStyles';
import TextComponent from '@components/Text';
import ImportedGrid from '@components/GridList';
import { breakpoint } from '@styles/breakpoints';

export const Description = styled(TextComponent)`
  font-family: ${({ theme }) => theme.fonts.secondary.regular};
  width: 50%;
  margin-bottom: 40px;

  @media ${breakpoint.md} {
    width: 100%;
    text-align: center;
  }
`;

export const Header = styled(GlobalHeader)`
  margin-bottom: 20px;
`;

export const GridList = styled(ImportedGrid)`
  margin-top: 33px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 64px;

  > li {
    border-radius: 4px;
  }
  > li:nth-of-type(odd) {
    border: 5px solid ${({ theme }) => theme.colors.highlight};
  }
  > li:nth-of-type(even) {
    border: 5px solid ${({ theme }) => theme.colors.quaternary};
  }

  @media ${breakpoint.md} {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const Separator = styled(GlobalSeparator)`
  margin-bottom: 10px;
`;

export const PricelistLink = styled.a`
  margin: 20px 0 20px auto;
  display: inline-block;

  @media ${breakpoint.md} {
    margin: 20px auto;
  }
`;

export const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;

  @media ${breakpoint.md} {
    align-content: center;
    text-align: center;
  }
`;

export const Benefits = styled.ul`
  margin-top: 33px;
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  min-height: 104px;
  background: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.h4};
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadows.primary};

  @media ${breakpoint.md} {
    flex-direction: column;
    height: auto;
  }
`;

export const Benefit = styled.li`
  margin: auto;
  height: 85%;
  padding: 10px 45px;
  border-right: 0.5px solid ${({ theme }) => theme.colors.stroke};
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;

  @media ${breakpoint.md} {
    width: 85%;
    border-right: none;
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.stroke};
  }

  &:last-child {
    border: none;
  }
`;

export const Icon = styled.img`
  height: 30px;
`;
