import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route as RouteRaw } from 'react-router-dom';
import StayTuned from '@components/StayTuned';

const Route = ({ children, ...rest }) => {
  useEffect(() => {
    // scroll to top on route change
    window.scrollTo(0, 0);
  }, [rest.location.pathname]);

  return (
    <RouteRaw
      {...rest}
      render={() => {
        return (
          <>
            {children}
            <StayTuned />
          </>
        );
      }}
    />
  );
};

Route.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Route;
