import { Flex } from 'rebass';
import styled from '@emotion/styled';

export const IndicatorsWrapper = styled(Flex)`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.backgrounds.shadow};);
  border-radius: 4px;
`;

export const StepIndicator = styled.span`
  display: inline-block;
  padding: 8px 12px;
  font-weight: ${({ isPassed, isCurrent }) =>
    isPassed || isCurrent ? '700' : '400'};
  border-bottom: ${({ theme, isCurrent }) =>
    `2px solid ${isCurrent ? theme.colors.highlight : 'transparent'}`};
`;
