import { Flex } from 'rebass';
import styled from '@emotion/styled';
import Text from '@components/Text';

export const StyledTitle = styled(Text)`
  font-family: ${({ theme, isExpanded }) =>
    isExpanded ? theme.fonts.secondary.bold : theme.fonts.secondary.regular};
`;

export const StyledRow = styled(Flex)`
  position: relative;
  cursor: pointer;
  padding: 16px 12px;
  background: ${({ theme, isExpanded }) =>
    isExpanded ? theme.table.headBackground : 'transparent'};
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeVariant};

  &:hover {
    background: ${({ theme }) => theme.table.headBackground};
  }
`;

export const StyledLabel = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.secondary.regular};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
`;
