import React from 'react';
import PropTypes from 'prop-types';
import ahoy from 'ahoy.js';
import i18n from '@utils/i18n';
import Button from '@components/Button';
import { APIRoutes } from '@app/api';
import { inject, observer } from 'mobx-react';

export const TYPES = {
  TRACK: 'TRACK',
  ALBUM: 'ALBUM',
};

const DATA_OF_TYPE = {
  [TYPES.TRACK]: {
    url: APIRoutes.TRACK_CUESHEET,
    ahoyAction: 'download_track_cuesheet',
    label: i18n.t('Track cuesheet'),
  },
  [TYPES.ALBUM]: {
    url: APIRoutes.ALBUM_CUESHEET,
    ahoyAction: 'download_album_cuesheet',
    label: i18n.t('Album cuesheet'),
  },
};

const CuesheetButton = ({
  isAuthenticated,
  albumUUID,
  id,
  type,
  blockDownloadStore: { isDownloadBlocked, openBlockedDownloadModal },
}) => {
  if (isDownloadBlocked) {
    return (
      <Button
        {...{
          label: DATA_OF_TYPE[type].label,
          onClick: openBlockedDownloadModal,
        }}
      />
    );
  }

  const downloadCuesheetProps = isAuthenticated
    ? {
        as: 'a',
        href: `${process.env.REACT_APP_API_URL}${DATA_OF_TYPE[type].url(
          albumUUID,
          id,
        )}`,
        rel: 'noopener noreferrer',
        onClick: () => ahoy.track(DATA_OF_TYPE[type].ahoyAction, { id }),
      }
    : {
        disabled: true,
      };

  return (
    <Button
      {...{
        label: DATA_OF_TYPE[type].label,
        ...downloadCuesheetProps,
      }}
    />
  );
};

CuesheetButton.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  albumUUID: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  type: PropTypes.oneOf(Object.values(TYPES)).isRequired,
  blockDownloadStore: PropTypes.object.isRequired,
};

export default inject('blockDownloadStore')(observer(CuesheetButton));
