import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Box } from 'rebass';
import {
  Form,
  StyledLabel,
  SelectWrapper,
} from '@components/AddToProjectModal/style';
import Text, { COLORS, VARIANTS } from '@components/Text';
import FormButton from '@components/Form/FormButton';
import Input from '@components/Form/TextField';

const AddToNewProjectForm = ({ isLoading, submit, errors }) => {
  const { t } = useTranslation();
  const { handleSubmit, register, watch } = useForm();

  const error = errors.name?.includes(watch('name'));

  return (
    <Form onSubmit={handleSubmit(submit)}>
      <StyledLabel>
        {t('Enter name of new project, and add track to it.')}
      </StyledLabel>
      <Box my={4}>
        <SelectWrapper pb={2}>
          <Input
            register={register}
            name="name"
            placeholder={t('Enter new project name...')}
            required
          />
        </SelectWrapper>
        {error && (
          <Box mt={-3}>
            <Text color={COLORS.WHITE} variant={VARIANTS.SMALL_BODY}>
              {t('Name has been taken')}
            </Text>
          </Box>
        )}
      </Box>
      <FormButton
        type="submit"
        isLoading={isLoading}
        disabled={error}
        label={t('Add track to new project')}
      />
    </Form>
  );
};

AddToNewProjectForm.propTypes = {
  submit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
};

export default AddToNewProjectForm;
