import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';
import { Separator as GlobalSeparator } from '@styles/globalStyles';
import Text from '@components/Text';

export const TopGrid = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  @media (min-width: 1200px) {
    display: grid;
    grid-template-columns: 50% 45%;
    grid-gap: 5%;
  }
`;

export const Separator = styled(GlobalSeparator)`
  margin-bottom: 10px;

  @media ${breakpoint.sm} {
    display: ${({ hideable }) => (hideable ? 'none' : 'block')};
  }
`;

export const SectionHeader = styled(Text)`
  font-weight: 900;
  font-size: ${({ theme }) => theme.fontSizes.nav};
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  @media ${breakpoint.sm} {
    margin-bottom: 40px;
  }
`;
