import Text from '@components/Text';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Separator } from '@styles/globalStyles';

export const Wrapper = styled.div`
  ${({ isCollapsed }) =>
    isCollapsed &&
    css`
      height: 70vh;
      overflow: hidden;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        height: 100px;
        right: 0;
        left: 0;
        bottom: 0;
        background: linear-gradient(
          to bottom,
          rgba(246, 246, 246, 0) 17%,
          #ffffff 82%
        );
      }
    `}
`;

export const PreloaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
`;

export const Cover = styled.img`
  max-width: 100%;
  border-radius: 4px;
`;

export const StyledText = styled(Text)`
  display: block;
  line-height: 2;
  font-weight: 300;
  & > strong {
    font-weight: 400;
  }
`;

export const StyledSeparator = styled(Separator)`
  margin-bottom: 0;
`;

export const Indicator = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 10;
  width: 100%;
  text-align: center;
  img {
    width: 24px;
  }
`;
