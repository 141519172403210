import React from 'react';
import PropTypes from 'prop-types';
import routes from '@app/routes';
import * as Styled from '@components/Topic/style';

const Topic = ({ id, name, image }) => (
  <Styled.Link to={routes.topic(id)}>
    <Styled.Image src={image} alt={name} />
    <Styled.Title>{name}</Styled.Title>
  </Styled.Link>
);

Topic.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default Topic;
