import styled from '@emotion/styled';
import { Flex } from 'rebass';
import { breakpoint } from '@styles/breakpoints';

export const InfoBox = styled(Flex)`
  margin: 55px 45px;
  height: 100%;
  min-height: 110px;
  align-items: center;

  > img {
    height: 92px;
    object-fit: contain;
    margin-right: 30px;

    @media ${breakpoint.md} {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }

  @media ${breakpoint.md} {
    flex-direction: column;
    margin: 35px 20px;
    min-height: unset;
  }
`;

export const Medley = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 50px;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.textLight};
  border-top: 0.5px solid ${({ theme }) => theme.colors.textLight};
`;

export const MedleyRight = styled(Flex)`
  align-items: center;
`;
