import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const SocialIconsWrapper = styled.div`
  border-top: 1px solid white;
  display: flex;
  margin-left: 34px;
  margin-right: 34px;
  flex-direction: column;
  justify-content: space-between;
  @media ${breakpoint.md} {
    border: none;
  }
  ul {
    padding-top: 20px;
    display: inline-flex;
    @media ${breakpoint.md} {
      justify-content: center;
      padding-top: 0;
    }
    li {
      margin-left: 5px;
      margin-right: 5px;
      @media ${breakpoint.md} {
        margin-left: 15px;
        margin-right: 15px;
      }
      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`;

export const SocialIcon = styled.img`
  max-width: 28px;
  height: auto;
`;
