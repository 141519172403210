import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Dialog from '@components/Dialog';
import { CONFIRM_TYPES } from '@root/stores/projectsStore';

const ConfirmDialog = ({
  projectsStore: {
    deleteProject,
    deleteTrackFromProject,
    closeConfirmDialog,
    confirmDialog: { isOpen, message, data, type },
  },
}) => {
  const onAccept =
    type === CONFIRM_TYPES.PROJECT ? deleteProject : deleteTrackFromProject;

  return (
    <Dialog
      {...{
        isOpen,
        message,
        onAccept: () => onAccept(data),
        closeHandler: closeConfirmDialog,
      }}
    />
  );
};

ConfirmDialog.propTypes = {
  projectsStore: PropTypes.shape({
    deleteProject: PropTypes.func,
    deleteTrackFromProject: PropTypes.func,
    closeConfirmDialog: PropTypes.func,
    confirmDialog: PropTypes.shape({
      isOpen: PropTypes.bool,
      message: PropTypes.string,
      data: PropTypes.object,
      type: PropTypes.string,
    }),
  }).isRequired,
};

export default inject('projectsStore')(observer(ConfirmDialog));
