import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Agent from '@components/Agent';
import Tabs from '@components/Tabs';
import { GridList } from '@containers/Agents/style';
import Preloader from '@components/Preloader';
import Header from '@components/Header';
import { MainContent } from '@styles/globalStyles';
import { history } from '@app/history';

export const KINDS = [
  { name: 'Intervox', value: 'intervox' },
  { name: 'Black is Blonde', value: 'black-is-blonde' },
  { name: 'Click Clock', value: 'click-clock' },
];

const Agents = ({ agentsStore: { agents, getAgents } }) => {
  const { t } = useTranslation();
  const [type, setType] = useState(0);
  const { id } = useParams();

  useEffect(() => {
    if (!id) return;
    const activeIndex = KINDS.findIndex(kind => kind.value === id);
    if (activeIndex > -1) setType(activeIndex);
  }, [id]);

  useEffect(() => {
    getAgents();
  }, [getAgents]);

  return (
    <MainContent>
      <Header
        title={t('Agents')}
        nav={
          <Tabs
            tabs={KINDS}
            activeTab={KINDS[type].value}
            setActiveTab={history.push}
          />
        }
        withSeparator
      />
      {agents.isLoading && <Preloader />}
      <GridList>
        {agents.data
          .filter(({ kind }) => kind === KINDS[type].name)
          .map(agent => (
            <li key={uniqueId()}>
              <Agent {...agent} />
            </li>
          ))}
      </GridList>
    </MainContent>
  );
};

Agents.propTypes = {
  agentsStore: PropTypes.shape({
    agents: PropTypes.object.isRequired,
    getAgents: PropTypes.func.isRequired,
  }).isRequired,
};

export default inject('agentsStore')(observer(Agents));
