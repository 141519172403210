class Typewriter {
  constructor({
    element,
    texts = [],
    loop = true,
    interval = 180,
    delay = 2000,
  }) {
    this.texts = texts;
    this.el = element;
    this.textIndex = 0;
    this.interval = interval;
    this.delay = delay;
    this.txt = '';
    this.isDeleting = false;
    this.timeoutRef = null;
    this.loop = loop;
    this.loopPassed = false;
  }

  init = () => {
    if (this.el) {
      this.tick();
    }
  };

  destroy = () => {
    clearInterval(this.timeoutRef);
  };

  tick = () => {
    const fullTxt = this.texts[this.textIndex];

    if (!fullTxt) return;

    if (this.isDeleting) {
      this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
      this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerText = this.txt;

    let delta = this.interval;

    if (this.isDeleting) {
      delta /= 2;
    }

    if (!this.isDeleting && this.txt === fullTxt) {
      delta = this.delay;
      this.isDeleting = true;
      this.el.classList.add('blink');
      if (this.loopPassed) return;
    } else if (this.isDeleting && this.txt === '') {
      this.isDeleting = false;
      this.textIndex += 1;
      if (this.textIndex >= this.texts.length) {
        this.textIndex = 0;
        if (!this.loop) {
          this.loopPassed = true;
        }
      }
      delta = this.interval;
    } else {
      this.el.classList.remove('blink');
    }

    this.timeoutRef = setTimeout(() => {
      this.tick();
    }, delta);
  };
}

export default Typewriter;
