import styled from '@emotion/styled';

export default styled.ul`
  padding: 16px 0 54px;
  overflow: visible;
  width: 100%;
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));

  > li {
    display: inline-block;
  }
`;
