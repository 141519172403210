import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import Checkbox from '@components/Form/Checkbox';
import { Asterix, Error, FieldWrapper } from '@components/Form/style';
import Text, { VARIANTS } from '@components/Text';
import { MARGINS } from '@styles/globalStyles';
import { ErrorWrapper } from '@components/Form/CheckboxGroup/style';

const CheckboxGroup = ({
  name,
  required,
  label,
  errors,
  options,
  register,
  direction,
  mb,
}) => {
  return (
    <FieldWrapper mb={mb}>
      <ErrorWrapper withError={!!errors[name]}>
        {label && (
          <Text variant={VARIANTS.H4_REGULAR} size={13} mb={MARGINS.SMALL}>
            {label} {required && <Asterix>*</Asterix>}
          </Text>
        )}
        <Flex
          flexDirection={direction}
          flexWrap="wrap"
          alignItems="center"
          mt={-3}
        >
          {options.map(({ value, label: optionLabel }, index) => (
            <div key={`${value}-${index}`}>
              <Checkbox
                {...{
                  register,
                  name,
                  value,
                  label: optionLabel,
                  mb: MARGINS.NONE,
                  required,
                  ofGroup: true,
                }}
              />
            </div>
          ))}
        </Flex>
      </ErrorWrapper>
      {errors[name] && <Error>{errors[name].message}</Error>}
    </FieldWrapper>
  );
};

CheckboxGroup.defaultProps = {
  required: false,
  errors: {},
  mb: MARGINS.BIG,
  direction: 'row',
  options: [],
};

CheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  required: PropTypes.bool,
  options: PropTypes.array,
  errors: PropTypes.object,
  mb: PropTypes.oneOf(Object.values(MARGINS)),
  direction: PropTypes.oneOf(['column', 'row']),
};

export default CheckboxGroup;
