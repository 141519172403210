import { makeAutoObservable, runInAction } from 'mobx';
import { API, APIRoutes } from '@app/api';

export class CreditsStore {
  constructor() {
    makeAutoObservable(this);
  }

  credits = {
    isLoading: false,
    data: [],
    error: null,
  };

  getCredits = async () => {
    try {
      this.credits.isLoading = true;

      const {
        data: { credits },
      } = await API(APIRoutes.CREDITS);

      runInAction(() => {
        this.credits.data = credits;
      });
    } catch (error) {
      runInAction(() => {
        this.credits.error = error;
      });
    } finally {
      runInAction(() => {
        this.credits.isLoading = false;
      });
    }
  };
}

export default new CreditsStore();
