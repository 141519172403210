import { makeAutoObservable, runInAction } from 'mobx';
import { APIRoutes, API } from '@app/api';

export class AgentsStore {
  constructor() {
    makeAutoObservable(this);
  }

  agents = {
    isLoading: false,
    data: [],
    error: null,
  };

  getAgents = async () => {
    try {
      this.agents.isLoading = true;

      const {
        data: { agents },
      } = await API(APIRoutes.AGENTS);
      runInAction(() => {
        this.agents.data = agents;
      });
    } catch (error) {
      runInAction(() => {
        this.agents.error = error;
      });
    } finally {
      runInAction(() => {
        this.agents.isLoading = false;
      });
    }
  };
}

export default new AgentsStore();
