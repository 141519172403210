import React, { useEffect } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass';
import { components } from 'react-select';

import TextField from '@components/Form/TextField';
import SectionTitle from '@components/SectionTitle';
import InputGrid from '@containers/LicenseApplication/components/InputGrid';
import {
  DurationIndicator,
  Section,
} from '@containers/LicenseApplication/components/style';
import Select, { components as customComponent } from '@components/Form/Select';
import { API, APIRoutes } from '@app/api';
import DeleteButton from '@components/IconButtons/DeleteButton';
import Button from '@components/Button';
import TrackTitleOptionLabel from './TrackTitleOptionLabel';

/* eslint-disable react/prop-types */
const SingleValue = ({ data, ...props }) => {
  return (
    <components.SingleValue {...props}>
      <TrackTitleOptionLabel {...data} />
    </components.SingleValue>
  );
};

const Option = ({ data, ...props }) => {
  return (
    <components.Option {...props}>
      <TrackTitleOptionLabel {...data} asOption />
    </components.Option>
  );
};
/* eslint-disable */

const TrackDetailsStep = () => {
  const { register, errors, control } = useFormContext();
  const { t } = useTranslation();

  const commonInputProps = {
    register,
    errors,
    required: true,
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'license_application_tracks_attributes',
    keyName: 'key',
  });

  useEffect(() => {
    if (fields.length === 0) {
      append({});
    }
  }, [fields]);

  const loadOptions = inputValue =>
    new Promise(resolve => {
      setTimeout(async () => {
        const {
          data: { tracks },
        } = await API(APIRoutes.LICENSE_TRACK(inputValue));

        const options = tracks?.map(({ id, ...track }) => ({
          value: id,
          ...track,
        }));

        resolve(options || []);
      }, 1000);
    });

  const onRowRemove = index => {
    if (fields.length > 1) {
      remove(index);
    }
  };

  const durationInputProps = {
    ...commonInputProps,
    placeholder: '00',
    type: 'number',
    inputIndicator: () => <DurationIndicator>{t('Sec')}</DurationIndicator>,
    indicatorPadding: 40,
  };

  return (
    <div>
      <Section>
        <SectionTitle>{t('Track details')}</SectionTitle>
        {fields.map((item, index) => {
          const dataKey = `license_application_tracks_attributes[${index}]`;
          return (
            <Flex key={item.key}>
              <InputGrid flex={1} cols={2}>
                <Select
                  {...{
                    control,
                    errors,
                    name: `${dataKey}.track_id`,
                    label: t('Track title'),
                    placeholder: t('Please select one'),
                    isAsync: true,
                    cacheOptions: true,
                    defaultOptions: true,
                    loadOptions,
                    options: [],
                    required: true,
                    defaultValue: item.track_id,
                    components: { ...customComponent, SingleValue, Option },
                  }}
                />
                <InputGrid cols={2}>
                  <TextField
                    {...{
                      ...durationInputProps,
                      name: `${dataKey}.usage_duration`,
                      label: t('Usage duration'),
                      defaultValue: item.usage_duration,
                    }}
                  />
                  <TextField
                    {...{
                      ...durationInputProps,
                      name: `${dataKey}.product_duration`,
                      label: t('Product duration'),
                      defaultValue: item.product_duration,
                    }}
                  />
                </InputGrid>
              </InputGrid>
              <DeleteButton
                callback={() => onRowRemove(index)}
                disabled={fields.length < 2}
              />
            </Flex>
          );
        })}
        <Box>
          <Button label={t('Add row')} onClick={() => append({})} />
        </Box>
      </Section>
    </div>
  );
};

TrackDetailsStep.propTypes = {};

export default TrackDetailsStep;
