import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

export const FieldWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: ${({ theme, mb }) => `${theme.inputs.margins[mb]}`}px;
`;

export const Label = styled.label`
  margin-right: 10px;
  display: block;
  margin-bottom: 5px;
  font-family: ${({ theme }) => theme.fonts.default};

  font-weight: 300;
  line-height: 1.4;
  font-size: 13px;
`;

export const Asterix = styled.span`
  color: red;
`;

export const Error = styled.span`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
  position: absolute;
  bottom: -15px;
  display: block;
  text-align: right;
  width: 100%;
  &:first-letter {
    text-transform: capitalize;
  }
`;

export const InputIndicatorWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const FormLink = styled(Link)`
  font-size: 14px;
  line-height: 1.4;
  font-weight: 300;

  &:hover {
    text-decoration: underline;
  }
`;
