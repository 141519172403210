/* eslint-disable camelcase */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextField, {
  VARIANTS,
  SIZES,
  MARGINS,
} from '@components/Form/TextField';
import * as Styled from '@components/SearchModal/style';
import AdvancedSearchForm from '@components/SearchModal/components/AdvancedForm';
import SuggestionsSection, {
  SUGGESTION_TYPES,
} from '@components/SearchSuggestions/components/SuggestionsSection';

import SearchIcon from '@assets/images/icons/search-icon.svg';
import BurgerMenuIcon from '@assets/images/icons/burger-menu.svg';
import Extra4YouButton from '@components/Button/Extra4You';

const SearchModal = ({
  searchStore: {
    advancedSearch,
    applySearchSuggestion,
    getSearchSuggestions,
    switchToAdvancedSearch,
    suggestions: { data, isLoading },
  },
  isMobile,
}) => {
  const { t } = useTranslation();
  const { register, handleSubmit, errors, setValue } = useForm();

  const timeoutRef = useRef(null);

  useEffect(() => {
    getSearchSuggestions();
  }, []);

  const {
    albums,
    keywords,
    moods,
    recent_searches,
    tracks,
    genres,
    instrumentations,
  } = data;

  const onChangeHandler = e => {
    const { value } = e.target;
    setValue('text', value);
    clearTimeout(timeoutRef?.current);
    timeoutRef.current = setTimeout(() => {
      getSearchSuggestions(value);
    }, 300);
  };

  if (advancedSearch) {
    return <AdvancedSearchForm />;
  }

  return (
    <Styled.Wrapper>
      <form onSubmit={handleSubmit(applySearchSuggestion)}>
        <Styled.Row>
          <Styled.Col width={[1, 1, 2 / 3]} px={[0, 0, 1]}>
            <TextField
              autofocus
              variant={VARIANTS.SEARCH}
              name="text"
              register={register}
              errors={errors}
              placeholder={t('Search for your music here…')}
              size={SIZES.SMALL}
              onChange={onChangeHandler}
              mb={MARGINS.NONE}
              inputIndicator={() => (
                <>
                  <Styled.SearchButton type="submit">
                    <img src={SearchIcon} alt="search" />
                  </Styled.SearchButton>
                </>
              )}
            />
          </Styled.Col>
          {!isMobile ? (
            <Styled.Col width={[1, 1 / 3]} px={1}>
              <Styled.Button type="button" onClick={switchToAdvancedSearch}>
                {t('Advanced search')}
                <img src={BurgerMenuIcon} alt="" />
              </Styled.Button>
            </Styled.Col>
          ) : (
            <Styled.ButtonsRow>
              <Extra4YouButton />
              <Styled.Button type="button" onClick={switchToAdvancedSearch}>
                {t('Advanced search')}
                <img src={BurgerMenuIcon} alt="" />
              </Styled.Button>
            </Styled.ButtonsRow>
          )}
        </Styled.Row>

        <Styled.Row mobileScroll py={[10, 10, 20]}>
          <Styled.Col width={[1, 2 / 3]} px={1}>
            <Styled.Row>
              <SuggestionsSection
                applyFilter={applySearchSuggestion}
                suggestions={keywords || []}
                title={t('Keywords')}
                isLoading={isLoading}
                type={SUGGESTION_TYPES.KEYWORDS}
                width={[(1, 1 / 2)]}
              />
              <SuggestionsSection
                applyFilter={applySearchSuggestion}
                suggestions={moods || []}
                title={t('Moods')}
                isLoading={isLoading}
                type={SUGGESTION_TYPES.MOODS}
                width={[(1, 1 / 2)]}
              />
            </Styled.Row>
            <Styled.Row py={[0, 0, 30]}>
              <SuggestionsSection
                applyFilter={applySearchSuggestion}
                suggestions={genres || []}
                title={t('Genres')}
                isLoading={isLoading}
                type={SUGGESTION_TYPES.GENRES}
                width={[(1, 1 / 2)]}
              />

              <SuggestionsSection
                applyFilter={applySearchSuggestion}
                suggestions={instrumentations || []}
                title={t('Instrumentations')}
                isLoading={isLoading}
                type={SUGGESTION_TYPES.INSTRUMENTATIONS}
                width={[(1, 1 / 2)]}
              />
            </Styled.Row>
            <Styled.Row>
              <SuggestionsSection
                applyFilter={applySearchSuggestion}
                suggestions={albums || []}
                title={t('Albums')}
                isLoading={isLoading}
                type={SUGGESTION_TYPES.ALBUMS}
                width={[(1, 1 / 2)]}
              />
              <SuggestionsSection
                applyFilter={applySearchSuggestion}
                suggestions={tracks || []}
                title={t('Tracks')}
                isLoading={isLoading}
                type={SUGGESTION_TYPES.TRACKS}
                width={[(1, 1 / 2)]}
              />
            </Styled.Row>
          </Styled.Col>

          <SuggestionsSection
            applyFilter={applySearchSuggestion}
            suggestions={recent_searches || []}
            title={t('Recent searches')}
            isLoading={isLoading}
            type={SUGGESTION_TYPES.RECENT_SEARCHES}
            width={[(1, 1 / 3)]}
          />
        </Styled.Row>
      </form>
    </Styled.Wrapper>
  );
};

SearchModal.defaultProps = {
  isMobile: false,
};

SearchModal.propTypes = {
  searchStore: PropTypes.shape({
    advancedSearch: PropTypes.bool,
    applySearchSuggestion: PropTypes.func,
    getSearchSuggestions: PropTypes.func,
    suggestions: PropTypes.shape({
      data: PropTypes.object,
      isLoading: PropTypes.bool,
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    }),
    switchToAdvancedSearch: PropTypes.func,
  }).isRequired,
  isMobile: PropTypes.bool,
};

export default inject('searchStore')(observer(SearchModal));
