import React, { useRef, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import useBreakpoint from '@hooks/useBreakpoint';
import { VARIANTS } from '@components/Text';
import { Separator, SectionHeader, Section } from '@containers/Label/style';
import { Table, ScrollContainer } from '@containers/Label/TracksBox/style';
import { Row, Cell } from '@components/Table/style';
import TrackCell from '@components/TracksTable/components/TrackCell';
import ActionsCell from '@components/TracksTable/components/ActionsCell';
import { PLAYER_CONTEXTS } from '@components/PlayerControlButtons/PlayPause';
import PerfectScrollbar from '@components/Scrollbars/PerfectScrollbar';

const TracksBox = ({ tracks, authStore: { isAuthenticated } }) => {
  const { t } = useTranslation();

  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  const scrollBarRef = useRef(null);

  useEffect(() => {
    if (scrollBarRef.current) {
      scrollBarRef.current.updateScroll();
    }
  }, [tracks.length]);

  if (tracks && tracks.length > 0) {
    return (
      <Section>
        <SectionHeader variant={VARIANTS.NAV_COLLAPSED}>
          {t('Popular tracks')}
        </SectionHeader>
        <Separator />
        <ScrollContainer>
          <PerfectScrollbar ref={scrollBarRef}>
            <Table>
              <tbody>
                {tracks.map(track => (
                  <Row key={`track_${track.id}`}>
                    <Cell>
                      <TrackCell
                        track={{ ...track }}
                        playerContext={PLAYER_CONTEXTS.LABELS}
                      />
                    </Cell>
                    <Cell style={{ minWidth: 100, textAlign: 'right' }}>
                      <ActionsCell
                        {...{ row: track, isAuthenticated, isMobile }}
                      />
                    </Cell>
                  </Row>
                ))}
              </tbody>
            </Table>
          </PerfectScrollbar>
        </ScrollContainer>
      </Section>
    );
  }

  return null;
};

TracksBox.propTypes = {
  tracks: PropTypes.array.isRequired,
  trackStore: PropTypes.object.isRequired,
  authStore: PropTypes.object.isRequired,
};

export default inject('trackStore', 'authStore')(observer(TracksBox));
