import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Text from '@components/Text';
import { VARIANTS } from '@components/Text/style';
import * as Styled from '@components/Agent/style';

const Agent = ({
  partner_name,
  contact_email,
  contact_name,
  country,
  site_url,
  phone_number,
  fax,
  address,
}) => {
  const { t } = useTranslation();
  return (
    <Styled.Wrapper>
      <Text variant={VARIANTS.H4}>{country}</Text>
      <Text>
        <a href={site_url}>{partner_name}</a>
      </Text>
      <Text variant={VARIANTS.SMALL_BODY}>
        <p>{address}</p>
        <Styled.Paragraph>
          <a href={`tel:${phone_number}`}>
            {t('Phone')}: {phone_number}
          </a>
          {fax && fax.length > 0 && (
            <p>
              {t('Fax')}: {fax}
            </p>
          )}
        </Styled.Paragraph>
        <p />
        <p>
          {t('Contact')}: <a href={`mailto:${contact_name}`}>{contact_email}</a>
        </p>
      </Text>
    </Styled.Wrapper>
  );
};

Agent.propTypes = {
  partner_name: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  phone_number: PropTypes.string.isRequired,
  fax: PropTypes.string.isRequired,
  site_url: PropTypes.string.isRequired,
  contact_name: PropTypes.string.isRequired,
  contact_email: PropTypes.string.isRequired,
};

export default Agent;
