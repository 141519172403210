import styled from '@emotion/styled';

export const Text = styled.span`
  text-align: center;
  margin-top: 10px;
  font-size: ${({ theme }) => theme.fontSizes.body};
  font-family: ${({ theme }) => theme.fonts.secondary.regular};
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  overflow: visible;
  padding: 20px;
  max-width: 210px;
  cursor: pointer;

  &:hover {
    ${Text} {
      text-decoration: underline;
    }
  }
`;
