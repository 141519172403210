import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import * as Styled from '@components/News/style';
import Tracks from '@components/News/components/Tracks';
import TrendingBar from '@components/News/components/TrendingBar';
import { Header } from '@styles/globalStyles';

const News = ({ newsStore: { getNews, initialized, news } }) => {
  useEffect(() => {
    const fetch = async () => {
      await getNews();
    };
    if (news.data.length === 0) fetch();
  }, []);

  const { t } = useTranslation();

  if (initialized && news.data.length === 0) return null;
  return (
    <Styled.Wrapper id="in-the-news">
      <Header margin="25px 0">{t('In the news')}</Header>
      <TrendingBar />
      <Tracks />
    </Styled.Wrapper>
  );
};

News.propTypes = {
  newsStore: PropTypes.shape({
    getNews: PropTypes.func,
    initialized: PropTypes.bool,
    news: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.array,
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  }).isRequired,
};

export default inject('newsStore')(observer(News));
