import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';
import Text from '@components/Text';
import { breakpoint } from '@styles/breakpoints';

export const Forms = styled(Flex)`
  @media ${breakpoint.md} {
    flex-direction: column;
  }
`;

export const FormBox = styled(Box)`
  width: 40%;
  margin: 0 auto;
  @media ${breakpoint.md} {
    margin: 20px auto;
    width: 90%;
  }
`;

export const StyledLabel = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.secondary.regular};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  margin-bottom: auto;
`;

export const SelectWrapper = styled(Box)`
  text-align: left;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
`;

export const TrackName = styled.strong`
  font-weight: 700;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
