import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from 'rebass';

import routes from '@app/routes';
import Text, {
  VARIANTS as TEXT_VARIANTS,
  COLORS,
  ALIGNS,
} from '@components/Text';
import { Wrapper, Description } from '@containers/Licenses/ContactTile/style';
import FormButton from '@components/Form/FormButton';

import Card, { Header as CardHeader, VARIANTS } from '@components/Card';

const ContactTile = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Card variant={VARIANTS.GRADIENT}>
        <Box p={3} textAlign="center">
          <CardHeader variant={TEXT_VARIANTS.H2} align={ALIGNS.CENTER}>
            {t('NOT SURE WHICH LICENCE?')}
          </CardHeader>
          <Box>
            <Description>
              <Text color={COLORS.WHITE} variant={TEXT_VARIANTS.NAV_COLLAPSED}>
                {t(
                  'Need some guidance or looking for something specific, get in touch?',
                )}
              </Text>
            </Description>
            <Box textAlign="center" mt={4}>
              <FormButton to={routes.contactUs} label={t('Get in touch')} />
            </Box>
          </Box>
        </Box>
      </Card>
    </Wrapper>
  );
};

export default ContactTile;
