import PropTypes from 'prop-types';

export const trackType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  number: PropTypes.number,
  length: PropTypes.string,
  description: PropTypes.string,

  version: PropTypes.string,
  filename: PropTypes.string,
  external_id: PropTypes.string,
  external_main_id: PropTypes.string,
  alternative_tracks_count: PropTypes.number,
  file: PropTypes.shape({
    url: PropTypes.string,
    waveform_peak_data: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  file_wav: PropTypes.shape({
    url: PropTypes.string,
  }),
  isrc: PropTypes.string,
  iswc: PropTypes.string,
  prs: PropTypes.string,
  bpm: PropTypes.string,
  updated_at: PropTypes.string,
  album_id: PropTypes.number,
  mixout: PropTypes.string,
  section: PropTypes.string,
  directory_with_number: PropTypes.string,
  gema_soundfile_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gema_work_number: PropTypes.string,
  album: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    release_date: PropTypes.string,
    directory_name: PropTypes.string,
    cover: PropTypes.shape({
      url: PropTypes.string,
    }),
    external_id: PropTypes.string,
    medley: PropTypes.shape({
      url: PropTypes.string,

      waveform_peak_data: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    label_code: PropTypes.string,
    ean: PropTypes.string,
    description: PropTypes.string,
    updated_at: PropTypes.string,
    tracks_count: PropTypes.number,
    uuid: PropTypes.string,
    zip: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  composers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      cae_ipi: PropTypes.string,
      uuid: PropTypes.string,
      pro: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  ),
});

export const dependsOnOtherProp = (
  dependencyPropName,
  propType = 'string',
  props,
  propName,
  componentName,
) => {
  let error;
  if (!props[dependencyPropName]) {
    const prop = props[propName];
    if (!prop) {
      error = new Error(
        `${propName} is required for ${componentName}, but it's value is undefined`,
      );
      // eslint-disable-next-line
    } else if (typeof prop !== propType) {
      error = new Error(
        `${componentName}: ${propName} should be ${propType}, but it's ${typeof prop}`,
      );
    }
  }
  return error;
};
