import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Flex } from 'rebass';
import { ExpandedRow, CellBox } from '@components/Table/style';
import { AlternativeTrackRow } from '@components/TracksTable/style';
import TrackCell from '@components/TracksTable/components/TrackCell';
import Preloader from '@components/Preloader';
import Waveform from '@components/Waveform';
import DownloadButton from '@components/IconButtons/DownloadButton';

const ExpandedTrack = ({
  row,
  columns,
  expandedAlternatives,
  isMobile,
  authStore: { isAuthenticated },
  playerStore: { activeTrackId },
  playerContext,
  blockDownloadStore: { isDownloadBlocked, openBlockedDownloadModal },
}) => {
  const { alternatives, file, id } = row;

  const isLoading = alternatives?.isLoading;
  const alternativesData = alternatives?.data || [];

  return (
    <ExpandedRow>
      <td colSpan={columns}>
        <Flex>
          {!isMobile && <CellBox minWidth="20%" />}
          <CellBox flex={1}>
            <Waveform
              waveformDataUrl={file.waveform_peak_data.url}
              trackID={id}
            />
          </CellBox>
          {!isMobile && <CellBox minWidth="24%" />}
        </Flex>
        {expandedAlternatives &&
          (isLoading ? (
            <CellBox width={1}>
              <Preloader center />
            </CellBox>
          ) : (
            alternativesData.map(track => (
              <AlternativeTrackRow
                key={`alternative-${track.version}`}
                alignItems="center"
                isHighlighted={track.id === activeTrackId}
              >
                <CellBox minWidth="20%">
                  <TrackCell
                    track={track}
                    playerContext={playerContext}
                    ofExpanded
                  />
                </CellBox>
                <CellBox flex={1}>
                  <Waveform
                    waveformDataUrl={track.file.waveform_peak_data.url}
                    trackID={track.id}
                  />
                </CellBox>
                <CellBox minWidth="9%">{track.length}</CellBox>
                <CellBox minWidth="15.5%">
                  <DownloadButton
                    {...{
                      id: track.id,
                      isAuthenticated,
                      isDownloadBlocked,
                      openBlockedDownloadModal,
                      isMp3: track.file?.url,
                      isWav: track.file_wav?.url,
                      external_id: track.external_id,
                    }}
                  />
                </CellBox>
              </AlternativeTrackRow>
            ))
          ))}
      </td>
    </ExpandedRow>
  );
};

ExpandedTrack.propTypes = {
  row: PropTypes.object.isRequired,
  columns: PropTypes.number.isRequired,
  expandedAlternatives: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  authStore: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
  }).isRequired,
  playerStore: PropTypes.object.isRequired,
  playerContext: PropTypes.string.isRequired,
  blockDownloadStore: PropTypes.object.isRequired,
};

export default inject(
  'authStore',
  'playerStore',
  'blockDownloadStore',
)(observer(ExpandedTrack));
