import React, { useState, useEffect, useMemo, memo } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import routes from '@routes';
import * as Styled from '@components/SideBar/components/SideBarItemList/style';
import Preloader from '@components/Preloader';
import ArrowUpIcon from '@assets/images/icons/arrow-up.svg';
import NestedList from './NestedList';

const linkTo = {
  topics: routes.topic,
  playlists: routes.playlistsOfCategory,
  labels: routes.label,
  info: routes.info,
};

const SidebarItemList = ({
  isSideBarOpened,
  item: { id, label, isOpen, to, icon, dataKey },
  sidebarStore: { infoListItems, toggleSidebarCategory, toggleSideBar },
  topicsStore: { topics, getTopics },
  playlistsStore: { playlistsCategories, getPlaylistsCategories },
  labelsStore: { labels, getLabels },
}) => {
  const isLoading = {
    topics: topics.isLoading,
    playlists: playlistsCategories.isLoading,
    labels: labels.isLoading,
  };

  const data = useMemo(
    () => ({
      topics: topics.data,
      playlists: playlistsCategories.data,
      labels: labels.groupedData,
      info: infoListItems,
    }),
    [topics.data, playlistsCategories.data, labels.groupedData, infoListItems],
  );

  const fetch = useMemo(
    () => ({
      topics: getTopics,
      playlists: getPlaylistsCategories,
      labels: getLabels,
    }),
    [getTopics, getPlaylistsCategories, getLabels],
  );

  const [displayList, setDisplayList] = useState(false);

  useEffect(() => {
    const fetchListItems = async () => {
      if (isOpen && data[dataKey].length === 0) {
        await fetch[dataKey]();
      }
      setDisplayList(isOpen);
    };

    fetchListItems();
  }, [isOpen]);

  return (
    <>
      <Styled.ListHeaderWrapper
        desktopcollapsed={isSideBarOpened ? 1 : 0}
        expanded={displayList ? 1 : 0}
        as={isSideBarOpened || dataKey === 'info' ? null : NavLink}
        to={isSideBarOpened || dataKey === 'info' ? null : to}
        onClick={() => {
          if (dataKey === 'info' && !isSideBarOpened) toggleSideBar();
          toggleSidebarCategory(id);
        }}
      >
        <Styled.ListHeaderTitleWrapper
          desktopcollapsed={isSideBarOpened ? 1 : 0}
        >
          <Styled.ListHeaderIcon
            src={icon}
            desktopcollapsed={isSideBarOpened ? 1 : 0}
          />
          <Styled.ListHeaderTitle
            isOpen={displayList}
            desktopcollapsed={isSideBarOpened}
          >
            {label}
          </Styled.ListHeaderTitle>
        </Styled.ListHeaderTitleWrapper>
        <Styled.ListHeaderArrow
          isOpen={displayList}
          desktopcollapsed={isSideBarOpened ? 1 : 0}
          src={ArrowUpIcon}
        />
        {isLoading[dataKey] && <Preloader />}
      </Styled.ListHeaderWrapper>

      <Styled.List expanded={displayList} desktopcollapsed={isSideBarOpened}>
        {data[dataKey].map((listItem, key) =>
          listItem.nestedItems ? (
            <NestedList
              title={listItem.name}
              list={listItem.nestedItems}
              key={key}
              linkTo={linkTo}
              dataKey={dataKey}
            />
          ) : (
            <Styled.ListItem key={key}>
              <Styled.ListItemInternalLink
                as={NavLink}
                to={
                  listItem.uuid
                    ? linkTo[dataKey](listItem.uuid)
                    : listItem.link || routes[dataKey]
                }
                exact
                activeClassName="activeLink"
              >
                {listItem.name}
              </Styled.ListItemInternalLink>
            </Styled.ListItem>
          ),
        )}
      </Styled.List>
    </>
  );
};

SidebarItemList.propTypes = {
  sidebarStore: PropTypes.shape({
    infoListItems: PropTypes.arrayOf(PropTypes.object),
    toggleSidebarCategory: PropTypes.func,
    toggleSideBar: PropTypes.func,
  }).isRequired,
  isSideBarOpened: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  topicsStore: PropTypes.shape({
    topics: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.array,
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
    getTopics: PropTypes.func,
  }).isRequired,
  playlistsStore: PropTypes.shape({
    playlistsCategories: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.array,
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
    getPlaylistsCategories: PropTypes.func,
  }).isRequired,
  labelsStore: PropTypes.shape({
    labels: PropTypes.shape({
      isLoading: PropTypes.bool,
      groupedData: PropTypes.array,
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
    getLabels: PropTypes.func,
  }).isRequired,
};

export default memo(
  inject(
    'sidebarStore',
    'topicsStore',
    'playlistsStore',
    'labelsStore',
  )(observer(SidebarItemList)),
);
