import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';
import { Header as DefaultHeader } from '@styles/globalStyles';

import XSymbol from '@assets/images/logos/x-symbol-white.svg';
import Button from '@components/Button';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 100%;

  @media ${breakpoint.lg} {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  padding: 64px;
  margin: 0;
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.secondary.regular};
  font-size: ${({ theme }) => theme.fontSizes.body};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // margin-right: auto;
  z-index: 2;

  @media ${breakpoint.xxl} {
    padding: 50px;
  }

  @media ${breakpoint.md} {
    padding: 36px;
  }
`;

export const Header = styled(DefaultHeader)`
  margin: 0 0 20px;
`;

export const Description = styled.div`
  margin-bottom: 32px;

  @media ${breakpoint.md} {
    margin-bottom: 64px;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    opacity: 0.4;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 165%;
    background-image: url(${XSymbol});
    left: -50%;
    top: 50%;
    transform: translate(10%, -50%);

    @media ${breakpoint.lg} {
      transform: translate(50%, -50%);
    }
  }
`;

export const Image = styled.img`
  opacity: 0.7;
  right: 0;
  margin-left: auto;
  width: 100%;
  min-height: 100%;
  height: 100%;

  object-fit: contain;
`;

export const LinkButton = styled(Button)`
  width: fit-content;
  text-align: center;
`;
