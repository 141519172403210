import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Preloader from '@components/Preloader';
import * as Styled from '@components/News/components/Tracks/style';

import FavoritesButton from '@components/IconButtons/FavoritesButton';
import TracksList from '@components/News/components/Tracks/components/TracksList';
import routes from '@routes';
import ShareButton from '@components/IconButtons/ShareButton';
import PlayPauseButton, {
  PLAYER_CONTEXTS,
} from '@components/PlayerControlButtons/PlayPause';

const Tracks = ({
  newsStore: {
    activeTrack,
    initialized,
    news: { isLoading },
  },
}) => {
  const { t } = useTranslation();

  return (
    <Styled.Wrapper>
      {!initialized || isLoading || !activeTrack ? <Preloader /> : null}
      {activeTrack && (
        <>
          <Styled.AlbumInfo>
            <Styled.ImageWrapper
              to={routes.album(activeTrack.album.uuid, activeTrack.id)}
            >
              <Styled.Image
                alt="album-cover"
                src={activeTrack.album.cover.url}
              />
            </Styled.ImageWrapper>

            <Styled.Title to={routes.album(activeTrack.album.uuid)}>
              {activeTrack.album.name}
            </Styled.Title>
            {activeTrack.composers.length > 0
              ? activeTrack.composers.map((item, index) => (
                  <Styled.AlbumComposerTitle key={`composer_${index}`}>
                    {`${item.first_name} ${item.last_name}`}
                  </Styled.AlbumComposerTitle>
                ))
              : null}
            <Styled.AlbumInfoButtons>
              <PlayPauseButton
                track={activeTrack}
                context={PLAYER_CONTEXTS.NEWS}
              />

              <FavoritesButton albumId={activeTrack.album.id} />
              <ShareButton
                copyText={`${window.location.origin}/albums/${activeTrack.album.uuid}`}
                notification={t("Album's url has been copied to clipboard")}
              />
            </Styled.AlbumInfoButtons>
          </Styled.AlbumInfo>

          <TracksList />
        </>
      )}
    </Styled.Wrapper>
  );
};

Tracks.propTypes = {
  authStore: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
  }).isRequired,
  newsStore: PropTypes.shape({
    activeTrack: PropTypes.object,
    addAlbumToFavorites: PropTypes.func,
    initialized: PropTypes.bool,
    news: PropTypes.shape({ isLoading: PropTypes.bool }),
  }).isRequired,
  playerStore: PropTypes.shape({
    playingTrackId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    loadAndPlayTrack: PropTypes.func,
    playPause: PropTypes.func,
  }).isRequired,
};

export default inject(
  'authStore',
  'newsStore',
  'playerStore',
)(observer(Tracks));
