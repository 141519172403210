import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import { useTranslation } from 'react-i18next';

import { Wrapper, Bar } from '@components/ProgressBar/style';
import Text, { VARIANTS } from '@components/Text';

const ProgressBar = ({ progress, prefix, ...boxProps }) => {
  const { t } = useTranslation();

  return (
    progress > 0 &&
    progress < 100 && (
      <Box {...boxProps}>
        <Wrapper>
          <Bar {...{ progress }} />
        </Wrapper>
        <Box my={2} textAlign="center">
          <Text variant={VARIANTS.H4_REGULAR}>
            {prefix}
            {progress}% {t('complete')}
          </Text>
        </Box>
      </Box>
    )
  );
};

ProgressBar.defaultProps = {
  progress: 0,
  prefix: undefined,
};

ProgressBar.propTypes = {
  progress: PropTypes.number,
  prefix: PropTypes.string,
};

export default ProgressBar;
