import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Header from '@components/Header';
import { MainContent } from '@styles/globalStyles';
import CmsBody from '@components/CmsBody';
import { STORE_TYPES } from '@stores/cmsPageStore';
import i18n from '@utils/i18n';

export const TYPES = {
  dataProtection: {
    name: i18n.t('Data Protection'),
    from: STORE_TYPES.dataProtectionPage,
  },
  impressum: {
    name: i18n.t('Impressum'),
    from: STORE_TYPES.impressumPage,
  },
  agb: {
    name: i18n.t('AGB'),
    from: STORE_TYPES.agbPage,
  },
};

const DataProtection = ({ cmsPageStore, type }) => {
  const { t } = useTranslation();
  useEffect(() => {
    cmsPageStore.getPage(cmsPageStore[type.from]);
  }, [cmsPageStore.getPage, type]);

  return (
    <MainContent>
      <Helmet>
        <title>{t(type.name)}</title>
      </Helmet>
      <Header title={t(type.name)} withSeparator />
      <CmsBody
        html={cmsPageStore[type.from].data.body}
        isLoading={cmsPageStore[type.from].isLoading}
        error={cmsPageStore[type.from].error}
      />
    </MainContent>
  );
};

DataProtection.propTypes = {
  cmsPageStore: PropTypes.object.isRequired,
  type: PropTypes.oneOf(Object.values(TYPES)).isRequired,
};

export default inject('cmsPageStore')(observer(DataProtection));
