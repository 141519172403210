import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import { useTranslation } from 'react-i18next';
/* eslint-disable import/no-cycle */
import { MRO_ATTRIBUTES, PRO_ATTRIBUTES } from '@root/stores/registrationStore';
import TextField from '@components/Form/TextField';
import SectionTitle from '@components/SectionTitle';
import Select from '@components/Form/Select';

const ComposerStep = ({ register, control, errors }) => {
  const { t } = useTranslation();

  return (
    <>
      <SectionTitle>{t('Composer')}</SectionTitle>
      <Box width={1}>
        <TextField
          name="composer_first_name"
          label={t('Composer first name')}
          placeholder={t('First name')}
          register={register}
          errors={errors}
        />
        <TextField
          name="composer_last_name"
          label={t('Composer last name')}
          placeholder={t('Last name')}
          register={register}
          required
          errors={errors}
        />
        <TextField
          name="ipi_no"
          label={t('IPI number')}
          placeholder={t('IPI number')}
          register={register}
          required
          errors={errors}
          type="number"
          disableSpinners
        />
        <Flex mx={-3}>
          <Box width={1} px={3}>
            <Select
              control={control}
              isSearchable
              name="pro"
              required
              label={t('PRO')}
              options={PRO_ATTRIBUTES}
              errors={errors}
            />
          </Box>
          <Box width={1} px={3}>
            <Select
              control={control}
              isSearchable
              name="mro"
              required
              label={t('MRO')}
              options={MRO_ATTRIBUTES}
              errors={errors}
            />
          </Box>
        </Flex>
      </Box>
    </>
  );
};

ComposerStep.defaultProps = {
  errors: {},
};

ComposerStep.propTypes = {
  register: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object,
};

export default ComposerStep;
