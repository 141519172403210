import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Flex } from 'rebass';
import { IconButton, MobileIcon } from '@components/TracksTable/style';
import ShareButton from '@components/IconButtons/ShareButton';
import Tooltip, { TRIGGER } from '@components/Tooltip';
import MobileActionsTooltipBody from '@components/TracksTable/components/MobileActionsTooltipBody';
import DownloadButton from '@components/IconButtons/DownloadButton';

import PlusIcon from '@assets/images/icons/plus-icon.svg';
import InfoIcon from '@assets/images/icons/info-icon.svg';
import ContextMenuIcon from '@components/IconButtons/ContextMenuIcon';

import searchURL from '@utils/searchURL';
import { APIRoutes } from '@app/api';

const ActionsCell = ({
  row,
  isAuthenticated,
  isMobile,
  trackStore: { openTrackModal },
  projectsStore: { openAddToProjectModal },
  blockDownloadStore: { isDownloadBlocked, openBlockedDownloadModal },
}) => {
  const { t } = useTranslation();

  const {
    id,
    name,
    external_id,
    directory_with_number,
    file,
    file_wav,
    album,
  } = row;

  const copyText = searchURL(directory_with_number);
  const copyNotification = t("Track's url has been copied to clipboard");

  const cuesheetUrl = `${
    process.env.REACT_APP_API_URL
  }${APIRoutes.TRACK_CUESHEET(album?.uuid, id)}`;

  return (
    <>
      {isMobile ? (
        <Tooltip
          trigger={TRIGGER.CLICK}
          tooltipBody={
            <MobileActionsTooltipBody
              {...{
                row,
                isAuthenticated,
                isDownloadBlocked,
                openBlockedDownloadModal,
                openAddToProjectModal,
                copyText,
                copyNotification,
                openTrackModal,
              }}
            />
          }
        >
          <MobileIcon>
            <ContextMenuIcon />
          </MobileIcon>
        </Tooltip>
      ) : (
        <Flex alignItems="center" justifyContent="flex-end">
          <DownloadButton
            {...{
              id,
              isAuthenticated,
              isDownloadBlocked,
              openBlockedDownloadModal,
              isMp3: file?.url,
              isWav: file_wav?.url,
              external_id,
              cuesheet: cuesheetUrl,
            }}
          />

          {isAuthenticated && (
            <IconButton onClick={() => openAddToProjectModal({ id, name })}>
              <img src={PlusIcon} alt="plus-icon" />
            </IconButton>
          )}
          <ShareButton copyText={copyText} notification={copyNotification} />
          <IconButton
            onClick={() => {
              openTrackModal(id);
            }}
          >
            <img src={InfoIcon} alt="info-icon" />
          </IconButton>
        </Flex>
      )}
    </>
  );
};

ActionsCell.propTypes = {
  row: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  blockDownloadStore: PropTypes.object.isRequired,
  trackStore: PropTypes.shape({ openTrackModal: PropTypes.func }).isRequired,
  projectsStore: PropTypes.shape({ openAddToProjectModal: PropTypes.func })
    .isRequired,
};

export default inject(
  'trackStore',
  'projectsStore',
  'blockDownloadStore',
)(observer(ActionsCell));
