import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Label } from '@components/Form/style';

export const Checkmark = styled.span`
  position: absolute;
  height: 25px;
  width: 25px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  border: 1px solid #ddd;
  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;

export const StyledInput = styled(Label)`
  position: relative;
  padding-left: 35px;
  padding-top: 5px;

  margin-right: ${({ ofGroup }) => (ofGroup ? '35' : '15')}px;
  margin-top: ${({ ofGroup }) => (ofGroup ? '16px' : '0')};
  cursor: pointer;
  user-select: none;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &:hover input ~ ${Checkmark} {
    background-color: #cedfe3;
  }

  & input:checked ~ ${Checkmark} {
    border-color: #2196f3;
  }

  & input:checked ~ ${Checkmark}:after {
    display: block;
  }

  & ${Checkmark}:after {
    left: 9px;
    top: 4px;
    width: 6px;
    height: 12px;
    border: solid #2196f3;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      filter: grayscale(0.5);

      ${Checkmark} {
        background-color: #eee;
      }
    `}
`;

const ERROR = theme => css`
  border-color: ${theme.colors.error};
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  ${({ withError, theme }) => withError && ERROR(theme)}
`;
