import { makeAutoObservable, runInAction } from 'mobx';

import { API, APIRoutes } from '@app/api';
import downloadFile from '@utils/downloadFile';

const formatContract = (c, offset, idx) => ({
  no: (offset + idx + 1).toString().padStart(3, '0'),
  ...c,
});

const PER_PAGE = 50;

export class LabelsStore {
  constructor() {
    makeAutoObservable(this);
  }

  helloContracts = {
    isLoading: false,
    data: [],
    meta: {},
    error: null,
  };

  getContracts = async (page = 1) => {
    try {
      this.helloContracts.isLoading = true;

      const {
        data: { hello_sign_contracts, meta },
      } = await API(APIRoutes.HELLO_SIGN_CONTRACTS(page, PER_PAGE));

      const totalPages = Math.ceil(meta.total_count / PER_PAGE);

      const offset = (page - 1) * PER_PAGE;

      const formattedContracts = hello_sign_contracts.map((c, idx) =>
        formatContract(c, offset, idx),
      );

      runInAction(() => {
        this.helloContracts.data = formattedContracts;
        this.helloContracts.meta = {
          ...meta,
          totalPages,
        };
      });
    } catch (error) {
      runInAction(() => {
        this.helloContracts.error = error;
      });
    } finally {
      runInAction(() => {
        this.helloContracts.isLoading = false;
      });
    }
  };

  downloadHelloContract = (id, fileName) =>
    downloadFile(APIRoutes.DOWNLOAD_HELLO_CONTRACT(id), fileName, 'pdf');
}

export default new LabelsStore();
