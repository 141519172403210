import { API, APIRoutes } from '@app/api';
import { makeAutoObservable, runInAction } from 'mobx';
import i18n from '@utils/i18n';

export class GenresStore {
  constructor() {
    makeAutoObservable(this);
  }

  genres = {
    isLoading: false,
    data: [],
    error: null,
  };

  getGenres = async () => {
    try {
      this.genres.isLoading = true;

      const {
        data: { genres },
      } = await API(APIRoutes.GENRES);

      runInAction(() => {
        this.genres.data = [
          { id: 'all', name: i18n.t('All genres') },
          ...genres,
        ];
      });
    } catch (error) {
      runInAction(() => {
        this.genres.error = error;
      });
    } finally {
      runInAction(() => {
        this.genres.isLoading = false;
      });
    }
  };
}

export default new GenresStore();
