// import { css } from '@emotion/react';
import styled from '@emotion/styled';

const ARROW_SIZE = 30;

export const TooltipArrow = styled.div`
  height: ${ARROW_SIZE}px;
  position: absolute;
  width: ${ARROW_SIZE}px;
  pointer-events: none;

  &::before {
    border-style: solid;
    content: '';
    display: block;
    height: 0;
    margin: auto;
    width: 0;
  }

  ::after {
    border-style: solid;
    content: '';
    display: block;
    height: 0;
    margin: auto;
    position: absolute;
    width: 0;
  }
`;

export const TooltipBody = styled.div`
  min-width: ${({ minWidth }) => minWidth || '130px'};
  width: 100%;
  height: 100%;
  border-radius: 4px;

  overflow: hidden;
`;

export const TooltipContainer = styled.div`
  --tooltipBackground: #fff;
  --tooltipBorder: rgba(13, 13, 13, 0.12);

  transition: opacity 0.3s;
  z-index: 9999;

  background-color: var(--tooltipBackground);
  border-radius: 4px;

  box-shadow: 0 0 7px 0 rgba(13, 13, 13, 0.18);
  color: #000;

  &[data-popper-interactive='false'] {
    ${TooltipBody} {
      pointer-events: none;
    }
  }

  &[data-popper-placement*='bottom'] {
    ${TooltipArrow} {
      left: 0;
      margin-top: -${0.4 * ARROW_SIZE}px;
      top: 0;

      &::before {
        border-color: transparent transparent var(--tooltipBorder) transparent;
        border-width: 0 ${0.5 * ARROW_SIZE}px ${0.4 * ARROW_SIZE}px
          ${0.5 * ARROW_SIZE}px;
        position: absolute;
        top: -1px;
      }

      &::after {
        border-color: transparent transparent var(--tooltipBackground)
          transparent;
        border-width: 0 ${0.5 * ARROW_SIZE}px ${0.4 * ARROW_SIZE}px
          ${0.5 * ARROW_SIZE}px;
      }
    }
  }

  &[data-popper-placement*='top'] {
    ${TooltipArrow} {
      bottom: 0;
      left: 0;
      margin-bottom: -${ARROW_SIZE}px;

      &::before {
        border-color: var(--tooltipBorder) transparent transparent transparent;
        border-width: ${0.4 * ARROW_SIZE}px ${0.5 * ARROW_SIZE}px 0
          ${0.5 * ARROW_SIZE}px;
        position: absolute;
        top: 1px;
      }

      &::after {
        border-color: var(--tooltipBackground) transparent transparent
          transparent;
        border-width: ${0.4 * ARROW_SIZE}px ${0.5 * ARROW_SIZE}px 0
          ${0.5 * ARROW_SIZE}px;
      }
    }
  }

  &[data-popper-placement*='right'] {
    ${TooltipArrow} {
      left: 0;
      margin-left: -${0.7 * ARROW_SIZE}px;

      &::before {
        border-color: transparent var(--tooltipBorder) transparent transparent;
        border-width: ${0.5 * ARROW_SIZE}px ${0.4 * ARROW_SIZE}px
          ${0.5 * ARROW_SIZE}px 0;
      }

      &::after {
        border-color: transparent var(--tooltipBackground) transparent
          transparent;
        border-width: ${0.5 * ARROW_SIZE}px ${0.4 * ARROW_SIZE}px
          ${0.5 * ARROW_SIZE}px 0;
        left: 6px;
        top: 0;
      }
    }
  }

  &[data-popper-placement*='left'] {
    ${TooltipArrow} {
      margin-right: -${0.7 * ARROW_SIZE}px;
      right: 0;

      &::before {
        border-color: transparent transparent transparent var(--tooltipBorder);
        border-width: ${0.5 * ARROW_SIZE}px 0 ${0.5 * ARROW_SIZE}px
          ${0.4 * ARROW_SIZE}px;
      }

      &::after {
        border-color: transparent transparent transparent
          var(--tooltipBackground);
        border-width: ${0.5 * ARROW_SIZE}px 0 ${0.5 * ARROW_SIZE}px
          ${0.4 * ARROW_SIZE}px;
        left: 6px;
        top: 0;
      }
    }
  }
`;
