import React from 'react';
import PropTypes from 'prop-types';

import DropdownItem from '@components/AccountDropdownBody/components/DropdownItem';
import { useTranslation } from 'react-i18next';

import routes from '@routes';

const AccountDropdownBody = ({
  isComposer,
  isProducer,
  logout,
  isAuthenticated,
}) => {
  const { t } = useTranslation();

  return isAuthenticated ? (
    <>
      <DropdownItem to={routes.account}>{t('My account')}</DropdownItem>
      <DropdownItem to={routes.myAlbums}>{t('My albums')}</DropdownItem>
      <DropdownItem to={routes.myProjects}>{t('My projects')}</DropdownItem>
      {isComposer || isProducer ? (
        <>
          <DropdownItem to={routes.statements}>
            {t('My statements')}
          </DropdownItem>
          <DropdownItem to={routes.briefings}>{t('Briefings')}</DropdownItem>
          <DropdownItem to={routes.myContracts}>{t('Contracts')}</DropdownItem>
        </>
      ) : (
        <DropdownItem to={routes.myDownloads}>{t('My downloads')}</DropdownItem>
      )}
      <DropdownItem as="button" divider={false} onClick={logout}>
        {t('Logout')}
      </DropdownItem>
    </>
  ) : (
    <>
      <DropdownItem to={routes.auth.signIn}>{t('Sign in')}</DropdownItem>
      <DropdownItem to={routes.auth.signUp}>{t('Sign up')}</DropdownItem>
    </>
  );
};

AccountDropdownBody.propTypes = {
  isComposer: PropTypes.bool.isRequired,
  isProducer: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
};

export default AccountDropdownBody;
