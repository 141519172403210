import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { MainContent } from '@styles/globalStyles';
import Preloader from '@components/Preloader';

import { PLAYER_CONTEXTS } from '@components/PlayerControlButtons/PlayPause';
import ListingHeaderWrapper from '@components/ListingHeaderWrapper';
import PlaylistHeader from '@containers/PlaylistListing/components/PlaylistHeader';
import TracksTable from '@components/TracksTable';
import NotFound from '@containers/NotFound';

const PlaylistListing = ({
  playlistsStore: {
    getPlaylistDetails,
    getPlaylistTracks,
    toggleTrack,
    toggleTrackAlternatives,
    playlistDetails: { data, isLoading, error },
    playlistTracks: {
      data: tracksData,
      isLoading: isTracksLoading,
      expandedTrack,
      expandedAlternatives,
      error: tracksError,
    },
  },
  authStore: { isAuthenticated },
}) => {
  const { id: playlistUuid } = useParams();

  useEffect(() => {
    getPlaylistDetails(playlistUuid);
    getPlaylistTracks(playlistUuid);
  }, [getPlaylistDetails, getPlaylistTracks, playlistUuid]);

  const { image, description, name, id } = data;

  if (error || tracksError) {
    return <NotFound />;
  }
  return (
    <MainContent>
      {isLoading ? (
        <Preloader center />
      ) : (
        <>
          <ListingHeaderWrapper>
            <PlaylistHeader
              {...{
                coverUrl: image?.url,
                name,
                description,
                id,
                isAuthenticated,
                playlistUuid,
                track: tracksData && tracksData.length ? tracksData[0] : null,
              }}
            />
          </ListingHeaderWrapper>
          {isTracksLoading ? (
            <Preloader center />
          ) : (
            <TracksTable
              {...{
                data: tracksData,
                toggleTrackAlternatives,
                toggleTrack,
                expandedTrack,
                expandedAlternatives,
                isAuthenticated,
                showAlbumCovers: true,
                playerContext: PLAYER_CONTEXTS.PLAYLISTS,
              }}
            />
          )}
        </>
      )}
    </MainContent>
  );
};

PlaylistListing.propTypes = {
  authStore: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
  }).isRequired,
  playlistsStore: PropTypes.shape({
    getPlaylistDetails: PropTypes.func,
    getPlaylistTracks: PropTypes.func,
    toggleTrack: PropTypes.func,
    toggleTrackAlternatives: PropTypes.func,
    playlistDetails: PropTypes.shape({
      data: PropTypes.object,
      isLoading: PropTypes.bool,
      error: PropTypes.any,
    }),
    playlistTracks: PropTypes.shape({
      data: MobxPropTypes.observableArray,
      isLoading: PropTypes.bool,
      expandedTrack: PropTypes.number,
      expandedAlternatives: PropTypes.bool,
      error: PropTypes.any,
    }),
  }).isRequired,
};

export default inject('playlistsStore', 'authStore')(observer(PlaylistListing));
