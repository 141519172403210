import { makeAutoObservable, runInAction } from 'mobx';
import { API, APIRoutes } from '@app/api';

export class TracksStore {
  constructor() {
    makeAutoObservable(this);
  }

  releasedTracks = {
    data: [],
    isLoading: false,
    error: null,
    pagesAmount: 0,
    expandedTrack: null,
    expandedAlternatives: false,
  };

  getReleasedTracks = async ({ page = 1, per = 12, kind }) => {
    this.releasedTracks.isLoading = true;
    try {
      const {
        data: {
          tracks,
          meta: { total_count },
        },
      } = await API(APIRoutes.RELEASED_TRACKS(page, per, kind));
      runInAction(() => {
        this.releasedTracks.pagesAmount = Math.ceil(total_count / per);
        this.releasedTracks.data = tracks;
      });
    } catch (error) {
      runInAction(() => {
        this.releasedTracks.error = error;
      });
    } finally {
      runInAction(() => {
        this.releasedTracks.isLoading = false;
      });
    }
  };

  toggleTrack = trackId => {
    const { expandedTrack } = this.releasedTracks;
    this.releasedTracks.expandedTrack =
      expandedTrack === trackId ? null : trackId;
  };

  toggleTrackAlternatives = trackId => {
    const { expandedTrack, expandedAlternatives } = this.releasedTracks;

    if (expandedTrack === trackId) {
      if (expandedAlternatives) {
        this.releasedTracks.expandedAlternatives = false;
      } else {
        this.releasedTracks.expandedAlternatives = true;
        this.getTrackAlternatives(trackId);
      }
    } else {
      this.releasedTracks.expandedTrack = trackId;
      this.releasedTracks.expandedAlternatives = true;
      this.getTrackAlternatives(trackId);
    }
  };

  getTrackAlternatives = async trackId => {
    const track = this.releasedTracks.data.find(({ id }) => id === trackId);

    if (!track.alternatives) {
      try {
        track.alternatives = { isLoading: true };

        const {
          data: { tracks },
        } = await API(APIRoutes.TRACK_ALTERNATIVES(trackId));

        const tracksWithAlbum = tracks.map(t => ({ ...t, album: track.album }));

        runInAction(() => {
          track.alternatives.data = tracksWithAlbum;
        });
      } catch (error) {
        runInAction(() => {
          track.alternatives.error = error;
        });
      } finally {
        runInAction(() => {
          track.alternatives.isLoading = false;
        });
      }
    }
  };
}

export default new TracksStore();
