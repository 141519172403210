import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TextField from '@components/Form/TextField';
import SectionTitle from '@components/SectionTitle';
import Text, { MARGINS, VARIANTS } from '@components/Text';
import InputGrid from '@containers/LicenseApplication/components/InputGrid';
import { Section } from '@containers/LicenseApplication/components/style';
import Radio from '@components/Form/Radio';
import { APPLICATION_KINDS } from '@containers/LicenseApplication/constants';

const ClientDetailsStep = () => {
  const { register, errors } = useFormContext();
  const { t } = useTranslation();

  const commonInputProps = {
    register,
    errors,
    required: true,
  };

  return (
    <div>
      <Section>
        <SectionTitle>{t('License type, please select')}</SectionTitle>

        <Text variant={VARIANTS.H4_REGULAR} mb={MARGINS.SMALL}>
          {t('Please select one')}
        </Text>
        <Radio
          {...{
            ...commonInputProps,
            name: 'kind',
            options: APPLICATION_KINDS,
          }}
        />
      </Section>
      <Section>
        <SectionTitle>{t('Email Address')}</SectionTitle>
        <InputGrid>
          <TextField
            {...{
              ...commonInputProps,
              name: 'email',
              label: t('Email Address'),
              placeholder: t('Your email address'),
            }}
          />
        </InputGrid>
      </Section>
      <Section>
        <SectionTitle>{t('Producer')}</SectionTitle>
        <InputGrid mb={3}>
          <TextField
            {...{
              ...commonInputProps,
              name: 'producer',
              label: t('Producer'),
              placeholder: t('Name of producer'),
            }}
          />
          <TextField
            {...{
              ...commonInputProps,
              name: 'producer_contact_person',
              label: t('Contact person (producer)'),
              placeholder: t('Full name'),
            }}
          />
        </InputGrid>
        <InputGrid>
          <TextField
            {...{
              ...commonInputProps,
              name: 'producer_address_1',
              label: t('Producer Address Line 01'),
              placeholder: t('Address line 01'),
            }}
          />
          <TextField
            {...{
              ...commonInputProps,
              name: 'producer_address_2',
              label: t('Producer Address Line 02'),
              placeholder: t('Address line 02'),
            }}
          />
          <TextField
            {...{
              ...commonInputProps,
              name: 'producer_address_3',
              label: t('Producer Address Line 03'),
              placeholder: t('Address line 03'),
              required: false,
            }}
          />
        </InputGrid>
        <InputGrid>
          <TextField
            {...{
              ...commonInputProps,
              name: 'producer_country',
              label: t('Country'),
              placeholder: t('Country'),
            }}
          />
          <TextField
            {...{
              ...commonInputProps,
              name: 'producer_post_code',
              label: t('Postcode'),
              placeholder: t('Postcode'),
            }}
          />
        </InputGrid>
      </Section>
      <Section>
        <SectionTitle>{t('License-header')}</SectionTitle>
        <InputGrid mb={3}>
          <TextField
            {...{
              ...commonInputProps,
              name: 'license_name',
              label: t('License-name'),
              placeholder: t('License-placeholder'),
            }}
          />
          <TextField
            {...{
              ...commonInputProps,
              name: 'license_contact_person',
              label: t('Contact person (license)'),
              placeholder: t('Full name'),
            }}
          />
        </InputGrid>
        <InputGrid>
          <TextField
            {...{
              ...commonInputProps,
              name: 'license_address_1',
              label: t('License Address Line 01'),
              placeholder: t('Address line 01'),
            }}
          />
          <TextField
            {...{
              ...commonInputProps,
              name: 'license_address_2',
              label: t('License Address Line 02'),
              placeholder: t('Address line 02'),
            }}
          />
          <TextField
            {...{
              ...commonInputProps,
              name: 'license_address_3',
              label: t('License Address Line 03'),
              placeholder: t('Address line 03'),
              required: false,
            }}
          />
        </InputGrid>
        <InputGrid>
          <TextField
            {...{
              ...commonInputProps,
              name: 'license_country',
              label: t('Country'),
              placeholder: t('Country'),
            }}
          />
          <TextField
            {...{
              ...commonInputProps,
              name: 'license_post_code',
              label: t('Postcode'),
              placeholder: t('Postcode'),
            }}
          />
        </InputGrid>
        <InputGrid mt={3}>
          <TextField
            {...{
              ...commonInputProps,
              name: 'gema_invoice_to_email',
              label: t('Send Synch/GEMA’s invoice to'),
              placeholder: t('Email addresss'),
            }}
          />
        </InputGrid>
      </Section>
    </div>
  );
};

ClientDetailsStep.propTypes = {};

export default ClientDetailsStep;
