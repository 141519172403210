import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const LinksWrapper = styled.div`
  border-top: 1px solid white;
  display: flex;
  min-width: 258px;
  justify-content: space-between;
  margin: 0 34px;
  @media ${breakpoint.md} {
    border: none;
    display: block;
    margin: 50px 0;
  }
  ul {
    padding-top: 20px;
    padding-right: 34px;
    @media ${breakpoint.md} {
      border-top: 1px solid white;
      padding-top: 10px;
      margin-bottom: 50px;
    }
  }
  li {
    margin-bottom: 8px;
    &:last-of-type {
      margin-bottom: 0;
    }
    a {
      line-height: 15px;
    }
  }
`;

export const SearchButton = styled.button`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 300;
`;
