import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Box } from 'rebass';

export const TYPES = {
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
};

const ERROR = theme => css`
  border: 1px solid ${theme.colors.error};

  & > span {
    color: ${theme.colors.error};
  }
`;

const SUCCESS = theme => css`
  border: 1px solid ${theme.colors.success};

  & > span {
    color: ${theme.colors.success};
  }
`;

const TYPE = {
  [TYPES.ERROR]: ERROR,
  [TYPES.SUCCESS]: SUCCESS,
};

export const Wrapper = styled(Box)`
  border-radius: 4px;

  ${({ type, theme }) => {
    if (type) {
      return TYPE[type](theme);
    }
    return null;
  }}
`;
