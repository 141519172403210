import React from 'react';
import { Link } from 'react-router-dom';
import { Btn, VARIANTS } from '@components/Button/style';
import PropTypes from 'prop-types';
import Preloader from '@components/Preloader';

export { VARIANTS } from '@components/Button/style';

const Button = ({ label, type, to, loading, ...props }) => {
  const btnProps = {
    ...props,
    ...(to
      ? {
          as: Link,
          to,
        }
      : {
          type,
        }),
  };

  return (
    <Btn loading={loading.toString()} {...btnProps}>
      {loading && <Preloader center cover />}
      {label}
    </Btn>
  );
};

Button.defaultProps = {
  variant: VARIANTS.PRIMARY,
  type: 'button',
  onClick: () => {},
  to: '',
  loading: false,
};

Button.propTypes = {
  label: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  variant: PropTypes.oneOf(Object.keys(VARIANTS)),
  onClick: PropTypes.func,
  to: PropTypes.string,
  loading: PropTypes.bool,
};

export default Button;
