import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import Text, { VARIANTS } from '@components/Text';
import { Separator } from '@styles/globalStyles';

const SectionTitle = ({ children, ...boxProps }) => {
  return (
    <Box width={1} {...boxProps}>
      <Text variant={VARIANTS.H4}>{children}</Text>
      <Separator />
    </Box>
  );
};

SectionTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SectionTitle;
