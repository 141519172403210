import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';

import useBreakpoint from '@hooks/useBreakpoint';

import i18n from '@utils/i18n';
import Table from '@components/Table';
import Button from '@components/Button';
import downloadFile from '@utils/downloadFile';
import { APIRoutes } from '@app/api';
import DownloadContractButton from '@containers/Contracts/components/DownloadContractButton';
import ItemsNotFound from '@components/ItemsNotFound';
import Paginator from '@components/Paginator';

/* eslint-disable react/prop-types */
const COLUMNS = isMobile => [
  {
    title: i18n.t('No.'),
    field: 'no',
    render: ({ cell }) => <span>{cell}</span>,
  },
  {
    title: i18n.t('Contract filename'),
    field: 'file_name',
    style: { width: '40%' },
  },
  ...(isMobile
    ? []
    : [
        {
          title: i18n.t('Uploader Name'),
          field: 'uploader_name',
        },
        {
          title: i18n.t('Created At'),
          field: 'created_at',
        },
      ]),
  {
    title: i18n.t('Download'),
    field: 'file',
    render: ({ cell, row }) => (
      <DownloadContractButton file={cell} fileName={row.file_name} />
    ),
    style: { textAlign: isMobile ? 'right' : 'center' },
  },
];
/* eslint-enable react/prop-types */

const FILE_NAME = 'Contracts';
const FILE_TYPES = {
  PDF: 'pdf',
  CSV: 'csv',
};

const MyContracts = ({
  contractsStore: {
    contracts: { data, isLoading, meta },
    getContracts,
  },
}) => {
  const { t } = useTranslation();
  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();

  let isMobile = false;

  if (matchBreakpoint(BREAKPOINTS.MD)) {
    isMobile = true;
  }

  useEffect(() => {
    getContracts();
  }, []);

  const paginate = async page => {
    await getContracts(page);
    window.scrollTo(0, 0);
  };

  return (
    <div>
      {data.length > 0 ? (
        <>
          {!isMobile && (
            <Flex justifyContent="flex-end" mb={4} mx={-2}>
              <Box mx={2}>
                <Button
                  label={t('Download PDF')}
                  onClick={() =>
                    downloadFile(
                      APIRoutes.DOWNLOAD_CONTRACTS_DATA(FILE_TYPES.PDF),
                      FILE_NAME,
                      FILE_TYPES.PDF,
                    )
                  }
                />
              </Box>
              <Box mx={2}>
                <Button
                  label={t('Download CSV')}
                  onClick={() =>
                    downloadFile(
                      APIRoutes.DOWNLOAD_CONTRACTS_DATA(FILE_TYPES.CSV),
                      FILE_NAME,
                      FILE_TYPES.CSV,
                    )
                  }
                />
              </Box>
            </Flex>
          )}
          <Table data={data} columns={COLUMNS(isMobile)} />
        </>
      ) : (
        !isLoading && <ItemsNotFound name={t('contracts')} />
      )}
      <Paginator length={meta.totalPages} onPageChange={paginate} />
    </div>
  );
};

MyContracts.propTypes = {
  contractsStore: PropTypes.shape({
    contracts: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.array,
      meta: PropTypes.object,
    }),
    getContracts: PropTypes.func,
  }).isRequired,
};

export default inject('authStore', 'contractsStore')(observer(MyContracts));
