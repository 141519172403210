import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.tabs.background};
  width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  padding: 7px;
  box-shadow: ${({ theme }) => theme.backgrounds.shadow};
`;

export const TabsRow = styled.div`
  margin: 0 -4px;
  display: flex;
  overflow: auto;

  @media ${breakpoint.md} {
    width: 100%;
  }
`;

export const Tab = styled.button`
  display: block;
  text-align: center;
  font-size: 12px;
  white-space: nowrap;
  padding: 12px 10px;
  min-width: 130px;
  @media ${breakpoint.md} {
    min-width: 100px;
    width: 100%;
  }
  border-radius: 4px;
  margin: 0 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  background-color: ${({ active, theme }) =>
    active ? theme.tabs.tabActiveBackground : theme.tabs.tabBackground};

  &:hover {
    background-color: ${({ active, theme }) =>
      active ? theme.tabs.tabActiveBackground : theme.tabs.tabHoverBackground};
  }
`;
