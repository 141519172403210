import React, { useState } from 'react';
import Select from 'react-select';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { locationsForDropdown } from '@utils/i18n/locations';
import { languageSelectorStyles } from '@styles/globalStyles';

import ArrowDownIcon from '@assets/images/icons/arrow-down.svg';
import ArrowDownIconWhite from '@assets/images/icons/arrow-down-white.svg';

const LanguageSwitcher = ({ isFooter }) => {
  const [currentLocation] = useState(
    locationsForDropdown.find(l => l.id === i18n.language),
  );

  const changeLanguageHandler = ({ value }) => {
    window.location.href = value;
  };

  const theme = useTheme();

  return (
    <Select
      defaultValue={currentLocation}
      options={locationsForDropdown}
      onChange={changeLanguageHandler}
      styles={languageSelectorStyles(theme)}
      isSearchable={false}
      border={
        isFooter
          ? theme.buttons.clean.borderVartiant
          : theme.buttons.clean.border
      }
      marginRight={isFooter ? '10px' : 0}
      components={{
        DropdownIndicator: () => (
          <img src={isFooter ? ArrowDownIconWhite : ArrowDownIcon} alt="" />
        ),
      }}
    />
  );
};

LanguageSwitcher.defaultProps = {
  isFooter: false,
};
LanguageSwitcher.propTypes = {
  isFooter: PropTypes.bool,
};

export default LanguageSwitcher;
