import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { trackType } from '@utils/propTypes';
import { Button, STYLE } from '@components/PlayerControlButtons/style';

export { STYLE } from '@components/PlayerControlButtons/style';

export const PLAYER_CONTEXTS = {
  NEWS: 'newsStore',
  ALBUMS: 'albumsStore',
  PLAYLISTS: 'playlistsStore',
  SEARCH: 'searchStore',
  LABELS: 'labelsStore',
  PROJECTS: 'projectsStore',
  UPLOADED: 'uploadedTracksStore',
  RELEASED: 'releasedTracksStore',
  DOWNLOADS: 'downloadsStore',
  COMPOSERS: 'composersWebsiteStore',
};

const PlayPauseButton = ({
  track,
  context,
  outlined,
  expandOnPlay,
  style,
  playerStore: {
    loadAndPlayTrack,
    playingTrackId,
    activeTrackId,
    playPause,
    playingPlaylistId,
    isPlaying,
  },
  playlistId,
  className,
}) => {
  const isCurrentlyPlaying =
    (playingTrackId === track.id && isPlaying) ||
    (playlistId && playingPlaylistId === playlistId && isPlaying);

  return (
    <Button
      kind={style}
      type="button"
      onClick={() => {
        if (isCurrentlyPlaying || activeTrackId === track.id) {
          playPause();
        } else {
          setTimeout(() => {
            loadAndPlayTrack({
              context,
              track,
              playlistId,
            });
          }, 1);
          expandOnPlay(track.id);
        }
      }}
      outlined={outlined && activeTrackId !== track.id}
      className={className}
    >
      {isCurrentlyPlaying ? (
        <svg
          className="pause"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <g fill="none" fillRule="evenodd">
            <g fill="#049DBF">
              <g>
                <g transform="translate(-365 -619) translate(306 398) translate(59 221)">
                  <rect width="3" height="12" x="7" y="6" rx="1" />
                  <rect width="3" height="12" x="14" y="6" rx="1" />
                </g>
              </g>
            </g>
          </g>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className="play-button"
        >
          <g fill="none" fillRule="evenodd">
            <path
              // eslint-disable-next-line max-len
              d="M5 17.399V6.635c0-.552.448-1 1-1 .158 0 .314.038.455.11l10.764 5.507c.492.251.687.854.435 1.345-.098.193-.257.349-.451.444L6.439 18.297c-.496.243-1.095.037-1.338-.46C5.035 17.702 5 17.552 5 17.4z"
              transform="translate(-420 -864) translate(80 599) translate(340 265)"
            />
          </g>
        </svg>
      )}
    </Button>
  );
};

PlayPauseButton.defaultProps = {
  outlined: false,
  expandOnPlay: () => {},
  style: STYLE.DEFAULT,
  playlistId: null,
  className: undefined,
};

PlayPauseButton.propTypes = {
  context: PropTypes.oneOf(Object.values(PLAYER_CONTEXTS)).isRequired,
  track: trackType.isRequired,
  playerStore: PropTypes.shape({
    loadAndPlayTrack: PropTypes.func,
    playPause: PropTypes.func,
    playingTrackId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    activeTrackId: PropTypes.number,
    playingPlaylistId: PropTypes.number,
    isPlaying: PropTypes.bool,
  }).isRequired,
  outlined: PropTypes.bool,
  expandOnPlay: PropTypes.func,
  style: PropTypes.oneOf(Object.values(STYLE)),
  playlistId: PropTypes.number,
  className: PropTypes.string,
};

export default inject('playerStore')(observer(PlayPauseButton));
