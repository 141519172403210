import React, { useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import * as Styled from '@components/Table/style';

const Row = ({
  isClickable,
  isExpanded,
  focus,
  highlight,
  onClick,
  children,
}) => {
  const ref = useRef();

  useLayoutEffect(() => {
    if (ref?.current && focus) {
      ref.current.scrollIntoView({
        block: 'center',
      });
    }
  }, [ref, focus]);

  return (
    <Styled.Row {...{ ref, isClickable, isExpanded, highlight, onClick }}>
      {children}
    </Styled.Row>
  );
};

Row.defaultProps = {
  isClickable: false,
  isExpanded: false,
  focus: false,
  highlight: Styled.HIGHLIGHTS.NONE,
  onClick: undefined,
};

Row.propTypes = {
  isClickable: PropTypes.bool,
  isExpanded: PropTypes.bool,
  highlight: PropTypes.oneOf(Object.values(Styled.HIGHLIGHTS)),
  focus: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default Row;
