import { makeAutoObservable, runInAction } from 'mobx';
import { API, APIRoutes } from '@app/api';

export class FavoritesStore {
  constructor() {
    makeAutoObservable(this);
  }

  favoritesList = {
    isLoading: false,
    data: [],
    error: null,
  };

  getFavorites = async () => {
    try {
      this.favoritesList.isLoading = true;

      const {
        data: { albums },
      } = await API(APIRoutes.FAVORITE_ALBUMS);
      runInAction(() => {
        this.favoritesList.data = [...albums];
      });
    } catch (error) {
      runInAction(() => {
        this.favoritesList.error = error;
      });
    } finally {
      runInAction(() => {
        this.favoritesList.isLoading = false;
      });
    }
  };

  toggleFavorites = async albumId => {
    try {
      await API.get(APIRoutes.ADD_TO_FAVORITES(albumId));

      let favoritesList = [...this.favoritesList.data];
      runInAction(() => {
        if (favoritesList.findIndex(({ id }) => id === albumId) === -1) {
          this.getFavorites();
        } else {
          favoritesList = favoritesList.filter(({ id }) => id !== albumId);
        }

        this.favoritesList.data = favoritesList;
      });
      return favoritesList;
    } catch (error) {
      return error;
    }
  };

  checkIsFavorite = albumId =>
    this.favoritesList.data.findIndex(({ id }) => id === albumId) > -1;
}

export default new FavoritesStore();
