import styled from '@emotion/styled';

export const Wrapper = styled.div`
  padding: 4px;
  overflow: hidden;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #ddd;
  line-height: 1.4;
  font-size: 14px;
  box-shadow: 0 0 7px 0 rgba(13, 13, 13, 0.18);
  background-color: #fff;
  margin-top: 16px;

  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
`;

export const SearchButton = styled.button`
  display: inline-flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 12px;
`;

export const MenuButton = styled.button`
  display: inline-flex;
  width: 36px;
  height: 36px;
`;

export const Keywords = styled.div`
  padding: 0 4px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
`;

export const Placeholder = styled.div`
  padding: 0 4px;
  margin-left: 8px;
`;

export const Buttons = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
`;

export const ClickableArea = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 0;
`;
