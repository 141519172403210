import { toast } from 'react-toastify';
import i18n from '@utils/i18n';

export default async copyText => {
  try {
    await navigator.share({
      title: document.title,
      text: copyText,
      url: copyText,
    });
    toast(i18n.t('Element has been shared.'));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err);
  }
};
