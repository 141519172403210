import React from 'react';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import Scrollbar from '@components/Scrollbars/Swiper';
import Artist from '@containers/Label/Artist';
import ItemsCarousel, { POSITIONS } from '@components/ItemsCarousel';
import { VARIANTS } from '@components/Text';
import * as Styled from '@containers/Label/style';

import LEFT_ARROW from '@assets/images/icons/small-left-arrow.svg';
import RIGHT_ARROW from '@assets/images/icons/small-right-arrow.svg';

const ArtistsBox = ({
  artists,
  isLoading,
  sidebarStore: { isSideBarOpened },
}) => {
  const { t } = useTranslation();
  const {
    colors: { textLight },
  } = useTheme();

  const CONTROLS = {
    buttonPrev: <img src={LEFT_ARROW} alt="arrow left" />,
    buttonNext: <img src={RIGHT_ARROW} alt="arrow right" />,
    scrollbar: <Scrollbar background={textLight} />,
  };

  return (!isLoading && artists.length > 0) || isLoading ? (
    <Styled.Section>
      <Styled.SectionHeader variant={VARIANTS.NAV_COLLAPSED}>
        {t('Artists')}
      </Styled.SectionHeader>
      <Styled.Separator />
      <ItemsCarousel
        itemComponent={Artist}
        itemProps={{
          display: 'inline',
          isSideBarOpened,
        }}
        items={artists}
        itemPropsFromItem={{
          firstName: 'first_name',
          lastName: 'last_name',
          image: 'image',
          uuid: 'uuid',
        }}
        {...CONTROLS}
        isLoading={isLoading}
        buttonsPositionForSM={POSITIONS.ABSOLUTE}
        ofComposers
      />
    </Styled.Section>
  ) : null;
};

ArtistsBox.propTypes = {
  artists: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  sidebarStore: PropTypes.object.isRequired,
};

export default inject('sidebarStore')(observer(ArtistsBox));
