import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Text, { VARIANTS, COLORS } from '@components/Text';
import { Wrapper } from '@components/ItemsNotFound/style';
import { currentLanguage } from '@utils/i18n';

const ItemsNotFound = ({ name }) => {
  const { t } = useTranslation();
  const nameDownCase = name.charAt(0).toLowerCase() + name.slice(1);
  return (
    <Wrapper>
      <Text variant={VARIANTS.H1_BOLD} color={COLORS.BLUE}>
        {t('{{name}} not found.', { name })}
      </Text>
      <p />
      {currentLanguage === 'CZ' ? (
        <Text>
          {t('You have not any {{name}} yet.', { name: nameDownCase })}
        </Text>
      ) : (
        <Text>{t('You have not any {{name}} yet.', { name })}</Text>
      )}
    </Wrapper>
  );
};

ItemsNotFound.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ItemsNotFound;
