import styled from '@emotion/styled';
import { Flex } from 'rebass';
import { breakpoint } from '@styles/breakpoints';
import Button from '@components/Button';
import { FieldWrapper } from '@components/Form/style';

export const Wrapper = styled.div`
  border-radius: 7px;
  box-shadow: 0 0 7px 0 rgba(13, 13, 13, 0.18);
  background-color: #fff;
  position: relative;
`;

export const Filters = styled(Flex)`
  border-bottom: ${({ noBorder }) =>
    noBorder ? 'none' : 'solid 1px rgba(13, 13, 13, 0.15)'};
`;

export const Tags = styled(Flex)`
  justify-content: space-between;
  padding: 10px;
  align-items: center;

  @media ${breakpoint.md} {
    padding: 10px 0;
  }
`;

export const TagsList = styled(Flex)`
  flex-wrap: wrap;
  padding-right: 15px;
  margin-left: -4px;
`;

export const FiltersTitle = styled(Flex)`
  border-right: solid 1px rgba(13, 13, 13, 0.15);
  height: 50px;
  align-items: center;
  padding: 0 10px;
`;

export const FilterDropdownWrapper = styled(Flex)`
  border-left: solid 1px rgba(13, 13, 13, 0.15);
  flex: 1;
  align-items: center;

  &:first-of-type {
    border-left: none;
  }

  transition: background 0.2s;
  &:hover {
    background-color: ${({ theme }) => theme.filters.tag.hoverBackgroundColor};
  }
`;

export const FiltersDropdowns = styled.form`
  display: flex;
  flex: 1;
`;

export const MobileFiltersDropdowns = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: 4px;

  ${FilterDropdownWrapper} {
    border: none;
    flex-wrap: wrap;
    padding: 6px 0;
    border-top: solid 1px rgba(13, 13, 13, 0.15);
  }

  ${FieldWrapper} {
    flex: unset;
    min-width: 90px;
  }
`;

export const FiltersListDropdown = styled.button`
  padding: 16px;
  text-align: center;
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    vertical-align: middle;
    transform: ${({ isExpanded }) => (isExpanded ? 'rotate(180deg)' : 'none')};
    transition: transform 0.3s;
  }
`;

export const MobileFilters = styled.div`
  .expanded-filters {
    &-enter {
      opacity: 0;
    }
    &-enter-active {
      opacity: 1;
      transition: opacity 0.3s;
    }
    &-exit {
      opacity: 1;
    }
    &-exit-active {
      opacity: 0;
      transition: opacity 0.3s;
    }
  }
`;

export const ResetButton = styled(Button)`
  min-width: fit-content;
  font-size: 16px;
`;

export const MobileReset = styled.button`
  text-decoration: underline;
  margin-top: 8px;
  padding: 6px 0;
`;
