import styled from '@emotion/styled';
import List from '@components/GridList';
import Text from '@components/Text';
import { breakpoint } from '@styles/breakpoints';

export const GridList = styled(List)`
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));

  @media ${breakpoint.md} {
    grid-template-columns: 1fr;
  }
`;

export const Description = styled(Text)`
  margin: 0 0 30px 0;
  max-width: 420px;
`;
