import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import downloadIcon from '@assets/images/icons/download-icon.svg';

const DownloadContractButton = ({
  contractsStore: { downloadLabelContract, downloadContract },
  file,
  fileName,
}) => {
  let onClick = () => {};
  if (file.uuid) {
    onClick = () => downloadLabelContract(file.uuid, fileName);
  } else if (file.id) {
    onClick = () => downloadContract(file.id, fileName);
  }

  return (
    <button type="button" onClick={onClick}>
      <img src={downloadIcon} alt="download" />
    </button>
  );
};

DownloadContractButton.propTypes = {
  file: PropTypes.object.isRequired,
  fileName: PropTypes.string.isRequired,
  contractsStore: PropTypes.shape({
    downloadLabelContract: PropTypes.func,
    downloadContract: PropTypes.func,
  }).isRequired,
};

export default inject('contractsStore')(observer(DownloadContractButton));
