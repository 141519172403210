import React from 'react';
import { uniqueId } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Box } from 'rebass';

import * as PropTypes from '@components/License/PropTypes';
import Text, { VARIANTS } from '@components/Text';
import * as Styled from '@components/License/style';
import Button from '@components/Button';
import LicenseTooltipbody from '@components/License/LicenseTooltipbody';

import INFO_ICON from '@assets/images/icons/info-icon.svg';
import Tooltip from '@components/Tooltip';
import routes from '@routes';

const Section = ({
  title,
  tooltip,
  button_text,
  button_url,
  price,
  details,
}) => (
  <section>
    <Styled.Separator />
    <Styled.FlexRow>
      <Styled.FlexText variant={VARIANTS.H4}>
        <Styled.SectionTitle>{title}</Styled.SectionTitle>
        <Tooltip
          tooltipBody={
            <LicenseTooltipbody title={title} description={tooltip} />
          }
        >
          <img src={INFO_ICON} alt="info" />
        </Tooltip>
      </Styled.FlexText>
      <Styled.SectionPrice variant={VARIANTS.H4}>{price}</Styled.SectionPrice>
    </Styled.FlexRow>
    <Styled.Details>
      {details.map(detail => (
        <Styled.Detail as="li" key={uniqueId()}>
          <Styled.DetailText>{detail.name}</Styled.DetailText>
          <Styled.DetailText>{detail.price}</Styled.DetailText>
        </Styled.Detail>
      ))}
    </Styled.Details>
    {button_text && button_url && (
      <Styled.Link href={button_url}>
        <Button label={button_text} />
      </Styled.Link>
    )}
  </section>
);

const License = ({ uuid, title, description, sections, disable_form }) => {
  const { t } = useTranslation();

  return (
    <Styled.Wrapper>
      <Styled.Header>{title}</Styled.Header>
      <Text>{description}</Text>
      {sections.map(section => (
        <Section key={uniqueId()} {...section} />
      ))}
      {!disable_form && (
        <Box mt={3}>
          <Button label={t('Apply')} to={routes.licenseApplication(uuid)} />
        </Box>
      )}
    </Styled.Wrapper>
  );
};

Section.propTypes = PropTypes.Section;
License.propTypes = PropTypes.License;

License.defaultProps = {
  sections: [],
};

export default License;
