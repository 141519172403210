import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';
import { MenuItem } from '@components/TooltipSelect';

export const StyledMenuItem = styled(MenuItem)`
  padding: 12px;
  @media ${breakpoint.md} {
    padding: 16px 12px;
  }
`;
