import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Text from '@components/Text';
import { breakpoint } from '@styles/breakpoints';
import { GRADIENT_BACKGROUND } from '@styles/globalStyles';

export const Header = styled(Text)`
  text-transform: uppercase;
  margin-bottom: 40px;
  color: inherit;
  font-weight: 900;
`;

export const VARIANTS = {
  TRANSPARENT: 'TRANSPARENT',
  WHITE: 'WHITE',
  GRADIENT: 'GRADIENT',
};

const TRANSPARENT = theme => css`
  background-color: ${theme.backgrounds.transparentWhite};
  border: 1px solid ${theme.colors.strokeVariant};
`;

const WHITE = theme => css`
  background-color: ${theme.colors.white};
  box-shadow: ${theme.backgrounds.shadow};
`;

const VARIANT = {
  [VARIANTS.TRANSPARENT]: TRANSPARENT,
  [VARIANTS.WHITE]: WHITE,
  [VARIANTS.GRADIENT]: GRADIENT_BACKGROUND,
};

export const Content = styled.div`
  position: relative;
  z-index: 1;
`;

export const StyledCard = styled.div`
  border-radius: 7px;
  padding: 32px;
  color: ${props => props.theme.colors.primaryVariant};

  @media ${breakpoint.sm} {
    padding: 16px;
  }

  ${({ variant, theme }) => {
    if (variant) {
      return VARIANT[variant](theme);
    }
    return null;
  }}
`;
