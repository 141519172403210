import { API, APIRoutes } from '@app/api';
import { uniqBy } from 'lodash-es';
import { makeAutoObservable, runInAction } from 'mobx';

export class NewsStore {
  constructor() {
    makeAutoObservable(this);
  }

  activeTabId = null;

  activeTrack = null;

  initialized = false;

  news = {
    isLoading: false,
    data: [],
    error: null,
  };

  tracks = [];

  getNews = async () => {
    try {
      this.news = { ...this.news, isLoading: true };

      const {
        data: { homepage_news },
      } = await API(APIRoutes.NEWS);
      const [firstItem] = homepage_news;

      runInAction(() => {
        this.news = {
          ...this.news,
          data: homepage_news,
        };
        this.activeTabId = firstItem.id;
        this.tracks = uniqBy(firstItem.tracks, 'id');
        [this.activeTrack] = firstItem.tracks;
      });
    } catch (error) {
      runInAction(() => {
        this.news = { ...this.news, error };
      });
    } finally {
      runInAction(() => {
        this.news = { ...this.news, isLoading: false };
        this.initialized = true;
      });
    }
  };

  selectTab = id => {
    this.activeTabId = id;
    this.tracks = uniqBy(this.news.data.find(n => n.id === id).tracks, 'id');
    [this.activeTrack] = this.tracks;
  };

  selectTrack = id => {
    this.activeTrack = this.tracks.find(t => t.id === id);
  };
}

export default new NewsStore();
