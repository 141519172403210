import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';
import { Flex } from 'rebass';

export const StyledFlex = styled(Flex)`
  justify-content: space-between;
  align-items: flex-end;
  gap: 48px;

  @media ${breakpoint.lg} {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;
