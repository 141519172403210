import i18n from '@utils/i18n';

export const LICENSE_KINDS = {
  CORPORATE: 'corporate',
  COMMERCIAL: 'commercial',
  ONLINE: 'online',
  FILM_AND_TRAILER: 'film_and_trailer',
  NEW_MEDIA: 'new_media',
};

export const APPLICATION_KINDS = [
  { value: 'first_time', label: i18n.t('First-Time License') },
  { value: 'follow_up', label: i18n.t('Follow-Up License') },
];

export const PRODUCTION_TYPES = {
  corporate: [
    { value: 'Corporate film', label: i18n.t('Corporate film') },
    { value: 'Educational film', label: i18n.t('Educational film') },
    { value: 'Image film', label: i18n.t('Image film') },
    { value: 'Other', label: i18n.t('Other') },
  ],
  commercial: [
    { value: 'Commercial', label: i18n.t('Commercial') },
    { value: 'Trailer', label: i18n.t('Trailer') },
  ],
  online: [
    { value: 'Web video', label: i18n.t('Web video') },
    { value: 'Tutorial', label: i18n.t('Tutorial') },
    { value: 'Vlog', label: i18n.t('Vlog') },
    { value: 'Other', label: i18n.t('Other') },
  ],
  film_and_trailer: [
    { value: 'Feature Film', label: i18n.t('Feature Film') },
    { value: 'Documentary', label: i18n.t('Documentary') },
    { value: 'Short Film', label: i18n.t('Short Film') },
    { value: 'TV Series', label: i18n.t('TV Series') },
    { value: 'Trailer', label: i18n.t('Trailer') },
  ],
  new_media: [
    { value: 'Podcast', label: i18n.t('Podcast') },
    { value: 'Audio play', label: i18n.t('Audio play') },
    { value: 'Audio book', label: i18n.t('Audio book') },
    { value: 'Game', label: i18n.t('Game') },
    { value: 'Mobile App', label: i18n.t('Mobile App') },
    { value: 'Online App', label: i18n.t('Online App') },
    { value: 'Other', label: i18n.t('Other') },
  ],
};

export const OFFLINE_USAGE_TYPES = {
  corporate: [
    {
      value: 'rec_image_and_sounds_carriers',
      label: i18n.t('Rec. image & sound carriers'),
    },
    {
      value: 'point_of_sales',
      label: i18n.t('Point-Of-Sales'),
    },
    {
      value: 'public_events_and_trade_fairs',
      label: i18n.t('Public events & trade fairs'),
    },
  ],
  commercial: [
    {
      value: 'tv',
      label: i18n.t('TV'),
    },
    {
      value: 'radio',
      label: i18n.t('Radio'),
    },
    {
      value: 'cinema',
      label: i18n.t('Cinema'),
    },
  ],
};

export const COMMON_USAGE_TYPES = [
  {
    value: 'all_web',
    label: i18n.t('All web'),
  },
  {
    value: 'social_media_and_youtube_only',
    label: i18n.t('Social media & YouTube only'),
  },
];

export const USAGE_TYPES = {
  corporate: [...COMMON_USAGE_TYPES],
  commercial: [...COMMON_USAGE_TYPES],
  online: [...COMMON_USAGE_TYPES],
  film_and_trailer: [
    ...COMMON_USAGE_TYPES,
    {
      value: 'streaming_services',
      label: i18n.t('Streaming services'),
    },
    {
      value: 'tv',
      label: i18n.t('TV'),
    },
    {
      value: 'public_screenings',
      label: i18n.t('Public Screenings'),
    },
    {
      value: 'dvd_and_bluray',
      label: i18n.t('DVD & Blu-Ray'),
    },
  ],
  new_media: [
    ...COMMON_USAGE_TYPES,
    {
      value: 'offline',
      label: i18n.t('Offline'),
    },
    {
      value: 'other',
      label: i18n.t('Other'),
    },
  ],
};

export const MEDIA_TYPES = [
  { value: 'purchased_media', label: i18n.t('Purchased media') },
  {
    value: 'non_purchased_media',
    label: i18n.t('Not purchased media'),
  },
];

export const TERRITORIES = [
  { value: 'german', label: i18n.t('Germany') },
  { value: 'gas', label: i18n.t('GAS') },
  { value: 'world', label: i18n.t('World') },
  { value: 'other', label: i18n.t('Other') },
];
