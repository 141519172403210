import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { VARIANTS as BTN_VARIANTS } from '@components/Button';
import { FormLink } from '@components/Form/style';
import FormButton from '@components/Form/FormButton';
import { Flex, Box } from 'rebass';
import TextField from '@components/Form/TextField';
import { useTranslation } from 'react-i18next';
import Checkbox from '@components/Form/Checkbox';
import Notification from '@components/Form/Notification';
import routes from '@routes';
import PasswordField from '@components/Form/PasswordField';

const SignInForm = ({ onSubmit, formStatus }) => {
  const { register, handleSubmit, errors } = useForm();
  const { t } = useTranslation();

  const { formNotification, isLoading } = formStatus;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {formNotification && <Notification {...formNotification} />}
      <Flex width={1} alignItems="center" flexDirection="column">
        <Box width={1}>
          <TextField
            name="email"
            label={t('E-mail')}
            placeholder={t('E-mail')}
            type="text"
            register={register}
            required
            errors={errors}
          />
          <PasswordField
            name="password"
            placeholder={t('Password')}
            label={t('Password')}
            register={register}
            required
            errors={errors}
          />
        </Box>

        <Flex
          width={1}
          mb={3}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Checkbox
            name="remember"
            label={t('Remember me')}
            register={register}
            errors={errors}
          />
          <FormLink to={routes.password.forgotten}>
            {t('Password forgotten?')}
          </FormLink>
        </Flex>

        <Flex
          flexDirection="column"
          justifyContent="space-between"
          my={2}
          textAlign="center"
        >
          <FormButton
            isLoading={isLoading}
            type="submit"
            variant={BTN_VARIANTS.GREEN}
            label={t('Login')}
          />
        </Flex>
      </Flex>
    </form>
  );
};

SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formStatus: PropTypes.object.isRequired,
};

export default SignInForm;
