import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Flex, Box } from 'rebass';
import { useTranslation } from 'react-i18next';
import { VARIANTS as BTN_VARIANTS } from '@components/Button';
import FormButton from '@components/Form/FormButton';
import PasswordField from '@components/Form/PasswordField';
import TextField from '@components/Form/TextField';
import Notification from '@components/Form/Notification';

const AccountDeleteForm = ({ onSubmit, formStatus, provider }) => {
  const { register, handleSubmit, errors } = useForm();
  const { t } = useTranslation();

  const { formNotification, isLoading } = formStatus;

  const CONFIRM_TEXT = t('DELETE_MY_ACCOUNT');

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      {formNotification && (
        <Box mt={4} mb={-3}>
          <Notification {...formNotification} />
        </Box>
      )}

      <Flex width={1} alignItems="center" flexDirection="column">
        <Box width={1} my={4}>
          {provider === 'email' && (
            <PasswordField
              name="password"
              placeholder={t('Password')}
              label={t('Password')}
              autoComplete="new-password"
              register={register}
              required
              errors={errors}
            />
          )}
          <TextField
            name="confirm"
            label={t('Type here {{text}}', { text: `: ${CONFIRM_TEXT}` })}
            placeholder={t('Type here {{text}}', { text: `: ${CONFIRM_TEXT}` })}
            type="text"
            autoComplete="off"
            register={register}
            required
            errors={errors}
            rules={{
              validate: {
                equal: val =>
                  val === CONFIRM_TEXT ||
                  t('Not equal to {{text}}', { text: `: ${CONFIRM_TEXT}` }),
              },
            }}
          />
        </Box>

        <Box width={1} my={2} textAlign="left">
          <FormButton
            type="submit"
            variant={BTN_VARIANTS.GREEN}
            label={t('Delete my account')}
            isLoading={isLoading}
          />
        </Box>
      </Flex>
    </form>
  );
};

AccountDeleteForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formStatus: PropTypes.object.isRequired,
  provider: PropTypes.string.isRequired,
};

export default AccountDeleteForm;
