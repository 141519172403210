import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import Modal from '@components/Modal';
import { MODAL_MODES } from '@root/stores/projectsStore';
import { VARIANTS as MODAL_VARIANTS } from '@components/Modal/style';
import { Header as CardHeader } from '@components/Card';
import Text, {
  ALIGNS,
  COLORS,
  VARIANTS as TEXT_VARIANTS,
} from '@components/Text';
import FormButton from '@components/Form/FormButton';
import { StyledLabel } from '@containers/MyProjects/components/style';
import TextField from '@components/Form/TextField';

const ProjectModal = ({
  projectsStore: {
    projectModal: {
      isOpen,
      mode,
      data,
      form: { errors, isLoading },
    },
    closeProjectModal,
    submitProject,
  },
}) => {
  const { t } = useTranslation();

  const { register, handleSubmit, reset, watch } = useForm({
    defaultValues: data,
  });

  useEffect(() => {
    if (data) {
      reset({ ...data });
    }
  }, [data]);

  const nameError = errors.name?.includes(watch('name'));

  const onSubmit = type => values => submitProject(values, type);

  return (
    <Modal
      closeHandler={closeProjectModal}
      isOpen={isOpen}
      variant={MODAL_VARIANTS.GRADIENT}
    >
      <Box p={4}>
        {mode === MODAL_MODES.EDIT ? (
          <Box p={3} textAlign="center">
            <CardHeader variant={TEXT_VARIANTS.H2} align={ALIGNS.CENTER}>
              {t('Rename your project')}
            </CardHeader>
            <Box width={[1, 1, 1, 1 / 2]} margin="0 auto">
              <form onSubmit={handleSubmit(onSubmit(MODAL_MODES.EDIT))}>
                <StyledLabel>{t("Enter a new project's name")}:</StyledLabel>
                <Box my={4}>
                  <TextField
                    name="name"
                    placeholder={t('Type here...')}
                    type="text"
                    register={register}
                    required
                  />
                  {nameError && (
                    <Box mt={-2}>
                      <Text
                        color={COLORS.WHITE}
                        variant={TEXT_VARIANTS.SMALL_BODY}
                      >
                        {t('Name')} {nameError}
                      </Text>
                    </Box>
                  )}
                </Box>
                <FormButton
                  type="submit"
                  isLoading={isLoading}
                  label={t('Edit project')}
                />
              </form>
            </Box>
          </Box>
        ) : (
          <Box p={3} textAlign="center">
            <CardHeader variant={TEXT_VARIANTS.H2} align={ALIGNS.CENTER}>
              {t('Name your next project')}
            </CardHeader>
            <Box width={[1, 1, 1, 1 / 2]} margin="0 auto">
              <form onSubmit={handleSubmit(onSubmit(MODAL_MODES.CREATE))}>
                <StyledLabel>{t('Enter a new project name')}:</StyledLabel>
                <Box my={4}>
                  <TextField
                    name="name"
                    placeholder={t('Type here...')}
                    type="text"
                    register={register}
                    required
                  />
                  {nameError && (
                    <Box mt={-2}>
                      <Text
                        color={COLORS.WHITE}
                        variant={TEXT_VARIANTS.SMALL_BODY}
                      >
                        {t('Name has been taken')}
                      </Text>
                    </Box>
                  )}
                </Box>
                <FormButton
                  disabled={nameError}
                  type="submit"
                  isLoading={isLoading}
                  label={t('Create project')}
                />
              </form>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

ProjectModal.propTypes = {
  projectsStore: PropTypes.shape({
    closeProjectModal: PropTypes.func,
    submitProject: PropTypes.func,
    projectModal: PropTypes.shape({
      data: PropTypes.object,
      isLoading: PropTypes.bool,
      isOpen: PropTypes.bool,
      mode: PropTypes.oneOf(Object.values(MODAL_MODES)),
      form: PropTypes.shape({
        errors: PropTypes.object,
        isLoading: PropTypes.bool,
      }),
    }),
  }).isRequired,
};

export default inject('projectsStore')(observer(ProjectModal));
