import React, { useEffect } from 'react';
import { uniqueId } from 'lodash-es';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import Header from '@components/Header';
import { MainContent } from '@styles/globalStyles';
import GridList from '@components/GridList';
import CrewItem from '@components/CrewItem';
import Preloader from '@components/Preloader';

const Crew = ({ crewStore: { getCrew, crew } }) => {
  const { t } = useTranslation();
  useEffect(() => {
    getCrew();
  }, [getCrew]);

  return (
    <MainContent>
      <Header title={t('Crew')} withSeparator />

      {crew.isLoading && <Preloader />}
      <GridList>
        {crew.data.map(({ first_name, last_name, image, image_2, role }) => (
          <li key={uniqueId()}>
            <CrewItem
              firstName={first_name}
              lastName={last_name}
              image={image}
              altImage={image_2}
              role={role}
            />
          </li>
        ))}
      </GridList>
    </MainContent>
  );
};

const CrewType = PropTypes.shape({
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      image: PropTypes.shape({ url: PropTypes.string }),
      role: PropTypes.string,
    }),
  ),
}).isRequired;

Crew.propTypes = {
  crewStore: PropTypes.shape({
    crew: CrewType,
    getCrew: PropTypes.func.isRequired,
  }).isRequired,
};

export default inject('crewStore')(observer(Crew));
