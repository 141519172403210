import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import * as Styled from '@containers/MainPlayer/components/Controls/style';

import LeftArrowIcon from '@assets/images/icons/small-left-arrow.svg';
import PauseIcon from '@assets/images/icons/pause-button.svg';
import PlayButton from '@assets/images/icons/active-play-button.svg';
import RightArrowIcon from '@assets/images/icons/small-right-arrow.svg';

const PlayerControls = ({
  playerStore: {
    playPause,
    isPlaying,
    nextTrack,
    nextTrackButtonIsActive,
    prevTrackButtonIsActive,
    prevTrack,
    context,
  },
}) => (
  <Styled.Wrapper>
    <Styled.PlaybackPreviousContainer
      onClick={prevTrack}
      disabled={!context || !prevTrackButtonIsActive}
    >
      <img src={LeftArrowIcon} alt="<-" />
    </Styled.PlaybackPreviousContainer>

    <Styled.PlaybackPauseContainer onClick={playPause}>
      <img src={isPlaying ? PauseIcon : PlayButton} alt="" />
    </Styled.PlaybackPauseContainer>

    <Styled.PlaybackNextContainer
      onClick={nextTrack}
      disabled={!context || !nextTrackButtonIsActive}
    >
      <img src={RightArrowIcon} alt="->" />
    </Styled.PlaybackNextContainer>
  </Styled.Wrapper>
);

PlayerControls.propTypes = {
  playerStore: PropTypes.shape({
    playPause: PropTypes.func,
    isPlaying: PropTypes.bool,
    nextTrack: PropTypes.func,
    nextTrackButtonIsActive: PropTypes.bool,
    prevTrack: PropTypes.func,
    prevTrackButtonIsActive: PropTypes.bool,
    context: PropTypes.object,
  }).isRequired,
};

export default inject('playerStore')(observer(PlayerControls));
