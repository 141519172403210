import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import TextField, { MARGINS } from '@components/Form/TextField';
import Text, { COLORS, VARIANTS } from '@components/Text';
import Select, { VARIANTS as SELECT_VARIANTS } from '@components/Form/Select';
import AsyncSelect from '@components/Form/Select/AsyncSelect';
import { APIRoutes } from '@app/api';

const OPTIONS_URLS = {
  keywords: APIRoutes.KEYWORDS_SUGGESTIONS,
  composers: APIRoutes.COMPOSERS_SUGGESTIONS,
};

const FilterInput = ({
  filter: { dataKey, type, value, placeholder },
  register,
  errors,
  control,
  labelsStore: { labels, getLabels },
  topicsStore: { topics, getTopics },
  temposStore: { tempos, getTempos },
  moodsStore: { moods, getMoods },
  genresStore: { genres, getGenres },
  instrumentationsStore: { instrumentations, getInstrumentations },
  target,
  mobile,
}) => {
  const { t } = useTranslation();
  const dataByKey = {
    labels: { ...labels, get: getLabels },
    topics: { ...topics, get: getTopics },
    tempo: { ...tempos, get: getTempos },
    moods: { ...moods, get: getMoods },
    genres: { ...genres, get: getGenres },
    instrumentations: { ...instrumentations, get: getInstrumentations },
  };
  const [options, setOptions] = useState([]); // eslint-disable-line

  useEffect(() => {
    if (type === 'select') {
      const fetchOptions = async () => {
        const { data, get } = dataByKey[dataKey];
        if (data.length === 0) {
          await get();
        }
      };
      fetchOptions();
    }
  }, [type, dataKey]);

  useEffect(() => {
    if (type === 'select') {
      const optionsForDropdown = [];
      dataByKey[dataKey].data.forEach(data => {
        if (data.uuid) {
          optionsForDropdown.push({
            label: data.name,
            value: data.uuid,
          });
        }
      });
      setOptions(optionsForDropdown);
    }
  }, [
    dataKey,
    labels.data,
    topics.data,
    moods.data,
    tempos.data,
    genres.data,
    instrumentations.data,
  ]);

  if (type === 'text') {
    return (
      <TextField
        register={register}
        errors={errors}
        name={value}
        placeholder={placeholder}
        mb={MARGINS.NONE}
        autofocus
      />
    );
  }

  let variant = SELECT_VARIANTS.DEFAULT;

  if (target === 'box') {
    if (mobile) {
      variant = SELECT_VARIANTS.FILTER_BOX_MOBILE;
    }
    variant = SELECT_VARIANTS.FILTER_BOX;
  }

  if (type === 'select') {
    return (
      <Select
        control={control}
        isSearchable={target !== 'box'}
        name={value}
        placeholder={placeholder}
        options={options}
        errors={errors}
        mb={MARGINS.NONE}
        isMulti={target !== 'box'}
        required
        variant={variant}
        overrides={{
          menu: {
            textTransform: 'uppercase',
          },
          control: {
            textTransform: 'uppercase',
          },
        }}
      />
    );
  }

  if (type === 'async-select') {
    return (
      <AsyncSelect
        control={control}
        isSearchable={target !== 'box'}
        name={value}
        placeholder={placeholder}
        options={options}
        errors={errors}
        mb={MARGINS.NONE}
        isMulti={target !== 'box'}
        required
        variant={variant}
        optionsUrl={OPTIONS_URLS[value]}
        optionValueKey="uuid"
        optionLabelKey={
          value === 'composers' ? ['first_name', 'last_name'] : 'name'
        }
      />
    );
  }

  return (
    <Text variant={VARIANTS.SMALL_BODY} color={COLORS.GREY}>
      {t('Select filter')}
    </Text>
  );
};

FilterInput.defaultProps = {
  target: 'modal',
  mobile: false,
};

FilterInput.propTypes = {
  control: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  labelsStore: PropTypes.shape({
    labels: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.array,
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
    getLabels: PropTypes.func,
  }).isRequired,
  temposStore: PropTypes.shape({
    tempos: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.array,
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
    getTempos: PropTypes.func,
  }).isRequired,
  moodsStore: PropTypes.shape({
    moods: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.array,
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
    getMoods: PropTypes.func,
  }).isRequired,
  genresStore: PropTypes.shape({
    genres: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.array,
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
    getGenres: PropTypes.func,
  }).isRequired,
  topicsStore: PropTypes.shape({
    topics: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.array,
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
    getTopics: PropTypes.func,
  }).isRequired,
  instrumentationsStore: PropTypes.shape({
    instrumentations: PropTypes.object,
    getInstrumentations: PropTypes.func,
  }).isRequired,
  target: PropTypes.oneOf(['box', 'modal']),
  mobile: PropTypes.bool,
};

export default inject(
  'labelsStore',
  'topicsStore',
  'moodsStore',
  'temposStore',
  'genresStore',
  'instrumentationsStore',
)(observer(FilterInput));
