import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Box } from 'rebass';
import {
  Form,
  StyledLabel,
  SelectWrapper,
  TrackName,
} from '@components/AddToProjectModal/style';
import Text, { COLORS, VARIANTS } from '@components/Text';
import Select from '@components/Form/Select';
import FormButton from '@components/Form/FormButton';

const SelectProjectForm = ({
  submit,
  options,
  errors,
  isLoading,
  trackName,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit, reset, watch } = useForm();

  const isError = errors.projectsTaken?.includes(watch('project').uuid);

  useEffect(() => {
    if (options && options.length > 0) {
      reset({
        project: options[0],
      });
    }
  }, [options]);

  return (
    <Form onSubmit={handleSubmit(submit)}>
      <StyledLabel>
        {t(
          'Select a project from below to decide where this track will be saved.',
        )}
      </StyledLabel>
      <Box my={4}>
        <SelectWrapper pb={2}>
          <Select
            control={control}
            isSearchable
            name="project"
            placeholder={t('Select project')}
            options={options}
            required
            getOptionLabel={({ name }) => name}
            getOptionValue={({ uuid }) => uuid}
          />
        </SelectWrapper>
        {isError && (
          <Box mt={-3}>
            <Text color={COLORS.WHITE} variant={VARIANTS.SMALL_BODY}>
              {t('Track')}: <TrackName>{trackName}</TrackName>{' '}
              {t('already exists in this project.')}
            </Text>
          </Box>
        )}
      </Box>
      <FormButton
        type="submit"
        isLoading={isLoading}
        disabled={isError}
        label={t('Add track')}
      />
    </Form>
  );
};

SelectProjectForm.propTypes = {
  submit: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  trackName: PropTypes.string,
};

SelectProjectForm.defaultProps = {
  trackName: null,
};

export default SelectProjectForm;
