import React, { useRef, useEffect } from 'react';
import { Flex, Box } from 'rebass';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import useWindowWidth from '@hooks/useBreakpoint';
import { size } from '@styles/breakpoints';
import { trackLength } from '@utils/formatters';
import Text, { COLORS, VARIANTS } from '@components/Text';
import ButtonsFromList from '@components/ButtonsFromList';
import * as Styled from '@components/News/components/Tracks/components/TracksList/style';
import ContextMenuIcon from '@components/IconButtons/ContextMenuIcon';
import InfoIcon from '@assets/images/icons/info-icon.svg';
import Tooltip, { TRIGGER } from '@components/Tooltip';
import PerfectScrollbar from '@components/Scrollbars/PerfectScrollbar';

import PlayPauseButton, {
  PLAYER_CONTEXTS,
} from '@components/PlayerControlButtons/PlayPause';
import DownloadButton from '@components/IconButtons/DownloadButton';
import DownloadButtonTooltipBody from '@components/IconButtons/DownloadButton/DownloadButtonTooltipBody';
import { Divider, MenuItem } from '@components/TooltipSelect';
import { APIRoutes } from '@app/api';

const Track = ({
  newsStore: { tracks, activeTrack, selectTrack },
  trackStore: { openTrackModal },
  authStore: { isAuthenticated },
  blockDownloadStore: { isDownloadBlocked, openBlockedDownloadModal },
}) => {
  const { t } = useTranslation();
  const checkSize = useWindowWidth();
  const { id: activeTrackId } = activeTrack;

  const scrollBarRef = useRef(null);

  useEffect(() => {
    if (scrollBarRef.current) {
      scrollBarRef.current.updateScroll();
    }
  }, [tracks.length]);

  const zeroBeforeIndex = n => (n < 10 ? `0${n}` : n);

  return (
    <Styled.ScrollContainer>
      <PerfectScrollbar ref={scrollBarRef}>
        {tracks.map((track, index) => (
          <Styled.Track
            key={`news-track_${track.id}`}
            active={track.id === activeTrackId ? 1 : 0}
            onClick={() => selectTrack(track.id)}
          >
            <Styled.TrackListItem>
              <Styled.TrackDetails>
                {window.innerWidth <= size.md || checkSize <= size.md ? (
                  <Styled.MobileImage src={track.album.cover.url} />
                ) : null}
                <Styled.TrackNo>
                  <Text color={COLORS.BLACK}>{zeroBeforeIndex(index + 1)}</Text>
                </Styled.TrackNo>

                <PlayPauseButton
                  context={PLAYER_CONTEXTS.NEWS}
                  track={track}
                  outlined
                />

                <Styled.Title active={track.id === activeTrackId}>
                  <Styled.TrackName
                    variant={VARIANTS.BODY}
                    color={COLORS.BLACK}
                    active={track.id === activeTrackId}
                  >
                    {track.name}
                  </Styled.TrackName>
                  {track.composers.length > 0 ? (
                    <Styled.TrackComposers>
                      <Text variant={VARIANTS.SMALL_BODY} color={COLORS.GREY}>
                        {track.composers
                          .map(
                            composer =>
                              `${composer.first_name} ${composer.last_name}`,
                          )
                          .join(', ')}
                      </Text>
                    </Styled.TrackComposers>
                  ) : null}
                </Styled.Title>
              </Styled.TrackDetails>

              {window.innerWidth > size.lg || checkSize > size.lg ? (
                <Flex>
                  <Styled.TrackInfo>
                    <Styled.TrackInfoText>
                      <Text variant={VARIANTS.SMALL_BODY} color={COLORS.BLACK}>
                        {`${track.tempo?.name || '-'}`}
                      </Text>
                    </Styled.TrackInfoText>
                    <Styled.TrackInfoText>
                      <Text variant={VARIANTS.SMALL_BODY} color={COLORS.BLACK}>
                        {trackLength(track.length)}
                      </Text>
                    </Styled.TrackInfoText>
                  </Styled.TrackInfo>

                  <Flex alignItems="center">
                    <DownloadButton
                      {...{
                        id: track.id,
                        isAuthenticated,
                        isDownloadBlocked,
                        openBlockedDownloadModal,
                        isMp3: track.file?.url,
                        isWav: track.file_wav?.url,
                        external_id: track.external_id,
                        cuesheet: `${
                          process.env.REACT_APP_API_URL
                        }${APIRoutes.TRACK_CUESHEET(
                          track.album?.uuid,
                          track.id,
                        )}`,
                      }}
                    />
                    <Styled.InfoButton
                      type="button"
                      as="button"
                      onClick={() => {
                        openTrackModal(track.id);
                      }}
                    >
                      <img src={InfoIcon} alt="info-icon" />
                    </Styled.InfoButton>
                    <ButtonsFromList
                      clipboardData={track.directory_with_number}
                      trackName={track.name}
                      trackId={track.id}
                    />
                  </Flex>
                </Flex>
              ) : (
                <Box position="relative">
                  <Tooltip
                    trigger={TRIGGER.CLICK}
                    tooltipBody={
                      <>
                        <ButtonsFromList
                          clipboardData={track.directory_with_number}
                          trackName={track.name}
                          trackId={track.id}
                          isList
                        />
                        <Divider />
                        <DownloadButtonTooltipBody
                          {...{
                            id: track.id,
                            isAuthenticated,
                            isDownloadBlocked,
                            openBlockedDownloadModal,
                            ...(track.file?.url &&
                              track.external_id && {
                                mp3: APIRoutes.TRACK_DOWNLOAD(
                                  track.external_id,
                                  'mp3',
                                ),
                              }),
                            ...(track.file_wav?.url &&
                              track.external_id && {
                                wav: APIRoutes.TRACK_DOWNLOAD(
                                  track.external_id,
                                  'wav',
                                ),
                              }),
                            cuesheet: `${
                              process.env.REACT_APP_API_URL
                            }${APIRoutes.TRACK_CUESHEET(
                              track.album?.uuid,
                              track.id,
                            )}`,
                            buttonStyle: {
                              justifyContent: 'flex-end',
                              flexDirection: 'row-reverse',
                              padding: '10px 5px',
                            },
                          }}
                        />
                        <Divider />
                        <MenuItem
                          onClick={() => {
                            openTrackModal(track.id);
                          }}
                        >
                          <img src={InfoIcon} alt="info-icon" />
                          <Text variant={VARIANTS.NAV}>{t('More info')}</Text>
                        </MenuItem>
                      </>
                    }
                  >
                    <Styled.MobileContextMenuTrigger>
                      <ContextMenuIcon />
                    </Styled.MobileContextMenuTrigger>
                  </Tooltip>
                </Box>
              )}
            </Styled.TrackListItem>
          </Styled.Track>
        ))}
      </PerfectScrollbar>
    </Styled.ScrollContainer>
  );
};

Track.propTypes = {
  newsStore: PropTypes.object.isRequired,
  playerStore: PropTypes.shape({
    setActiveTrack: PropTypes.func,
  }).isRequired,
  authStore: PropTypes.object.isRequired,
  trackStore: PropTypes.object.isRequired,
  blockDownloadStore: PropTypes.object.isRequired,
};
export default inject(
  'newsStore',
  'playerStore',
  'trackStore',
  'authStore',
  'blockDownloadStore',
)(observer(Track));
