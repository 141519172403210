import React, { useState, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@components/Tooltip';
import { Waveform, TooltipText } from '@containers/MainPlayer/style';
import getPointerPosition from '@utils/getPointerPosition';
import { playerTime } from '@utils/formatters';
import { VARIANTS } from '@components/Text';

const InteractiveWaveform = memo(({ length, seekTo }) => {
  const [time, setTime] = useState(0);

  const handlePointerPosition = useCallback(
    e => {
      const {
        percent: { x },
      } = getPointerPosition(e);
      setTime(length * x);
    },
    [length],
  );

  const onSeek = useCallback(
    e => {
      const {
        percent: { x },
      } = getPointerPosition(e);
      seekTo(x);
    },
    [seekTo],
  );

  return (
    <Waveform onMouseMove={handlePointerPosition} onMouseDown={onSeek}>
      <Tooltip
        tooltipBody={
          <TooltipText variant={VARIANTS.BODY_LIGHT}>
            {playerTime(time)}
          </TooltipText>
        }
        trigger="hover"
        isActive
        hookArguments={{ followCursor: true }}
        minWidth="50px"
      >
        <Waveform className="waveform" />
      </Tooltip>
    </Waveform>
  );
});

InteractiveWaveform.propTypes = {
  length: PropTypes.number,
  seekTo: PropTypes.func.isRequired,
};

InteractiveWaveform.defaultProps = {
  length: 0,
};

export default InteractiveWaveform;
