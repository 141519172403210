import styled from '@emotion/styled';

export const Cell = styled.div`
  padding: 12px;
  border: 0.5px solid ${({ theme }) => theme.colors.quaternary};
  border-radius: 4px;
  height: ${({ fillAvaliable }) => (fillAvaliable ? '150px' : 'auto')};
  margin-top: 10px;
  font-size: ${({ theme }) => theme.fontSizes.bodySmall};
`;
