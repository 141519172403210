/* eslint-disable react/prop-types */

import React from 'react';
import i18n from '@utils/i18n';
import ActionsCell from '@components/TracksTable/components/ActionsCell';
import VersionCell from '@components/TracksTable/components/VersionCell';
import TrackCell from '@components/TracksTable/components/TrackCell';

const albumColumns = ({
  onToggleAlternatives,
  expandOnPlay,
  expandedTrack,
  expandedAlternatives,
  isAuthenticated,
  isMobile,
  showAlbumCovers,
  playerContext,
  renderActionsCell,
}) => {
  return [
    {
      title: i18n.t('Track'),
      render: ({ row }) => (
        <TrackCell
          {...{ track: row, expandOnPlay, showAlbumCovers, playerContext }}
        />
      ),
      style: {
        width: isMobile ? '100%' : '20%',
      },
    },
    ...(isMobile
      ? [
          {
            title: '',
            field: 'alternative_tracks_count',
            style: {
              textAlign: 'center',
            },
            render: ({ cell, row: { id, section } }) => (
              <VersionCell
                versions={cell}
                onClick={e => onToggleAlternatives(e, id, section)}
                isOpen={id === expandedTrack && expandedAlternatives}
              />
            ),
          },
        ]
      : [
          {
            title: i18n.t('Track description'),
            field: 'description',
            style: {
              width: '40%',
              minWidth: 160,
            },
          },
          {
            title: i18n.t('Version'),
            field: 'alternative_tracks_count',
            style: {
              textAlign: 'center',
            },
            render: ({ cell, row: { id, section } }) => (
              <VersionCell
                versions={cell}
                onClick={e => onToggleAlternatives(e, id, section)}
                isOpen={id === expandedTrack && expandedAlternatives}
              />
            ),
          },
          {
            title: i18n.t('Tempo'),
            field: 'bpm',
            render: ({ cell }) => <span>{cell} BPM</span>,
            style: {
              minWidth: 80,
            },
          },
          { title: i18n.t('Time'), field: 'length' },
          { title: i18n.t('Nr'), field: 'directory_with_number' },
        ]),
    {
      title: '',
      field: '',
      style: {
        textAlign: 'right',
      },
      render: props =>
        renderActionsCell ? (
          React.createElement(renderActionsCell, {
            ...props,
            isAuthenticated,
            isMobile,
          })
        ) : (
          <ActionsCell
            {...{
              ...props,
              isAuthenticated,
              isMobile,
            }}
          />
        ),
    },
  ];
};

export default albumColumns;
