import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled.div`
  margin: 10px 0 40px 0;
  position: relative;

  @media ${breakpoint.md} {
    padding: 0 16px;
  }
`;
