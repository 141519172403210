import styled from '@emotion/styled';
import { Flex } from 'rebass';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled(Flex)`
  padding: 0 30px;
  align-items: center;

  @media ${breakpoint.md} {
    padding: 0 16px;
  }
`;

export const GreyButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px;
  width: 32px;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.stroke};
  border-radius: 50%;
`;

export const PlaybackPauseContainer = styled(GreyButton)`
  width: 39px;
  height: 39px;
`;

export const PlaybackPreviousContainer = styled(GreyButton)``;
export const PlaybackNextContainer = styled(GreyButton)``;
