import styled from '@emotion/styled';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  z-index: 0;
`;

export const Wave = styled.div``;
