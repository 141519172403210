import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import * as Styled from '@containers/MainPlayer/components/TrackDetails/style';
import MoreInfoOpenIcon from '@assets/images/icons/mini-player-more-info-open.svg';
import AlbumInfo from '@containers/MainPlayer/components/AlbumInfo';
import {
  DetailsContainer,
  DetailsContainerWrapper,
} from '@containers/MainPlayer/style';
import routes from '@routes';
import CoverPlaceholder from '@components/CoverPlaceholder';

const TrackDetails = ({
  authStore: { isAuthenticated },
  playerStore: { activeTrack },
  labelsStore: { labels, getLabels },
}) => {
  useEffect(() => {
    if (labels.data.length === 0) getLabels();
  }, [getLabels]);
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(x => !x);

  const trigger = (
    <Styled.MoreInfoButton
      expanded={expanded}
      type="button"
      onClick={toggleExpanded}
    >
      <img src={MoreInfoOpenIcon} alt="+" />
    </Styled.MoreInfoButton>
  );

  let link = null;
  if (
    activeTrack?.medley &&
    !(activeTrack?.album && activeTrack?.album?.uuid)
  ) {
    link = routes.label(
      labels?.data?.find(({ name }) => name === activeTrack?.name)?.uuid,
    );
  } else if (activeTrack?.medley && activeTrack?.album) {
    link = routes.album(activeTrack?.album?.uuid, null);
  } else {
    link = routes.album(activeTrack?.album?.uuid, activeTrack?.id);
  }
  if (activeTrack) {
    return (
      <>
        <DetailsContainerWrapper>
          {expanded ? (
            <DetailsContainer />
          ) : (
            <DetailsContainer padding="20px 34px">
              {activeTrack.album && activeTrack.album.cover ? (
                <Styled.CoverContainer flexShrink="0" as={Link} to={link}>
                  {activeTrack.album.cover.url ? (
                    <Styled.Cover
                      src={activeTrack.album.cover.url}
                      alt={activeTrack.album.cover.name}
                    />
                  ) : (
                    <CoverPlaceholder
                      size="2rem"
                      text={activeTrack.album.cover.name}
                    />
                  )}
                </Styled.CoverContainer>
              ) : null}
              <Styled.TextInfo>
                <Styled.Title>{activeTrack.name}</Styled.Title>
                {activeTrack.composers && activeTrack.composers.length > 0 && (
                  <Styled.Artist>
                    {activeTrack.composers.map((item, index) => (
                      <span key={`composer_${index}`}>
                        {`${index > 0 ? ',' : ''} ${item.first_name} ${
                          item.last_name
                        }`}
                      </span>
                    ))}
                  </Styled.Artist>
                )}
              </Styled.TextInfo>
            </DetailsContainer>
          )}
          {activeTrack.album && !expanded && trigger}
        </DetailsContainerWrapper>
        {activeTrack.album ? (
          <AlbumInfo
            {...{ isAuthenticated, ...activeTrack.album }}
            trigger={trigger}
            isOpen={expanded}
            isNotFullTrack={!!activeTrack.medley || !!activeTrack.isBriefing}
            url={link}
          />
        ) : null}
      </>
    );
  }

  return null;
};

TrackDetails.propTypes = {
  playerStore: PropTypes.shape({
    activeTrack: PropTypes.object,
    isPlaying: PropTypes.bool,
  }).isRequired,
  authStore: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
  }).isRequired,
  labelsStore: PropTypes.object.isRequired,
};

export default inject(
  'authStore',
  'playerStore',
  'labelsStore',
)(observer(TrackDetails));
