import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled.div`
  margin-top: 10px;
  border: 1px #d8d8d8 solid;
  height: 58px;
  background-color: ${({ theme }) => theme.tags.trackBackground};
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-right: 50px;
  border-radius: 4px;
  margin-bottom: 25px;
  @media ${breakpoint.md} {
    margin-bottom: 38px;
  }
`;

export const Tag = styled.button`
  font-size: 14px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  height: 52px;
  min-width: 300px;
  @media ${breakpoint.md} {
    min-width: 180px;
  }
  border-radius: 4px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  background-color: ${({ active, theme }) =>
    active ? theme.tags.tagHoverBackground : theme.tags.tagBackground};

  &:hover {
    background-color: ${({ theme }) => theme.tags.tagHoverBackground};
  }
`;

export const TagsScroll = styled.div`
  overflow-x: scroll;
  height: calc(100% + 20px);
  padding-top: 2px;
  display: flex;
`;

export const ScrollButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 100%;
`;

export const TagsControls = styled.div`
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  background: linear-gradient(
    90deg,
    rgba(237, 242, 247, 0) 0%,
    rgba(255, 255, 255, 1) 36%
  );
  display: flex;
  justify-content: flex-end;
  width: 100px;
`;

export const DummyItem = styled.div`
  width: 100px;
  flex: 0 0 100px;
`;
