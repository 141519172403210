import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Content, VARIANTS } from '@components/CmsBody/style';
import Preloader from '@components/Preloader';

export { VARIANTS } from '@components/CmsBody/style';

const CmsBody = ({ html, isLoading, error, variant }) => {
  const { t } = useTranslation();

  return (
    <div>
      {html ? (
        <Content
          dangerouslySetInnerHTML={{
            __html: html,
          }}
          variant={variant}
        />
      ) : (
        <>
          {isLoading && <Preloader />}
          {error && <span>{t('Failed to load page.')}</span>}
        </>
      )}
    </div>
  );
};

CmsBody.defaultProps = {
  html: '',
  isLoading: false,
  error: null,
  variant: VARIANTS.DEFAULT,
};

CmsBody.propTypes = {
  html: PropTypes.string,
  isLoading: PropTypes.bool,
  error: PropTypes.any,
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
};

export default CmsBody;
