import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from '@components/Header/style';

const Header = ({ title, nav, withSeparator }) => {
  return (
    <>
      <Styled.Header withSeparator={withSeparator}>
        <Styled.Title>{title}</Styled.Title>
        {nav && <Styled.Nav>{nav}</Styled.Nav>}
      </Styled.Header>
    </>
  );
};

Header.defaultProps = {
  nav: undefined,
  withSeparator: false,
};

Header.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  nav: PropTypes.node,
  withSeparator: PropTypes.bool,
};

export default Header;
