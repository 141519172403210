import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import * as Styled from '@components/SelectDropdown/style';
import SearchIcon from '@assets/images/icons/search-icon.svg';
import ArrowDownIcon from '@assets/images/icons/arrow-down.svg';
import theme from '@styles/theme';

const selectStyles = {
  control: provided => ({
    ...provided,
    margin: 8,
    borderWidth: 1,
    boxShadow: 'none',
    '&:focus-within': { borderColor: theme.colors.secondary },
    textTransform: 'uppercase',
  }),
  placeholder: provided => ({
    ...provided,
    textTransform: 'initial',
  }),
  menu: () => ({
    boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)',
    textTransform: 'uppercase',
  }),
};

const SelectDropdown = ({ label, options, onChange }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(undefined);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const onSelectChange = newValue => {
    toggleOpen();
    setValue(newValue);
    if (onChange) {
      onChange(newValue?.value);
    }
  };

  return (
    <Styled.DropdownWrapper>
      <Styled.Button type="button" onClick={toggleOpen}>
        {label}
        <Styled.ButtonIndicator src={ArrowDownIcon} alt="" isOpen={isOpen} />
      </Styled.Button>
      {isOpen && (
        <Styled.Menu>
          <Select
            autoFocus
            backspaceRemovesValue={false}
            components={{
              DropdownIndicator: () => (
                <Styled.DropdownIndicator src={SearchIcon} alt="" />
              ),
              IndicatorSeparator: null,
            }}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            isClearable={false}
            menuIsOpen
            onChange={onSelectChange}
            options={options}
            placeholder={t('Search')}
            styles={selectStyles}
            tabSelectsValue={false}
            value={value}
          />
        </Styled.Menu>
      )}
      {isOpen && <Styled.Backdrop onClick={toggleOpen} />}
    </Styled.DropdownWrapper>
  );
};

SelectDropdown.defaultProps = {
  onChange: undefined,
};

SelectDropdown.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default SelectDropdown;
