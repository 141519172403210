import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { breakpoint } from '@styles/breakpoints';

export const VARIANTS = {
  CONTACT: 'CONTACT',
  DEFAULT: 'DEFAULT',
};

export const DEFAULT = () => css`
  h2 {
    display: block;
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  p {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
  }
`;

export const CONTACT = theme => css`
  margin-top: 36px;

  @media ${breakpoint.md} {
    margin-top: -24px;
  }

  & > h3 {
    font-size: 18px;
    line-height: 1.3;
    font-family: ${theme.fonts.secondary.bold};
    margin-bottom: 3em;
  }

  & > h4 {
    font-size: 14px;
    line-height: 1.4;
    font-family: ${theme.fonts.secondary.bold};
    padding-bottom: 0.5em;
    border-bottom: 1px solid ${theme.colors.strokeVariant};
    margin-bottom: 1em;
  }

  & > p {
    font-size: 13px;
    line-height: 1.3;
    margin-bottom: 3em;
  }
`;

const VARIANT = {
  [VARIANTS.CONTACT]: CONTACT,
  [VARIANTS.DEFAULT]: DEFAULT,
};

export const Content = styled.div`
  strong {
    font-weight: 600;
  }

  ${({ variant, theme }) => {
    if (variant) {
      return VARIANT[variant](theme);
    }
    return null;
  }}
`;
