import React, { useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { ToastContainer } from 'react-toastify';
import { Switch } from 'react-router-dom';
import { Route, ProtectedRoute, AuthRoute, ROLES } from '@components/Route';

import BaseLayout from '@layouts/BaseLayout';
import Home from '@containers/Home';
import Player from '@containers/MainPlayer';
import Albums from '@containers/Albums';
import { ALBUMS_TYPES } from '@root/stores/albumsStore';
import Playlists from '@containers/Playlists';
import SignInPage from '@containers/SignIn';
import SignUpPage from '@containers/SignUp';
import ComposerSignUp from '@containers/ComposerSignUp';
import ConfirmationPage from '@containers/Confirmation';
import Crew from '@containers/Crew';
import Credits from '@containers/Credits';
import Topics from '@containers/Topics';
import Tutorials from '@containers/Tutorials';
import Agents from '@containers/Agents';
import SearchResultsPage from '@containers/SearchResults';
import ExtraForYou from '@containers/ExtraForYou';
import PasswordForgotten from '@containers/PasswordForgotten';
import PasswordReset from '@containers/PasswordReset';
import WriteForUs from '@containers/WriteForUs';
import AccountPage from '@containers/Account';
import ContactUs from '@containers/ContactUs';
import RequestMusic from '@containers/RequestMusic';
import AlbumListing from '@containers/AlbumListing';
import Licenses from '@containers/Licenses';
import MyAlbums from '@containers/MyAlbums';
import Composer, { TYPES as COMPOSER_TYPES } from '@containers/Composer';
import MyProjects from '@containers/MyProjects';
import PlaylistListing from '@containers/PlaylistListing';
import Contracts, { TYPES as CONTRACTS_TYPES } from '@containers/Contracts';
import Labels from '@containers/Labels';
import Label from '@containers/Label';
import MyDownloads from '@containers/MyDownloads';
import CookieBanner from '@components/CookieBanner';
import AccountDelete from '@containers/AccountDelete';
import TrackModal from '@components/TrackModal';
import NotFound from '@containers/NotFound';
import AddToProjectModal from '@components/AddToProjectModal';
import BriefingPage, { FORM_TYPE } from '@containers/BriefingPage';
import CmsSimplePage, { TYPES as CMSType } from '@containers/CmsSimplePage';
import AboutUs from '@containers/AboutUs';
import OAuthForm from '@containers/OAuthForm';
import LicenseApplication from '@containers/LicenseApplication';
// import WelcomeModal from '@components/WelcomeModal';
import CookiesPage from '@containers/CookiesPage/CookiesPage';
import Collections from '@containers/Collections';
import Statements from '@containers/Statements';
import FakeAccountModal from '@containers/BlockDownload/FakeAccountModal';
import GeoBlockingModal from '@containers/BlockDownload/GeoBlockingModal';

import ComposerWebsite from '@containers/ComposerWebsite/ComposerWebsite';
import ComposerWebsites from '@containers/ComposerWebsite/ComposerWebsites';

import routes from '@routes';
import useBreakpoint from '@hooks/useBreakpoint';
import { isLanguage, LANG_KEYS } from '@utils/i18n';

import 'react-toastify/dist/ReactToastify.css';

const AppRoutes = ({
  authStore: { validateCustomerSession, isAuthenticated },
  playerStore: { initPlayer },
  favoritesStore: { getFavorites },
  sidebarStore: { openSideBar },
  cookiesStore: { cookiesInit },
}) => {
  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  useEffect(() => {
    validateCustomerSession();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      getFavorites();
    }
  }, [isAuthenticated]);

  /* Fix for mobile menu styling */
  useEffect(() => {
    if (isMobile) {
      openSideBar();
    }
  }, [isMobile]);

  useLayoutEffect(() => {
    initPlayer();
    cookiesInit();
  }, []);

  return (
    <>
      <BaseLayout>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path={routes.albums}>
            <Albums />
          </Route>
          <Route exact path={routes.album()}>
            <AlbumListing />
          </Route>
          <Route exact path={routes.playlistsOfCategory()}>
            <Playlists />
          </Route>
          <Route path={routes.playlists}>
            <Playlists />
          </Route>
          <Route exact path={routes.playlist()}>
            <PlaylistListing />
          </Route>
          <Route exact path={routes.collections()}>
            <Collections />
          </Route>
          <AuthRoute exact path={routes.auth.signIn}>
            <SignInPage />
          </AuthRoute>
          <AuthRoute exact path={routes.auth.signUp}>
            <SignUpPage />
          </AuthRoute>
          {!isLanguage(LANG_KEYS.CZ) && (
            <AuthRoute exact path={routes.auth.composerSignUp}>
              <ComposerSignUp />
            </AuthRoute>
          )}
          <AuthRoute exact path={routes.auth.confirmation}>
            <ConfirmationPage />
          </AuthRoute>
          <AuthRoute exact path={routes.password.forgotten}>
            <PasswordForgotten />
          </AuthRoute>
          <AuthRoute exact path={routes.password.reset}>
            <PasswordReset />
          </AuthRoute>
          <Route exact path={routes.search()}>
            <SearchResultsPage />
          </Route>
          <Route exact path={routes.crew}>
            <Crew />
          </Route>
          <Route exact path={routes.composerWebsite()}>
            <ComposerWebsite />
          </Route>
          <Route exact path={routes.composerWebsites}>
            <ComposerWebsites />
          </Route>
          <Route exact path={routes.topics}>
            <Topics />
          </Route>
          <Route exact path={routes.extra4you}>
            <ExtraForYou />
          </Route>
          {!isLanguage(LANG_KEYS.CZ) && (
            <Route exact path={routes.writeForUs}>
              <WriteForUs />
            </Route>
          )}
          {!isLanguage(LANG_KEYS.CZ) && (
            <Route exact path={routes.tutorials}>
              <Tutorials />
            </Route>
          )}
          {!isLanguage(LANG_KEYS.CZ) && (
            <Route exact path={routes.agents()}>
              <Agents />
            </Route>
          )}
          {!isLanguage(LANG_KEYS.CZ) && (
            <Route exact path={routes.credits}>
              <Credits />
            </Route>
          )}
          <Route exact path={routes.contactUs}>
            <ContactUs />
          </Route>
          <Route exact path={routes.requestMusic}>
            <RequestMusic />
          </Route>
          {!isLanguage(LANG_KEYS.EN) && (
            <Route exact path={routes.license}>
              <Licenses />
            </Route>
          )}
          <Route exact path={routes.topic()}>
            <Albums type={ALBUMS_TYPES.TOPICS} />
          </Route>
          <ProtectedRoute exact path={routes.auth.delete}>
            <AccountDelete />
          </ProtectedRoute>
          <ProtectedRoute exact path={routes.account}>
            <AccountPage />
          </ProtectedRoute>
          <ProtectedRoute exact path={routes.myAlbums}>
            <MyAlbums />
          </ProtectedRoute>
          <ProtectedRoute exact path={routes.myProjects}>
            <MyProjects />
          </ProtectedRoute>
          <ProtectedRoute exact path={routes.statements}>
            <Statements />
          </ProtectedRoute>
          <ProtectedRoute exact path={routes.briefings} role={ROLES.COMPOSER}>
            <Composer type={COMPOSER_TYPES.briefings} />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path={routes.releasedTracks}
            role={ROLES.COMPOSER}
          >
            <Composer type={COMPOSER_TYPES.releasedTracks} />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path={routes.uploadedTracks}
            role={ROLES.COMPOSER}
          >
            <Composer type={COMPOSER_TYPES.uploadedTracks} />
          </ProtectedRoute>
          <ProtectedRoute exact path={routes.faqs} role={ROLES.COMPOSER}>
            <Composer type={COMPOSER_TYPES.faqs} />
          </ProtectedRoute>
          <ProtectedRoute exact path={routes.briefing()}>
            <BriefingPage action={FORM_TYPE.ADD} />
          </ProtectedRoute>
          <ProtectedRoute exact path={routes.track()}>
            <BriefingPage action={FORM_TYPE.UPDATE} />
          </ProtectedRoute>
          <ProtectedRoute exact path={routes.myContracts} role={ROLES.COMPOSER}>
            <Contracts type={CONTRACTS_TYPES.myContracts} />
          </ProtectedRoute>
          {process.env.REACT_APP_HELLO_SIGN_ENABLED === 'true' && (
            <ProtectedRoute
              exact
              path={routes.helloContracts}
              role={ROLES.COMPOSER}
            >
              <Contracts type={CONTRACTS_TYPES.helloContracts} />
            </ProtectedRoute>
          )}
          <ProtectedRoute
            exact
            path={routes.uploadContract}
            role={ROLES.COMPOSER}
          >
            <Contracts type={CONTRACTS_TYPES.uploadContract} />
          </ProtectedRoute>
          <Route exact path={routes.labels}>
            <Labels />
          </Route>
          <Route exact path={routes.label()}>
            <Label />
          </Route>
          <ProtectedRoute exact path={routes.myDownloads}>
            <MyDownloads />
          </ProtectedRoute>
          <Route exact path={routes.dataProtection}>
            <CmsSimplePage type={CMSType.dataProtection} />
          </Route>
          <Route exact path={routes.impressum}>
            <CmsSimplePage type={CMSType.impressum} />
          </Route>
          <Route exact path={routes.agb}>
            <CmsSimplePage type={CMSType.agb} />
          </Route>
          <Route exact path={routes.aboutUs}>
            <AboutUs />
          </Route>
          <Route exact path={routes.cookies}>
            <CookiesPage />
          </Route>
          <AuthRoute exact path={routes.oAuthForm}>
            <OAuthForm />
          </AuthRoute>
          <Route exact path={routes.licenseApplication()}>
            <LicenseApplication />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </BaseLayout>
      <Player />
      <ToastContainer />
      <TrackModal />
      <AddToProjectModal />
      <FakeAccountModal />
      <GeoBlockingModal />
      {/* Disabled until further notice */}
      {/* {!isLanguage(LANG_KEYS.CZ) && <WelcomeModal />} */}
      <CookieBanner />
    </>
  );
};

AppRoutes.propTypes = {
  authStore: PropTypes.shape({
    validateCustomerSession: PropTypes.func,
    isAuthenticated: PropTypes.bool,
  }).isRequired,
  playerStore: PropTypes.shape({ initPlayer: PropTypes.func }).isRequired,
  favoritesStore: PropTypes.shape({ getFavorites: PropTypes.func }).isRequired,
  sidebarStore: PropTypes.shape({
    openSideBar: PropTypes.func,
  }).isRequired,
  cookiesStore: PropTypes.shape({
    cookiesInit: PropTypes.func,
  }).isRequired,
};

export default inject(
  'authStore',
  'playerStore',
  'favoritesStore',
  'sidebarStore',
  'cookiesStore',
)(observer(AppRoutes));
