import styled from '@emotion/styled';
import { css } from '@emotion/react';

const ERROR = theme => css`
  border-color: ${theme.colors.error};
`;

export const ErrorWrapper = styled.div`
  border: 1px solid transparent;
  border-radius: 4px;
  ${({ withError, theme }) => withError && ERROR(theme)}
`;
