import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import ModalWrapper from '@components/Modal/ModalWrapper';
import * as Styled from '@components/Modal/style';
import { VARIANTS } from '@components/Modal/style';
import { toggleBodyScroll } from '@utils/scrollLock';
import CloseIconWhite from '@assets/images/icons/close-icon-white.svg';
import CloseIcon from '@assets/images/icons/close-icon.svg';

export { VARIANTS } from '@components/Modal/style';

const Modal = ({ closeHandler, children, isOpen, variant, portalId }) => {
  const isMounted = useRef(false);

  const [isVisible, setIsVisible] = useState(false);

  const keyPressHandler = e => {
    if (e.which === 27) {
      closeHandler();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', keyPressHandler);

    return () => {
      window.removeEventListener('keydown', keyPressHandler);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      toggleBodyScroll(true);
      setIsVisible(true);
    } else if (isMounted.current) {
      toggleBodyScroll(false);
      setTimeout(() => {
        setIsVisible(false);
      }, 500);
    }
    isMounted.current = true;
  }, [isOpen]);

  return (
    <ModalWrapper portalId={portalId} isVisible={isVisible}>
      <Styled.ModalAnimations />
      <CSSTransition
        in={isOpen}
        timeout={200}
        classNames="modal-cover"
        unmountOnExit
      >
        <Styled.Cover onClick={closeHandler} variant={variant} />
      </CSSTransition>
      <CSSTransition
        in={isOpen}
        timeout={400}
        classNames="modal-content"
        unmountOnExit
      >
        <Styled.Modal variant={variant}>
          <Styled.Body>
            <Styled.GradientModalCloseBox
              as="button"
              onClick={closeHandler}
              type="button"
              data-role="close"
            >
              <img
                src={variant === VARIANTS.GRADIENT ? CloseIconWhite : CloseIcon}
                alt="remove"
              />
            </Styled.GradientModalCloseBox>
            {children}
          </Styled.Body>
        </Styled.Modal>
      </CSSTransition>
    </ModalWrapper>
  );
};

Modal.defaultProps = {
  variant: VARIANTS.DEFAULT,
  portalId: undefined,
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  closeHandler: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  portalId: PropTypes.string,
};

export default Modal;
