import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Box } from 'rebass';
import { Helmet } from 'react-helmet';
import ContactForm, {
  VARIANTS as FORM_VARIANTS,
} from '@components/ContactForm';
import {
  PageContent,
  PageWrapper,
  FormWrapper,
} from '@components/ContactForm/style';
import Header from '@components/Header';
import { MainContent } from '@styles/globalStyles';
import Card from '@components/Card';
import Text, { VARIANTS as TEXT_VARIANTS } from '@components/Text';
import CmsBody, { VARIANTS as CMS_VARIANTS } from '@components/CmsBody';

const WriteForUs = ({
  customerStore: {
    customer: { data },
  },
  contactStore: {
    onWriteForUsSubmit,
    writeForUsForm,
    clearForms,
    getWriteForUsContent,
    writeForUsPage,
  },
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    getWriteForUsContent();

    return () => clearForms();
  }, []);

  const initialData = data
    ? {
        full_name: `${data.first_name} ${data.last_name}`,
        email: data.email,
      }
    : null;

  return (
    <MainContent>
      <Helmet>
        <title>{t('Write for us')}</title>
      </Helmet>
      <Header title={t('Write for us')} withSeparator />

      <PageWrapper>
        <PageContent>
          <CmsBody
            html={writeForUsPage.data.body}
            isLoading={writeForUsPage.isLoading}
            error={writeForUsPage.error}
            variant={CMS_VARIANTS.CONTACT}
          />
        </PageContent>
        <FormWrapper>
          <Card>
            <Box mb={4}>
              <Box mb={3}>
                <Text
                  variant={TEXT_VARIANTS.H4}
                  style={{ textTransform: 'initial' }}
                >
                  {t('Apply to become an Intervox composer')}
                </Text>
              </Box>

              <Text variant={TEXT_VARIANTS.BODY}>
                {t('Fill in the form and we’ll process your enquiry.')}
              </Text>
            </Box>
            <ContactForm
              onSubmit={onWriteForUsSubmit}
              formStatus={toJS(writeForUsForm)}
              initialData={initialData}
              variant={FORM_VARIANTS.WRITE_FOR_US}
            />
          </Card>
        </FormWrapper>
      </PageWrapper>
    </MainContent>
  );
};

WriteForUs.propTypes = {
  contactStore: PropTypes.object.isRequired,
  customerStore: PropTypes.object.isRequired,
};

export default inject('contactStore', 'customerStore')(observer(WriteForUs));
