import { makeAutoObservable, runInAction } from 'mobx';
import { API, APIRoutes } from '@app/api';

export class DownloadsStore {
  constructor() {
    makeAutoObservable(this);
  }

  downloadsTracks = {
    expandedTrack: null,
    expandedAlternatives: false,
    isLoading: false,
    data: [],
    pagesAmount: 0,
    error: null,
  };

  getDownloadsTracks = async (page = 1, per = 20) => {
    try {
      this.downloadsTracks.isLoading = true;

      const {
        data: {
          tracks,
          meta: { total_count },
        },
      } = await API(APIRoutes.DOWNLOADS_LIST(page, per));

      runInAction(() => {
        this.downloadsTracks.data = tracks;
        this.downloadsTracks.pagesAmount = Math.ceil(total_count / per);
      });
    } catch (error) {
      runInAction(() => {
        this.downloadsTracks.error = error;
      });
    } finally {
      runInAction(() => {
        this.downloadsTracks.isLoading = false;
      });
    }
  };

  toggleTrack = trackId => {
    const { expandedTrack } = this.downloadsTracks;
    this.downloadsTracks.expandedTrack =
      expandedTrack === trackId ? null : trackId;
    this.downloadsTracks.expandedAlternatives = false;
  };

  toggleTrackAlternatives = trackId => {
    const { expandedTrack, expandedAlternatives } = this.downloadsTracks;

    if (expandedTrack === trackId) {
      if (expandedAlternatives) {
        this.downloadsTracks.expandedAlternatives = false;
      } else {
        this.downloadsTracks.expandedAlternatives = true;
        this.getTrackAlternatives(trackId);
      }
    } else {
      this.downloadsTracks.expandedTrack = trackId;
      this.downloadsTracks.expandedAlternatives = true;
      this.getTrackAlternatives(trackId);
    }
  };

  getTrackAlternatives = async trackId => {
    const track = this.downloadsTracks.data.find(({ id }) => id === trackId);

    if (!track.alternatives) {
      try {
        track.alternatives = { isLoading: true };

        const {
          data: { tracks },
        } = await API(APIRoutes.TRACK_ALTERNATIVES(trackId));

        runInAction(() => {
          track.alternatives.data = tracks;
        });
      } catch (error) {
        runInAction(() => {
          track.alternatives.error = error;
        });
      } finally {
        runInAction(() => {
          track.alternatives.isLoading = false;
        });
      }
    }
  };
}

export default new DownloadsStore();
