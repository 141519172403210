/* eslint-disable consistent-return */
import { currentLanguage } from '@utils/i18n';

import LogoFull from '@assets/images/logos/intervox-logo-full.svg';
import LogoMobile from '@assets/images/logos/intervox-logo-mobile.svg';
import XLogo from '@assets/images/logos/x-symbol-logo.svg';

import ProvoxLogoFull from '@assets/images/logos/ProVox_Music_Library-logo.png';
import ProvoxFooterLogo from '@assets/images/logos/PML_arrow_.png';
import ProvoxXLogo from '@assets/images/logos/provox_arrow.svg';

import FooterLogo from '@assets/images/logos/logo-blue@3x.jpg';

const ProvoxLogoMobile = ProvoxLogoFull;

export const COUNTRY_ANALYTIC_CODES = {
  EN: 'G-863CCCPNNG',
  DE: 'G-HXT3CXMSQD',
  IT: 'G-P8KTMMEVFY',
  ES: 'G-BPE8C5LNC2',
  PT: 'G-135PPKTE6',
  /* TODO: UPDATE FOR CZECH */
  CZ: 'G-863CCCPNNG',
};

export const COUNTRY_TAG_CODES = {
  EN: 'GTM-MBH7HM7',
  DE: 'GTM-PFCVKP4',
  IT: 'GTM-K7HMTK3',
  ES: 'GTM-TGZ45RJ',
  PT: 'GTM-WTLNRGX',
  /* TODO: UPDATE FOR CZECH */
  CZ: 'GTM-MBH7HM7',
};

export const LOGO_FULL = (() => {
  switch (currentLanguage) {
    case 'CZ':
      return ProvoxLogoFull;

    default:
      return LogoFull;
  }
})();

export const SIDEBAR_CLOSED = (() => {
  switch (currentLanguage) {
    case 'CZ':
      return ProvoxXLogo;

    default:
      return XLogo;
  }
})();

export const LOGO_MOBILE = (() => {
  switch (currentLanguage) {
    case 'CZ':
      return ProvoxLogoMobile;

    default:
      return LogoMobile;
  }
})();

export const FOOTER_LOGO = (() => {
  switch (currentLanguage) {
    case 'CZ':
      return ProvoxFooterLogo;

    default:
      return FooterLogo;
  }
})();

export const COPY_TEXT = (() => {
  switch (currentLanguage) {
    case 'CZ':
      return 'ProVox Music Library';

    default:
      return 'Intervox Production Music';
  }
})();

export const PAGE_TITLE = (() => {
  switch (currentLanguage) {
    case 'CZ':
      return 'ProVox Music Library';

    default:
      return 'Intervox - Intervox Production Music';
  }
})();

export const PHONE = {
  DE: '+49 (0)89 189 409 0',
  EN: '+44 (0)20 7563 7028',
  PT: '+49 (0)89 189 409 0',
  IT: '+39 06 556 2061',
  ES: '+34 911 230400',
  CZ: '+420 603 142 873',
};

export const MAIL = {
  EN: 'info@intervox.co.uk',
  DE: 'info@intervox.de',
  ES: 'info@intervoxmusic.es',
  IT: 'info@intervox.it',
  PT: 'info@intervox.pt',
  CZ: 'info@provoxmusic.cz',
};

export const YOUTUBE = 'https://youtube.com/user/intervoxprodmusic';

export const FACEBOOK = {
  ES: 'https://www.facebook.com/Ediciones-Musicales-Intervox-364502943593625/',
  DE: 'https://www.facebook.com/intervoxmusic/',
  IT: 'https://www.facebook.com/intervoxitalia',
  EN: 'https://www.facebook.com/intervoxmusic/',
  PT: 'https://www.facebook.com/intervoxmusic/',
  /* TODO: UPDATE FOR CZECH */
  CZ: 'https://www.facebook.com/intervoxmusic/',
};

export const SPOTIFY =
  'https://open.spotify.com/user/intervoxplay?si=KB-74wxQQzuFOKXC_upsag';

export const INSTAGRAM = {
  IT: 'https://www.instagram.com/intervoxitalia/?hl=it',
  ES: 'https://www.instagram.com/edicionesmusicalesintervox/',
  DE: 'https://www.instagram.com/intervoxproductionmusic/',
};

export const WELCOME_VIDEOS = {
  DE: 'https://youtu.be/fiCypUe27-s',
  EN: 'https://youtu.be/lcCTj2zmYKc',
  PT: 'https://youtu.be/lcCTj2zmYKc',
  IT: 'https://youtu.be/9b04NVtdcY0',
  ES: 'https://youtu.be/lcCTj2zmYKc',
  // CZ: 'https://youtu.be/fiCypUe27-s',
};

export const HELLO_SIGN_CLIENT_ID = {
  DE: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID_DE,
  EN: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID_EN,
  PT: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID_PT,
  IT: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID_IT,
  ES: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID_ES,
  CZ: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID_CZ,
};
