import React from 'react';
import PropTypes from 'prop-types';

import { StyledCard, Content, VARIANTS } from '@components/Card/style';

const Card = ({ variant, children, className }) => (
  <StyledCard {...{ variant, className }}>
    <Content>{children}</Content>
  </StyledCard>
);

Card.defaultProps = {
  variant: VARIANTS.TRANSPARENT,
  children: null,
  className: '',
};

Card.propTypes = {
  variant: PropTypes.oneOf(Object.keys(VARIANTS)),
  children: PropTypes.node,
  className: PropTypes.string,
};

export * from './style';
export default Card;
