import React from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';

import routes from '@routes';
import Header from '@components/Header';
import { MainContent } from '@styles/globalStyles';
import Tabs from '@components/Tabs';
import MyContracts from '@containers/Contracts/components/MyContracts';
import HelloContracts from '@containers/Contracts/components/HelloContracts';
import UploadContract from '@containers/Contracts/components/UploadContract';
import i18n from '@utils/i18n';

export const TYPES = {
  helloContracts: i18n.t('Digital Contracts'),
  myContracts: i18n.t('Contracts History'),
  uploadContract: i18n.t('Upload Contract'),
};

const TABS = [
  ...(process.env.REACT_APP_HELLO_SIGN_ENABLED === 'true'
    ? [{ name: TYPES.helloContracts, value: routes.helloContracts }]
    : []),
  { name: TYPES.myContracts, value: routes.myContracts },
  {
    name: TYPES.uploadContract,
    value: routes.uploadContract,
  },
];

const Component = type => {
  switch (type) {
    case TYPES.myContracts:
      return <MyContracts />;
    case TYPES.helloContracts:
      return <HelloContracts />;
    case TYPES.uploadContract:
      return <UploadContract />;
    default:
      return null;
  }
};

const Contracts = ({ routerStore: { history }, type }) => {
  return (
    <MainContent>
      <Header
        title={type}
        nav={
          <Tabs
            tabs={TABS}
            activeTab={TABS.find(({ name }) => name === type).value}
            setActiveTab={history.push}
          />
        }
      />
      {Component(type)}
    </MainContent>
  );
};

Contracts.propTypes = {
  routerStore: PropTypes.shape({
    history: PropTypes.object.isRequired,
  }).isRequired,
  type: PropTypes.oneOf(Object.values(TYPES)).isRequired,
};

export default inject('customerStore', 'routerStore')(observer(Contracts));
