import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash-es';
import { Link } from 'react-router-dom';

import Text, { VARIANTS as TEXT_VARIANTS } from '@components/Text';
import Preloader from '@components/Preloader';
import { MARGINS } from '@styles/globalStyles';
import Tooltip, { TRIGGER } from '@components/Tooltip';
import {
  Wrapper,
  PreloaderWrapper,
  Cover,
  StyledText,
  StyledSeparator,
  Indicator,
} from '@components/TrackModal/components/style';
import Button, { VARIANTS as BUTTON_VARIANTS } from '@components/Button';
import DisabledDownloadTooltipBody from '@components/IconButtons/DisabledDownloadTooltipBody';
import { APIRoutes } from '@app/api';
import useBreakpoint from '@hooks/useBreakpoint';
import CuesheetButton, { TYPES } from '@components/CuesheetButton';
import DownloadButtonTooltipBody from '@components/IconButtons/DownloadButton/DownloadButtonTooltipBody';
import ArrowDownIcon from '@assets/images/icons/arrow-down.svg';
import routes from '@routes';
import { inject, observer } from 'mobx-react';

const TrackModalBody = ({
  trackData,
  isLoading,
  isAuthenticated,
  blockDownloadStore: { isDownloadBlocked, openBlockedDownloadModal },
}) => {
  const { t } = useTranslation();

  const {
    name: title,
    labels,
    composers,
    lyricists,
    arrangers,
    isrc,
    description,
    genres,
    topics,
    moods,
    tempo,
    keywords,
    album,
    external_id,
    id,
    instrumentations,
    file,
    file_wav,
  } = trackData;

  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  const [isCollapsed, setIsCollapsed] = useState(isMobile);

  const formatAuthors = arr =>
    arr.map(
      ({ first_name, last_name }, index) =>
        `${first_name} ${last_name}${index < arr.length - 1 ? ' / ' : ''}`,
    );

  return (
    <>
      {isLoading && (
        <PreloaderWrapper>
          <Preloader center />
        </PreloaderWrapper>
      )}
      {!isEmpty(trackData) && (
        <Wrapper
          isCollapsed={isCollapsed}
          onClick={() => setIsCollapsed(false)}
        >
          <Flex flexWrap="wrap" p={4} mx={-3}>
            <Box width={[1, 1, 1, 1 / 3]} px={[0, 0, 0, 3]}>
              <Box p={[3, 3, 3, 0]} textAlign="center">
                <Link to={routes.album(album?.uuid)}>
                  <Cover src={album?.cover.url} alt={album?.name} />
                </Link>
              </Box>
              <Box width={1} my={3}>
                <Text variant={TEXT_VARIANTS.H3} mb={MARGINS.SMALL}>
                  {album?.name}
                </Text>
                <StyledSeparator />
              </Box>
              <StyledText>
                <strong>{t('Title')}:</strong> {title}
              </StyledText>
              <StyledText>
                <strong>{t('Composer')}:</strong> {formatAuthors(composers)}
              </StyledText>
              {lyricists.length > 0 && (
                <StyledText>
                  <strong>{t('Lyricist')}:</strong> {formatAuthors(lyricists)}
                </StyledText>
              )}
              {arrangers.length > 0 && (
                <StyledText>
                  <strong>{t('Arranger')}:</strong> {formatAuthors(arrangers)}
                </StyledText>
              )}
              <StyledText>
                <strong>{t('Label')}:</strong>{' '}
                {labels.map(({ name }) => `${name} / `)}
                {album?.label_code}
              </StyledText>
              <StyledText>
                <strong>{t('CD Nr')}:</strong> {album?.directory_name}
              </StyledText>
              <StyledText>
                <strong>{t('EAN Nr')}:</strong> {album?.ean}
              </StyledText>
              <StyledText>
                <strong>{t('ISRC')}:</strong> {isrc}
              </StyledText>
            </Box>
            <Flex
              flexDirection="column"
              width={[1, 1, 1, 2 / 3]}
              px={[0, 0, 0, 3]}
            >
              <Box flex={1}>
                <StyledText>
                  <strong>{t('Description')}</strong>
                </StyledText>
                <StyledText mb={MARGINS.SMALL}>{description}</StyledText>
                <StyledText>
                  <strong>{t('Genre')}</strong>
                </StyledText>
                <StyledText mb={MARGINS.SMALL}>
                  {genres.map(
                    ({ name }, index) =>
                      `${name}${index < genres.length - 1 ? ' / ' : ''}`,
                  )}
                </StyledText>
                <StyledText>
                  <strong>{t('Topic')}</strong>
                </StyledText>
                <StyledText mb={MARGINS.SMALL}>
                  {' '}
                  {topics.map(
                    ({ name }, index) =>
                      `${name}${index < topics.length - 1 ? ' / ' : ''}`,
                  )}
                </StyledText>
                <StyledText>
                  <strong>{t('Mood')}</strong>
                </StyledText>
                <StyledText mb={MARGINS.SMALL}>
                  {moods.map(
                    ({ name }, index) =>
                      `${name}${index < moods.length - 1 ? ' / ' : ''}`,
                  )}
                </StyledText>
                <StyledText>
                  <strong>{t('Tempo')}</strong>
                </StyledText>
                <StyledText mb={MARGINS.SMALL}>{tempo?.name}</StyledText>
                <StyledText>
                  <strong>{t('Keywords')}</strong>
                </StyledText>
                <StyledText mb={MARGINS.SMALL}>
                  {keywords.map(
                    ({ name }, index) =>
                      `${name}${index < keywords.length - 1 ? ', ' : ''}`,
                  )}
                </StyledText>
                <StyledText>
                  <strong>{t('Instrumentation')}</strong>
                </StyledText>
                <StyledText mb={MARGINS.SMALL}>
                  {instrumentations.map(
                    ({ name }, index) =>
                      `${name}${
                        index < instrumentations.length - 1 ? ' / ' : ''
                      }`,
                  )}
                </StyledText>
              </Box>
              <Flex
                flexWrap="wrap"
                mt={4}
                justifyContent={isMobile ? 'center' : 'space-between'}
                mx={-2}
              >
                <Box mr={1} mb={2}>
                  <Tooltip
                    tooltipBody={
                      isAuthenticated ? (
                        <DownloadButtonTooltipBody
                          {...{
                            id,
                            ...(file?.url &&
                              external_id && {
                                mp3: APIRoutes.TRACK_DOWNLOAD(
                                  external_id,
                                  'mp3',
                                ),
                              }),
                            ...(file_wav?.url &&
                              external_id && {
                                wav: APIRoutes.TRACK_DOWNLOAD(
                                  external_id,
                                  'wav',
                                ),
                              }),
                          }}
                        />
                      ) : (
                        <DisabledDownloadTooltipBody />
                      )
                    }
                    isActive={!isDownloadBlocked}
                    trigger={isAuthenticated ? TRIGGER.CLICK : TRIGGER.HOVER}
                  >
                    <div>
                      <Button
                        {...{
                          variant: BUTTON_VARIANTS.SECONDARY,
                          label: t('Download track'),
                          disabled: !isAuthenticated,
                          ...(isDownloadBlocked && {
                            onClick: openBlockedDownloadModal,
                          }),
                        }}
                      />
                    </div>
                  </Tooltip>
                </Box>

                <Box mb={2} mr={1}>
                  <Tooltip
                    tooltipBody={<DisabledDownloadTooltipBody />}
                    trigger="hover"
                    isActive={!isAuthenticated}
                  >
                    <div>
                      <CuesheetButton
                        {...{
                          isAuthenticated,
                          id,
                          albumUUID: album?.uuid,
                          type: TYPES.TRACK,
                        }}
                      />
                    </div>
                  </Tooltip>
                </Box>
              </Flex>
            </Flex>
          </Flex>
          {isCollapsed && (
            <Indicator>
              <img src={ArrowDownIcon} alt="" />
            </Indicator>
          )}
        </Wrapper>
      )}
    </>
  );
};

TrackModalBody.defaultProps = {};

TrackModalBody.propTypes = {
  trackData: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  blockDownloadStore: PropTypes.object.isRequired,
};

export default inject('blockDownloadStore')(observer(TrackModalBody));
