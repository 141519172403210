import { makeAutoObservable, runInAction } from 'mobx';
import { API, APIRoutes } from '@app/api';

export class ExtraForYouStore {
  constructor() {
    makeAutoObservable(this);
  }

  extraForYou = {
    isLoading: false,
    data: [],
    error: null,
  };

  getExtraForYou = async (page = 1, per = 4) => {
    try {
      this.extraForYou.isLoading = true;

      const {
        data: { tracks },
      } = await API(APIRoutes.EXTRA_FOR_YOU(page, per));
      runInAction(() => {
        this.extraForYou.data.push(...tracks);
      });
    } catch (error) {
      runInAction(() => {
        this.extraForYou.error = error;
      });
    } finally {
      runInAction(() => {
        this.extraForYou.isLoading = false;
      });
    }
  };
}

export default new ExtraForYouStore();
