import { API, APIRoutes } from '@app/api';
import { makeAutoObservable, runInAction } from 'mobx';
import { groupBy } from 'lodash-es';
import downloadFile from '@utils/downloadFile';
import customerStore from '@stores/customerStore';

export class StatementsStore {
  constructor() {
    makeAutoObservable(this);
  }

  documents = {
    isLoading: false,
    data: [],
    meta: {},
    error: null,
  };

  getDocuments = async () => {
    try {
      this.documents.isLoading = true;

      const {
        data: { documents },
      } = await API(APIRoutes.DOCUMENTS);

      if (documents.length > 0) {
        const groupedDocuments = groupBy(documents, 'subject_to_display');

        const documentsData = Object.entries(groupedDocuments).map(
          ([key, value]) => ({
            title: key,
            files: value,
          }),
        );

        runInAction(() => {
          this.documents.data = documentsData;
        });
      } else {
        runInAction(() => {
          this.documents.data = [];
        });
      }
    } catch (error) {
      runInAction(() => {
        this.documents.error = error;
      });
    } finally {
      runInAction(() => {
        this.documents.isLoading = false;
      });
    }
  };

  formatFileName = fileName =>
    `${customerStore.customer.data.last_name}_${fileName
      .toLowerCase()
      .replace(' - ', '_')
      .replace(' ', '-')}`;

  downloadLabelContract = (uuid, fileName) =>
    downloadFile(
      APIRoutes.DOWNLOAD_CUSTOMER_TRACKS(uuid),
      this.formatFileName(fileName),
      'pdf',
    );

  downloadContract = (id, fileName) =>
    downloadFile(APIRoutes.DOWNLOAD_CONTRACT(id), fileName, 'pdf');
}

export default new StatementsStore();
