import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Box } from 'rebass';

export const TableWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
`;

export const StyledTable = styled.table`
  width: 100%;

  th,
  td {
    text-align: left;
    font-size: 12px;
    line-height: 1.3;
  }

  th:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  th:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export const HeaderRow = styled.tr`
  background: ${({ theme }) => theme.table.headBackground};
`;

export const HeaderCell = styled.th`
  padding: 20px 12px;
  text-transform: uppercase;
`;

export const Cell = styled.td`
  padding: 16px 12px;
  vertical-align: middle;
`;

export const HIGHLIGHTS = {
  NONE: 'NONE',
  LIGHT: 'LIGHT',
  NORMAL: 'NORMAL',
};

const NONE = (theme, isExpanded) => css`
  background: ${isExpanded ? theme.table.expandedBackground : 'transparent'};
`;

const LIGHT = theme => css`
  background: ${theme.table.lightHighlightBackground};
`;

const NORMAL = theme => css`
  background: ${theme.table.highlightBackground};
`;

const HIGHLIGHT = {
  [HIGHLIGHTS.NONE]: NONE,
  [HIGHLIGHTS.LIGHT]: LIGHT,
  [HIGHLIGHTS.NORMAL]: NORMAL,
};

export const Row = styled.tr`
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'initial')};

  border-bottom: 1px solid
    ${({ theme, isExpanded }) =>
      isExpanded ? 'transparent' : theme.colors.strokeVariant};

  ${({ theme, highlight, isExpanded }) => {
    if (highlight) {
      return HIGHLIGHT[highlight](theme, isExpanded);
    }
    return null;
  }}

  transition: background 0.1s;

  &:hover {
    background: ${({ theme }) => theme.table.rowHoverBackground};
    .play-button {
      g {
        opacity: 1;
      }
    }
  }
`;

export const ExpandedRow = styled.tr`
  background: ${({ theme }) => theme.table.expandedBackground};
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeVariant};
`;

export const CellBox = styled(Box)`
  padding: 16px 12px;
`;
