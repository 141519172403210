import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { breakpoint } from '@styles/breakpoints';

export const PageContent = styled.div`
  width: 50%;
  padding: 16px;
  padding-right: 128px;

  @media ${breakpoint.lg} {
    padding-right: 64px;
  }

  @media ${breakpoint.md} {
    width: 100%;
    margin-bottom: 16px;
    padding-right: 16px;
  }
`;

export const FormWrapper = styled.div`
  width: 50%;
  padding: 0 16px;

  @media ${breakpoint.md} {
    width: 100%;
    padding: 0 8px;
  }
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 16px -16px 32px;

  @media ${breakpoint.md} {
    margin-bottom: 0;
  }
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`;
