import React from 'react';
import { currentLanguage } from '@utils/i18n';
import * as Styled from '@components/Footer/components/SocialIcons/style';
import EmailIcon from '@assets/images/socials/email.svg';
import YoutubeIcon from '@assets/images/socials/youtube.svg';
import FacebookIcon from '@assets/images/socials/facebook.svg';
import SpotifyIcon from '@assets/images/socials/spotify.svg';
import InstagramIcon from '@assets/images/socials/instagram.svg';

import {
  MAIL,
  YOUTUBE,
  FACEBOOK,
  SPOTIFY,
  INSTAGRAM,
} from '@utils/i18n/locationsContent';

const LINKS = {
  MAIL: MAIL[currentLanguage],
  YOUTUBE,
  FACEBOOK: FACEBOOK[currentLanguage],
  SPOTIFY,
  INSTAGRAM: INSTAGRAM[currentLanguage],
};

export const ContactUs = () => (
  <Styled.SocialIconsWrapper>
    <ul>
      <li>
        <a href={`mailto:${MAIL[currentLanguage]}`}>
          <Styled.SocialIcon src={EmailIcon} alt="email" />
        </a>
      </li>
      <li>
        <a href={LINKS.YOUTUBE}>
          <Styled.SocialIcon src={YoutubeIcon} alt="youtube" />
        </a>
      </li>
      {LINKS.FACEBOOK && (
        <li>
          <a href={LINKS.FACEBOOK}>
            <Styled.SocialIcon src={FacebookIcon} alt="facebook" />
          </a>
        </li>
      )}
      <li>
        <a href={LINKS.SPOTIFY}>
          <Styled.SocialIcon src={SpotifyIcon} alt="spotify" />
        </a>
      </li>
      {LINKS.INSTAGRAM && (
        <li>
          <a href={LINKS.INSTAGRAM}>
            <Styled.SocialIcon src={InstagramIcon} alt="instagram" />
          </a>
        </li>
      )}
    </ul>
  </Styled.SocialIconsWrapper>
);

export default ContactUs;
