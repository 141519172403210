import { API, APIRoutes } from '@app/api';
import { makeAutoObservable, runInAction } from 'mobx';
import i18n from '@utils/i18n';
import { uniqueId, last } from 'lodash-es';

export class LabelsStore {
  constructor() {
    makeAutoObservable(this);
  }

  label = {
    data: {},
    isLoading: false,
    error: null,
  };

  composers = {
    data: [],
    isLoading: false,
    error: null,
  };

  tracks = {
    data: [],
    isLoading: false,
    error: null,
  };

  labels = {
    isLoading: false,
    data: [],
    groupedData: [],
    error: null,
  };

  getLabels = async () => {
    try {
      this.labels.isLoading = true;

      const {
        data: { labels },
      } = await API(APIRoutes.LABELS);

      const groupedLabels = [];

      labels.forEach(({ group, ...label }) => {
        if (group) {
          const lastLabel = last(groupedLabels);
          if (lastLabel?.nestedItems) {
            lastLabel.nestedItems.push(label);
          } else {
            groupedLabels.push({
              name: group,
              nestedItems: [label],
              link: '',
              id: uniqueId(),
            });
          }
        } else {
          groupedLabels.push(label);
        }
      });

      runInAction(() => {
        this.labels.data = labels;

        this.labels.groupedData = [
          { id: 'all', name: i18n.t('All labels') },
          ...groupedLabels,
        ];
      });
    } catch (error) {
      runInAction(() => {
        this.labels.error = error;
      });
    } finally {
      runInAction(() => {
        this.labels.isLoading = false;
      });
    }
  };

  getLabel = async uuid => {
    this.label.error = null;
    this.label.isLoading = true;

    try {
      const {
        data: { label },
      } = await API(APIRoutes.LABEL(uuid));
      runInAction(() => {
        this.label.data = label;
      });
    } catch (error) {
      runInAction(() => {
        this.label.error = error;
      });
    } finally {
      runInAction(() => {
        this.label.isLoading = false;
      });
    }
  };

  getComposers = async uuid => {
    this.composers.error = null;
    this.composers.isLoading = true;

    try {
      const {
        data: { composers },
      } = await API(APIRoutes.COMPOSERS_OF_LABEL(uuid));
      runInAction(() => {
        this.composers.data = composers;
      });
    } catch (error) {
      runInAction(() => {
        this.composers.error = error;
      });
    } finally {
      runInAction(() => {
        this.composers.isLoading = false;
      });
    }
  };

  getTracks = async uuid => {
    this.tracks.error = null;
    this.tracks.isLoading = true;

    try {
      const {
        data: { tracks },
      } = await API(APIRoutes.TRACKS_OF_LABEL(uuid));
      runInAction(() => {
        this.tracks.data = tracks;
      });
    } catch (error) {
      runInAction(() => {
        this.tracks.error = error;
      });
    } finally {
      runInAction(() => {
        this.tracks.isLoading = false;
      });
    }
  };
}

export default new LabelsStore();
