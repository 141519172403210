import styled from '@emotion/styled';
import {
  Header as GlobalHeader,
  Separator as GlobalSeparator,
} from '@styles/globalStyles';
import Text from '@components/Text';

export const Wrapper = styled.article`
  background: #fff;
  border-radius: 4px;
  padding: 25px 21px;
  overflow: auto;
  height: 100%;
`;

export const Header = styled(GlobalHeader)`
  margin: 0 0 27px 0;
`;

export const Separator = styled(GlobalSeparator)`
  margin: 30px 0 10px 0;
`;

export const Details = styled.ul`
  list-style: none;
  margin-top: 10px;
`;

export const FlexRow = styled.span`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
`;

export const Detail = styled(FlexRow)`
  > :first-of-type {
    &::before {
      content: '>';
      position: relative;
      margin-right: 10px;
    }
  }
`;

export const DetailText = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.secondary.regular};
`;

export const FlexText = styled(Text)`
  display: inline-flex;
`;

export const SectionTitle = styled.span`
  margin: auto 10px auto 0;
`;

export const SectionPrice = styled(Text)`
  margin: auto 0;
`;

export const Link = styled.a`
  margin-top: 30px;
  display: inline-block;
`;
