import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import downloadFile from '@utils/downloadFile';
import { APIRoutes } from '@app/api';
import * as Styled from '@components/ReleasedTracks/DownloadTracksForm/style';
import Button from '@components/Button';
import Select from '@components/Form/Select';
import { MARGINS } from '@styles/globalStyles';
import { Label } from '@components/Form/style';

const DownloadTracksForm = () => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm();

  const download = format => async ({ label }) => {
    await downloadFile(
      APIRoutes.TRACKS_DOCKS(format, label.value),
      'tracks',
      format,
    );
  };

  const LABELS = [
    {
      value: 'intervox-production-music',
      label: t('Intervox Production Music'),
    },
    { value: 'black-is-blonde', label: t('Black Is Blonde') },
    { value: 'click-clock', label: t('Click Clock') },
    { value: 'iconica', label: t('Iconica') },
  ];

  return (
    <Styled.Wrapper>
      <Styled.SelectWrapper>
        <Label>{t('Download label')}</Label>

        <Select
          options={LABELS}
          defaultValue={LABELS[0]}
          control={control}
          mb={MARGINS.NONE}
          name="label"
        />
      </Styled.SelectWrapper>
      <Styled.ButtonWrapper>
        <Button
          type="submit"
          label={`${t('Download PDF')}`}
          onClick={handleSubmit(download('pdf'))}
        />
        <Button
          type="submit"
          label={`${t('Download CSV')}`}
          onClick={handleSubmit(download('csv'))}
        />
      </Styled.ButtonWrapper>
    </Styled.Wrapper>
  );
};

export default DownloadTracksForm;
