import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { Flex } from 'rebass';
import routes from '@routes';
import ButtonsFromList from '@components/ButtonsFromList';
import * as Styled from '@components/ExtraForYouItem/style';

import PLAY from '@assets/images/icons/large-mini-player-play-button.svg';
import DownloadButton from '@components/IconButtons/DownloadButton';

const ExtraForYouItem = ({
  playerStore: { playerContext, loadAndPlayTrack },
  blockDownloadStore: { isDownloadBlocked, openBlockedDownloadModal },
  name,
  description,
  image,
  directoryWithNumber,
  albumId,
  albumUUID,
  id,
  isAuthenticated,
  track,
}) => {
  return (
    <Styled.Wrapper>
      <Styled.DescriptionBox>
        <Flex margin="0 0 10px 0">
          <button
            type="button"
            onClick={() => {
              setTimeout(() => {
                loadAndPlayTrack({
                  playerContext,
                  track,
                });
              }, 1);
            }}
          >
            <img src={PLAY} alt="Play" />
          </button>
          <Styled.Title>{name}</Styled.Title>
        </Flex>
        <Styled.Description>{description}</Styled.Description>
        <Styled.ButtonsFromListContainer>
          <ButtonsFromList
            clipboardData={directoryWithNumber}
            albumId={albumId}
            trackId={id}
            trackName={name}
          />
          <DownloadButton
            {...{
              id,
              isAuthenticated,
              isDownloadBlocked,
              openBlockedDownloadModal,
              isMp3: track.file?.url,
              isWav: track.file_wav?.url,
              external_id: track.external_id,
            }}
          />
        </Styled.ButtonsFromListContainer>
      </Styled.DescriptionBox>
      <NavLink to={routes.album(albumUUID)}>
        <Styled.Image src={image} alt={name} id={id} />
      </NavLink>
    </Styled.Wrapper>
  );
};

ExtraForYouItem.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  directoryWithNumber: PropTypes.string.isRequired,
  albumId: PropTypes.string.isRequired,
  albumUUID: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  track: PropTypes.object.isRequired,
  playerStore: PropTypes.object.isRequired,
  blockDownloadStore: PropTypes.object.isRequired,
};

export default inject(
  'playerStore',
  'blockDownloadStore',
)(observer(ExtraForYouItem));
