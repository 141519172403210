import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass';
import * as Styled from '@components/TooltipSelect';
import Text, { VARIANTS } from '@components/Text';
import DownloadIcon from '@assets/images/icons/download-icon.svg';
import { APIRoutes } from '@app/api';
import downloadFile from '@utils/downloadFile';

const DownloadProjectTooltipBody = ({ uuid, name }) => {
  const { t } = useTranslation();

  return (
    <>
      <Styled.MenuItem
        as="a"
        href={`${process.env.REACT_APP_API_URL}${APIRoutes.DOWNLOAD_PROJECT(
          uuid,
        )}`}
        rel="noopener noreferrer"
      >
        <Flex
          width={1}
          px={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Text variant={VARIANTS.NAV}>{t('ZIP')}</Text>
          <img src={DownloadIcon} alt="download" />
        </Flex>
      </Styled.MenuItem>
      <Styled.Divider />
      <Styled.MenuItem
        type="button"
        onClick={() =>
          downloadFile(
            APIRoutes.DOWNLOAD_PROJECT_FILE(uuid, 'csv'),
            `${name} Cuesheet`,
            'csv',
          )
        }
      >
        <Flex
          width={1}
          px={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Text variant={VARIANTS.NAV}>{t('CSV')}</Text>
          <img src={DownloadIcon} alt="download" />
        </Flex>
      </Styled.MenuItem>
      <Styled.Divider />
      <Styled.MenuItem
        type="button"
        onClick={() =>
          downloadFile(
            APIRoutes.DOWNLOAD_PROJECT_FILE(uuid, 'pdf'),
            `${name}`,
            'pdf',
          )
        }
      >
        <Flex
          width={1}
          px={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Text variant={VARIANTS.NAV}>{t('PDF')}</Text>
          <img src={DownloadIcon} alt="download" />
        </Flex>
      </Styled.MenuItem>
    </>
  );
};

DownloadProjectTooltipBody.propTypes = {
  uuid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default DownloadProjectTooltipBody;
