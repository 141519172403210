import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useParams, useLocation } from 'react-router-dom';
import { Box } from 'rebass';
import qs from 'query-string';

import { MainContent, MARGINS } from '@styles/globalStyles';
import Preloader from '@components/Preloader';
import { PLAYER_CONTEXTS } from '@components/PlayerControlButtons/PlayPause';
import ListingHeaderWrapper from '@components/ListingHeaderWrapper';
import AlbumHeader from '@containers/AlbumListing/components/AlbumHeader';
import { VARIANTS } from '@components/Text';
import { SectionTitle } from '@containers/AlbumListing/components/style';
import TracksTable from '@components/TracksTable';
import NotFound from '@containers/NotFound';

const AlbumListing = ({
  albumsStore: {
    getAlbumDetails,
    getAlbumTracks,
    toggleTrack,
    toggleTrackAlternatives,
    albumDetails: { data, isLoading, error },
    albumTracks: {
      data: tracksData,
      isLoading: isTracksLoading,
      expandedTrack,
      expandedAlternatives,
      error: tracksError,
    },
  },
  authStore: { isAuthenticated },
}) => {
  const { id: albumUuid } = useParams();

  const { trackId } = qs.parse(useLocation().search);

  useEffect(() => {
    getAlbumDetails(albumUuid);
    getAlbumTracks(albumUuid);
  }, [getAlbumDetails, getAlbumTracks, albumUuid]);

  const { cover, description, name, id, medley } = data;

  if (tracksError || error) {
    return <NotFound />;
  }

  return (
    <MainContent>
      {isLoading ? (
        <Preloader center />
      ) : (
        <>
          <ListingHeaderWrapper>
            <AlbumHeader
              {...{
                coverUrl: cover?.url,
                uuid: albumUuid,
                name,
                description,
                id,
                isAuthenticated,
                albumUuid,
                medley,
              }}
            />
          </ListingHeaderWrapper>
          {isTracksLoading ? (
            <Preloader center />
          ) : (
            tracksData.map(({ title, tracks }) => (
              <Box key={`section-${title}`} width={1} mb={4}>
                {title && (
                  <SectionTitle variant={VARIANTS.H4} mb={MARGINS.SMALL}>
                    {title}
                  </SectionTitle>
                )}
                <TracksTable
                  {...{
                    data: tracks,
                    toggleTrackAlternatives,
                    toggleTrack,
                    expandedTrack,
                    expandedAlternatives,
                    isAuthenticated,
                    showAlbumCovers: false,
                    playerContext: PLAYER_CONTEXTS.ALBUMS,
                    focusTracks: trackId?.split(','),
                  }}
                />
              </Box>
            ))
          )}
        </>
      )}
    </MainContent>
  );
};

AlbumListing.propTypes = {
  albumsStore: PropTypes.object.isRequired,
  authStore: PropTypes.object.isRequired,
};

export default inject('albumsStore', 'authStore')(observer(AlbumListing));
