import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import SuggestionsSection, {
  SUGGESTION_TYPES,
} from '@components/SearchSuggestions/components/SuggestionsSection';
import * as Styled from '@components/SearchSuggestions/style';

const SearchSuggestions = ({
  searchStore: {
    applySearchSuggestion,
    getSearchSuggestions,
    suggestions: { data, isLoading },
  },
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    getSearchSuggestions();
  }, []);

  const { keywords, recent_searches, instrumentations } = data;

  return (
    <Styled.Wrapper>
      <Styled.Row>
        <SuggestionsSection
          applyFilter={applySearchSuggestion}
          suggestions={keywords || []}
          title={t('Keywords')}
          isLoading={isLoading}
          type={SUGGESTION_TYPES.KEYWORDS}
          width={1 / 3}
        />

        <SuggestionsSection
          applyFilter={applySearchSuggestion}
          suggestions={instrumentations || []}
          title={t('Instrumentations')}
          isLoading={isLoading}
          type={SUGGESTION_TYPES.INSTRUMENTATIONS}
          width={1 / 3}
        />

        <SuggestionsSection
          applyFilter={applySearchSuggestion}
          suggestions={recent_searches || []}
          title={t('Recent searches')}
          isLoading={isLoading}
          type={SUGGESTION_TYPES.RECENT_SEARCHES}
          width={1 / 3}
        />
      </Styled.Row>
    </Styled.Wrapper>
  );
};

SearchSuggestions.propTypes = {
  searchStore: PropTypes.shape({
    advancedSearch: PropTypes.bool,
    applySearchSuggestion: PropTypes.func,
    getSearchSuggestions: PropTypes.func,
    suggestions: PropTypes.shape({
      data: PropTypes.object,
      isLoading: PropTypes.bool,
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    }),
    switchToAdvancedSearch: PropTypes.func,
  }).isRequired,
};

export default inject('searchStore')(observer(SearchSuggestions));
