import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import Text, { COLORS } from '@components/Text';

import i18n from '@utils/i18n';
import {
  DropzoneWrapper,
  DropzoneTitle,
  Error,
} from '@components/Dropzone/style';

export * from '@components/Dropzone/style';

const Dropzone = ({ accept, onDrop, label, text, multiple, error }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    onDrop,
    multiple,
  });

  const withError = !!error?.message;

  return (
    <>
      <DropzoneWrapper
        {...getRootProps({ isDragActive })}
        withError={withError}
      >
        <input {...getInputProps()} autoComplete="off" />
        <DropzoneTitle color={COLORS.WHITE}>{label}</DropzoneTitle>
        <Text color={COLORS.WHITE}>{text}</Text>
      </DropzoneWrapper>
      {withError ? <Error>{error?.message}</Error> : null}
    </>
  );
};

Dropzone.defaultProps = {
  accept: '',
  label: i18n.t('Upload'),
  text: i18n.t('Drag and drop files here, or click to select files'),
  multiple: true,
  error: undefined,
};

Dropzone.propTypes = {
  accept: PropTypes.string,
  onDrop: PropTypes.func.isRequired,
  label: PropTypes.string,
  text: PropTypes.string,
  multiple: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

export default Dropzone;
