import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import { uniqueId } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import { useResizeDetector } from 'react-resize-detector';

import Preloader from '@components/Preloader';
import Banner from '@components/Banner';
import * as Styled from '@components/BannersSlider/style';
import useBreakpoint from '@hooks/useBreakpoint';

SwiperCore.use([Pagination]);

const BannersSlider = ({ bannersStore: { banners, getBanners } }) => {
  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();

  const isMobile = matchBreakpoint(BREAKPOINTS.SM);

  const uuid = uniqueId();
  useEffect(() => {
    if (banners.data.length === 0) getBanners();
  }, [getBanners]);

  const { ref } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 100,
  });

  return (
    <Styled.Wrapper>
      {banners.isLoading && <Preloader />}
      {banners.data.length > 0 && (
        <Swiper
          ref={ref}
          slidesPerView={1}
          direction="horizontal"
          pagination={{ clickable: true, el: `#slider-pagination-${uuid}` }}
        >
          {banners.data.map(
            ({
              title,
              description,
              image,
              image_mobile,
              button_text,
              button_url,
            }) => (
              <SwiperSlide key={uniqueId()}>
                <Banner
                  title={title}
                  description={description}
                  image={
                    isMobile && image_mobile?.url ? image_mobile.url : image.url
                  }
                  buttonText={button_text}
                  url={button_url}
                />
              </SwiperSlide>
            ),
          )}
        </Swiper>
      )}
      <Styled.Pagination id={`slider-pagination-${uuid}`} />
    </Styled.Wrapper>
  );
};

const BannersPropType = PropTypes.shape({
  isLoading: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
      button_text: PropTypes.string,
      button_url: PropTypes.string,
    }),
  ),
});

BannersSlider.propTypes = {
  bannersStore: PropTypes.shape({
    banners: BannersPropType,
    getBanners: PropTypes.func,
  }).isRequired,
};

export default inject('bannersStore')(observer(BannersSlider));
