import { makeAutoObservable } from 'mobx';
import Rollbar from 'rollbar';

export class RollbarStore {
  constructor() {
    makeAutoObservable(this);
  }

  rollbar = new Rollbar({
    accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: false,
    payload: {
      environment: 'production',
      person: {
        id: null,
      },
    },
  });

  configureRollbar = async email => {
    this.rollbar.configure({
      payload: {
        person: {
          id: email,
        },
      },
    });
  };
}

export default new RollbarStore();
