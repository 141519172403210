import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Header, MainContent } from '@styles/globalStyles';
import { Flex } from 'rebass';
import FormButton from '@components/Form/FormButton';
import { VARIANTS } from '@components/Button';
import Tile from '@components/Tile';
import GridList from '@components/GridList';
import Preloader from '@components/Preloader';
import NotFound from '@containers/NotFound';

const Playlists = ({
  playlistsStore: { getPlaylists, getMorePlaylists, playlists },
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const categoryUuid = id || '';

  useEffect(() => {
    if (playlists.categoryUuid !== categoryUuid) getPlaylists(categoryUuid);
  }, [categoryUuid]);

  if (playlists.error) {
    return <NotFound />;
  }

  return (
    <MainContent>
      <Header>{t(playlists.categoryName)}</Header>
      {playlists.isLoading && <Preloader />}
      <GridList>
        {playlists.data.map(({ name, image, uuid }) => (
          <li key={uniqueId()}>
            <Tile
              name={name}
              cover={image}
              uuid={uuid}
              withBackground={false}
            />
          </li>
        ))}
      </GridList>
      {!playlists.last && (
        <Flex margin="auto">
          <FormButton
            isLoading={playlists.isLoading}
            variant={VARIANTS.GREEN}
            label={t('Show more')}
            onClick={() => getMorePlaylists(categoryUuid)}
          />
        </Flex>
      )}
    </MainContent>
  );
};

Playlists.propTypes = {
  playlistsStore: PropTypes.shape({
    getPlaylists: PropTypes.func,
    getMorePlaylists: PropTypes.func,
    playlists: PropTypes.object,
  }).isRequired,
};

export default inject('playlistsStore')(observer(Playlists));
