import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  min-width: ${({ minWidth }) => minWidth};
`;

export const Characters = styled.div`
  margin: auto;
`;

export const Character = styled.div`
  font-size: ${({ size }) => size};
  font-weight: 900;
  display: inline;

  &:first-of-type {
    color: ${({ theme }) => theme.colors.highlight};
  }
`;
