import React from 'react';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/react';
import { GRADIENT_BACKGROUND } from '@styles/globalStyles';

export const VARIANTS = {
  DEFAULT: 'DEFAULT',
  GRADIENT: 'GRADIENT',
};

export const GradientModalCloseBox = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 99;
`;

export const Cover = styled.div`
  z-index: 900;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ variant }) =>
    variant === VARIANTS.GRADIENT
      ? 'rgba(0,0,0,0.2)'
      : 'rgba(9, 95, 115, 0.8)'};
  button {
    position: absolute;
    right: 55px;
    top: 31px;
  }
`;

export const Modal = styled.div`
  width: 90%;
  max-width: 880px;
  border-radius: 7px;
  box-shadow: 0 0 7px 0 rgba(13, 13, 13, 0.18);
  background-color: #fff;
  position: relative;
  z-index: 901;
  padding: 8px 10px;
  transform: scale(1);
  min-height: 300px;
  margin: auto;

  ${({ variant, theme }) => {
    if (variant === VARIANTS.GRADIENT) {
      return GRADIENT_BACKGROUND(theme);
    }
    return null;
  }}
`;

export const Body = styled.div`
  position: relative;
  z-index: 1;
`;

export const ModalAnimations = () => (
  <Global
    styles={css`
      .modal-cover-enter {
        opacity: 0;
      }
      .modal-cover-enter-active {
        opacity: 1;
        transition: opacity 0.3s;
      }
      .modal-cover-exit {
        opacity: 1;
      }
      .modal-cover-exit-active {
        opacity: 0;
        transition: opacity 0.3s;
      }

      .modal-content-enter {
        opacity: 0;
        transform: scale(0) translateY(-150px) !important;
        transform-origin: top center;
      }
      .modal-content-enter-active {
        transform: scale(1) translateY(0) !important;
        opacity: 1;
        transition: opacity 0.3s ease-in-out 0.3s,
          transform 0.3s ease-in-out 0.3s;
        transform-origin: top center;
      }
      .modal-content-exit {
        transform: scale(1) translateY(0) !important;
        opacity: 1;
        transform-origin: top center;
      }
      .modal-content-exit-active {
        transform: scale(0) translateY(-150px) !important;
        opacity: 0;
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
        transform-origin: top center;
      }
    `}
  />
);
