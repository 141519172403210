import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Header from '@components/Header';
import { MainContent } from '@styles/globalStyles';
import Tutorial from '@components/Tutorial';
import Preloader from '@components/Preloader';
import * as Styled from '@containers/Credits/style';

const Credits = ({ creditsStore: { credits, getCredits } }) => {
  const { t } = useTranslation();
  useEffect(() => {
    getCredits();
  }, [getCredits]);

  return (
    <MainContent>
      <Header title={t('Credits')} withSeparator />
      {credits.isLoading && <Preloader />}
      <Styled.GridList>
        {credits?.data?.length > 0 &&
          credits.data.map(({ title, description, youtube_url }) => (
            <li key={uniqueId()}>
              <Tutorial
                title={title}
                description={description}
                url={youtube_url}
              />
            </li>
          ))}
      </Styled.GridList>
    </MainContent>
  );
};

Credits.propTypes = {
  creditsStore: PropTypes.shape({
    credits: PropTypes.object.isRequired,
    getCredits: PropTypes.func.isRequired,
  }).isRequired,
};

export default inject('creditsStore')(observer(Credits));
