import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Flex, Box } from 'rebass';
import { Helmet } from 'react-helmet';
import { toJS } from 'mobx';
import SignUpForm from '@containers/SignUp/components/SignUpForm';
import Header from '@components/Header';
import { MainContent } from '@styles/globalStyles';
import Card, { Header as CardHeader, VARIANTS } from '@components/Card';
import FormButton from '@components/Form/FormButton';
import routes from '@routes';
import { ALIGNS, VARIANTS as TEXT_VARIANTS } from '@components/Text';
import OAuthBox from '@components/OAuthBox';
import { currentLanguage } from '@utils/i18n';

const SignUpPage = ({
  registrationStore: {
    onSignUp,
    customerStatus,
    countries,
    getCountries,
    userCountry,
  },
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <MainContent>
      <Helmet>
        <title>{t('Sign up')}</title>
      </Helmet>
      <Header title={t('Sign up')} withSeparator />

      <OAuthBox />
      <Flex flexWrap="wrap" mx={-3} mt={3} mb={[0, 0, 0, 6]}>
        <Box width={[1, 1, 1, 1 / 2]} px={3} mb={[5, 5, 5, 0]}>
          <Card>
            <CardHeader variant={TEXT_VARIANTS.H2}>
              {t('New here? Sign up')}
            </CardHeader>

            <SignUpForm
              onSubmit={onSignUp}
              formStatus={toJS(customerStatus)}
              countries={countries.data}
              userCountry={userCountry}
            />
          </Card>
        </Box>
        <Box width={[1, 1, 1, 1 / 2]} px={[0, 0, 0, 3]}>
          <Box mb={3}>
            <Card variant={VARIANTS.GRADIENT}>
              <Box p={3} textAlign="center">
                <CardHeader variant={TEXT_VARIANTS.H2} align={ALIGNS.CENTER}>
                  {t('Already have an account?')}
                </CardHeader>
                <Box>
                  <FormButton to={routes.auth.signIn} label={t('Sign in')} />
                </Box>
              </Box>
            </Card>
          </Box>
          {currentLanguage !== 'CZ' && (
            <Card variant={VARIANTS.GRADIENT}>
              <Box p={3} textAlign="center">
                <CardHeader variant={TEXT_VARIANTS.H2} align={ALIGNS.CENTER}>
                  {t('Composer?')}
                </CardHeader>
                <Box>
                  <FormButton
                    to={routes.auth.composerSignUp}
                    label={t('Sign up')}
                  />
                </Box>
              </Box>
            </Card>
          )}
        </Box>
      </Flex>
    </MainContent>
  );
};

SignUpPage.propTypes = {
  registrationStore: PropTypes.object.isRequired,
};

export default inject('registrationStore')(observer(SignUpPage));
