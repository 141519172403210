import Text from '@components/Text';
import styled from '@emotion/styled';

export const DropzoneTitle = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const DropzoneText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  font-weight: 400;
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
  padding: 4px 0;
  text-align: right;
  width: 100%;
  &:first-letter {
    text-transform: capitalize;
  }
`;

export const DropzoneWrapper = styled.div`
  height: 38px;
  padding: 2px 12px 2px 12px;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 4px;
  background-color: ${({ theme, isDragStarted }) =>
    isDragStarted ? theme.colors.highlight : 'transparent'};
  position: relative;

  &::before {
    content: '';
    user-select: none;
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 4px;
    right: 4px;
    border: 1px white;
    border: 1px
      ${({ theme, withError }) =>
        withError ? theme.colors.error : theme.colors.white}
      dashed;
    border-radius: 4px;
    transition: opacity 0.2s;
    opacity: ${({ isDragActive }) => (isDragActive ? '1' : '0.5')};
  }
`;
