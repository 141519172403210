import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Box } from 'rebass';

import * as Styled from '@containers/Label/style';
import { VARIANTS } from '@components/Text';
import { MainContent, Header } from '@styles/globalStyles';
import Preloader from '@components/Preloader';
import InfoBox from '@containers/Label/InfoBox';
import searchURL from '@utils/searchURL';
import Albums from '@containers/Albums';
import Artistsbox from '@containers/Label/ArtistsBox';
import TracksBox from '@containers/Label/TracksBox';
import NotFound from '@containers/NotFound';
import { ALBUMS_TYPES } from '@root/stores/albumsStore';

// it is a config to show composers info on right hand side. Empty bcs of https://www.pivotaltracker.com/story/show/185571824
const DEFAULTS = [];

const Label = ({
  labelsStore: {
    getLabel,
    label: { isLoading, data: label, error },
    getComposers,
    composers,
    getTracks,
    tracks,
  },
}) => {
  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (label?.uuid !== id) {
      getLabel(id);
      if (DEFAULTS.includes(id)) {
        getComposers(id);
      } else {
        getTracks(id);
      }
    }
  }, [getLabel, label, id]);

  const isDefault = DEFAULTS.includes(id);

  const isError = () => {
    if (isDefault) return composers.error || error;
    return error || tracks.error;
  };

  if (isError()) {
    return <NotFound />;
  }

  if (isLoading) {
    return (
      <MainContent>
        <Preloader />
      </MainContent>
    );
  }

  return (
    <MainContent>
      <Header>{label.name}</Header>
      {Object.keys(label).length > 0 && (
        <>
          <Styled.TopGrid>
            <InfoBox
              image={label.image?.url}
              description={label.description}
              medley={{ ...label.medley, name: label.name, id: label.id }}
              medleyLength={label.medley_length}
              shareLink={searchURL(label.uuid, 'label')}
              name={label.name}
            />
            {(composers.isLoading || tracks.isLoading) && <Preloader center />}
            {isDefault
              ? !composers.isLoading && (
                  <Artistsbox
                    artists={composers.data}
                    isLoading={composers.isLoading}
                  />
                )
              : !tracks.isLoading &&
                tracks.data && <TracksBox tracks={tracks.data} />}
          </Styled.TopGrid>
          <section>
            <Styled.SectionHeader
              variant={VARIANTS.NAV_COLLAPSED}
              marginBottom="50px"
            >
              {t('Albums')}
            </Styled.SectionHeader>
            <Styled.Separator />

            <Box pt={3}>
              <Albums listOnly label={label.uuid} type={ALBUMS_TYPES.LABELS} />
            </Box>
          </section>
        </>
      )}
    </MainContent>
  );
};

Label.propTypes = {
  labelsStore: PropTypes.shape({
    getLabel: PropTypes.func,
    getComposers: PropTypes.func,
    getTracks: PropTypes.func,
    label: PropTypes.object,
    composers: PropTypes.object,
    tracks: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.array,
      error: PropTypes.object,
    }),
  }).isRequired,
};

export default inject('labelsStore')(observer(Label));
