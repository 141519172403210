import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { VARIANTS, MARGINS } from '@components/Form/TextField';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import SearchPhrases from '@components/SearchPhrases';
import * as Styled from '@components/SearchBar/style';
import Modal from '@components/Modal';
import SearchModal from '@components/SearchModal';

import TypewriterText from '@components/TypewriterText';

import SearchIcon from '@assets/images/icons/search-icon.svg';
import BurgerMenuIcon from '@assets/images/icons/burger-menu.svg';
import SearchSuggestions from '@components/SearchSuggestions';
import SearchField from '@components/Form/SearchField';
import Extra4YouButton from '@components/Button/Extra4You';

const SearchBar = ({
  searchStore: {
    getSearchPhrases,
    applySearchSuggestion,
    searchPhrases,
    showSearchPhrasesSelector,
    searchModalIsOpen,
    openSearchModal,
    closeSearchModal,
    getSearchSuggestions,
  },
}) => {
  const { t } = useTranslation();
  const { pathname, search } = useLocation();

  const [isSearchFocus, setIsSearchFocus] = useState(false);
  const [text, setText] = useState('');

  const inputRef = useRef(null);
  const timeoutRef = useRef(null);

  const isSearchResultPage = pathname.includes('search');

  useEffect(() => {
    getSearchPhrases();
  }, []);

  // close modal on location change
  useEffect(() => {
    closeSearchModal();
  }, [pathname, search, inputRef]);

  const selectPhraseHandler = phrase => {
    applySearchSuggestion({ text: phrase });
    setText('');
  };

  const onChangeHandler = e => {
    const { value } = e.target;
    setText(value);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      getSearchSuggestions(value);
    }, 300);
  };

  const onBlurHandler = useCallback(() => {
    setText('');
    setTimeout(() => {
      setIsSearchFocus(false);
    }, 200);
  }, []);

  const onFocus = () => {
    if (isSearchResultPage) {
      setIsSearchFocus(true);
    }
  };

  const onClick = () => {
    if (!isSearchResultPage) {
      openSearchModal();
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    selectPhraseHandler(text);
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  const dynamicPlaceholderTexts = [
    t('Search your music here...'),
    t('Search by genre (e.g. rock, blues)...'),
    t('Search by instrumentation (e.g. ukulele, pads, guitar)...'),
    t('Search by Mood (ironic, heroic, melancholic)...'),
  ];

  return (
    <Styled.Wrapper>
      <Styled.InputWrapper>
        <form onSubmit={onSubmit}>
          <Styled.SearchWrapper>
            <SearchField
              ref={inputRef}
              variant={VARIANTS.SEARCH}
              name="text"
              value={text}
              onChange={onChangeHandler}
              onFocus={onFocus}
              onClick={onClick}
              onBlur={onBlurHandler}
              readOnly={!isSearchResultPage}
              mb={MARGINS.NONE}
              inputIndicator={() => (
                <>
                  <Styled.SearchButton type="submit">
                    <img src={SearchIcon} alt="search" />
                  </Styled.SearchButton>

                  {!isSearchResultPage && <Extra4YouButton />}

                  <Styled.MenuButton
                    type="button"
                    onClick={() => openSearchModal(true)}
                  >
                    <img src={BurgerMenuIcon} alt="" height="100%" />
                  </Styled.MenuButton>
                </>
              )}
            />
            {!isSearchFocus && (
              <Styled.DynamicContainer isSearchResultPage={isSearchResultPage}>
                <TypewriterText texts={dynamicPlaceholderTexts} />
              </Styled.DynamicContainer>
            )}
          </Styled.SearchWrapper>
        </form>
        {isSearchFocus && (
          <Styled.SearchDropDown>
            <SearchSuggestions />
          </Styled.SearchDropDown>
        )}
      </Styled.InputWrapper>
      {showSearchPhrasesSelector && searchPhrases.data.length ? (
        <SearchPhrases
          searchPhrases={searchPhrases}
          onSelect={selectPhraseHandler}
        />
      ) : null}
      <Modal closeHandler={closeSearchModal} isOpen={searchModalIsOpen}>
        <SearchModal />
      </Modal>
    </Styled.Wrapper>
  );
};

SearchBar.propTypes = {
  searchStore: PropTypes.object.isRequired,
};

export default inject('searchStore')(observer(SearchBar));
