import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Redirect, Route as RouteRaw } from 'react-router-dom';

import routes from '@routes';

export const ROLES = {
  CUSTOMER: 'CUSTOMER',
  COMPOSER: 'COMPOSER',
};

const ProtectedRoute = ({
  authStore: { isAuthenticated, isValidated },
  customerStore: {
    customer: { data: customerData },
  },
  children,
  role,
  ...rest
}) => {
  useEffect(() => {
    // scroll to top on route change
    window.scrollTo(0, 0);
  }, [rest.location.pathname]);

  let redirectTo = '';

  if (isValidated) {
    if (!isAuthenticated) {
      redirectTo = routes.auth.signIn;
    } else if (
      role === ROLES.COMPOSER &&
      !(customerData.is_composer || customerData.is_producer)
    ) {
      redirectTo = routes.base;
    }
  }

  return (
    <RouteRaw
      {...rest}
      render={() => (redirectTo ? <Redirect to={redirectTo} /> : children)}
    />
  );
};

ProtectedRoute.defaultProps = {
  role: ROLES.CUSTOMER,
};

ProtectedRoute.propTypes = {
  authStore: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    isValidated: PropTypes.bool,
  }).isRequired,
  customerStore: PropTypes.shape({ customer: PropTypes.object }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  role: PropTypes.oneOf(Object.keys(ROLES)),
};

export default inject('authStore', 'customerStore')(observer(ProtectedRoute));
