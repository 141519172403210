import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const VARIANTS = {
  SEARCH: 'SEARCH',
  DEFAULT: 'DEFAULT',
  PASSWORD: 'PASSWORD',
};

export const SIZES = {
  SMALL: 'SMALL',
};

export const Input = styled.input`
  font-family: ${({ theme }) => theme.fonts.default};
  padding: 10px;
  border-radius: 5px;
  border: 1px
    ${({ theme, withError }) => (withError ? theme.colors.error : '#ddd')} solid;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  flex: 1;
  font-weight: 300;
  line-height: 1.4;
  font-size: 14px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'initial')};
  ${({ indicatorPadding }) =>
    indicatorPadding && `padding-right: ${indicatorPadding}px`};

  ${({ as }) =>
    as === 'textarea'
      ? css`
          resize: vertical;
        `
      : null}
  @media (max-width: 640px) {
    ${({ as }) => (as === 'textarea' ? 'min-height: 100px;' : null)}
  }

  &:focus,
  &:active {
    outline: none;
    border-color: ${({ theme }) => theme.inputs.focus.borderColor};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.inputs.focus.borderColor};
  }

  ${({ disableSpinners }) =>
    disableSpinners &&
    css`
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    `}
`;

export const DEFAULT = (theme, _size, mb) => css`
  ${Input} {
    ${mb &&
      css`
        margin-bottom: ${theme.inputs.margins[mb]}px;
      `}
  }
`;

export const PASSWORD = (theme, _size, mb) => css`
  ${Input} {
    padding-right: 40px;
    ${mb &&
      css`
        margin-bottom: ${theme.inputs.margins[mb]}px;
      `}
  }
`;

export const SEARCH = (theme, size) => css`
  box-shadow: 0 0 10px rgba(13, 13, 13, 0.2);
  border: solid 1px #d8d8d8;
  border-radius: ${theme.inputs.search.borderRadius};

  ${Input} {
    height: 50px;
    padding: 0 100px 0 18px;
    border-radius: ${theme.inputs.search.borderRadius};
    border: none;
    width: 100%;
    font-size: ${theme.inputs.search.fontSize};

    ::-webkit-input-placeholder {
      color: ${theme.inputs.search.placeholder.color};
      font-size: ${theme.inputs.search.placeholder.fontSize};
    }
    ::-moz-placeholder {
      color: ${theme.inputs.search.placeholder.color};
      font-size: ${theme.inputs.search.placeholder.fontSize};
    }
    :-ms-input-placeholder {
      color: ${theme.inputs.search.placeholder.color};
      font-size: ${theme.inputs.search.placeholder.fontSize};
    }
    :-moz-placeholder {
      color: ${theme.inputs.search.placeholder.color};
      font-size: ${theme.inputs.search.placeholder.fontSize};
    }

    ${size && size === SIZES.SMALL
      ? css`
          height: 46px;
        `
      : null}
  }
`;

const VARIANT = {
  [VARIANTS.SEARCH]: SEARCH,
  [VARIANTS.DEFAULT]: DEFAULT,
  [VARIANTS.PASSWORD]: PASSWORD,
};

export const InputWrapper = styled.div`
  position: relative;

  ${({ variant, theme, size, mb }) => {
    if (variant) {
      return VARIANT[variant](theme, size, mb);
    }
    return null;
  }}
`;
