/* eslint-disable no-console */
import { makeAutoObservable } from 'mobx';

export class CurrentAlbumStore {
  constructor() {
    makeAutoObservable(this);
  }

  currentAlbum = '';

  play = url => {
    if (url) this.currentAlbum = url;
    console.log(this.currentAlbum);
  };

  save = url => {
    if (url) this.currentAlbum = url;
    console.log(this.currentAlbum);
  };
}

export default new CurrentAlbumStore();
