import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import { StyledLink, StyledText } from '@components/CookieBanner/style';
import routes from '@routes';

const buttonStyle = {
  height: 32,
  width: 80,
  borderRadius: 16,
  backgroundColor: '#049dbf',
  color: '#ffffff',
};

const CookieBanner = ({ cookiesStore: { cookiesAccept } }) => {
  const { t } = useTranslation();

  return (
    <CookieConsent
      buttonText="OK"
      buttonStyle={buttonStyle}
      onAccept={cookiesAccept}
      cookieName="intervoxCookiesAccept"
    >
      <StyledText>
        {t(
          'This website uses cookies to ensure you get the best experience on our website',
        )}
        <StyledLink className="" to={routes.cookies}>
          <b>{t('More info')}</b>
        </StyledLink>
      </StyledText>
    </CookieConsent>
  );
};

CookieBanner.propTypes = {
  cookiesStore: PropTypes.object.isRequired,
};

export default inject('cookiesStore')(observer(CookieBanner));
