import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { breakpoint } from '@styles/breakpoints';

export const List = styled.ul`
  .activeLink {
    color: ${({ theme }) => theme.colors.highlight};
    font-weight: 500;
  }

  ${({ expanded }) =>
    expanded
      ? css`
          padding-top: 23px;
          max-height: 1000px;
        `
      : css`
          padding-top: 0;
          max-height: 0;
        `};

  overflow: hidden;
  transition: padding-top 0.7s, max-height 0.7s;
  padding-left: 27px;
  padding-right: 27px;

  ${({ desktopcollapsed }) =>
    desktopcollapsed
      ? css``
      : css`
          max-height: 0;
          padding-top: 0;
        `};

  @media ${breakpoint.md} {
    background-color: ${({ theme }) => theme.backgrounds.secondary};
    ${({ expanded }) =>
      expanded
        ? css`
            max-height: 500px;
          `
        : css`
            max-height: 0;
          `};
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const ListItem = styled.li`
  min-width: 200px;
  color: ${({ theme }) => theme.colors.primaryVariant};
  @media ${breakpoint.md} {
    min-width: 0;
    padding-right: 15px;
    padding-left: 15px;
    border-right: ${({ theme, internal }) =>
      !internal && `1px solid ${theme.colors.strokeVariant}`};

    ${({ internal }) =>
      css`
        display: block;
        padding: 7px;
        &:nth-of-type(even) {
          border-right: none;
        }
        &:first-of-type {
          padding-top: ${internal ? '10px' : '26px'};
        }
        &:nth-of-type(2) {
          padding-top: ${!internal && '26px'};
        }
        &:last-child {
          padding-bottom: ${!internal && '26px'};
        }
      `};
  }
`;

export const ListItemInternalLink = styled.button`
  font-size: ${({ theme }) => theme.sidebar.list.fontSize};
  text-decoration: none;
  line-height: 16px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.primaryVariant};
  font-weight: 300;
  display: flex;
  flex-direction: row;
  width: 100%;

  @media ${breakpoint.md} {
    margin-bottom: 0;
    margin-left: 10px;
  }
`;

export const InternalListIndicator = styled.span`
  transform: ${({ expanded }) =>
    expanded ? 'rotate(270deg)' : 'rotate(90deg)'};
  margin-left: 10px;
  transition: transform 0.5s;
`;

export const ListHeaderArrow = styled.img`
  margin-right: 20px;
  transition: transform 0.3s ease-in-out;
  transform: rotate(${({ isOpen }) => (isOpen ? 0 : -180)}deg);
  display: ${({ desktopcollapsed }) => (desktopcollapsed ? 'block' : 'none')};

  @media ${breakpoint.md} {
    position: absolute;
    right: 0;
  }
`;

export const ListHeaderIcon = styled.img`
  margin-right: ${({ desktopcollapsed }) => (desktopcollapsed ? '10px' : 0)};
`;

export const ListHeaderTitle = styled.h3`
  transition: font-family 0.3s ease-in-out;
  font-family: ${({ isOpen, desktopcollapsed, theme }) =>
    isOpen && desktopcollapsed
      ? theme.fonts.secondary.bold
      : theme.fonts.secondary.regular};
  line-height: 17px;
`;

export const ListHeaderTitleWrapper = styled.div`
  ${({ desktopcollapsed }) =>
    desktopcollapsed
      ? css`
          display: flex;
          align-items: center;
          text-align: left;
        `
      : css`
          display: block;
          text-align: center;
          width: 100%;
        `}

  @media ${breakpoint.md} {
    margin-left: 32px;
  }
  @media ${breakpoint.md} and (orientation: landscape) {
    margin-left: 0;
  }
`;

export const ListHeaderWrapper = styled.div`
  text-align: center;
  justify-content: space-between;
  display: flex;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.stroke};
  position: relative;
  &:first-of-type {
    border-top: 1px solid ${({ theme }) => theme.colors.stroke};
  }

  .preloader {
    position: absolute;
    right: 45px;
  }

  ${({ expanded, desktopcollapsed, theme }) =>
    expanded
      ? css`
          ${
            desktopcollapsed
              ? `background-color: ${theme.sidebar.header.activeBackground};`
              : `background-color: inherit;`
          }
          @media${breakpoint.md} {
            background-color: ${theme.colors.secondaryVariant};
          }
        `
      : css`
          background-color: inherit;
        `};

  padding: ${({ desktopcollapsed, theme }) =>
    desktopcollapsed ? theme.sidebar.header.padding : '15px 0'};

  ${({ languageSwitcher }) =>
    languageSwitcher
      ? css`
          padding-left: 15px;
        `
      : css``};

  @media ${breakpoint.md} and (orientation: landscape) {
    min-height: 57px;
  }
  @media ${breakpoint.md} and (orientation: portrait) {
    height: calc((100vh - 53px - 145px) / 7);
    padding: 0;
  }
`;

export const NestedList = styled.ul`
  ${({ expanded }) =>
    expanded
      ? css`
          padding: 5px 0 10px 0;
          max-height: 1000px;
        `
      : css`
          padding: 0;
          max-height: 0;
        `};

  overflow: hidden;
  transition: padding-top 0.7s, max-height 0.7s;
  position: relative;

  > li {
    &::before {
      position: absolute;
      content: '>';
      left: 0;
      @media ${breakpoint.md} {
        left: 10px;
      }
    }
    > a {
      margin-left: 10%;
    }
  }
`;
