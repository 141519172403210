import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Flex, Box } from 'rebass';
import { Header as GlobalHeader } from '@styles/globalStyles';
import { breakpoint } from '@styles/breakpoints';
import Text from '@components/Text';

export const Wrapper = styled(Flex)`
  z-index: 1;
  width: 100%;
  margin-bottom: 64px;

  ${({ theme, isFixed }) =>
    isFixed &&
    css`
      position: sticky;
      top: 176px;

      &:before {
        content: '';
        position: absolute;
        height: 100vh;
        bottom: -15px;
        right: -${theme.page.mainContentPaddingX};
        left: -${theme.page.mainContentPaddingX};
        z-index: -1;
        background: ${theme.colors.white};
        box-shadow: 0 4px 8px -6px rgba(0, 0, 0, 0.25);
        @media ${breakpoint.md} {
          bottom: -40px;
        }
      }
    `}

  @media ${breakpoint.md} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 60px;
    margin-bottom: 32px;
  }
`;

export const Details = styled(Box)`
  width: 33%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  justify-content: space-between;

  ${({ isFixed }) =>
    isFixed &&
    css`
      width: 100%;
      padding: 0;
      flex-direction: row;
      align-items: center;
    `};

  @media ${breakpoint.md} {
    width: 100%;
    justify-content: center;
  }
`;

export const Cover = styled.img`
  width: 100%;
  transition: all 0.2s;
  max-width: ${({ isFixed }) => (isFixed ? 50 : 200)}px;
  @media ${breakpoint.md} {
    display: ${({ isFixed }) => (isFixed ? 'none' : 'block')};
  }
`;

export const DescriptionBox = styled(Box)`
  margin-bottom: 8px;
  @media ${breakpoint.md} {
    text-align: center;

    & > span {
      text-align: center;
    }
  }
`;

export const Header = styled(GlobalHeader)`
  margin: 0 0 16px;
  ${({ isFixed }) =>
    isFixed &&
    css`
      margin: 0;
      font-size: 20px;
    `}
`;

export const Controls = styled.div`
  display: flex;
  margin: 0 -5px;
  align-items: center;

  @media ${breakpoint.md} {
    justify-content: center;
    margin-top: 16px;
    display: ${({ isFixed }) => (isFixed ? 'none' : 'flex')};
  }
`;

export const IconButton = styled.button`
  img {
    display: inline-block;
    margin: 0 5px;
    min-width: 29px;
  }
`;

export const SectionTitle = styled(Text)`
  @media ${breakpoint.md} {
    text-align: center;
    width: 100%;
    position: sticky;
    top: 90px;
    z-index: 1;
  }
`;
