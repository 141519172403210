import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Flex } from 'rebass';
import { PLAYER_CONTEXTS } from '@components/PlayerControlButtons/PlayPause';
import * as Styled from '@components/TracksTable/components/TrackCell/style';
import routes from '@routes';

const TrackCell = ({
  playerStore: { activeTrackId },
  track,
  showAlbumCovers,
  playerContext,
  ofExpanded,
  expandOnPlay,
  ...flexProps
}) => {
  return (
    <Flex {...flexProps}>
      {showAlbumCovers && track.album?.cover?.url && (
        <Styled.CoverLink to={routes.album(track.album.uuid, track.id)}>
          <Styled.Cover src={track.album.cover.url || ''} alt="" />
        </Styled.CoverLink>
      )}

      <Styled.PlayPause
        context={playerContext}
        track={{ ...track, singleTrack: ofExpanded }}
        expandOnPlay={expandOnPlay}
        outlined
      />

      {track && (
        <Styled.Title active={track.id === activeTrackId}>
          <Styled.TrackName active={track.id === activeTrackId}>
            {ofExpanded ? track.version : track.name}
          </Styled.TrackName>
          {track.composers && (
            <Styled.Artist>
              {track.composers.map(
                ({ first_name, last_name }, index) =>
                  `${first_name} ${last_name}${
                    index !== track.composers.length - 1 ? ', ' : ''
                  }`,
              )}
            </Styled.Artist>
          )}
        </Styled.Title>
      )}
    </Flex>
  );
};

TrackCell.propTypes = {
  playerStore: PropTypes.object.isRequired,
  track: PropTypes.object.isRequired,
  showAlbumCovers: PropTypes.bool,
  playerContext: PropTypes.oneOf(Object.values(PLAYER_CONTEXTS)).isRequired,
  ofExpanded: PropTypes.bool,
  expandOnPlay: PropTypes.func,
};

TrackCell.defaultProps = {
  showAlbumCovers: false,
  ofExpanded: false,
  expandOnPlay: () => {},
};

export default inject('playerStore')(observer(TrackCell));
