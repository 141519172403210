import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import { useTranslation } from 'react-i18next';
import Text, { VARIANTS } from '@components/Text';

const DisabledDownloadTooltipBody = ({ message }) => {
  const { t } = useTranslation();

  return (
    <Box textAlign="center" p={2}>
      <Text variant={VARIANTS.SMALL_BODY}>
        {message || t('Sign in to download')}
      </Text>
    </Box>
  );
};

DisabledDownloadTooltipBody.propTypes = {
  message: PropTypes.string,
};

DisabledDownloadTooltipBody.defaultProps = {
  message: null,
};

export default DisabledDownloadTooltipBody;
