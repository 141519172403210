import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { CSSTransition } from 'react-transition-group';
import SearchModal from '@components/SearchModal';
import { Wrapper } from '@components/MobileSearch/style';

const MobileSearch = ({
  searchStore: { mobileSearchIsVisible, setMobileSearchVisibility },
}) => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    setMobileSearchVisibility(false);
  }, [pathname, search]);

  return (
    <CSSTransition
      in={mobileSearchIsVisible}
      timeout={300}
      classNames="modal-cover"
      unmountOnExit
    >
      <Wrapper>
        <SearchModal isMobile />
      </Wrapper>
    </CSSTransition>
  );
};

MobileSearch.propTypes = {
  searchStore: PropTypes.shape({
    mobileSearchIsVisible: PropTypes.bool,
    setMobileSearchVisibility: PropTypes.func,
  }).isRequired,
};

export default inject('searchStore')(observer(MobileSearch));
