import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import * as Styled from '@components/SideBar/components/SideBarItemList/style';

const NestedList = ({ title, list, linkTo, dataKey }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Styled.ListItem>
        <Styled.ListItemInternalLink
          as="button"
          activeClassName="activeLink"
          onClick={() => setIsOpen(!isOpen)}
        >
          {title}
          <Styled.InternalListIndicator expanded={isOpen}>
            &gt;
          </Styled.InternalListIndicator>
        </Styled.ListItemInternalLink>
        <Styled.NestedList expanded={isOpen}>
          {list.map(({ name, to, uuid }, index) => (
            <Styled.ListItem key={`title-${index}`} internal>
              <Styled.ListItemInternalLink
                activeClassName="activeLink"
                as={NavLink}
                to={to || linkTo[dataKey](uuid) || ''}
              >
                {name}
              </Styled.ListItemInternalLink>
            </Styled.ListItem>
          ))}
        </Styled.NestedList>
      </Styled.ListItem>
    </>
  );
};

NestedList.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  linkTo: PropTypes.object.isRequired,
  dataKey: PropTypes.string.isRequired,
};

export default NestedList;
