import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Flex } from 'rebass';
import { VARIANTS as CARD_VARIANTS } from '@components/Card';
import Text, {
  ALIGNS,
  VARIANTS as TEXT_VARIANTS,
  COLORS,
  MARGINS,
} from '@components/Text';
import Button, { VARIANTS as BUTTON_VARIANTS } from '@components/Button';

import { Wrapper } from '@containers/SearchResults/components/NoResults/style';
import routes from '@routes';

const NoResultsBox = () => {
  const { t } = useTranslation();

  return (
    <Wrapper variant={CARD_VARIANTS.GRADIENT}>
      <Flex justifyContent="center">
        <Text
          as="h2"
          variant={TEXT_VARIANTS.H1_BOLD}
          align={ALIGNS.CENTER}
          mb={MARGINS.SMALL}
          color={COLORS.WHITE}
        >
          {t('No success?')}
        </Text>
      </Flex>
      <Flex justifyContent="center">
        <Text
          variant={TEXT_VARIANTS.H4_REGULAR}
          color={COLORS.WHITE}
          size={13}
          align={ALIGNS.CENTER}
          mb={MARGINS.SMALL}
        >
          {t("Not found what you're after?\nWe're sure we can help you…")}
        </Text>
      </Flex>
      <Flex justifyContent="center" align={ALIGNS.CENTER}>
        <Text
          variant={TEXT_VARIANTS.BODY_LIGHT}
          color={COLORS.WHITE}
          align={ALIGNS.CENTER}
          mb={MARGINS.BIG}
        >
          {t(
            'Tell us the details of your project and we will send you\na selection of tracks in no time!',
          )}
        </Text>
      </Flex>
      <Flex justifyContent="center">
        <Button
          as={NavLink}
          to={routes.requestMusic}
          variant={BUTTON_VARIANTS.PRIMARY}
          label={t('Help me')}
        />
      </Flex>
    </Wrapper>
  );
};

export default NoResultsBox;
