import styled from '@emotion/styled';
import GlobalButton from '@components/Button';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled.div`
  background: #fff;
  padding: 21px 31px 52px 31px;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.backgrounds.shadow};
`;

export const Image = styled.img`
  height: 41px;
  margin-right: 25px;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  width: 100%;
  margin-top: 32px;
  grid-gap: 32px;
  min-height: 170px;

  @media ${breakpoint.md} {
    display: flex;
    flex-direction: column;
  }
`;

export const Links = styled.ul`
  list-style: none;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
`;

export const Link = styled.li`
  padding: 15px 0;
  border-top: 0.5px solid ${({ theme }) => theme.colors.quaternary};

  &:first-of-type {
    padding-top: 3px;
    border-top: none;
  }
`;

export const Button = styled(GlobalButton)`
  @media ${breakpoint.md} {
    margin-top: 20px;
  }
`;

export const Timezone = styled.span`
  margin-left: 4px;
  color: gray;
`;
