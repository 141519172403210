import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Flex, Box } from 'rebass';
import { useTranslation } from 'react-i18next';

import formatBytes from '@utils/formatBytes';
import DeleteButton from '@components/IconButtons/DeleteButton';
import { FILE_STATES } from '@containers/BriefingPage/constants';
import { COLORS, VARIANTS as TEXT_VARIANTS } from '@components/Text';
import {
  StyledText,
  FileWrapper,
  StyledFormButton,
  AltTrackGrid,
} from '@containers/BriefingPage/components/style';
import { VARIANTS as BTN_VARIANTS } from '@components/Button';
import successIcon from '@assets/images/icons/upload-success-icon.svg';
import failIcon from '@assets/images/icons/upload-fail-icon.svg';
import EditFileButton from '@containers/BriefingPage/components/EditFileButton';
import Select from '@components/Form/Select';
import TextField from '@components/Form/TextField';
import TrackDropzone from '@containers/BriefingPage/components/TrackDropzone';
import useBreakpoint from '@hooks/useBreakpoint';
import PlayPauseButton, {
  PLAYER_CONTEXTS,
} from '@components/PlayerControlButtons/PlayPause';

const AlternativeTrackRow = ({
  item,
  dataKey,
  index,
  control,
  errors,
  watch,
  register,
  remove,
  setValue,
  getValues,
  reset,
  trigger,
  isDragStarted,
  briefing,
  briefingsStore: {
    uploadAlternativeTrack,
    deleteAlternativeTrack,
    versionOptions,
    setVersion,
  },
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    register(`${dataKey}.id`);
    register(`${dataKey}.state`);
    register(`${dataKey}.file`);
  }, [register, index]);

  const id = watch(`${dataKey}.id`);
  const version = watch(`${dataKey}.version`);
  const state = watch(`${dataKey}.state`);
  const file = watch(`${dataKey}.file`);

  const [prevVersion, setPrevVersion] = useState(null);

  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  useEffect(() => {
    if (version) {
      if (version.value !== 'Stem') {
        setValue(`${dataKey}.description`, '');
      }
      setVersion(version, prevVersion);
      setPrevVersion(version);
    }
  }, [version]);

  const isStem = version?.value === 'Stem';

  const onFileUpdate = async () => {
    const isValid = await trigger([
      `${dataKey}.version`,
      `${dataKey}.description`,
    ]);

    if (isValid) {
      const { alternative_tracks } = getValues();

      const data = { ...alternative_tracks[index] };

      uploadAlternativeTrack(data, index, setValue);
    }
  };

  const onFileDelete = () => {
    const handleDelete = () => {
      setVersion(null, prevVersion);
      const formData = getValues();
      const { alternative_tracks } = formData;
      alternative_tracks.splice(index, 1);
      remove(index);
      reset({ ...formData, alternative_tracks });
    };

    if (id) {
      const setFileState = value => setValue(`${dataKey}.state`, value);

      deleteAlternativeTrack(id, setFileState, handleDelete);
    } else {
      handleDelete();
    }
  };

  const onFileChange = newFile => {
    setValue(`${dataKey}.file`, newFile);
    setValue(`${dataKey}.state`, FILE_STATES.NEW);
  };

  const fileActions = (
    <>
      {state === FILE_STATES.UPLOADING || state === FILE_STATES.NEW ? (
        <StyledFormButton
          isLoading={state === FILE_STATES.UPLOADING}
          variant={BTN_VARIANTS.SECONDARY}
          label={state === FILE_STATES.ERROR ? t('Re-upload') : t('Upload')}
          onClick={onFileUpdate}
        />
      ) : (
        <>
          <Box mr={2}>
            <PlayPauseButton
              context={PLAYER_CONTEXTS.UPLOADED}
              track={{
                ...item.track,
                name: `${item.track?.version} ${item.track?.name || ''}`,
                album: briefing,
                singleTrack: true,
                isBriefing: true,
              }}
              outlined
            />
          </Box>
          <EditFileButton
            onChange={e => {
              onFileChange(e.target.files[0]);
            }}
          />
        </>
      )}
      <DeleteButton
        callback={onFileDelete}
        disabled={
          state === FILE_STATES.DELETING || state === FILE_STATES.UPLOADING
        }
      />
    </>
  );

  useEffect(() => {
    setValue(`${dataKey}.id`, item.id);
    setValue(`${dataKey}.state`, item.state);
    setValue(`${dataKey}.file`, item.file);
  }, [item]);

  const validateDescription = value => {
    if (!value) return true;
    const { alternative_tracks } = getValues();
    const descriptions = alternative_tracks.map(
      ({ description }) => description,
    );

    return (
      descriptions.filter(
        description => description.toLowerCase() === value.toLowerCase(),
      ).length < 2 || t('Version already added')
    );
  };

  const trackDropzone = (
    <TrackDropzone
      accept="audio/wav"
      onDrop={files => onFileChange(files[0])}
      isDragStarted={isDragStarted}
      file={
        <FileWrapper alignItems="center">
          {state === FILE_STATES.UPLOADED && (
            <img src={successIcon} alt="success-icon" />
          )}
          {state === FILE_STATES.ERROR && (
            <img src={failIcon} alt="fail-icon" />
          )}
          <StyledText variant={TEXT_VARIANTS.H4} color={COLORS.BLUE}>
            {file.name}
            {file.size > 0 && <>- {formatBytes(file.size)}</>}
          </StyledText>
        </FileWrapper>
      }
    />
  );

  const typeSelect = (
    <Select
      {...{
        control,
        errors,
        options: versionOptions.map(option => ({
          label: option,
          value: option,
        })),
        name: `${dataKey}.version`,
        isSearchable: false,
        required: true,
        isMulti: false,
        label: t('Track type'),
        placeholder: t('Select type'),
        defaultValue: item.version,
        isDisabled: !!id,
      }}
    />
  );

  const descriptionInput = (
    <TextField
      {...{
        name: `${dataKey}.description`,
        register,
        errors,
        required: isStem,
        rules: {
          required: {
            value: isStem,
            message: t('Required'),
          },
          validate: {
            unique: validateDescription,
          },
        },
        placeholder: t('Add instrumentation e.g. Guitar or Bass'),
        label: t('Description'),
        defaultValue: item.description,
        disabled: !!id,
      }}
    />
  );

  return (
    <AltTrackGrid>
      {isMobile ? (
        <>
          <Flex mb={2} width={1} alignItems="center">
            <Box width={1} mr={3}>
              {trackDropzone}
            </Box>
            <Flex
              minWidth={state === FILE_STATES.NEW ? 160 : 0}
              justifyContent="flex-end"
              alignItems="center"
            >
              {fileActions}
            </Flex>
          </Flex>
          <Flex mb={2} width={1} alignItems="center">
            <Box width={1} mr={3}>
              {typeSelect}
            </Box>
            <Box width={1}>
              <Box display={isStem ? 'block' : 'none'}>{descriptionInput}</Box>
            </Box>
          </Flex>
        </>
      ) : (
        <>
          <Box mt={1}>{trackDropzone}</Box>
          <Box>{typeSelect}</Box>
          <Box>
            <Box display={isStem ? 'block' : 'none'}>{descriptionInput}</Box>
          </Box>
          <Flex justifyContent="flex-end" alignItems="center">
            {fileActions}
          </Flex>
        </>
      )}
    </AltTrackGrid>
  );
};

AlternativeTrackRow.propTypes = {
  briefingsStore: PropTypes.shape({
    alternativeTrackFiles: PropTypes.array,
    uploadAlternativeTrack: PropTypes.func,
    deleteAlternativeTrack: PropTypes.func,
    setVersion: PropTypes.func,
    versionOptions: PropTypes.array,
  }).isRequired,
  briefing: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  dataKey: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  isDragStarted: PropTypes.bool.isRequired,
};

export default inject('briefingsStore')(observer(AlternativeTrackRow));
