import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from 'rebass';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';

import useBreakpoint from '@hooks/useBreakpoint';
import Text, { VARIANTS as TEXT_VARIANTS } from '@components/Text';
import { MARGINS } from '@styles/globalStyles';
import { API, APIRoutes } from '@app/api';
import {
  Wrapper,
  StyledHeader,
  SubmitButton,
  StyledInput,
} from '@components/StayTuned/style';
import FormButton from '@components/Form/FormButton';
import { VARIANTS as BUTTON_VARIANTS } from '@components/Button';
import Notification from '@components/Form/Notification';
import { TYPES } from '@components/Form/Notification/style';

const StayTuned = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, reset } = useForm();

  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();
  let isMobile = false;

  if (matchBreakpoint(BREAKPOINTS.MD)) {
    isMobile = true;
  }

  const location = useLocation();
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    setNotification(null);
    reset();
  }, [location]);

  const onSubmit = async formData => {
    try {
      await API.post(APIRoutes.STAY_TUNED, {
        stay_tuned_user: formData,
      });
      setNotification({
        type: TYPES.SUCCESS,
        message: t('Email has been subscribed'),
      });

      reset();
    } catch (error) {
      if (error.errors?.email) {
        setNotification({
          type: TYPES.ERROR,
          message: t('Email already subscribed'),
        });
      }
    }
  };

  return (
    <Wrapper>
      <Box width={1} maxWidth={600} textAlign={isMobile ? 'center' : 'left'}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledHeader>{t('Stay tuned')}</StyledHeader>
          <Text variant={TEXT_VARIANTS.H4_REGULAR} mb={MARGINS.BIG}>
            {t('Keep up to date with our latest news')}
          </Text>
          {notification && <Notification {...notification} />}
          <StyledInput
            name="email"
            placeholder={t('Enter your email')}
            register={register}
            required
            mb={MARGINS.BIG}
            isMobile={isMobile}
            {...(!isMobile && {
              inputIndicator: () => (
                <SubmitButton type="submit">{t('Subscribe')}</SubmitButton>
              ),
            })}
          />
          <Box>
            <Text>
              {t(
                'By providing us with your email, you are giving your consent for us to contact you and agree to our terms and conditions',
              )}
            </Text>
          </Box>
          {isMobile && (
            <Box mt={4}>
              <FormButton
                variant={BUTTON_VARIANTS.GREEN}
                type="submit"
                label={t('Subscribe')}
              />
            </Box>
          )}
        </form>
      </Box>
    </Wrapper>
  );
};

export default StayTuned;
