import React from 'react';
import PropTypes from 'prop-types';

import { StyledButton } from '@components/IconButtons/style';
import UPLOAD_ICON from '@assets/images/icons/upload-file-icon.svg';

const UploadButton = ({ callback, ...props }) => (
  <StyledButton onClick={callback} {...props}>
    <img src={UPLOAD_ICON} alt="download-icon" />
  </StyledButton>
);

UploadButton.propTypes = {
  callback: PropTypes.func.isRequired,
};

export default UploadButton;
