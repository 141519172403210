import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export default styled.div`
  display: block !important;
  background: ${props => props.background || props.theme.colors.quaternary};
  margin: auto 0 auto 16px;
  width: 100%;
  cursor: pointer;
  height: 1px;

  .swiper-scrollbar-drag {
    &::after {
      position: absolute;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      background-color: ${props => props.theme.colors.primary};
      content: '';
      right: 50%;
      transform: translate(50%, -50%);
    }
  }

  @media ${breakpoint.sm} {
    display: none !important;
  }
`;
