import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Preloader from '@components/Preloader';
import routes from '@routes';

import * as Styled from '@components/SearchSuggestions/components/SuggestionsSection/style';

export const SUGGESTION_TYPES = {
  ALBUMS: 'ALBUMS',
  TRACKS: 'TRACKS',
  MOODS: 'MOODS',
  RECENT_SEARCHES: 'RECENT_SEARCHES',
  KEYWORDS: 'KEYWORDS',
  GENRES: 'GENRES',
  INSTRUMENTATIONS: 'INSTRUMENTATIONS',
};

const href = {
  ALBUMS: uuid => routes.album(uuid),
  TRACKS: (uuid, trackId) => routes.album(uuid, trackId),
};

const SuggestionsSection = ({
  applyFilter,
  title,
  isLoading,
  suggestions,
  type,
  width,
}) => (
  <Styled.Wrapper
    width={width}
    disabled={!suggestions.length}
    isLoading={isLoading}
  >
    <Styled.Header>{title}:</Styled.Header>{' '}
    {isLoading ? <Preloader center cover /> : ''}
    {suggestions.length ? (
      <Styled.List>
        {suggestions.map((suggestion, index) => {
          let linkHref;
          const suggestionUuid = suggestion.uuid || suggestion.name;
          const filter = { type, uuid: suggestionUuid };
          if (type === SUGGESTION_TYPES.ALBUMS) {
            linkHref = href[type](suggestionUuid);
          }
          if (type === SUGGESTION_TYPES.TRACKS) {
            linkHref = href[type](suggestion.album_uuid, suggestion.id);
            filter.trackId = suggestion.id;
          }

          return (
            <Styled.Item key={`${suggestionUuid}__${index}`}>
              {type === SUGGESTION_TYPES.TRACKS ||
              type === SUGGESTION_TYPES.ALBUMS ? (
                <NavLink to={linkHref}>{suggestion.name}</NavLink>
              ) : (
                <button type="button" onClick={() => applyFilter(filter)}>
                  {suggestion.name}
                </button>
              )}
            </Styled.Item>
          );
        })}
      </Styled.List>
    ) : null}
  </Styled.Wrapper>
);

SuggestionsSection.propTypes = {
  title: PropTypes.string.isRequired,
  suggestions: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  width: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.number,
  ]).isRequired,
  type: PropTypes.oneOf(Object.values(SUGGESTION_TYPES)).isRequired,
  applyFilter: PropTypes.func.isRequired,
};

export default SuggestionsSection;
