import styled from '@emotion/styled';
import List from '@components/GridList';
import Text from '@components/Text';

export const GridList = styled(List)`
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
`;

export const Description = styled(Text)`
  margin: 50px 0;
  max-width: 420px;
`;
