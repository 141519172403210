import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Box, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';

import { VARIANTS as BTN_VARIANTS } from '@components/Button';
import FormButton from '@components/Form/FormButton';

import {
  BUSINESS_TYPES,
  MRO_ATTRIBUTES,
  PRO_ATTRIBUTES,
} from '@root/stores/registrationStore';

const AccountForm = ({ onSubmit, formStatus, countries, customer, tabs }) => {
  const { t } = useTranslation();

  const {
    watch,
    register,
    handleSubmit,
    control,
    errors: validationErrors,
    reset,
  } = useForm({
    mode: 'onChange', // required to clear errors from 'trigger'
  });

  const { activeTab, errors: formErrors, isLoading } = formStatus;

  const errors = {
    ...validationErrors,
    ...formErrors,
  };

  useEffect(() => {
    if (customer && countries.length) {
      const country = countries.find(({ value }) => value === customer.country);
      const country_of_residence = countries.find(
        ({ value }) => value === customer.country_of_residence,
      );
      const bank_country = countries.find(
        ({ value }) => value === customer.bank_country,
      );

      const business_type = BUSINESS_TYPES.find(
        ({ value }) => value === customer.business_type,
      );

      const mro = MRO_ATTRIBUTES.find(({ value }) => value === customer.mro);

      const pro = PRO_ATTRIBUTES.find(({ value }) => value === customer.pro);

      reset({
        ...customer,
        country,
        country_of_residence,
        bank_country,
        business_type,
        mro,
        pro,
      });
    }
  }, [customer, countries, activeTab]);

  const ActivePanel = tabs[activeTab]?.Component;
  const activeTabId = tabs[activeTab]?.id;

  const isComposer = customer?.is_composer || false;
  const isProducer = customer?.is_producer || false;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex width={1} alignItems="center" flexDirection="column">
        <Box width={1}>
          {ActivePanel && (
            <ActivePanel
              {...{
                watch,
                customer,
                register,
                control,
                errors,
                countries,
                isComposer,
                isProducer,
              }}
            />
          )}
        </Box>
        {activeTabId !== 'composers' && (
          <Box mt={4} width={1} textAlign="left">
            <FormButton
              isLoading={isLoading}
              type="submit"
              variant={BTN_VARIANTS.GREEN}
              label={t('Update')}
            />
          </Box>
        )}
      </Flex>
    </form>
  );
};

AccountForm.defaultProps = {
  countries: [],
  customer: {},
};

AccountForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formStatus: PropTypes.object.isRequired,
  customer: PropTypes.object,
  countries: PropTypes.array,
  tabs: PropTypes.array.isRequired,
};

export default AccountForm;
