import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import { Box } from 'rebass';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Header, MainContent } from '@styles/globalStyles';
import LicenseForm from '@containers/LicenseApplication/components/LicenseForm';
import Text, { VARIANTS } from '@components/Text';
import Preloader from '@components/Preloader';
import NotFound from '@containers/NotFound';

const LicenseApplication = ({
  licensesStore: {
    singleLicense: { data, error, isLoading },
    getSingleLicense,
    clearForm,
  },
}) => {
  const { t } = useTranslation();

  const { id: uuid } = useParams();

  const { kind, title } = data;

  useEffect(() => {
    getSingleLicense(uuid);

    return () => clearForm();
  }, []);

  if (error) {
    return <NotFound />;
  }

  return (
    <MainContent>
      <Header>
        <Box mb={3}>
          {t('License application')}: {title}
        </Box>
        <Box width={[1, 1, 1, 1 / 2]}>
          <Text variant={VARIANTS.H4_REGULAR}>
            {t(
              // eslint-disable-next-line max-len
              'This application is for the utilisation (type specified below) of music recordings (described in detail below) from the archives of lntervox Production Publishing Music GmbH. Unless otherwise agreed, the licence award and calculations shall be performed in accordance with our corresponding current price list. ',
            )}
          </Text>
        </Box>
      </Header>
      {isLoading ? (
        <Preloader center />
      ) : (
        <LicenseForm uuid={uuid} kind={kind} />
      )}
      {error && <Text>{t('')}</Text>}
    </MainContent>
  );
};

LicenseApplication.propTypes = {
  licensesStore: PropTypes.shape({
    singleLicense: PropTypes.shape({
      data: PropTypes.object,
      error: PropTypes.object,
      isLoading: PropTypes.bool,
    }),
    getSingleLicense: PropTypes.func,
    clearForm: PropTypes.func,
  }).isRequired,
};

export default inject('licensesStore')(observer(LicenseApplication));
