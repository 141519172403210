import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled.footer`
  position: relative;
  background: ${({ theme }) => theme.backgrounds.secondaryGradientBackground};
  min-height: 357px;
  padding: 34px 24px 19px 56px;
  color: #fff;
  font-weight: 300;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  overflow: hidden;

  @media ${breakpoint.md} {
    padding: 39px 23px 21px 29px;
  }
`;

export const MainFooter = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const MainInfo = styled.div`
  display: flex;
`;

export const SecondFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  @media ${breakpoint.md} {
    margin-top: 50px;
    display: block;
    position: relative;
    img {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
`;

export const Copy = styled.div`
  @media ${breakpoint.md} {
    text-align: center;
    padding-bottom: 100px;
    display: block;
  }
`;

export const FooterLogo = styled.div`
  padding-right: 56px;
  img {
    max-width: 56px;
    height: auto;
  }
`;
