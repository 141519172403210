import styled from '@emotion/styled';

export const Wrapper = styled.div`
  padding: 20px 15px;
  border-top: 1px solid #eaeaea;
  position: fixed;
  z-index: 999;
  background-color: #fff;
  height: calc(100vh - 53px);
  /* overflow-y: scroll; */
  display: flex;
  flex-direction: column;
  width: 100%;
`;
