import React from 'react';
import { useHistory, useLocation } from 'react-router';

import routes from '@routes';
import i18n from '@utils/i18n';
import Tabs from '@components/Tabs';

const CUSTOMER_TABS = [
  { id: 'myAlbums', name: i18n.t('My albums'), route: routes.myAlbums },
  { id: 'myProjects', name: i18n.t('My projects'), route: routes.myProjects },
];

const CustomerTabs = () => {
  const history = useHistory();
  const location = useLocation();

  const setActiveTab = id => {
    history.push(CUSTOMER_TABS[id].route);
  };

  const activeTab = CUSTOMER_TABS.findIndex(
    ({ route }) => route === location.pathname,
  );

  return (
    <Tabs
      tabs={CUSTOMER_TABS}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
  );
};

export default CustomerTabs;
