import { API, APIRoutes } from '@app/api';
import { makeAutoObservable, runInAction } from 'mobx';
import i18n from '@utils/i18n';

export class TemposStore {
  constructor() {
    makeAutoObservable(this);
  }

  tempos = {
    isLoading: false,
    data: [],
    error: null,
  };

  getTempos = async () => {
    try {
      this.tempos.isLoading = true;

      const {
        data: { tempos },
      } = await API(APIRoutes.TEMPOS);

      runInAction(() => {
        this.tempos.data = [
          { id: 'all', name: i18n.t('All tempos') },
          ...tempos,
        ];
      });
    } catch (error) {
      runInAction(() => {
        this.tempos.error = error;
      });
    } finally {
      runInAction(() => {
        this.tempos.isLoading = false;
      });
    }
  };
}

export default new TemposStore();
