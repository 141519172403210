import { runInAction, makeAutoObservable } from 'mobx';
import { debounce, isFunction, sum } from 'lodash-es';
import { toast } from 'react-toastify';

import { API, APIRoutes } from '@app/api';
import addObjectToForm from '@utils/addObjectToForm';
import { history } from '@app/history';
import routes from '@routes';
import i18n from '@utils/i18n';
import getFileName from '@utils/getFileName';
import { FILE_STATES } from '@containers/BriefingPage';

import playerStore from '@stores/playerStore';

const DEFAULT_BRIEFING_TRACK = {
  isLoading: false,
  isFetching: false,
  progress: null,
  data: {},
  errors: null,
  dataError: null,
  briefing: {},
};

const DEFAULT_REMOVED_DATA = {
  composers: [],
  lyricists: [],
};

const DEFAULT_ALTERNATIVE_TRACK_PROGRESS = {
  totals: {},
  loads: {},
  value: 0,
  files: 0,
  onSuccess: null,
};

const DEFAULT_VERSION_OPTIONS = ['Stem'];

export class BriefingsStore {
  constructor() {
    makeAutoObservable(this);
  }

  briefings = {
    isLoading: false,
    data: [],
    error: null,
  };

  briefingTrack = { ...DEFAULT_BRIEFING_TRACK };

  removedData = { ...DEFAULT_REMOVED_DATA };

  fullTrackFile = {};

  fullTrackProgress = 0;

  alternativeTracksProgress = { ...DEFAULT_ALTERNATIVE_TRACK_PROGRESS };

  versionOptions = [...DEFAULT_VERSION_OPTIONS];

  loadings = {};

  onSubmitSuccess = null;

  getBriefings = async () => {
    try {
      this.briefings.isLoading = true;

      const {
        data: { briefings },
      } = await API(APIRoutes.BRIEFINGS);
      runInAction(() => {
        this.briefings.data = briefings;
      });
    } catch (error) {
      runInAction(() => {
        this.briefings.error = error;
      });
    } finally {
      runInAction(() => {
        this.briefings.isLoading = false;
      });
    }
  };

  setVersion = (version, prevVersion) => {
    const value = version?.value;
    const prevValue = prevVersion?.value;
    let newOptions = [...this.versionOptions];

    if (value && value !== 'Stem') {
      newOptions = this.versionOptions.filter(option => option !== value);
    }

    if (prevValue && !this.versionOptions.includes(prevValue)) {
      newOptions.push(prevValue);
    }

    this.versionOptions = newOptions;
  };

  formatAlternativeTrack = track => {
    const { file, stem, version, id } = track;
    return {
      version: stem
        ? { label: 'Stem', value: 'Stem' }
        : { label: version, value: version },
      description: stem ? version : '',

      file: new File([''], getFileName(file.url)),

      state: FILE_STATES.UPLOADED,
      id,
      track,
    };
  };

  getBriefingTrack = async id => {
    try {
      this.briefingTrack.isFetching = true;
      this.briefingTrack.dataError = null;

      const {
        data: { briefing_track },
      } = await API(APIRoutes.GET_BRIEFING_TRACK(id));

      const {
        file,
        alternative_tracks,
        briefing,
        ...trackData
      } = briefing_track;

      const formattedAlternatives = alternative_tracks.map(
        this.formatAlternativeTrack,
      );

      const formattedData = {
        ...trackData,
        alternative_tracks: formattedAlternatives,
        track: briefing_track,
      };

      runInAction(() => {
        this.fullTrackFile = {
          name: getFileName(file.url),
          state: FILE_STATES.UPLOADED,
        };

        this.briefingTrack.data = formattedData;
        this.briefingTrack.briefing = briefing;
        this.versionOptions = [
          ...briefing.available_versions,
          ...DEFAULT_VERSION_OPTIONS,
        ];
      });
    } catch (error) {
      runInAction(() => {
        this.briefingTrack.dataError = error;
      });
    } finally {
      runInAction(() => {
        this.briefingTrack.isFetching = false;
      });
    }
  };

  clearForm = () => {
    this.briefingTrack.data = { ...DEFAULT_BRIEFING_TRACK };
    this.fullTrackFile = {};
    this.removedData = { ...DEFAULT_REMOVED_DATA };
    this.fullTrackProgress = 0;
    this.alternativeTracksProgress = { ...DEFAULT_ALTERNATIVE_TRACK_PROGRESS };
    this.versionOptions = [...DEFAULT_VERSION_OPTIONS];
  };

  removeComposer = data => {
    if (!data.isNew) {
      this.removedData.composers.push({ ...data, _destroy: true });
    }
  };

  removeLyricist = data => {
    if (!data.isNew) {
      this.removedData.lyricists.push({ ...data, _destroy: true });
    }
  };

  addFullTrackFile = (files, state) => {
    let file = {};
    if (files?.length) {
      // eslint-disable-next-line prefer-destructuring
      file = files[0];
      if (state) {
        file.state = state;
      }
    }
    this.fullTrackFile = file;
  };

  getValidTrackName = debounce(
    (resolve, reject, name) =>
      API(APIRoutes.VALIDATE_TRACK_NAME(name))
        .then(({ data: { already_exists } }) => resolve(!already_exists))
        .catch(error => reject(error)),
    400,
  );

  validateTrackName = name =>
    new Promise((resolve, reject) => {
      if (name === this.briefingTrack.data.name) {
        resolve(true);
      } else {
        this.getValidTrackName(resolve, reject, name);
      }
    });

  getValidIpi = debounce(
    (resolve, _reject, type, ipi) =>
      API(APIRoutes.CHECK_COMPOSERS(type, ipi))
        .then(() => resolve(true))
        .catch(() => resolve(false)),
    400,
  );

  validateIpi = (type, ipi) =>
    new Promise((resolve, reject) =>
      this.getValidIpi(resolve, reject, type, ipi),
    );

  submitBriefingTrack = async ({
    uuid,
    genres,
    moods,
    topics,
    instrumentations,
    briefing_tracks_composers,
    briefing_tracks_lyricists,
    file_control,
    ...formData
  }) => {
    try {
      this.briefingTrack.isLoading = true;
      const formattedData = {
        version: 'full',
        genre_ids: genres ? genres.map(({ id }) => id) : [],
        mood_ids: moods ? moods.map(({ id }) => id) : [],
        topic_ids: topics ? topics.map(({ id }) => id) : [],
        instrumentation_ids: instrumentations
          ? instrumentations.map(({ id }) => id)
          : [],
        composers: briefing_tracks_composers,
        lyricists: briefing_tracks_lyricists,
        ...formData,
      };

      const payload = new FormData();

      addObjectToForm(formattedData, payload, 'briefing_track');

      payload.append('briefing_track[file]', this.fullTrackFile);

      await API.post(
        APIRoutes.SUBMIT_BRIEFING_TRACK(uuid),
        payload,
        this.fullTrackProgressConfig,
      );

      history.push(routes.uploadedTracks);
      toast(i18n.t('Track uploaded successfully'));
    } catch (e) {
      this.handleFormErrors(e);
    } finally {
      runInAction(() => {
        this.briefingTrack.isLoading = false;
      });
    }
  };

  submitBriefingTrackUpdate = async (
    {
      genres,
      moods,
      topics,
      instrumentations,
      briefing_tracks_composers = [],
      briefing_tracks_lyricists = [],
      file_control,
      alternative_tracks = [],
      ...formData
    },
    setValue,
  ) => {
    try {
      this.loadings.full = true;

      const formattedData = {
        version: 'full',
        genre_ids: genres ? genres.map(({ id }) => id) : [],
        mood_ids: moods ? moods.map(({ id }) => id) : [],
        topic_ids: topics ? topics.map(({ id }) => id) : [],
        instrumentation_ids: instrumentations
          ? instrumentations.map(({ id }) => id)
          : [],
        composers: [
          ...briefing_tracks_composers,
          ...this.removedData.composers,
        ],
        lyricists: [
          ...briefing_tracks_lyricists,
          ...this.removedData.lyricists,
        ],
        ...formData,
      };

      const payload = new FormData();

      addObjectToForm(formattedData, payload, 'briefing_track');

      if (this.fullTrackFile.state === FILE_STATES.NEW) {
        runInAction(() => {
          this.fullTrackFile.state = FILE_STATES.UPLOADING;
        });
        payload.append('briefing_track[file]', this.fullTrackFile);
      }

      const {
        data: { id },
        briefing: { uuid },
      } = this.briefingTrack;

      alternative_tracks.forEach((track, index) => {
        if (track.state === FILE_STATES.NEW) {
          this.uploadAlternativeTrack(track, index, setValue, false);
        }
      });

      await API.put(
        APIRoutes.UPDATE_BRIEFING_TRACK_FORM(uuid, id),
        payload,
        this.fullTrackProgressConfig,
      );

      runInAction(() => {
        this.fullTrackFile.state = FILE_STATES.UPLOADED;
        this.loadings.full = false;
      });

      const isMultiFiles = alternative_tracks
        .map(({ state }) => state)
        .includes(FILE_STATES.NEW);

      const onSuccess = () => {
        if (!Object.values(this.loadings).includes(true)) {
          toast(i18n.t('Briefing track updated successfully'));

          this.handleUpdateSuccess();

          runInAction(() => {
            this.onSubmitSuccess = null;
          });
        }
      };

      if (isMultiFiles) {
        runInAction(() => {
          this.onSubmitSuccess = onSuccess;
        });
      }

      onSuccess();
    } catch (e) {
      console.warn(e); // eslint-disable-line no-console
      this.handleFormErrors(e);
      runInAction(() => {
        this.fullTrackFile.state = FILE_STATES.ERROR;
        this.loadings.full = false;
      });
    }
  };

  uploadFullTrack = async () => {
    try {
      this.loadings.full = true;

      runInAction(() => {
        this.fullTrackFile.state = FILE_STATES.UPLOADING;
      });

      const payload = new FormData();

      payload.append('file_only', true);
      payload.append('briefing_track[file]', this.fullTrackFile);

      const {
        briefing: { uuid },
        data: { id },
      } = this.briefingTrack;

      await API.put(
        APIRoutes.UPLOAD_FULL_TRACK(uuid, id),
        payload,
        this.fullTrackProgressConfig,
      );

      toast(i18n.t('Track updated successfully'));
      this.handleUpdateSuccess();

      runInAction(() => {
        this.fullTrackFile.state = FILE_STATES.UPLOADED;
      });
    } catch (e) {
      this.handleFormErrors(e);

      runInAction(() => {
        this.fullTrackFile.state = FILE_STATES.ERROR;
      });
    } finally {
      runInAction(() => {
        this.loadings.full = false;
      });
    }
  };

  uploadAlternativeTrack = async (
    values,
    index,
    setValue = () => {},
    isSingleUpdate = true,
  ) => {
    const { id, file, version, description } = values;

    const dataKey = `alternative_tracks[${index}]`;

    try {
      this.loadings[index] = true;
      setValue(`${dataKey}.state`, FILE_STATES.UPLOADING);
      const payload = new FormData();

      const {
        briefing: { uuid },
        data: { id: trackId },
      } = this.briefingTrack;

      if (id) {
        payload.append('briefing_track[id]', id);
        payload.append('briefing_track[file]', file);

        await API.put(APIRoutes.UPDATE_ALTERNATIVE_TRACK(uuid), payload, {
          onUploadProgress: progressEvent =>
            this.onUploadProgress(progressEvent, index),
        });
      } else {
        const isStem = version.value === 'Stem';

        payload.append(
          'briefing_track[version]',
          isStem ? description : version.value,
        );
        payload.append('briefing_track[stem]', isStem);
        payload.append('briefing_track[file]', file);

        const {
          data: {
            briefing_track: { id: newId },
          },
        } = await API.post(
          APIRoutes.ADD_ALTERNATIVE_TACK(uuid, trackId),
          payload,
          {
            onUploadProgress: progressEvent =>
              this.onUploadProgress(progressEvent, index),
          },
        );

        if (newId) {
          setValue(`${dataKey}.id`, newId);
        }
      }

      runInAction(() => {
        this.loadings[index] = false;
      });

      if (isFunction(this.onSubmitSuccess)) {
        this.onSubmitSuccess();
      }

      if (isSingleUpdate) {
        this.handleUpdateSuccess();

        if (id) {
          toast(i18n.t('Track updated successfully'));
        } else {
          toast(i18n.t('Track added successfully'));
        }
      }

      setValue(`${dataKey}.state`, FILE_STATES.UPLOADED);
    } catch (e) {
      console.warn(e); // eslint-disable-line no-console

      setValue(`${dataKey}.state`, FILE_STATES.ERROR);

      this.handleFormErrors(e);

      runInAction(() => {
        this.loadings[index] = false;
      });
    }
  };

  deleteAlternativeTrack = async (
    id,
    setFileState = () => {},
    onSuccess = () => {},
  ) => {
    try {
      this.briefingTrack.isLoading = true;
      setFileState(FILE_STATES.DELETING);
      const payload = new FormData();

      const {
        briefing: { uuid },
      } = this.briefingTrack;

      if (id) {
        payload.append('briefing_track[id]', id);

        payload.append('briefing_track[_destroy]', true);

        await API.put(APIRoutes.UPDATE_ALTERNATIVE_TRACK(uuid), payload);

        onSuccess();

        this.handleUpdateSuccess();
      }

      toast(i18n.t('Track deleted successfully'));
    } catch (e) {
      setFileState(FILE_STATES.ERROR);

      console.warn(e); // eslint-disable-line no-console
    } finally {
      runInAction(() => {
        this.briefingTrack.isLoading = false;
      });
    }
  };

  handleUpdateSuccess = () => {
    const {
      data: { id },
    } = this.briefingTrack;

    playerStore.resetPlayer();
    this.getBriefingTrack(id);
  };

  handleFormErrors = error => {
    const formErrors = { ...error.errors };
    Object.keys(formErrors).forEach(key => {
      formErrors[key] = { message: formErrors[key] };
    });

    runInAction(() => {
      this.briefingTrack.errors = formErrors;
    });
  };

  fullTrackProgressConfig = {
    onUploadProgress: ({ loaded, total }) => {
      const percentCompleted = Math.round((loaded * 100) / total);

      runInAction(() => {
        this.fullTrackProgress = percentCompleted;
      });
    },
  };

  onUploadProgress = ({ loaded, total }, index) => {
    const { totals, loads } = this.alternativeTracksProgress;

    runInAction(() => {
      totals[index] = total;
      loads[index] = loaded;
    });

    const percentCompleted = Math.round(
      (sum(Object.values(loads)) * 100) / sum(Object.values(totals)),
    );

    runInAction(() => {
      this.alternativeTracksProgress.value = percentCompleted;
      this.alternativeTracksProgress.files = Object.keys(totals).length;
    });
  };
}

export default new BriefingsStore();
