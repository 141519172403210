import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, TabsRow, Tab } from '@components/Tabs/style';

const Tabs = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <Wrapper>
      <TabsRow>
        {tabs.map(({ name, value }, id) => (
          <Tab
            key={`tab-${name}-${id}`}
            active={value ? activeTab === value : activeTab === id}
            onClick={() => setActiveTab(value || id)}
            type="button"
          >
            {name}
          </Tab>
        ))}
      </TabsRow>
    </Wrapper>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  activeTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default Tabs;
