import React, { useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { Box } from 'rebass';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Text, { COLORS, VARIANTS, MARGINS } from '@components/Text';
import Button, { VARIANTS as BUTTON_VARIANTS } from '@components/Button';
import { Row, Col } from '@components/SearchModal/style';
import Select from 'react-select';
import FilterInput from '@components/SearchModal/components/AdvancedForm/components/FilterInput';
import { primaryDropdownStyles } from '@styles/globalStyles';
import * as Styled from '@components/SearchModal/components/AdvancedForm/style';
import useBreakpoint from '@hooks/useBreakpoint';

import ArrowDownIcon from '@assets/images/icons/arrow-down.svg';
import PlusIcon from '@assets/images/icons/plus-icon.svg';

const AdvancedSearchForm = ({
  searchStore: {
    advancedSearchFilters,
    submitAdvancedSearch,
    selectFilter,
    selectedFilters,
    addFilter,
    removeFilter,
    resetAdvancedForm,
  },
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { control, register, unregister, handleSubmit, errors } = useForm();
  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();

  useEffect(
    () => () => {
      resetAdvancedForm(unregister);
    },
    [],
  );

  return (
    <Styled.Wrapper>
      <Row>
        <Col px={[0, 0, 1]} width={[1, 2 / 3]}>
          <Styled.SectionTitle>{t('Advanced search')}</Styled.SectionTitle>
          <Styled.FiltersFormWrapper
            as="form"
            onSubmit={handleSubmit(submitAdvancedSearch)}
          >
            {selectedFilters.map((f, index) =>
              f ? (
                <Styled.FilterRow key={`search-filter_${index}`}>
                  <Styled.FilterType>
                    <Select
                      defaultValue={f.value ? f : null}
                      placeholder={t('Select field')}
                      options={advancedSearchFilters.filter(
                        filter =>
                          !selectedFilters.find(
                            sf => sf !== null && sf.value === filter.value,
                          ),
                      )}
                      onChange={async v => {
                        await selectFilter(v, index, unregister);
                      }}
                      styles={primaryDropdownStyles(theme)}
                      isSearchable={false}
                      components={{
                        DropdownIndicator: ({
                          // eslint-disable-next-line
                          selectProps: { menuIsOpen },
                        }) => (
                          <Styled.Indicator
                            menuIsOpen={menuIsOpen}
                            src={ArrowDownIcon}
                            alt=""
                          />
                        ),
                      }}
                    />
                  </Styled.FilterType>
                  <Styled.FilterInput>
                    <FilterInput
                      control={control}
                      filter={f}
                      register={register}
                      errors={errors}
                    />
                  </Styled.FilterInput>
                  <Styled.RemoveWrapper>
                    {selectedFilters.length > 1 && index !== 0 && (
                      <Styled.RemoveFilterButton
                        onClick={() => removeFilter(index, unregister)}
                        type="button"
                      >
                        <img src={PlusIcon} alt="remove" />
                      </Styled.RemoveFilterButton>
                    )}
                  </Styled.RemoveWrapper>
                </Styled.FilterRow>
              ) : null,
            )}
            <Box>
              <Button
                label={t('Add row')}
                onClick={addFilter}
                disabled={
                  selectedFilters.reduce(
                    (acc, value) => (value ? acc + 1 : acc),
                    0,
                  ) === advancedSearchFilters.length
                }
              />
            </Box>
            <Styled.ButtonsRow>
              <Button
                label={t('Search')}
                type="submit"
                variant={BUTTON_VARIANTS.SECONDARY}
              />
            </Styled.ButtonsRow>
          </Styled.FiltersFormWrapper>
        </Col>
        {!matchBreakpoint(BREAKPOINTS.MD) ? (
          <Col px={1} width={[1, 1 / 3]} border>
            <Styled.SectionTitle>{t('Keywords')}</Styled.SectionTitle>
            <Text
              variant={VARIANTS.SMALL_BODY}
              color={COLORS.GREY}
              as="p"
              mb={MARGINS.SMALL}
            >
              {t('advanced search instruction 1')}
            </Text>
            <Text variant={VARIANTS.SMALL_BODY} color={COLORS.GREY} as="p">
              {t('advanced search instruction 2')}
            </Text>
          </Col>
        ) : null}
      </Row>
    </Styled.Wrapper>
  );
};

AdvancedSearchForm.propTypes = {
  searchStore: PropTypes.shape({
    addFilter: PropTypes.func,
    removeFilter: PropTypes.func,
    advancedSearchFilters: PropTypes.array,
    submitAdvancedSearch: PropTypes.func,
    selectFilter: PropTypes.func,
    selectedFilters: PropTypes.array,
    resetAdvancedForm: PropTypes.func,
  }).isRequired,
};

export default inject('searchStore')(observer(AdvancedSearchForm));
