import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import gtmParts from 'react-google-tag-manager';

export { COUNTRY_TAG_CODES } from '@utils/i18n/locationsContent';

const GoogleTagManager = ({
  gtmId,
  dataLayerName,
  additionalEvents,
  previewVariables,
  scriptId,
}) => {
  useEffect(() => {
    if (!window[dataLayerName]) {
      const gtmScriptNode = document.getElementById(scriptId);
      // eslint-disable-next-line
      eval(gtmScriptNode.textContent);
    }
  }, []);

  const gtm = gtmParts({
    id: gtmId,
    dataLayerName,
    additionalEvents,
    previewVariables,
  });

  return (
    <div>
      <div>{gtm.noScriptAsReact()}</div>
      <div id={scriptId}>{gtm.scriptAsReact()}</div>
    </div>
  );
};

GoogleTagManager.propTypes = {
  gtmId: PropTypes.string.isRequired,
  dataLayerName: PropTypes.string,
  additionalEvents: PropTypes.object,
  previewVariables: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  scriptId: PropTypes.string,
};

GoogleTagManager.defaultProps = {
  dataLayerName: 'dataLayer',
  additionalEvents: {},
  previewVariables: false,
  scriptId: 'react-google-tag-manager-gtm',
};

export default GoogleTagManager;
