import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled.div`
  width: 100px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;

  @media ${breakpoint.md} {
    display: none;
  }
`;

export const Icon = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  position: relative;
  width: 36px;
  height: 36px;
  flex: 0 0 36px;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const VolumeTrack = styled.div`
  width: 100%;
  height: 2px;
  background-color: #c5c5c5;
`;

export const VolumeThumb = styled.div`
  width: 13px;
  height: 13px;
  background-color: #0d0d0d;
  border-radius: 100%;
  &:focus {
    outline: none;
  }
`;
