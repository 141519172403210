import { makeAutoObservable, runInAction } from 'mobx';
import { API, APIRoutes } from '@app/api';

export class AboutUsStore {
  constructor() {
    makeAutoObservable(this);
  }

  content = {
    data: {},
    isLoading: false,
    error: null,
  };

  statistics = {
    data: {},
    isLoading: false,
    error: null,
  };

  getStatistics = async () => {
    runInAction(() => {
      this.statistics.error = false;
      this.statistics.isLoading = true;
    });
    try {
      const { data } = await API(APIRoutes.ABOUT_STATISTICS);
      runInAction(() => {
        this.statistics.data = data;
      });
    } catch (error) {
      runInAction(() => {
        this.statistics.error = error;
      });
    } finally {
      runInAction(() => {
        this.statistics.isLoading = false;
      });
    }
  };

  getContent = async () => {
    runInAction(() => {
      this.content.error = false;
      this.content.isLoading = true;
    });
    try {
      const {
        data: { page },
      } = await API(APIRoutes.ABOUT_CONTENT);
      runInAction(() => {
        this.content.data = page;
      });
    } catch (error) {
      runInAction(() => {
        this.content.error = error;
      });
    } finally {
      runInAction(() => {
        this.content.isLoading = false;
      });
    }
  };
}

export default new AboutUsStore();
