import styled from '@emotion/styled';
import Card from '@components/Card';

export const Wrapper = styled(Card)`
  width: 100%;
  max-width: 592px;
  margin: 60px auto;
  white-space: pre-line;

  h2 {
    text-transform: uppercase;
  }
`;
