import styled from '@emotion/styled';

export const Wrapper = styled.div`
  position: fixed;
  bottom: -100px;
  right: 0;
`;

export const Progress = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 2px;

  svg {
    width: 100%;
    height: 100%;

    transform: scaleX(-1) rotate(-90deg);

    circle {
      fill: none;
      stroke: #cedfe3;
      stroke-width: 5px;
    }
  }
`;

export const Close = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.secondary};
  opacity: 0;
  padding: 4px;
  background-clip: content-box;
  transition: opacity 0.2s;
`;

export const Bars = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 30px;

  .bar {
    background: ${({ theme }) => theme.colors.secondary};
    top: 50%;
    transform: translateY(-50%);
    height: 3px;
    position: absolute;
    width: 3px;
    animation: sound 0ms -800ms linear infinite alternate;
    animation-play-state: ${({ isPlaying }) =>
      isPlaying ? 'running' : 'paused'};

    &:nth-of-type(1) {
      left: 1px;
      animation-duration: 474ms;
    }

    &:nth-of-type(2) {
      left: 5px;
      animation-duration: 433ms;
    }

    &:nth-of-type(3) {
      left: 9px;
      animation-duration: 407ms;
    }

    &:nth-of-type(4) {
      left: 13px;
      animation-duration: 458ms;
    }

    &:nth-of-type(5) {
      left: 17px;
      animation-duration: 400ms;
    }

    &:nth-of-type(6) {
      left: 21px;
      animation-duration: 427ms;
    }

    &:nth-of-type(7) {
      left: 25px;
      animation-duration: 441ms;
    }

    &:nth-of-type(8) {
      left: 29px;
      animation-duration: 419ms;
    }

    &:nth-of-type(9) {
      left: 33px;
      animation-duration: 487ms;
    }

    &:nth-of-type(10) {
      left: 37px;
      animation-duration: 442ms;
    }
  }

  @keyframes sound {
    0% {
      opacity: 0.35;
      height: 3px;
    }
    100% {
      opacity: 1;
      height: 28px;
    }
  }
`;

export const Container = styled.div`
  position: absolute;
  bottom: 30px;
  right: 30px;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);

  &:hover {
    ${Close} {
      opacity: 1;
    }
  }
`;

export const TooltipBody = styled.div`
  padding: 8px;
  text-align: center;
  font-size: 0.7rem;

  strong {
    font-size: 0.9rem;
    font-weight: 500;
  }
`;
