import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { StyledButton } from '@components/IconButtons/style';
import Tooltip from '@components/Tooltip';
import DisabledDownloadTooltipBody from '@components/IconButtons/DisabledDownloadTooltipBody';

import StarIcon from '@assets/images/icons/star-icon.svg';
import StarIconSaved from '@assets/images/icons/star-icon-saved.svg';

const FavoritesButton = ({
  albumId,
  authStore: { isAuthenticated },
  favoritesStore: { checkIsFavorite, toggleFavorites },
}) => {
  const { t } = useTranslation();
  const isFavorite = checkIsFavorite(albumId);

  return (
    <Tooltip
      isActive={!isAuthenticated}
      tooltipBody={
        <DisabledDownloadTooltipBody
          message={t('Sign in to add to favorites')}
        />
      }
    >
      <div>
        <StyledButton
          type="button"
          onClick={() => toggleFavorites(albumId)}
          disabled={!isAuthenticated}
        >
          <img
            src={isFavorite ? StarIconSaved : StarIcon}
            alt="add to favorites"
          />
        </StyledButton>
      </div>
    </Tooltip>
  );
};

FavoritesButton.defaultProps = {
  albumId: null,
};

FavoritesButton.propTypes = {
  albumId: PropTypes.number,
  favoritesStore: PropTypes.shape({
    checkIsFavorite: PropTypes.func,
    toggleFavorites: PropTypes.func,
  }).isRequired,
  authStore: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
  }).isRequired,
};

export default inject('favoritesStore', 'authStore')(observer(FavoritesButton));
