import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { CSSTransition } from 'react-transition-group';
import * as Styled from '@containers/MainPlayer/style';
import PlayerControls from '@containers/MainPlayer/components/Controls';
import VolumeControl from '@containers/MainPlayer/components/VolumeControl';
import Text, { COLORS, VARIANTS } from '@components/Text';
import Preloader from '@components/Preloader';
import CurrentTrackTime from '@containers/MainPlayer/components/CurrentTrackTime';

import TrackDetails from '@containers/MainPlayer/components/TrackDetails';
import ButtonsFromList from '@components/ButtonsFromList';
import DownloadButton from '@components/IconButtons/DownloadButton';
import InteractiveWaveform from '@containers/MainPlayer/InteractiveWaveform';

import MoreInfoOpenIcon from '@assets/images/icons/mini-player-more-info-open.svg';
import ReducedPlayer from '@containers/MainPlayer/components/ReducedPlayer';
import useBreakpoint from '@hooks/useBreakpoint';

const MainPlayer = ({
  playerStore: {
    activeTrack,
    mainPlayerIsVisible,
    reducedPlayerIsVisible,
    trackTotalTime,
    trackDuration,
    isLoading,
    togglePlayer,
    seekTo,
  },
  authStore: { isAuthenticated },
  blockDownloadStore: { isDownloadBlocked, openBlockedDownloadModal },
}) => {
  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  return (
    <Styled.Wrapper>
      <CSSTransition
        in={mainPlayerIsVisible}
        timeout={300}
        classNames="slide_up"
      >
        <Styled.Container>
          <TrackDetails />

          <Styled.ControlsContainer>
            <PlayerControls />

            <Styled.WaveContainer>
              {isLoading && <Preloader center cover />}
              <CurrentTrackTime />
              <InteractiveWaveform length={trackDuration} seekTo={seekTo} />
              <Text color={COLORS.GREY} variant={VARIANTS.SMALL_BODY}>
                {trackTotalTime}
              </Text>
            </Styled.WaveContainer>

            <VolumeControl />

            <Styled.OptionsContainer>
              {activeTrack && (
                <>
                  <DownloadButton
                    {...{
                      id: activeTrack.id,
                      isAuthenticated,
                      isDownloadBlocked,
                      openBlockedDownloadModal,
                      isMp3: activeTrack.file?.url,
                      isWav: activeTrack.file_wav?.url,
                      external_id: activeTrack.external_id,
                      ...(activeTrack.isBriefing && {
                        wav: activeTrack.file?.url,
                      }),
                      ...(!activeTrack.external_id && {
                        wav: activeTrack.file_wav?.url,
                        mp3: activeTrack.file?.url,
                      }),
                    }}
                  />
                  {!activeTrack.medley && !activeTrack.isBriefing && (
                    <ButtonsFromList
                      clipboardData={activeTrack.directory_with_number}
                      albumId={activeTrack.album_id}
                      trackName={activeTrack.name}
                      trackId={activeTrack.id}
                    />
                  )}
                </>
              )}
              {isMobile && (
                <Styled.MobileToggle onClick={togglePlayer}>
                  <img src={MoreInfoOpenIcon} alt="" />
                </Styled.MobileToggle>
              )}
            </Styled.OptionsContainer>
          </Styled.ControlsContainer>
          {!isMobile && (
            <Styled.Toggle onClick={togglePlayer}>
              <img src={MoreInfoOpenIcon} alt="" />
            </Styled.Toggle>
          )}
        </Styled.Container>
      </CSSTransition>
      <CSSTransition
        in={reducedPlayerIsVisible}
        timeout={300}
        classNames="slide_up"
        appear
      >
        <ReducedPlayer />
      </CSSTransition>
    </Styled.Wrapper>
  );
};

MainPlayer.propTypes = {
  playerStore: PropTypes.shape({
    activeTrack: PropTypes.object,
    mainPlayerIsVisible: PropTypes.bool,
    reducedPlayerIsVisible: PropTypes.bool,
    trackCurrentTime: PropTypes.string,
    trackDuration: PropTypes.number,
    trackTotalTime: PropTypes.string,
    isLoading: PropTypes.bool,
    togglePlayer: PropTypes.func,
    isPlaying: PropTypes.bool,
    seekTo: PropTypes.func,
  }).isRequired,
  authStore: PropTypes.object.isRequired,
  blockDownloadStore: PropTypes.object.isRequired,
};
export default inject(
  'playerStore',
  'authStore',
  'blockDownloadStore',
)(observer(MainPlayer));
