import { makeAutoObservable } from 'mobx';
import { setItem, getItem, KEYS } from '@utils/storage';
import i18n, { isLanguage, LANG_KEYS } from '@utils/i18n';
import routes from '@routes';

import TopicsNavIcon from '@assets/images/icons/nav-topics.svg';
import CategoriesNavIcon from '@assets/images/icons/nav-categories.svg';
import LatestNavIcon from '@assets/images/icons/nav-latest.svg';
import PlaylistsNavIcon from '@assets/images/icons/nav-playlists.svg';
import InfoNavIcon from '@assets/images/icons/nav-info.svg';
import LicenseNavIcon from '@assets/images/icons/nav-license.svg';
import { searchStore } from '@stores';
import { KINDS } from '@containers/Agents';

const FORMATED_KINDS = KINDS.map(kind => ({
  ...kind,
  to: routes.agents(kind.value),
}));

export class SidebarStore {
  constructor() {
    makeAutoObservable(this);
  }

  isMobileOpen = false;

  isSideBarOpened = !JSON.parse(getItem(KEYS.SIDEBAR_COLLAPSED));

  infoListItems = [
    { id: 2, name: i18n.t('About us'), link: routes.aboutUs },
    { id: 4, name: i18n.t('Crew'), link: routes.crew },
    ...(!isLanguage(LANG_KEYS.CZ)
      ? [{ id: 8, name: i18n.t('Credits'), link: routes.credits }]
      : []),
    ...(!isLanguage(LANG_KEYS.CZ)
      ? [
          { id: 3, name: i18n.t('Tutorials'), link: routes.tutorials },
          { id: 6, name: i18n.t('Write for us'), link: routes.writeForUs },
          {
            id: 1,
            name: i18n.t('Agents'),
            nestedItems: FORMATED_KINDS,
          },
        ]
      : []),
    { id: 7, name: i18n.t('Contact us'), link: routes.contactUs },
  ];

  sidebarCategories = [
    {
      id: 1,
      icon: TopicsNavIcon,
      isOpen: false,
      type: 'list',
      label: i18n.t('Topics'),
      dataKey: 'topics',
      to: routes.topics,
    },
    {
      id: 2,
      icon: LatestNavIcon,
      isOpen: false,
      type: 'link',
      label: i18n.t('All albums'),
      link: routes.albums,
    },
    {
      id: 3,
      icon: CategoriesNavIcon,
      isOpen: false,
      type: 'list',
      label: i18n.t('Labels'),
      dataKey: 'labels',
      to: routes.labels,
    },
    {
      id: 4,
      icon: PlaylistsNavIcon,
      isOpen: false,
      type: 'list',
      label: i18n.t('Playlists'),
      dataKey: 'playlists',
      to: routes.playlists,
    },
    ...(!isLanguage(LANG_KEYS.EN)
      ? [
          {
            id: 5,
            icon: LicenseNavIcon,
            type: 'link',
            label: i18n.t('License-nav'),
            link: routes.license,
          },
        ]
      : []),
    {
      id: 6,
      icon: InfoNavIcon,
      isOpen: false,
      type: 'list',
      label: i18n.t('Info'),
      dataKey: 'info',
      to: routes.info,
    },
  ];

  toggleMobileNavigation = () => {
    if (searchStore.mobileSearchIsVisible) {
      searchStore.setMobileSearchVisibility(false);
    }
    this.isMobileOpen = !this.isMobileOpen;

    if (this.isMobileOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  };

  toggleSideBar = () => {
    const currentlyOpened = this.sidebarCategories.find(sc => sc.isOpen);
    const newSideBarOpened = !this.isSideBarOpened;

    if (currentlyOpened) {
      currentlyOpened.isOpen = false;
      setTimeout(() => {
        this.isSideBarOpened = newSideBarOpened;
      }, 700);
    } else {
      this.isSideBarOpened = newSideBarOpened;
    }

    setItem(KEYS.SIDEBAR_COLLAPSED, !newSideBarOpened);
  };

  openSideBar = () => {
    this.isSideBarOpened = true;
  };

  toggleSidebarCategory = id => {
    try {
      const currentlyOpened = this.sidebarCategories.find(sc => sc.isOpen);
      if (currentlyOpened) {
        if (currentlyOpened.id === id) {
          currentlyOpened.isOpen = false;

          return;
        }

        currentlyOpened.isOpen = false;
      }
      const selectedCategory = this.sidebarCategories.find(sc => sc.id === id);

      selectedCategory.isOpen = true;
    } catch (error) {
      console.warn(error); // eslint-disable-line no-console
    }
  };
}

export default new SidebarStore();
