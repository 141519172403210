import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const Row = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -8px;

  button {
    margin: 8px;
  }
`;

export const Separator = styled.div`
  text-align: center;
  font-weight: 800;
  font-size: 30px;
  margin: 30px 0 0;
  position: relative;

  &:before,
  &:after {
    position: absolute;
    top: 51%;
    right: 0;
    overflow: hidden;
    width: calc(50% - 36px);
    height: 1px;
    content: '';
    background-color: #858585;
  }
  &:before {
    left: 0;
    text-align: right;
  }

  span {
    display: inline-block;
  }
`;

export const Box = styled.div`
  margin: 10px 0 30px;

  @media ${breakpoint.md} {
    margin: -10px 0 20px;

    ${Row} {
      button {
        width: 100%;
        max-width: 320px;
      }
    }
  }
`;
