import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@components/TooltipSelect';
import Text, { VARIANTS as TEXT_VARIANTS } from '@components/Text';
import { Link } from 'react-router-dom';
import { StyledMenuItem } from '@components/AccountDropdownBody/style';

const DropdownItem = ({ as, to, divider, children, ...props }) => {
  return (
    <>
      <StyledMenuItem {...{ as, to, ...props }}>
        <Text variant={TEXT_VARIANTS.NAV}>{children}</Text>
      </StyledMenuItem>
      {divider && <Divider />}
    </>
  );
};

DropdownItem.defaultProps = {
  as: Link,
  to: '',
  divider: true,
};

DropdownItem.propTypes = {
  as: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.string]),
  to: PropTypes.string,
  divider: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default DropdownItem;
