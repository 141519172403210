import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import useBreakpoint from '@hooks/useBreakpoint';
import Table from '@components/Table';
import ExpandedTrack from '@components/TracksTable/components/ExpandedTrack';
import getDefaultColumns from '@components/TracksTable/getDefaultColumns';
import { PLAYER_CONTEXTS } from '@components/PlayerControlButtons/PlayPause';
import { HIGHLIGHTS } from '@components/Table/style';

const TracksTable = ({
  data,
  toggleTrack,
  toggleTrackAlternatives,
  expandedTrack,
  expandedAlternatives,
  isAuthenticated,
  getColumns,
  showAlbumCovers,
  playerContext,
  focusTracks,
  playerStore: { activeTrackId },
  renderActionsCell,
}) => {
  const onToggleAlternatives = (e, trackId, section) => {
    e.stopPropagation();
    toggleTrackAlternatives(trackId, section);
  };

  const onRowClick = (e, { row: { id } }) => {
    if (!(e.target.closest('button') || e.target.closest('a'))) {
      toggleTrack(id);
    }
  };

  const expandOnPlay = trackId => {
    if (trackId !== expandedTrack) {
      toggleTrack(trackId);
    }
  };

  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();

  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  const COLUMNS = getColumns({
    onToggleAlternatives,
    expandOnPlay,
    expandedTrack,
    expandedAlternatives,
    isAuthenticated,
    isMobile,
    showAlbumCovers,
    playerContext,
    renderActionsCell,
  });

  const highligh = ({ row: { id } }) => {
    if (id === activeTrackId) return HIGHLIGHTS.NORMAL;
    if (focusTracks.includes(id.toString())) return HIGHLIGHTS.LIGHT;
    return HIGHLIGHTS.NONE;
  };

  return (
    <Table
      columns={COLUMNS}
      data={data}
      renderExpandedRow={props => (
        <ExpandedTrack
          {...{ ...props, expandedAlternatives, isMobile, playerContext }}
        />
      )}
      shouldExpand={({ row: { id } }) => id === expandedTrack}
      highlight={highligh}
      shouldFocus={({ row: { id } }) =>
        focusTracks.length > 0 && id.toString() === focusTracks[0]
      }
      onRowClick={onRowClick}
      playerContext={playerContext}
    />
  );
};

TracksTable.defaultProps = {
  expandedTrack: null,
  data: [],
  expandedAlternatives: false,
  toggleTrackAlternatives: () => {},
  getColumns: getDefaultColumns,
  showAlbumCovers: true,
  focusTracks: [],
  renderActionsCell: undefined,
};

TracksTable.propTypes = {
  data: PropTypes.array,
  toggleTrack: PropTypes.func.isRequired,
  toggleTrackAlternatives: PropTypes.func,
  expandedTrack: PropTypes.number,
  expandedAlternatives: PropTypes.bool,
  isAuthenticated: PropTypes.bool.isRequired,
  getColumns: PropTypes.func,
  showAlbumCovers: PropTypes.bool,
  playerContext: PropTypes.oneOf(Object.values(PLAYER_CONTEXTS)).isRequired,
  playerStore: PropTypes.object.isRequired,
  focusTracks: PropTypes.array,
  renderActionsCell: PropTypes.object,
};

export default inject('playerStore')(observer(TracksTable));
