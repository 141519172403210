import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Preloader from '@components/Preloader';
import Paginator from '@components/Paginator';
import TracksFilter from '@components/ReleasedTracks/TracksFilter';
import DownloadTracksForm from '@components/ReleasedTracks/DownloadTracksForm';
import TracksTable from '@components/TracksTable';
import ItemsNotFound from '@components/ItemsNotFound';
import { PLAYER_CONTEXTS } from '@components/PlayerControlButtons/PlayPause';
import { StyledFlex } from '@components/ReleasedTracks/style';

const Tracks = ({
  releasedTracksStore: {
    getReleasedTracks,
    releasedTracks: {
      isLoading,
      data,
      pagesAmount,
      expandedTrack,
      expandedAlternatives,
    },
    toggleTrackAlternatives,
    toggleTrack,
  },
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    getReleasedTracks({ page: 1 });
  }, [getReleasedTracks]);

  const paginate = async page => {
    await getReleasedTracks({ page });
    window.scrollTo(0, 0);
  };

  return (
    <>
      <StyledFlex mb={4}>
        <TracksFilter onChange={getReleasedTracks} />
        <DownloadTracksForm />
      </StyledFlex>
      {isLoading && <Preloader />}
      {data.length > 0 && !isLoading ? (
        <TracksTable
          {...{
            data,
            toggleTrackAlternatives,
            toggleTrack,
            expandedTrack,
            expandedAlternatives,
            isAuthenticated: true,
            playerContext: PLAYER_CONTEXTS.RELEASED,
          }}
          showAlbumCovers
        />
      ) : (
        !isLoading && <ItemsNotFound name={t('released tracks')} />
      )}
      <Paginator length={pagesAmount} onPageChange={paginate} />
    </>
  );
};

Tracks.propTypes = {
  releasedTracksStore: PropTypes.shape({
    releasedTracks: PropTypes.shape({
      data: PropTypes.array,
      isLoading: PropTypes.bool,
      error: PropTypes.object,
      pagesAmount: PropTypes.number,
      expandedTrack: PropTypes.number,
      expandedAlternatives: PropTypes.bool,
    }),
    getReleasedTracks: PropTypes.func,
    toggleTrack: PropTypes.func,
    toggleTrackAlternatives: PropTypes.func,
  }).isRequired,
};

export default inject('releasedTracksStore')(observer(Tracks));
