import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import { uniqueId } from 'lodash-es';
import { Flex } from 'rebass';
import ExtraForYouItem from '@components/ExtraForYouItem';
import { MainContent, Header } from '@styles/globalStyles';
import Button, { VARIANTS } from '@components/Button';
import GridList from '@components/GridList';
import BannerSlider from '@components/BannersSlider';
import Preloader from '@components/Preloader';
import * as Styled from '@containers/ExtraForYou/style';

const ExtraForYou = ({
  extraForYouStore: { extraForYou, getExtraForYou },
  authStore: { isAuthenticated },
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    getExtraForYou(0, 4);
  }, [getExtraForYou]);

  return (
    <MainContent>
      <Header>
        {t('Extra 4 you')}
        <Styled.Description>
          {t('4 unique track results for you, based on you search')}
        </Styled.Description>
      </Header>
      {extraForYou.isLoading && <Preloader />}
      <GridList>
        {extraForYou.data.map(track => (
          <li key={uniqueId()}>
            <ExtraForYouItem
              name={track.name}
              description={track.description}
              image={track.album?.cover?.url}
              directoryWithNumber={track.directory_with_number}
              albumId={track.album_id.toString()}
              id={track.id}
              isAuthenticated={isAuthenticated}
              albumUUID={track.album?.uuid}
              track={track}
            />
          </li>
        ))}
      </GridList>
      <Flex margin="auto">
        <Button
          variant={VARIANTS.GREEN}
          label={t('Show 4 more!')}
          onClick={() => getExtraForYou(0, 4)}
        />
      </Flex>
      <BannerSlider />
    </MainContent>
  );
};

ExtraForYou.propTypes = {
  extraForYouStore: PropTypes.shape({
    extraForYou: PropTypes.object,
    getExtraForYou: PropTypes.func,
  }).isRequired,
  authStore: PropTypes.object.isRequired,
};

export default inject('extraForYouStore', 'authStore')(observer(ExtraForYou));
