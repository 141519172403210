export const trackLength = str => {
  try {
    const splitted = str.split(':');
    if (splitted[0].length === 1) {
      return `0${str}`;
    }

    return str;
  } catch (err) {
    console.warn(err); // eslint-disable-line
  }
  return str;
};

export const playerTime = seconds => {
  const sec = Math.floor(seconds);
  const h = Math.floor(sec / 3600);
  const m = Math.floor((sec - h * 3600) / 60);
  const s = sec - h * 3600 - m * 60;

  return `${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}`;
};

export const getSecondsFromTimeString = time => {
  try {
    const [m, s] = time.split(':');
    const min = parseInt(m, 10);
    const sec = parseInt(s, 10);

    return min * 60 + sec;
  } catch (error) {
    console.warn(error); // eslint-disable-line no-console
    return 0;
  }
};
