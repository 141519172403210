import React from 'react';
import { observer, inject } from 'mobx-react';
import i18n from '@utils/i18n';
import PropTypes from 'prop-types';

import routes from '@routes';
import { MainContent } from '@styles/globalStyles';
import Tabs from '@components/Tabs';

import Briefings from '@components/Briefings';
import ReleasedTracks from '@components/ReleasedTracks';
import UploadedTracks from '@components/UploadedTracks';
import Faqs from '@containers/Faqs';

import Header from '@components/Header';

export const TYPES = {
  briefings: i18n.t('Open briefings'),
  uploadedTracks: i18n.t('Uploaded tracks'),
  releasedTracks: i18n.t('Released tracks'),
  faqs: i18n.t('FAQ'),
};

const TABS = [
  { name: TYPES.briefings, value: routes.briefings },
  {
    name: TYPES.uploadedTracks,
    value: routes.uploadedTracks,
  },
  {
    name: TYPES.releasedTracks,
    value: routes.releasedTracks,
  },
  {
    name: TYPES.faqs,
    value: routes.faqs,
  },
];

const Component = type => {
  switch (type) {
    case TYPES.briefings:
      return <Briefings />;
    case TYPES.uploadedTracks:
      return <UploadedTracks />;
    case TYPES.releasedTracks:
      return <ReleasedTracks />;
    case TYPES.faqs:
      return <Faqs />;
    default:
      return null;
  }
};

const Composer = ({ routerStore: { history }, type }) => (
  <MainContent>
    <Header
      title={type}
      nav={
        <Tabs
          tabs={TABS}
          activeTab={TABS.find(({ name }) => name === type).value}
          setActiveTab={history.push}
        />
      }
    />
    {Component(type)}
  </MainContent>
);

Composer.propTypes = {
  routerStore: PropTypes.shape({
    history: PropTypes.object.isRequired,
  }).isRequired,
  type: PropTypes.oneOf(Object.values(TYPES)).isRequired,
};

export default inject('customerStore', 'routerStore')(observer(Composer));
