import styled from '@emotion/styled';
import Text from '@components/Text';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 0 12px 0 rgba(13, 13, 13, 0.15);
  border-radius: 4px;
  padding: 22px;
`;

export const Title = styled(Text)`
  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeVariant};
`;

export const Description = styled(Text)`
  margin: 20px 0;
  flex: 1;
`;

export const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  margin-bottom: 10px;

  > iframe,
  > object,
  > embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
