import { makeAutoObservable, runInAction } from 'mobx';

import { API, APIRoutes } from '@app/api';
import { history } from '@app/history';
import routes from '@routes';
// eslint-disable-next-line import/no-cycle
import AccountStep from '@containers/ComposerSignUp/components/AccountStep';
// eslint-disable-next-line import/no-cycle
import ComposerStep from '@containers/ComposerSignUp/components/ComposerStep';
// eslint-disable-next-line import/no-cycle
import PaymentsStep from '@containers/ComposerSignUp/components/PaymentsStep';

import i18n from '@utils/i18n';

export const BUSINESS_TYPES = [
  {
    value: 'broadcaster',
    label: i18n.t('Broadcaster'),
  },
  {
    value: 'production_company_broadcast',
    label: i18n.t('Production Company - Broadcaster'),
  },
  {
    value: 'production_company_film_vod',
    label: i18n.t('Production Company - Film & VoD'),
  },
  {
    value: 'production_company_trailer',
    label: i18n.t('Production Company - Trailer'),
  },
  { value: 'radio_station', label: i18n.t('Radio Station') },
  { value: 'freelancer', label: i18n.t('Freelancer') },
  { value: 'music_supervisor', label: i18n.t('Music Supervisor') },
  { value: 'advertising_agency', label: i18n.t('Advertising Agency') },
  { value: 'marketing_department', label: i18n.t('Marketing-Department') },
  { value: 'private_use', label: i18n.t('Private User') },
  { value: 'other', label: i18n.t('Other') },
];

export const MRO_ATTRIBUTES = [
  { value: 'None', label: 'None' },
  { value: 'ABRAMUS', label: 'ABRAMUS' },
  { value: 'ACUM', label: 'ACUM' },
  { value: 'AKM', label: 'AKM' },
  { value: 'AMCOS', label: 'AMCOS' },
  { value: 'ARTISJUS', label: 'ARTISJUS' },
  { value: 'ASCAP', label: 'ASCAP' },
  { value: 'AUME', label: 'AUME' },
  { value: 'AUTODIA', label: 'AUTODIA' },
  { value: 'BUMA', label: 'BUMA' },
  { value: 'COMPASS', label: 'COMPASS' },
  { value: 'EAU', label: 'EAU' },
  { value: 'GEMA', label: 'GEMA' },
  { value: 'IMRO', label: 'IMRO' },
  { value: 'IPRS', label: 'IPRS' },
  { value: 'JASRAC', label: 'JASRAC' },
  { value: 'KODA', label: 'KODA' },
  { value: 'KOMCA', label: 'KOMCA' },
  { value: 'LATGA-A', label: 'LATGA-A' },
  { value: 'MCPS', label: 'MCPS' },
  { value: 'MESAM', label: 'MESAM' },
  { value: 'MUSICAUTOR', label: 'MUSICAUTOR' },
  { value: 'NCB', label: 'NCB' },
  { value: 'OSA', label: 'OSA' },
  { value: 'RAO', label: 'RAO' },
  { value: 'SABAM', label: 'SABAM' },
  { value: 'SACEM', label: 'SACEM' },
  { value: 'SAMRO', label: 'SAMRO' },
  { value: 'SCD', label: 'SCD' },
  { value: 'SESAC', label: 'SESAC' },
  { value: 'SGAE', label: 'SGAE' },
  { value: 'SIAE', label: 'SIAE' },
  { value: 'SOCAN', label: 'SOCAN' },
  { value: 'SODRAC', label: 'SODRAC' },
  { value: 'SOZA', label: 'SOZA' },
  { value: 'SPA', label: 'SPA' },
  { value: 'STEMRA', label: 'STEMRA' },
  { value: 'STIM', label: 'STIM' },
  { value: 'SUISA', label: 'SUISA' },
  { value: 'TEOSTO', label: 'TEOSTO' },
  { value: 'TONO', label: 'TONO' },
  { value: 'UBC', label: 'UBC' },
  { value: 'UCMR-ADA', label: 'UCMR-ADA' },
  { value: 'UMCRA', label: 'UMCRA' },
  { value: 'ZAIKS', label: 'ZAIKS' },
  { value: 'HDS-ZAMP', label: 'HDS-ZAMP' },
];

export const PRO_ATTRIBUTES = [
  { value: 'ABRAMUS', label: 'ABRAMUS' },
  { value: 'ACUM', label: 'ACUM' },
  { value: 'AKM', label: 'AKM' },
  { value: 'APRA', label: 'APRA' },
  { value: 'ARTISJUS', label: 'ARTISJUS' },
  { value: 'ASCAP', label: 'ASCAP' },
  { value: 'AUTODIA', label: 'AUTODIA' },
  { value: 'BMI', label: 'BMI' },
  { value: 'BUMA', label: 'BUMA' },
  { value: 'COMPASS', label: 'COMPASS' },
  { value: 'EAU', label: 'EAU' },
  { value: 'GEMA', label: 'GEMA' },
  { value: 'IMRO', label: 'IMRO' },
  { value: 'IPRS', label: 'IPRS' },
  { value: 'JASRAC', label: 'JASRAC' },
  { value: 'KODA', label: 'KODA' },
  { value: 'KOMCA', label: 'KOMCA' },
  { value: 'LATGA-A', label: 'LATGA-A' },
  { value: 'MESAM', label: 'MESAM' },
  { value: 'MUSICAUTOR', label: 'MUSICAUTOR' },
  { value: 'NCB', label: 'NCB' },
  { value: 'OSA', label: 'OSA' },
  { value: 'PRS', label: 'PRS' },
  { value: 'RAO', label: 'RAO' },
  { value: 'SABAM', label: 'SABAM' },
  { value: 'SACEM', label: 'SACEM' },
  { value: 'SAMRO', label: 'SAMRO' },
  { value: 'SCD', label: 'SCD' },
  { value: 'SESAC', label: 'SESAC' },
  { value: 'SGAE', label: 'SGAE' },
  { value: 'SIAE', label: 'SIAE' },
  { value: 'SOCAN', label: 'SOCAN' },
  { value: 'SODRAC', label: 'SODRAC' },
  { value: 'SOZA', label: 'SOZA' },
  { value: 'SPA', label: 'SPA' },
  { value: 'STEMRA', label: 'STEMRA' },
  { value: 'STIM', label: 'STIM' },
  { value: 'SUISA', label: 'SUISA' },
  { value: 'TEOSTO', label: 'TEOSTO' },
  { value: 'TONO', label: 'TONO' },
  { value: 'UBC', label: 'UBC' },
  { value: 'UCMR-ADA', label: 'UCMR-ADA' },
  { value: 'UMCRA', label: 'UMCRA' },
  { value: 'ZAIKS', label: 'ZAIKS' },
  { value: 'HDS-ZAMP', label: 'HDS-ZAMP' },
];

export const SIGN_IN_STEPS = [
  {
    name: i18n.t('Account'),
    Component: AccountStep,
    fields: [
      'first_name',
      'last_name',
      'password',
      'password_confirmation',
      'email',
      'website',
      'country',
      'street',
      'city',
      'zip_code',
    ],
  },
  {
    name: i18n.t('Composer'),
    Component: ComposerStep,
    fields: ['first_name', 'last_name', 'ipi_no', 'pro', 'mro'],
  },
  {
    name: i18n.t('Payments'),
    Component: PaymentsStep,
    fields: [
      'account_owner',
      'bank_name',
      'bank_blz',
      'bank_account_no',
      'bank_iban',
      'bank_swift',
      'terms_and_conditions_accepted',
    ],
  },
];

export class AuthStore {
  customerStatus = {
    isLoading: false,
    errors: {},
  };

  composerStatus = {
    isLoading: false,
    errors: {},
    currentStep: 0,
  };

  countries = {
    isLoading: false,
    data: [],
    error: null,
  };

  userCountry = null;

  constructor() {
    makeAutoObservable(this);
  }

  onSignUp = async formData => {
    this.customerStatus.isLoading = true;
    try {
      await API.post(APIRoutes.SIGN_UP, {
        ...formData,
        save: true,
        confirm_success_url: `${window.location.origin}${routes.auth.signIn}`,
        country: formData.country?.value,
        business_type: formData.business_type?.value,
        mro: formData.mro?.value,
        pro: formData.pro?.value,
        terms_and_conditions_accepted: true,
      });

      const redirectUrl = routes.auth.confirmation;
      history.push(redirectUrl);
    } catch (e) {
      const signUpErrors = { ...e.errors };
      Object.keys(signUpErrors).forEach(key => {
        signUpErrors[key] = { message: signUpErrors[key] };
      });

      runInAction(() => {
        this.customerStatus.errors = signUpErrors;
      });
    } finally {
      runInAction(() => {
        this.customerStatus.isLoading = false;
      });
    }

    return null;
  };

  onComposerSignUp = async formData => {
    this.composerStatus.isLoading = true;

    try {
      const isLastStep =
        this.composerStatus.currentStep === SIGN_IN_STEPS.length - 1;

      await API.post(APIRoutes.SIGN_UP, {
        ...formData,
        save: isLastStep,
        confirm_success_url: `${window.location.origin}${routes.auth.signIn}`,
        is_composer: true,
        country: formData.country?.value,
        country_of_residence: formData.country_of_residence?.value,
        bank_country: formData.bank_country?.value,
        mro: formData.mro?.value,
        pro: formData.pro?.value,
        terms_and_conditions_accepted: true,
      });

      runInAction(() => {
        this.resetComposerStatus();
      });

      const redirectUrl = routes.auth.confirmation;
      history.push(redirectUrl);
    } catch (e) {
      const currentFields =
        SIGN_IN_STEPS[this.composerStatus.currentStep].fields;

      const responseErrors = e.errors;
      const signUpErrors = {};

      currentFields.forEach(key => {
        if (responseErrors[key]) {
          signUpErrors[key] = { message: responseErrors[key] };
        }
      });

      if (this.composerStatus.currentStep < SIGN_IN_STEPS.length - 1) {
        const isError = Object.keys(signUpErrors).some(ai =>
          currentFields.includes(ai),
        );
        if (!isError) {
          runInAction(() => {
            this.setNextStep();
          });
        }
      }

      runInAction(() => {
        this.composerStatus.errors = signUpErrors;
      });
    } finally {
      runInAction(() => {
        this.composerStatus.isLoading = false;
      });
    }

    return null;
  };

  setNextStep = () => {
    const yOffset = -162; // navbar height
    const element = document.getElementById('composer-sign-up-form');
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y });

    this.composerStatus.currentStep += 1;
  };

  setPreviousStep = () => {
    this.composerStatus.currentStep -= 1;
  };

  resetComposerStatus = () => {
    this.composerStatus.currentStep = 0;
    this.composerStatus.errors = {};
  };

  getCountries = async () => {
    if (this.countries.data.length === 0) {
      try {
        runInAction(() => {
          this.countries.isLoading = true;
        });
        const { data, headers } = await API(APIRoutes.COUNTRIES);

        const userCountry = headers && headers['user-country'];

        const countries = data.map(item => {
          if (item[0] === userCountry) {
            this.userCountry = {
              value: item[0],
              label: item[1],
            };
          }

          return {
            value: item[0],
            label: item[1],
          };
        });
        runInAction(() => {
          this.countries.data = countries;
        });
      } catch (error) {
        runInAction(() => {
          this.countries.error = error;
        });
      } finally {
        runInAction(() => {
          this.countries.isLoading = false;
        });
      }
    }
  };
}

export default new AuthStore();
