import { makeAutoObservable, runInAction } from 'mobx';
import { API, APIRoutes } from '@app/api';

export const ALBUMS_TYPES = {
  LAST_RELEASES: 'LAST_RELEASES',
  ALBUMS: 'ALBUMS',
  TOPICS: 'TOPICS',
  LABELS: 'LABELS',
};

export class AlbumsStore {
  constructor() {
    makeAutoObservable(this);
  }

  albums = {
    isLoading: false,
    data: [],
    error: null,
    per: 48,
    last: false,
    page: 1,
    type: '',
    topic: '',
    label: '',
  };

  lastReleasesAlbums = {
    isLoading: false,
    data: [],
    error: null,
  };

  albumDetails = {
    isLoading: false,
    data: {},
    error: null,
  };

  albumTracks = {
    expandedTrack: null,
    expandedAlternatives: false,
    isLoading: false,
    data: [],
    tracks: [],
    error: null,
  };

  getAlbumsOfEndpoint = async (endpoint, type = 'albums') => {
    try {
      this.albums.error = null;
      this.albums.isLoading = true;

      const {
        data: { albums, meta },
      } = await API(endpoint);
      runInAction(() => {
        this.albums.last = meta?.last;
        this.albums.page = meta?.self;
        this.albums.data = [...this.albums.data, ...albums];
      });
    } catch (error) {
      runInAction(() => {
        this.albums.error = error;
      });
    } finally {
      runInAction(() => {
        this.albums.isLoading = false;
        this.albums.type = type;
      });
    }
  };

  getLastReleases = async () => {
    try {
      this.lastReleasesAlbums.error = null;
      this.lastReleasesAlbums.isLoading = true;

      const {
        data: { albums },
      } = await API(APIRoutes.LAST_RELEASES);
      runInAction(() => {
        this.lastReleasesAlbums.data = albums;
      });
    } catch (error) {
      runInAction(() => {
        this.lastReleasesAlbums.error = error;
      });
    } finally {
      runInAction(() => {
        this.lastReleasesAlbums.isLoading = false;
      });
    }
  };

  _getAlbumsMethods = {
    ALBUMS: (_, per = this.albums.per, page = this.albums.page) => {
      this.albums.topic = '';
      this.albums.label = '';

      this.getAlbumsOfEndpoint(
        APIRoutes.ALBUMS(page, per),
        ALBUMS_TYPES.ALBUMS,
      );
    },
    TOPICS: ({ topic }, per = this.albums.per, page = this.albums.page) => {
      this.albums.topic = topic;
      this.albums.label = '';

      this.getAlbumsOfEndpoint(
        APIRoutes.ALBUMS_OF_TOPIC(page, per, topic),
        ALBUMS_TYPES.TOPICS,
      );
    },
    LABELS: ({ label }, per = this.albums.per, page = this.albums.page) => {
      this.albums.label = label;
      this.albums.topic = '';

      this.getAlbumsOfEndpoint(
        APIRoutes.ALBUMS_OF_LABEL(page, per, label),
        ALBUMS_TYPES.LABELS,
      );
    },
    LAST_RELEASES: () => this.getLastReleases(),
  };

  getAlbumsOf = () => {
    this.albums.data = [];
    this.albums.page = 1;
    return this._getAlbumsMethods;
  };

  updateAlbumsOf = () => {
    this.albums.page += 1;
    return this._getAlbumsMethods;
  };

  getAlbumDetails = async albumId => {
    try {
      this.albumDetails.error = null;
      this.albumDetails.isLoading = true;

      const {
        data: { album },
      } = await API(APIRoutes.ALBUM_DETAILS(albumId));

      runInAction(() => {
        this.albumDetails.data = album;
      });
    } catch (error) {
      runInAction(() => {
        this.albumDetails.error = error;
      });
    } finally {
      runInAction(() => {
        this.albumDetails.isLoading = false;
      });
    }
  };

  getAlbumTracks = async albumId => {
    try {
      this.albumTracks.error = null;
      this.albumTracks.isLoading = true;

      const {
        data: { tracks },
      } = await API(APIRoutes.ALBUM_TRACKS(albumId));

      runInAction(() => {
        this.albumTracks.data = this.groupTracksBySections(tracks);
        this.albumTracks.tracks = tracks;
      });
    } catch (error) {
      runInAction(() => {
        this.albumTracks.error = error;
      });
    } finally {
      runInAction(() => {
        this.albumTracks.isLoading = false;
      });
    }
  };

  toggleTrack = trackId => {
    const { expandedTrack } = this.albumTracks;
    this.albumTracks.expandedTrack = expandedTrack === trackId ? null : trackId;
    this.albumTracks.expandedAlternatives = false;
  };

  toggleTrackAlternatives = (trackId, section) => {
    const { expandedTrack, expandedAlternatives } = this.albumTracks;

    if (expandedTrack === trackId) {
      if (expandedAlternatives) {
        this.albumTracks.expandedAlternatives = false;
      } else {
        this.albumTracks.expandedAlternatives = true;
        this.getTrackAlternatives(trackId, section);
      }
    } else {
      this.albumTracks.expandedTrack = trackId;
      this.albumTracks.expandedAlternatives = true;
      this.getTrackAlternatives(trackId, section);
    }
  };

  getTrackAlternatives = async (trackId, trackSection) => {
    const section = this.albumTracks.data.find(
      ({ title }) => title === trackSection,
    );

    const track = section.tracks.find(({ id }) => id === trackId);

    if (!track.alternatives) {
      try {
        track.alternatives = { isLoading: true };

        const {
          data: { tracks },
        } = await API(APIRoutes.TRACK_ALTERNATIVES(trackId));

        const tracksWithAlbum = tracks.map(t => ({ ...t, album: track.album }));

        runInAction(() => {
          track.alternatives.data = tracksWithAlbum;
        });
      } catch (error) {
        runInAction(() => {
          track.alternatives.error = error;
        });
      } finally {
        runInAction(() => {
          track.alternatives.isLoading = false;
        });
      }
    }
  };

  groupTracksBySections = tracks => {
    const sectionsTitles = [...new Set(tracks.map(({ section }) => section))];
    const sections = sectionsTitles.map(title => ({
      title,
      tracks: tracks.filter(({ section }) => section === title),
    }));

    return sections;
  };
}

export default new AlbumsStore();
