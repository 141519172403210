import { syncHistoryWithStore } from 'mobx-react-router';
import { createBrowserHistory } from 'history';
import { routerStore } from '@stores';
import googleHistoryListener from '@utils/googleAnalytics';

const browserHistory = createBrowserHistory();

const history = syncHistoryWithStore(browserHistory, routerStore);
history.listen(googleHistoryListener);

export { history };
