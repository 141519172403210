import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import Button, { VARIANTS } from '@components/Button';
import SearchBar from '@components/SearchBar';
import * as Styled from '@components/NavBar/style';
import routes from '@routes';
import Dropdown from '@components/Dropdown';
import useBreakpoint from '@hooks/useBreakpoint';
import MobileSearch from '@components/MobileSearch';
import AccountDropdownBody from '@components/AccountDropdownBody';
import ProfileIcon from '@components/ProfileIcon';

const NavBar = ({
  customerStore: {
    customer: { data: customerData },
  },
  authStore: { logout, isAuthenticated, isValidated },
}) => {
  const { t } = useTranslation();
  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();
  const navbar = useRef();

  if (matchBreakpoint(BREAKPOINTS.MD)) {
    return <MobileSearch />;
  }

  const isComposer = customerData?.is_composer;
  const isProducer = customerData?.is_producer;

  return (
    <Styled.Wrapper ref={navbar}>
      <Styled.Navbar>
        <SearchBar />
        <Styled.RightSection>
          {(isValidated || isAuthenticated) &&
            (isAuthenticated ? (
              <Dropdown
                label={customerData?.first_name || t('Account')}
                icon={<ProfileIcon />}
                maxTextWidth={120}
                minTextWidth={50}
              >
                <AccountDropdownBody
                  {...{ isComposer, isProducer, logout, isAuthenticated }}
                />
              </Dropdown>
            ) : (
              <>
                <Button
                  variant={VARIANTS.SECONDARY}
                  label={t('Sign up')}
                  to={routes.auth.signUp}
                />
                <Button label={t('Login')} to={routes.auth.signIn} />
              </>
            ))}
        </Styled.RightSection>
      </Styled.Navbar>
    </Styled.Wrapper>
  );
};

NavBar.propTypes = {
  customerStore: PropTypes.object.isRequired,
  authStore: PropTypes.object.isRequired,
};

export default inject('customerStore', 'authStore')(observer(NavBar));
