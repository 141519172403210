import React from 'react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';
import { Global, css } from '@emotion/react';
import {
  DetailsContainer,
  DetailsContainerWrapper,
} from '@containers/MainPlayer/style';
import Button from '@components/Button';
import GlobalText from '@components/Text';
import FavButton from '@components/IconButtons/FavoritesButton';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled(DetailsContainerWrapper)`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;

  @media ${breakpoint.md} {
    bottom: 80px;
    filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.15));
  }
`;

export const AlbumInfo = styled(DetailsContainer)`
  width: 473px;
  min-height: 240px;
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 35px, calc(100% - 35px) 0);
  padding: 15px 18px 20px 20px;

  @media ${breakpoint.md} {
    width: 100%;
  }
`;

export const AlbumInfoInner = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 2fr;
`;

export const Image = styled.img`
  width: 153px;
  border-radius: 4px;
  margin-top: 10px;
`;

export const ButtonsWrapper = styled(Flex)`
  grid-column: 1/3;
  margin-top: 20px;
  @media ${breakpoint.md} {
    flex-wrap: wrap;
  }
`;

export const LeftButton = styled(Button)`
  margin-right: 10px;
`;

export const Text = styled(GlobalText)`
  margin: 10px 20px;
`;

export const Title = styled(Text)`
  font-size: 15px;
  color: ${({ theme }) => theme.colors.primary};
`;

export const FavoritesButton = styled(FavButton)`
  margin-left: auto;
`;

export const AlbumInfoAnimations = () => (
  <Global
    styles={css`
      .expanded-album-enter {
        transform: scale(0.6, 0.35);
        transform-origin: bottom left;
      }
      .expanded-album-enter-active {
        transform: scale(1, 1);
        transition: transform 0.3s;
        transform-origin: bottom left;
      }
      .expanded-album-exit {
        transform: scale(1, 1);
        transform-origin: bottom left;
      }
      .expanded-album-exit-active {
        transform: scale(0.6, 0.35);
        transition: transform 0.3s;
        transform-origin: bottom left;
      }

      @media ${breakpoint.md} {
        .expanded-album-enter {
          transform: scale(1, 0.35);
          transform-origin: bottom center;
        }
        .expanded-album-enter-active {
          transform: scale(1, 1);
          transition: transform 0.3s;
          transform-origin: bottom center;
        }
        .expanded-album-exit {
          transform: scale(1, 1);
          transform-origin: bottom center;
        }
        .expanded-album-exit-active {
          transform: scale(1, 0.35);
          transition: transform 0.3s;
          transform-origin: bottom center;
        }
      }
    `}
  />
);
