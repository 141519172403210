import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Header from '@components/Header';
import { MainContent } from '@styles/globalStyles';
import Tutorial from '@components/Tutorial';
import Preloader from '@components/Preloader';
import { VARIANTS } from '@components/Text';
import * as Styled from '@containers/Tutorials/style';

const Tutorials = ({ tutorialsStore: { tutorials, getTutorials } }) => {
  const { t } = useTranslation();
  useEffect(() => {
    getTutorials();
  }, [getTutorials]);

  return (
    <MainContent>
      <Header title={t('Tutorials')} withSeparator />
      <Styled.Description variant={VARIANTS.H4}>
        {t('Let us help you improve your working with this helpful tutorials')}
      </Styled.Description>
      {tutorials.isLoading && <Preloader />}
      <Styled.GridList>
        {tutorials?.data?.length > 0 &&
          tutorials.data.map(({ title, description, youtube_url }) => (
            <li key={uniqueId()}>
              <Tutorial
                title={title}
                description={description}
                url={youtube_url}
              />
            </li>
          ))}
      </Styled.GridList>
    </MainContent>
  );
};

Tutorials.propTypes = {
  tutorialsStore: PropTypes.shape({
    tutorials: PropTypes.object.isRequired,
    getTutorials: PropTypes.func.isRequired,
  }).isRequired,
};

export default inject('tutorialsStore')(observer(Tutorials));
