import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled.div`
  margin: 100px auto 0;
  max-width: 592px;

  @media ${breakpoint.md} {
    margin: 50px -16px 0;
    max-width: unset;
  }
`;

export const Description = styled.div`
  margin: 10px auto 40px;
  width: 50%;

  @media ${breakpoint.md} {
    width: 80%;
  }
`;
