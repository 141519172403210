import { makeAutoObservable, runInAction } from 'mobx';
import { API, APIRoutes } from '@app/api';

export class FaqsStore {
  constructor() {
    makeAutoObservable(this);
  }

  faqs = {
    isLoading: false,
    data: [],
    error: null,
  };

  getFaqs = async () => {
    if (this.faqs.data.length === 0) {
      try {
        this.faqs.isLoading = true;

        const {
          data: { faqs },
        } = await API(APIRoutes.FAQS);
        runInAction(() => {
          this.faqs.data = faqs;
        });
      } catch (error) {
        runInAction(() => {
          this.faqs.error = error;
        });
      } finally {
        runInAction(() => {
          this.faqs.isLoading = false;
        });
      }
    }
  };
}

export default new FaqsStore();
