import styled from '@emotion/styled';

export const Status = styled.div`
  text-align: center;
  width: 104px;
  height: 24px;
  font-weight: 500;
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.white};
  text-transform: capitalize;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StatusAccepted = styled(Status)`
  background: ${({ theme }) => theme.colors.success};
`;

export const StatusPending = styled(Status)`
  background: ${({ theme }) => theme.colors.yellow};
`;

export const FloatRight = styled.div`
  float: right;
`;
