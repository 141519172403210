import React from 'react';
import PropTypes from 'prop-types';
import { StyleButton } from '@components/Form/FormButton/style';
import Preloader from '@components/Preloader';

const FormButton = ({ isLoading, label, ...props }) => {
  return (
    <StyleButton
      disabled={isLoading}
      label={isLoading ? <Preloader center light /> : label}
      {...props}
    />
  );
};

FormButton.defaultProps = {
  isLoading: false,
};

FormButton.propTypes = {
  isLoading: PropTypes.bool,
  label: PropTypes.node.isRequired,
};

export default FormButton;
