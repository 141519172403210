import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash-es';
import {
  Checkmark,
  InputWrapper,
  StyledInput,
} from '@components/Form/Checkbox/style';
import { Asterix, Error, FieldWrapper } from '@components/Form/style';
import i18n from '@utils/i18n';
import { MARGINS } from '@styles/globalStyles';

const Checkbox = ({
  name,
  label,
  register,
  required,
  style,
  errors,
  mb,
  value,
  ofGroup,
  disabled,
  onChange,
}) => {
  const fieldId = useMemo(() => uniqueId(`input_${name}_`), []);

  return (
    <FieldWrapper style={style} mb={mb}>
      <InputWrapper withError={errors[name] ? 1 : 0}>
        <StyledInput htmlFor={fieldId} ofGroup={ofGroup} isDisabled={disabled}>
          {label} {required && !ofGroup && <Asterix>*</Asterix>}
          <input
            name={name}
            type="checkbox"
            ref={register({
              required: {
                value: required,
                message: i18n.t('Required'),
              },
            })}
            id={fieldId}
            value={value}
            disabled={disabled}
            onChange={onChange}
          />
          <Checkmark />
        </StyledInput>
      </InputWrapper>

      {errors[name] ? <Error>{errors[name].message}</Error> : null}
    </FieldWrapper>
  );
};

Checkbox.defaultProps = {
  required: false,
  style: {},
  errors: {},
  mb: MARGINS.SMALL,
  value: undefined,
  ofGroup: false,
  disabled: false,
  onChange: undefined,
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  register: PropTypes.func.isRequired,
  required: PropTypes.bool,
  style: PropTypes.object,
  errors: PropTypes.object,
  mb: PropTypes.oneOf(Object.values(MARGINS)),
  value: PropTypes.string,
  ofGroup: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Checkbox;
