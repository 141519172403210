import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { NavLink } from 'react-router-dom';
import { breakpoint } from '@styles/breakpoints';

export const LogoWrapper = styled(NavLink)`
  display: flex;
  justify-content: center;
  padding: 50px 0 30px;
  height: ${({ theme }) => theme.sidebar.logo.wrapperHeight}px;

  img {
    display: block;
    max-width: 100%;
    max-height: 50px;
    padding: 0 8px;
  }

  @media ${breakpoint.md} {
    padding: 0;
    height: 53px;
    width: 112px;
    align-items: center;
    margin: 0 auto;
  }
`;

export const Wrapper = styled.div`
  ${({ collapsed, theme }) =>
    collapsed
      ? css`
          width: ${theme.sidebar.width.desktop.collapsed}px;

          ${LogoWrapper} {
            padding: 0 0 30px;
            align-items: flex-end;
          }
        `
      : css`
          width: ${theme.sidebar.width.desktop.default}px;
        `}

  position: relative;
  transition: width ${({ theme }) => theme.sidebar.transitionTime}s ease;
  flex-grow: 0;
  flex-shrink: 0;
  z-index: 6;
  min-height: ${({ theme }) => `calc(100vh - ${theme.footer.height}px)`};

  @media ${breakpoint.md} {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99;
    background-color: white;
    height: 53px;
    min-height: unset;
  }
`;

export const Sidebar = styled.div`
  position: sticky;
  top: 0;
  max-height: 100vh;

  @media ${breakpoint.md} {
    position: relative;
    z-index: 2;
    transition: height 0.5s ease-in-out;
    height: ${({ isMobileOpen }) => (isMobileOpen ? '100vh' : '53px')};
    background-color: #fff;
    overflow: hidden;
  }
`;

export const ToggleButton = styled.button`
  position: absolute;
  top: 24px;
  transition: left ${({ theme }) => theme.sidebar.transitionTime}s ease,
    transform ${({ theme }) => theme.sidebar.transitionTime}s ease;
  z-index: 1;

  ${({ collapsed, theme }) =>
    collapsed
      ? css`
          left: ${theme.sidebar.width.desktop.collapsed - 12}px;
          transform: rotate(180deg);
        `
      : css`
          left: ${theme.sidebar.width.desktop.default - 12}px;
          transform: rotate(0);
        `}

  @media ${breakpoint.md} {
    display: none;
  }
`;

export const MobileButton = styled.button`
  display: none;

  @media ${breakpoint.md} {
    padding: 10px;
    background-color: ${({ active, theme }) =>
      active ? theme.colors.highlight : 'inherit'};
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      width: 32px;
      height: 32px;
    }
  }
`;

export const SearchButton = styled.button`
  display: none;

  @media ${breakpoint.md} {
    display: block;
    width: 53px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    height: 100%;
    padding: 9px 9px 0 0;

    img {
      display: block;
      width: 32px;
      height: 32px;
    }
  }
`;

export const MobileContainer = styled.div`
  max-height: calc(100vh - 53px);
`;

export const DesktopScrollContainer = styled.div`
  overflow-y: auto;
  max-height: calc(
    100vh - ${({ theme }) => theme.sidebar.logo.wrapperHeight}px
  );

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.scrollbar.sidebar.track};
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: ${({ theme }) => theme.scrollbar.sidebar.track};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.scrollbar.sidebar.scrollbar};
  }

  scrollbar-color: ${({ theme }) => theme.scrollbar.sidebar.scrollbar}
    ${({ theme }) => theme.scrollbar.sidebar.track};
  scrollbar-width: thin;
`;

export const MobileProfileButton = styled(MobileButton)`
  svg {
    display: block;
    width: 30px;
    height: 30px;
    padding: 4px;

    * {
      stroke: #095f73;
      stroke-width: 1.5px;

      ${({ isAuthenticated }) =>
        isAuthenticated
          ? `
      fill: #095f73;
      `
          : `
        fill: none;

      `}
    }
  }
`;
