/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import ahoy from 'ahoy.js';
import { useTranslation } from 'react-i18next';

import Text, { VARIANTS } from '@components/Text';
import * as Styled from '@components/TooltipSelect';
import DOWNLOAD_ICON from '@assets/images/icons/download-icon.svg';

const DownloadButtonTooltipBody = ({
  mp3,
  wav,
  id,
  cuesheet,
  buttonStyle,
  isAuthenticated,
  isDownloadBlocked,
  openBlockedDownloadModal,
}) => {
  const { t } = useTranslation();

  const track = type => ahoy.track(`download_track_${type}`, { id });

  const downloadButtonProps = isAuthenticated
    ? isDownloadBlocked
      ? {
          as: 'button',
          type: 'button',
          onClick: openBlockedDownloadModal,
        }
      : {
          as: 'a',
        }
    : {
        as: 'button',
        type: 'button',
        disabled: true,
        title: t('Sign in to download'),
      };

  return (
    <>
      {mp3 && (
        <Styled.MenuItem
          justifyContent="space-between"
          padding="12px"
          href={mp3}
          onClick={() => track('mp3')}
          {...buttonStyle}
          {...downloadButtonProps}
        >
          <Text variant={VARIANTS.NAV}>MP3</Text>
          <Styled.Icon src={DOWNLOAD_ICON} alt="download" />
        </Styled.MenuItem>
      )}
      {wav && (
        <>
          <Styled.Divider />
          <Styled.MenuItem
            justifyContent="space-between"
            padding="12px"
            href={wav}
            onClick={() => track('wav')}
            {...buttonStyle}
            {...downloadButtonProps}
          >
            <Text variant={VARIANTS.NAV}>WAV</Text>
            <Styled.Icon src={DOWNLOAD_ICON} alt="download" />
          </Styled.MenuItem>
        </>
      )}
      {cuesheet && (
        <>
          <Styled.Divider />
          <Styled.MenuItem
            justifyContent="space-between"
            padding="12px"
            href={cuesheet}
            onClick={() => ahoy.track('download_track_cuesheet', { id })}
            {...buttonStyle}
            {...downloadButtonProps}
          >
            <Text variant={VARIANTS.NAV}>CUESHEET</Text>
            <Styled.Icon src={DOWNLOAD_ICON} alt="download" />
          </Styled.MenuItem>
        </>
      )}
    </>
  );
};

DownloadButtonTooltipBody.propTypes = {
  mp3: PropTypes.string,
  wav: PropTypes.string,
  cuesheet: PropTypes.string,
  id: PropTypes.number.isRequired,
  buttonStyle: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  isDownloadBlocked: PropTypes.bool,
  openBlockedDownloadModal: PropTypes.func,
};

DownloadButtonTooltipBody.defaultProps = {
  mp3: null,
  wav: null,
  cuesheet: null,
  buttonStyle: null,
  isAuthenticated: true,
  isDownloadBlocked: false,
  openBlockedDownloadModal: () => {},
};

export default DownloadButtonTooltipBody;
