import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

const ModalWrapper = ({ children, isVisible, portalId }) => {
  const [portal, setPortal] = useState(null);

  useEffect(() => {
    let portalEl = portalId
      ? document.getElementById(portalId)
      : document.querySelector('.portal-modal-wrapper');

    if (!portalEl) {
      portalEl = document.createElement('div');

      if (portalId) {
        portalEl.id = portalId;
      }
      portalEl.classList.add('portal-modal-wrapper');

      document.body.appendChild(portalEl);
    }

    if (isVisible) {
      portalEl.classList.add('modal-visible');
    } else {
      portalEl.classList.remove('modal-visible');
    }

    setPortal(portalEl);
  }, [portalId, isVisible]);

  if (portal) {
    return createPortal(children, portal);
  }

  return null;
};

ModalWrapper.defaultProps = {
  portalId: null,
};

ModalWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  portalId: PropTypes.string,
};

export default ModalWrapper;
