import React from 'react';
import PropTypes from 'prop-types';
import { StyledButton } from '@components/IconButtons/style';
import DownloadIcon from '@assets/images/icons/download-icon.svg';
import Tooltip, { TRIGGER } from '@components/Tooltip';
import DownloadButtonTooltipBody from '@components/IconButtons/DownloadButton/DownloadButtonTooltipBody';
import DisabledDownloadTooltipBody from '@components/IconButtons/DisabledDownloadTooltipBody';
import { APIRoutes } from '@app/api';

const DownloadButton = ({
  isMp3,
  isWav,
  external_id,
  mp3,
  wav,
  isAuthenticated,
  isDownloadBlocked,
  openBlockedDownloadModal,
  id,
  cuesheet,
}) => {
  let mp3Url =
    isMp3 && external_id ? APIRoutes.TRACK_DOWNLOAD(external_id, 'mp3') : null;
  if (mp3) mp3Url = mp3;

  let wavUrl =
    isWav && external_id ? APIRoutes.TRACK_DOWNLOAD(external_id, 'wav') : null;
  if (wav) wavUrl = wav;

  return (
    <Tooltip
      tooltipBody={
        isAuthenticated ? (
          <DownloadButtonTooltipBody
            {...{ mp3: mp3Url, wav: wavUrl, id, cuesheet }}
          />
        ) : (
          <DisabledDownloadTooltipBody />
        )
      }
      trigger={isAuthenticated ? TRIGGER.CLICK : TRIGGER.HOVER}
      isActive={
        (!isAuthenticated || !!mp3Url || !!wavUrl) && !isDownloadBlocked
      }
    >
      <span>
        <StyledButton
          disabled={!isAuthenticated || (!mp3Url && !wavUrl)}
          {...(isDownloadBlocked && {
            onClick: openBlockedDownloadModal,
          })}
        >
          <img src={DownloadIcon} alt="download-icon" />
        </StyledButton>
      </span>
    </Tooltip>
  );
};

DownloadButton.propTypes = {
  mp3: PropTypes.string,
  wav: PropTypes.string,
  id: PropTypes.number.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isDownloadBlocked: PropTypes.bool.isRequired,
  cuesheet: PropTypes.string,
  openBlockedDownloadModal: PropTypes.func.isRequired,
  isMp3: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isWav: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  external_id: PropTypes.string,
};

DownloadButton.defaultProps = {
  mp3: null,
  wav: null,
  cuesheet: null,
  isMp3: false,
  isWav: false,
  external_id: undefined,
};

export default DownloadButton;
