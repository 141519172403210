import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MainContent, Header } from '@styles/globalStyles';
import Label from '@components/Label';
import Preloader from '@components/Preloader';
import * as Styled from '@containers/Labels/style';

const Labels = ({ labelsStore: { labels, getLabels } }) => {
  const { t } = useTranslation();
  useEffect(() => {
    getLabels();
  }, [getLabels]);

  return (
    <MainContent>
      <Header>{t('Catalogues')}</Header>
      {labels.isLoading && <Preloader />}
      <Styled.GridList>
        {labels.data.map(({ name, description, image, uuid }) => (
          <li key={uniqueId()}>
            <Label
              name={name}
              description={description}
              image={{ ...image }.url}
              uuid={uuid}
            />
          </li>
        ))}
      </Styled.GridList>
    </MainContent>
  );
};

Labels.propTypes = {
  labelsStore: PropTypes.shape({
    labels: PropTypes.object.isRequired,
    getLabels: PropTypes.func.isRequired,
  }).isRequired,
};

export default inject('labelsStore')(observer(Labels));
