import styled from '@emotion/styled';
import { Wrapper } from '@components/BannersSlider/style';
import { breakpoint } from '@styles/breakpoints';

export const PreviewWrapper = styled(Wrapper)`
  margin: 0 auto;
  padding: 16px;

  @media ${breakpoint.md} {
    margin: 0 auto;
  }
`;
