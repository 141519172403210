import React from 'react';
import PropTypes from 'prop-types';

import Text, { VARIANTS } from '@components/Text';
import * as Styled from '@components/Briefing/BriefingCell/style';

const BriefingCell = ({ title, fillAvaliable, children }) => (
  <div>
    <Text variant={VARIANTS.H4}>{title}</Text>
    <Styled.Cell fillAvaliable={fillAvaliable}>{children}</Styled.Cell>
  </div>
);

BriefingCell.propTypes = {
  title: PropTypes.string.isRequired,
  fillAvaliable: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

BriefingCell.defaultProps = {
  fillAvaliable: false,
};

export default BriefingCell;
