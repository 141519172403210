import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Statistics from '@containers/AboutUs/Statistics';
import { Header, MainContent, Separator } from '@styles/globalStyles';
import { Flex } from 'rebass';
import Text, { VARIANTS } from '@components/Text';
import Preloader from '@components/Preloader';
import CmsBody from '@components/CmsBody';

const AboutUs = ({
  aboutUsStore: { getContent, content, getStatistics, statistics },
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    getStatistics();
  }, [getStatistics]);
  useEffect(() => {
    getContent();
  }, [getContent]);

  if (content.isLoading && statistics.isLoading) {
    return <Preloader center />;
  }

  return (
    <MainContent>
      <Header>{t('about intervox')}</Header>
      <Separator />
      <Flex alignSelf="center" margin="16px 0 50px 0">
        <Text variant={VARIANTS.H4}>
          {t('We’re as excited about music as you are')}
        </Text>
      </Flex>
      <Statistics
        artists={statistics.data.composers}
        users={statistics.data.users}
        downloads={statistics.data.downloads}
      />
      <CmsBody
        html={content.data.body}
        isLoading={content.isLoading}
        error={content.error}
      />
    </MainContent>
  );
};

AboutUs.propTypes = {
  aboutUsStore: PropTypes.object.isRequired,
};

export default inject('aboutUsStore')(observer(AboutUs));
