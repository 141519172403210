import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import routes from '@routes';
import Header from '@components/Header';
import { MainContent } from '@styles/globalStyles';
import BriefingForm from '@containers/BriefingPage/components/BriefingForm';
import Button from '@components/Button';
import { FORM_TYPE } from '@containers/BriefingPage/constants';
import NotFound from '@containers/NotFound';
import Preloader from '@components/Preloader';

export * from '@containers/BriefingPage/constants';

const Briefing = ({
  action,
  briefingsStore: {
    briefings: { data: listData, error: listError, isLoading: listLoading },
    briefingTrack: { dataError: formError, isFetching: formLoading },
    getBriefings,
    getBriefingTrack,
  },
}) => {
  const { t } = useTranslation();

  const { id } = useParams();

  useEffect(() => {
    if (action === FORM_TYPE.UPDATE) {
      getBriefingTrack(id);
    } else if (!listData.length) {
      getBriefings();
    }
  }, [action, listData, getBriefings]);

  const isUpdate = action === FORM_TYPE.UPDATE;

  if (
    (isUpdate && !formLoading && formError) ||
    (!isUpdate &&
      !listLoading &&
      (listError || listData.findIndex(item => item.uuid === id)) < 0)
  ) {
    return <NotFound />;
  }

  return (
    <MainContent>
      <Header
        title={t('Briefing Application')}
        nav={
          action === FORM_TYPE.UPDATE ? (
            <Button label={t('Back to tracks')} to={routes.uploadedTracks} />
          ) : (
            <Button label={t('Back to briefings')} to={routes.briefings} />
          )
        }
      />
      {listLoading || formLoading ? (
        <Preloader center />
      ) : (
        <BriefingForm {...{ param: id, action }} />
      )}
    </MainContent>
  );
};

Briefing.defaultProps = {
  action: FORM_TYPE.ADD,
};

Briefing.propTypes = {
  action: PropTypes.oneOf(Object.values(FORM_TYPE)),
  briefingsStore: PropTypes.shape({
    briefings: PropTypes.shape({
      data: PropTypes.array,
      error: PropTypes.object,
      isLoading: PropTypes.bool,
    }),
    briefingTrack: PropTypes.shape({
      dataError: PropTypes.object,
      isFetching: PropTypes.bool,
    }),
    getBriefings: PropTypes.func,
    getBriefingTrack: PropTypes.func,
  }).isRequired,
};

export default inject('briefingsStore')(observer(Briefing));
