import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ahoy from 'ahoy.js';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import CuesheetButton, { TYPES } from '@components/CuesheetButton';
import * as Styled from '@containers/MainPlayer/components/AlbumInfo/style';
import Text, { VARIANTS, COLORS } from '@components/Text';
import { VARIANTS as BUTTON_VARIANTS } from '@components/Button';
import { APIRoutes } from '@app/api';
import { dependsOnOtherProp } from '@utils/propTypes';
import Tooltip, { TRIGGER } from '@components/Tooltip';
import DisabledDownloadTooltipBody from '@components/IconButtons/DisabledDownloadTooltipBody';
import CoverPlaceholder from '@components/CoverPlaceholder';
import { MenuItem, Icon, Divider } from '@components/TooltipSelect';
import DownloadIcon from '@assets/images/icons/download-icon.svg';

const AlbumInfo = ({
  cover,
  description,
  name,
  uuid,
  isAuthenticated,
  id,
  isOpen,
  trigger,
  isNotFullTrack,
  url,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Styled.AlbumInfoAnimations />
      <CSSTransition
        in={isOpen}
        timeout={200}
        classNames="expanded-album"
        unmountOnExit
      >
        <Styled.Wrapper>
          <Styled.AlbumInfo>
            <Styled.AlbumInfoInner>
              <Link to={url}>
                {cover.url ? (
                  <Styled.Image src={cover.url} alt={cover.name} />
                ) : (
                  <CoverPlaceholder size="4rem" text={cover.name} />
                )}
              </Link>
              <div>
                <Styled.Title>{name}</Styled.Title>
                {description ? (
                  <div>
                    <Styled.Text
                      variant={VARIANTS.SMALL_BODY}
                      color={COLORS.GREY}
                    >
                      {description}
                    </Styled.Text>
                  </div>
                ) : null}
              </div>
              {!isNotFullTrack && (
                <Styled.ButtonsWrapper>
                  <Tooltip
                    tooltipBody={
                      isAuthenticated ? (
                        <>
                          <MenuItem
                            as="a"
                            href={`${
                              process.env.REACT_APP_API_URL
                            }${APIRoutes.ALBUM_DOWNLOAD(id, 'wav')}`}
                            onClick={
                              isAuthenticated
                                ? () => ahoy.track('download_album_zip', { id })
                                : undefined
                            }
                            rel="noopener noreferrer"
                            justifyContent="space-between"
                          >
                            <Text variant={VARIANTS.NAV}>ZIP WAV</Text>
                            <Icon src={DownloadIcon} />
                          </MenuItem>
                          <Divider />
                          <MenuItem
                            as="a"
                            href={`${
                              process.env.REACT_APP_API_URL
                            }${APIRoutes.ALBUM_DOWNLOAD(id, 'mp3')}`}
                            onClick={
                              isAuthenticated
                                ? () => ahoy.track('download_album_zip', { id })
                                : undefined
                            }
                            rel="noopener noreferrer"
                            justifyContent="space-between"
                          >
                            <Text variant={VARIANTS.NAV}>ZIP MP3</Text>
                            <Icon src={DownloadIcon} />
                          </MenuItem>
                        </>
                      ) : (
                        <DisabledDownloadTooltipBody />
                      )
                    }
                    isActive
                    trigger={isAuthenticated ? TRIGGER.CLICK : TRIGGER.HOVER}
                  >
                    <div>
                      <Styled.LeftButton
                        label={t('Download album')}
                        variant={BUTTON_VARIANTS.SECONDARY}
                        disabled={!isAuthenticated}
                      />
                    </div>
                  </Tooltip>
                  <Tooltip
                    tooltipBody={<DisabledDownloadTooltipBody />}
                    isActive={!isAuthenticated}
                    trigger={TRIGGER.HOVER}
                  >
                    <div>
                      <CuesheetButton
                        isAuthenticated={isAuthenticated}
                        albumUUID={uuid}
                        id={id}
                        type={TYPES.ALBUM}
                      />
                    </div>
                  </Tooltip>
                  <Styled.FavoritesButton albumId={id} />
                </Styled.ButtonsWrapper>
              )}
            </Styled.AlbumInfoInner>
          </Styled.AlbumInfo>
          {trigger}
        </Styled.Wrapper>
      </CSSTransition>
    </>
  );
};
AlbumInfo.defaultProps = {
  id: null,
  uuid: null,
  description: null,
};

AlbumInfo.propTypes = {
  cover: PropTypes.object.isRequired,
  description: PropTypes.string,
  name: PropTypes.string.isRequired,
  uuid: (props, propName, componentName) =>
    dependsOnOtherProp(
      'isNotFullTrack',
      'string',
      props,
      propName,
      componentName,
    ),
  isAuthenticated: PropTypes.bool.isRequired,
  id: (props, propName, componentName) =>
    dependsOnOtherProp(
      'isNotFullTrack',
      'number',
      props,
      propName,
      componentName,
    ),
  isOpen: PropTypes.bool.isRequired,
  trigger: PropTypes.node.isRequired,
  isNotFullTrack: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
};

export default AlbumInfo;
