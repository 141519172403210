import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import SelectDropdown from '@components/SelectDropdown';

const FilterDropdown = ({
  filter: { dataKey, type, placeholder },
  labelsStore: { labels, getLabels },
  topicsStore: { topics, getTopics },
  temposStore: { tempos, getTempos },
  moodsStore: { moods, getMoods },
  genresStore: { genres, getGenres },
  instrumentationsStore: { instrumentations, getInstrumentations },
  onChange,
}) => {
  const dataByKey = {
    labels: { ...labels, get: getLabels },
    topics: { ...topics, get: getTopics },
    tempo: { ...tempos, get: getTempos },
    moods: { ...moods, get: getMoods },
    genres: { ...genres, get: getGenres },
    instrumentations: { ...instrumentations, get: getInstrumentations },
  };
  const [options, setOptions] = useState([]); // eslint-disable-line

  useEffect(() => {
    if (type === 'select') {
      const fetchOptions = async () => {
        const { data, get } = dataByKey[dataKey];
        if (data.length === 0) {
          await get();
        }
      };
      fetchOptions();
    }
  }, [type, dataKey]);

  useEffect(() => {
    if (type === 'select') {
      const optionsForDropdown = [];
      dataByKey[dataKey].data.forEach(data => {
        if (data.uuid) {
          optionsForDropdown.push({
            label: data.name,
            value: data.uuid,
          });
        }
      });
      setOptions(optionsForDropdown);
    }
  }, [
    dataKey,
    labels.data,
    topics.data,
    moods.data,
    tempos.data,
    genres.data,
    instrumentations.data,
  ]);

  return <SelectDropdown {...{ options, label: placeholder, onChange }} />;
};

FilterDropdown.propTypes = {
  filter: PropTypes.object.isRequired,
  labelsStore: PropTypes.shape({
    labels: PropTypes.shape({
      data: PropTypes.array,
    }),
    getLabels: PropTypes.func,
  }).isRequired,
  temposStore: PropTypes.shape({
    tempos: PropTypes.shape({
      data: PropTypes.array,
    }),
    getTempos: PropTypes.func,
  }).isRequired,
  moodsStore: PropTypes.shape({
    moods: PropTypes.shape({
      data: PropTypes.array,
    }),
    getMoods: PropTypes.func,
  }).isRequired,
  genresStore: PropTypes.shape({
    genres: PropTypes.shape({
      data: PropTypes.array,
    }),
    getGenres: PropTypes.func,
  }).isRequired,
  topicsStore: PropTypes.shape({
    topics: PropTypes.shape({
      data: PropTypes.array,
    }),
    getTopics: PropTypes.func,
  }).isRequired,
  instrumentationsStore: PropTypes.shape({
    instrumentations: PropTypes.object,
    getInstrumentations: PropTypes.func,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default inject(
  'labelsStore',
  'topicsStore',
  'moodsStore',
  'temposStore',
  'genresStore',
  'instrumentationsStore',
)(observer(FilterDropdown));
