import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import * as Styled from '@components/SideBar/components/SideBarItemLink/style';

const SideBarItemLink = ({
  label,
  icon,
  to,
  sidebarStore: { isSideBarOpened },
}) => (
  <Styled.HeaderTitleLinkWrapper to={to}>
    <Styled.LinkTitleWrapper desktopcollapsed={isSideBarOpened ? 1 : 0}>
      <Styled.HeaderIcon
        src={icon}
        desktopcollapsed={isSideBarOpened ? 1 : 0}
      />
      <Styled.LinkHeaderTitle>{label}</Styled.LinkHeaderTitle>
    </Styled.LinkTitleWrapper>
  </Styled.HeaderTitleLinkWrapper>
);

SideBarItemLink.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  sidebarStore: PropTypes.object.isRequired,
};

export default inject('sidebarStore')(observer(SideBarItemLink));
