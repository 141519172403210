export const FORM_TYPE = {
  ADD: 'ADD',
  UPDATE: 'UPDATE',
};

export const FILE_STATES = {
  NEW: 'NEW',
  UPLOADED: 'UPLOADED',
  UPLOADING: 'UPLOADING',
  DELETING: 'DELETING',
  ERROR: 'ERROR',
};
