import ReactGA from 'react-ga4';
import { currentLanguage } from '@utils/i18n';
import { COUNTRY_ANALYTIC_CODES } from '@utils/i18n/locationsContent';
import { getItem, KEYS } from '@utils/storage';

window[`ga-disable-${COUNTRY_ANALYTIC_CODES[currentLanguage]}`] = !(
  getItem(KEYS.DISABLE_PERFORMANCE_COOKIES) === 'false'
);

// for debugging: ReactGA.initialize(countryAnalyticCodes[currentLanguage], { debug: true });

export const initGA = () => {
  ReactGA.initialize(COUNTRY_ANALYTIC_CODES[currentLanguage]);
};

const historyListener = location => {
  const page =
    location.pathname + location.search ||
    window.location.pathname + window.location.search;
  if (!window[`ga-disable-${COUNTRY_ANALYTIC_CODES[currentLanguage]}`]) {
    ReactGA.set({ page });
  }
};

export default historyListener;
