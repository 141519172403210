import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 100%;
  height: 7px;
  background: #d8d8d8;
`;

export const Bar = styled.div`
  width: ${({ progress }) => progress}%;
  height: 100%;
  background: ${({ theme }) => theme.colors.highlight};
`;
