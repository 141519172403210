import styled from '@emotion/styled';
import { Flex } from 'rebass';

export const IconButton = styled.button`
  img {
    display: inline-block;
    margin: 0 5px;
    min-width: 29px;
  }
`;

export const MobileIcon = styled.button`
  img {
    display: inline-block;
    min-width: 24px;
  }
`;

export const TrackButton = styled.button`
  img {
    display: inline-block;
    margin-right: 10px;
    min-width: 30px;
  }
`;

export const ExpandIcon = styled.img`
  transform: rotate(${({ isOpen }) => (isOpen ? '180' : '0')}deg);
  padding: 8px;
  margin-right: -16px;
`;

export const AlternativeTrackRow = styled(Flex)`
  background: ${({ theme, isHighlighted }) =>
    isHighlighted ? theme.table.rowHoverBackground : 'transparent'};

  &:hover {
    background: ${({ theme }) => theme.table.rowHoverBackground};
  }
`;
