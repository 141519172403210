const FONTS = new Map();
FONTS.set('secondary-regular', 'PTMono-Regular');
FONTS.set('secondary-bold', 'PTMono-Bold');

const COLORS = new Map();
COLORS.set('black', '#0d0d0d');
COLORS.set('turquoise-blue', '#049dbf');
COLORS.set('dark-aqua', '#095f73');
COLORS.set('aqua-blue', '#04c4d9');
COLORS.set('very-light-pink', '#eaeaea');
COLORS.set('brown-grey', '#858585');
COLORS.set('white', '#f6f6f6');
COLORS.set('mid-grey', '#979797');
COLORS.set('colour-white', '#ffffff');
COLORS.set('pale-sky-blue', '#cedfe3');
COLORS.set('red', '#ff0000');
COLORS.set('green', '#48bb78');
COLORS.set('yellow', '#f7b61b');

export default {
  colors: {
    white: COLORS.get('colour-white'),
    primary: '#1d1d1d',
    primaryVariant: COLORS.get('black'),
    secondary: COLORS.get('turquoise-blue'),
    secondaryVariant: 'rgba(4, 196, 217, 0.5)',
    stroke: COLORS.get('very-light-pink'),
    strokeVariant: 'rgba(133, 133, 133, 0.5)',
    preloader: COLORS.get('turquoise-blue'),
    tertiary: '#0e4d60',
    quaternary: COLORS.get('dark-aqua'),
    textLight: COLORS.get('brown-grey'),
    textPrimary: COLORS.get('black'),
    error: COLORS.get('red'),
    success: COLORS.get('green'),
    backgroundGradient: COLORS.get('turquoise-blue'),
    highlight: COLORS.get('aqua-blue'),
    yellow: COLORS.get('yellow'),
    midGrey: COLORS.get('mid-grey'),
  },
  backgrounds: {
    pageContent: '#f9f9f9',
    secondaryGradientBackground:
      'linear-gradient(to bottom, #3b3b3b, #131313 99%)',
    secondary: '#f6f6f6',
    patternGradient: `linear-gradient(
      0deg,
      ${COLORS.get('dark-aqua')} 0%,
      ${COLORS.get('aqua-blue')} 100%
    )`,
    transparentWhite: 'rgba(255, 255, 255, 0.5)',
    shadow: '0 0 7px 0 rgba(13, 13, 13, 0.18)',
    banner: '#b9dce5',
  },
  shadows: {
    primary: '0 0 7px 0 rgba(13, 13, 13, 0.18)',
  },
  page: {
    mainContentPadding: '0 56px',
    mainContentPaddingX: '56px',
    mainContentPaddingMobile: '0 16px',
  },
  fonts: {
    secondary: {
      regular: FONTS.get('secondary-regular'),
      bold: FONTS.get('secondary-bold'),
    },
  },
  buttons: {
    fontFamily: FONTS.get('secondary-regular'),
    clean: {
      border: '1px #049dbf solid',
      borderVartiant: '1px #fff solid',
      borderRadius: '22.5px',
    },
    primary: {
      background: COLORS.get('colour-white'),
      color: COLORS.get('black'),
    },
    secondary: {
      background: COLORS.get('turquoise-blue'),
      color: COLORS.get('colour-white'),
    },
    green: {
      background: COLORS.get('dark-aqua'),
      color: COLORS.get('colour-white'),
      hoverBackground: '#084f5f',
    },
  },
  inputs: {
    search: {
      fontSize: '15px',
      color: '#0d0d0d',
      borderRadius: '4px',
      placeholder: {
        fontSize: '15px',
        color: '#0d0d0d',
      },
    },
    asterix: {
      color: COLORS.get('red'),
    },
    margins: {
      none: 0,
      small: 15,
      big: 30,
    },
    focus: {
      borderColor: COLORS.get('turquoise-blue'),
    },
  },
  dropdowns: {
    primary: {
      backgroundColor: COLORS.get('turquoise-blue'),
      openedBackgroundColor: COLORS.get('aqua-blue'),
      color: COLORS.get('black'),
      borderRadius: '4px',
    },
  },
  sidebar: {
    width: {
      desktop: {
        collapsed: 84,
        default: 250,
      },
    },
    header: {
      activeBackground: COLORS.get('aqua-blue'),
      padding: '15px 0 15px 27px',
    },
    list: {
      fontSize: '13px',
      color: COLORS.get('black'),
    },
    logo: {
      wrapperHeight: 130,
    },
    transitionTime: 0.7,
  },
  footer: {
    height: 357,
  },
  tags: {
    trackBackground: 'rgba(255, 255, 255, 0.5)',
    tagBackground: 'rgba(13, 13, 13, 0.05)',
    tagHoverBackground: `rgba(4, 157, 191, 0.6)`,
  },
  tabs: {
    background: 'rgba(255, 255, 255, 0.70)',
    tabBackground: 'transparent',
    tabActiveBackground: COLORS.get('aqua-blue'),
    tabHoverBackground: `rgba(13, 13, 13, 0.05)`,
  },
  table: {
    headBackground: 'rgba(13, 13, 13, 0.05)',
    rowHoverBackground: `rgba(4, 157, 191, 0.6)`,
    highlightBackground: `rgba(4, 196, 217, 0.5)`,
    lightHighlightBackground: `rgba(4, 196, 217, 0.25)`,
    expandedBackground: COLORS.get('colour-white'),
  },
  filters: {
    tag: {
      backgroundColor: 'rgba(4, 157, 191, 0.15)',
      hoverBackgroundColor: 'rgba(4, 157, 191, 0.3)',
      borderRadius: 5,
    },
  },
  fontSizes: {
    h1: '30px',
    h2: '28px',
    h3: '18px',
    h4: '15px',
    nav: '13px',
    navCollapsed: '11px',
    body: '13px',
    bodySmall: '12px',
  },
  scrollbar: {
    sidebar: {
      track: COLORS.get('pale-sky-blue'),
      scrollbar: COLORS.get('dark-aqua'),
    },
    content: {
      track: COLORS.get('brown-grey'),
      scrollbar: {
        size: 12,
        background: COLORS.get('black'),
      },
    },
  },
  track: {
    listItemHeight: 53,
  },
  spacing: {
    none: 0,
    small: 15,
    big: 30,
  },
};
