import styled from '@emotion/styled';

export const ButtonsFromList = styled.div`
  width: 100px;
  display: flex;
  justify-content: space-around;
  flex-direction: inherit;
  flex: 0;
  img {
    &:first-of-type {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
`;
