import styled from '@emotion/styled';
import PlayPauseButton from '@components/PlayerControlButtons/PlayPause';
import { Link } from 'react-router-dom';

export const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.secondary.regular};
  font-size: ${({ theme }) => theme.fontSizes.body};
  margin-left: 5px;
`;

export const TrackName = styled.span`
  font-weight: ${({ active }) => (active ? 'bold' : 'regular')};
`;

export const Cover = styled.img`
  width: 34px;
  margin-right: 8px;
`;

export const Artist = styled.div`
  font-family: ${({ theme }) => theme.fonts.secondary.regular};
  font-size: ${({ theme }) => theme.fontSizes.bodySmall};
  color: ${({ theme }) => theme.colors.textLight};
  margin-top: 5px;
  font-weight: 'regular';
`;

export const PlayPause = styled(PlayPauseButton)`
  padding: 0 8px;
`;

export const CoverLink = styled(Link)`
  display: flex;
  align-items: center;
`;
