import styled from '@emotion/styled';
import { Flex } from 'rebass';

export const Wrapper = styled.div`
  padding: 16px;
`;

export const Row = styled(Flex)`
  flex-wrap: wrap;
  flex: 1;
  overflow: auto;
  align-items: flex-start;
`;
