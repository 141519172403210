import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const Backdrop = styled.div`
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  position: fixed;
  z-index: 99;
`;

const shadow = 'hsla(218, 50%, 10%, 0.1)';

export const Menu = styled.div`
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 0 1px ${shadow}, 0 4px 11px ${shadow};
  position: absolute;
  z-index: 999;
  overflow: hidden;
  width: 100%;
  min-width: fit-content;
`;

export const DropdownIndicator = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

export const DropdownWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Button = styled.button`
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 18px;
  line-height: 1;

  @media ${breakpoint.md} {
    padding: 10px 0;
    font-size: 16px;
  }
`;

export const ButtonIndicator = styled.img`
  vertical-align: middle;
  margin-left: 8px;
  transition: transform 0.3s ease;
  transform: rotate(${({ isOpen }) => (isOpen ? 180 : 0)}deg);
`;
