import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import { Controller } from 'react-hook-form';
import { uniqueId } from 'lodash-es';
import ArrowDownIcon from '@assets/images/icons/arrow-down.svg';
import { useTheme } from '@emotion/react';
import { Asterix, Error, FieldWrapper, Label } from '@components/Form/style';
import { InputWrapper, Indicator } from '@components/Form/Select/style';
import i18n from '@utils/i18n';
import { MARGINS } from '@styles/globalStyles';
import resolvePath from '@utils/resolvePath';

export const VARIANTS = {
  DEFAULT: 'DEFAULT',
  FILTER_BOX: 'FILTER_BOX',
  FILTER_BOX_MOBILE: 'FILTER_BOX_MOBILE',
};

export const components = {
  // eslint-disable-next-line
  DropdownIndicator: ({ selectProps: { async, menuIsOpen, isLoading } }) => {
    if (!isLoading && !async) {
      return <Indicator menuIsOpen={menuIsOpen} src={ArrowDownIcon} alt="" />;
    }

    return null;
  },
};

export const customStyles = (theme, withError, overrides = {}) => ({
  control: base => ({
    ...base,
    borderColor: withError ? theme.colors.error : '#dddddd',
    padding: 4,
    boxShadow: 'unset',
    fontSize: '14px',
    lineHeight: '1.5',
    '&:hover': {
      color: '#dddddd',
    },
    '&:focus-within': { borderColor: theme.colors.secondary },
    position: 'relative',
    ...overrides.control,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: () => ({
    position: 'absolute',
    right: 10,
    top: '50%',
    transform: 'translateY(-50%)',
  }),
  menu: (base, props) => ({
    ...base,
    display: props.options.length === 0 ? 'none' : 'block',
    ...overrides.menu,
  }),
  valueContainer: base => ({
    ...base,
    paddingRight: 30,
  }),
});

export const filterStyles = (
  _theme,
  _fieldError,
  _overrides,
  mobile = false,
) => ({
  control: base => ({
    ...base,
    border: 'none',
    padding: 4,
    boxShadow: 'unset',
    fontSize: '14px',
    lineHeight: '1.5',
    position: 'relative',
    justifyContent: mobile ? 'space-between' : 'center',
    cursor: 'pointer',
    background: 'transparent',
  }),
  valueContainer: () => ({
    width: 'auto',
    display: 'flex',
  }),
  placeholder: () => ({
    width: '100%',
    textAlign: 'center',
    fontSize: mobile ? '12px' : '18px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: () => ({
    height: '16px',
    marginLeft: '5px',
  }),
  singleValue: () => ({}),
  menu: provided => ({
    ...provided,
    width: 'fit-content',
    maxWidth: '100vw',
    minWidth: mobile ? 180 : '100%',
    textTransform: 'uppercase',
  }),
});

export const variantStyles = {
  DEFAULT: customStyles,
  FILTER_BOX: filterStyles,
  FILTER_BOX_MOBILE: (theme, fieldError, overrides) =>
    filterStyles(theme, fieldError, overrides, true),
};

const Select = ({
  name,
  label,
  options,
  control,
  isSearchable,
  required,
  style,
  errors,
  defaultValue,
  mb,
  placeholder,
  isMulti,
  variant,
  isAsync,
  overrides,
  ...selectProps
}) => {
  const theme = useTheme();
  const fieldId = useMemo(() => uniqueId(`input_${name}_`), []);

  const fieldError = resolvePath(errors, name);

  return (
    <FieldWrapper style={style} mb={mb}>
      {label ? (
        <Label htmlFor={fieldId}>
          {label} {required ? <Asterix>*</Asterix> : null}
        </Label>
      ) : null}
      <InputWrapper>
        <Controller
          {...{
            control,
            name,
            options,
            isSearchable,
            isMulti,
            components,
            defaultValue,
            placeholder,
            isClearable: false,
            rules: {
              required: {
                value: required,
                message: i18n.t('Required'),
              },
            },
            styles: variantStyles[variant](theme, fieldError, overrides),
            as: isAsync ? AsyncSelect : ReactSelect,
            ...selectProps,
          }}
        />
      </InputWrapper>
      {fieldError ? <Error>{fieldError.message}</Error> : null}
    </FieldWrapper>
  );
};

Select.defaultProps = {
  isSearchable: true,
  required: false,
  label: '',
  options: undefined,
  mb: MARGINS.SMALL,
  style: {},
  errors: {},
  defaultValue: null,
  placeholder: null,
  isMulti: false,
  variant: VARIANTS.DEFAULT,
  selectProps: {},
  isAsync: false,
  overrides: {},
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.array,
  control: PropTypes.object.isRequired,
  isSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
  required: PropTypes.bool,
  mb: PropTypes.oneOf(Object.values(MARGINS)),
  style: PropTypes.object,
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  errors: PropTypes.object,
  defaultValue: PropTypes.object,
  placeholder: PropTypes.string,
  selectProps: PropTypes.object,
  isAsync: PropTypes.bool,
  overrides: PropTypes.shape({
    control: PropTypes.object,
    menu: PropTypes.object,
  }),
};

export default Select;
