import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import * as Styled from '@containers/AboutUs/Statistics/style';
import { VARIANTS } from '@components/Text';

const toHundreds = number => {
  if (number / 100 > 1) return Math.floor(number / 100) * 100;
  if (number / 10 > 1) return Math.floor(number / 10) * 10;
  return number - 1;
};

const formatNumber = number =>
  number.toLocaleString().replace(String.fromCharCode(160), ',');

const incrementRate = number => {
  if (number > 10000) return 10;
  if (number > 1000) return 5;
  return 1;
};

const StatisticsItem = ({ number, name }) => {
  const [current, setCurrent] = useState(0);

  const count = () => () => {
    let now = 0;
    // eslint-disable-next-line consistent-return
    const interval = setInterval(() => {
      if (now > toHundreds(number)) return clearInterval(interval);
      setCurrent(formatNumber(now));
      now += incrementRate(number);
    }, 1);
  };

  useEffect(count(), [number]);

  return (
    <Styled.Item>
      <Styled.Number>{current}+</Styled.Number>
      <Styled.Name variant={VARIANTS.H4}>{name}</Styled.Name>
    </Styled.Item>
  );
};

StatisticsItem.propTypes = {
  number: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

const Statistics = ({ artists, users, downloads }) => {
  const { t } = useTranslation();
  return (
    <Styled.Wrapper>
      {artists > 0 && <StatisticsItem name={t('Composers')} number={artists} />}
      {users > 0 && <StatisticsItem name={t('Users')} number={users} />}
      {downloads > 0 && (
        <StatisticsItem name={t('Monthly downloads')} number={downloads} />
      )}
    </Styled.Wrapper>
  );
};

Statistics.propTypes = {
  artists: PropTypes.number,
  users: PropTypes.number,
  downloads: PropTypes.number,
};

Statistics.defaultProps = {
  artists: null,
  users: null,
  downloads: null,
};

export default Statistics;
