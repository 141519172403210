const CONFIG = {
  staging: {
    EN: 'https://intervox-stg-uk-2.goodylabs.com',
    DE: 'https://intervox-stg-de-2.goodylabs.com',
    IT: 'https://intervox-stg-it-2.goodylabs.com',
    ES: 'https://intervox-stg-es-2.goodylabs.com',
    PT: 'https://intervox-stg-pt-2.goodylabs.com',
    CZ: 'https://intervox-stg-cz-2.goodylabs.com',
  },
  development: {
    EN: 'http://intervox-local.en:3000',
    DE: 'http://intervox-local.de:3000',
    IT: 'http://intervox-local.it:3000',
    ES: 'http://intervox-local.es:3000',
    PT: 'http://intervox-local.pt:3000',
    CZ: 'http://intervox-local.cz:3000',
  },
  production: {
    EN: 'https://www.intervox.co.uk',
    DE: 'https://www.intervox.de',
    IT: 'https://www.intervox.it',
    ES: 'https://www.intervoxmusic.es',
    PT: 'https://www.intervox.pt',
    CZ: 'https://www.provoxmusic.cz',
  },
};

export const LANG_KEYS = {
  EN: 'EN',
  DE: 'DE',
  IT: 'IT',
  ES: 'ES',
  PT: 'PT',
  CZ: 'CZ',
};

const LANGUAGE_NAME = {
  EN: 'English',
  DE: 'Deutsch',
  IT: 'Italiano',
  ES: 'Espanol',
  PT: 'Portugues',
  CZ: 'Čeština',
};

export const locations = CONFIG[process.env.REACT_APP_DISTRIBUTION];

export const langsToExclude = ['CZ'];

const prepareDataForDropdown = () => {
  const dropdownLocations = {
    ...locations,
  };

  /* remove Czech language from dropdown */
  langsToExclude.forEach(lang => delete dropdownLocations[lang]);

  return Object.keys(dropdownLocations).map(location => ({
    id: location,
    value: locations[location],
    label: location,
    name: LANGUAGE_NAME[location],
  }));
};

export const locationsForDropdown = prepareDataForDropdown();

export default CONFIG;
