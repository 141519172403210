import styled from '@emotion/styled';
import { Flex } from 'rebass';
import Text from '@components/Text';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled(Flex)`
  width: 100%;
  justify-content: space-around;
  overflow: hidden;

  @media ${breakpoint.md} {
    flex-direction: column;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fonts.secondary.regular};
  border-right: 0.5px solid ${({ theme }) => theme.colors.textLight};
  width: 100%;
  padding: 0 8px;
  justify-content: center;

  &:last-child {
    border-right: none;
  }

  @media ${breakpoint.md} {
    margin-bottom: 40px;
    border-right: none;
  }
`;

export const Number = styled.span`
  font-size: 50px;
  color: ${({ theme }) => theme.colors.highlight};
  margin-bottom: 20px;
  text-align: center;

  @media ${breakpoint.md} {
    font-size: 40px;
  }
`;

export const Name = styled(Text)`
  text-align: center;
`;
