import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Flex, Box } from 'rebass';
import { Helmet } from 'react-helmet';
import AccountDeleteForm from '@containers/AccountDelete/components/AccountDeleteForm';
import Header from '@components/Header';
import { MainContent } from '@styles/globalStyles';
import Card, { VARIANTS as CARD_VARIANTS } from '@components/Card';
import Text, { COLORS, VARIANTS as TEXT_VARIANTS } from '@components/Text';

const PasswordReset = ({
  authStore: { accountDeleteStatus, deleteAccount },
  customerStore: { customer },
}) => {
  const { t } = useTranslation();

  return (
    <MainContent>
      <Helmet>
        <title>{t('Delete my account')}</title>
      </Helmet>
      <Header title={t('Delete my account')} withSeparator />

      <Flex flexWrap="wrap" mx={-3} mt={3} mb={[0, 0, 0, 6]}>
        <Box width={[1, 1, 1, 1 / 2]} px={3} mb={[5, 5, 5, 0]}>
          <Card variant={CARD_VARIANTS.TRANSPARENT}>
            <Box mb={3}>
              <Text
                variant={TEXT_VARIANTS.H4}
                style={{ textTransform: 'initial' }}
              >
                {t(
                  'Enter password and confirmation text to delete your account',
                )}
              </Text>
            </Box>

            <Text variant={TEXT_VARIANTS.BODY} color={COLORS.ERROR}>
              {t(
                'Attention! The account deletion process is permanent and cannot be reversed.',
              )}
            </Text>
            <AccountDeleteForm
              onSubmit={deleteAccount}
              formStatus={accountDeleteStatus}
              provider={customer?.data?.provider || ''}
            />
          </Card>
        </Box>
      </Flex>
    </MainContent>
  );
};

PasswordReset.propTypes = {
  authStore: PropTypes.object.isRequired,
  customerStore: PropTypes.object.isRequired,
};

export default inject('authStore', 'customerStore')(observer(PasswordReset));
