import React, { useRef } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import * as Styled from '@components/News/components/TrendingBar/style';
import Preloader from '@components/Preloader';
import RightArrowIcon from '@assets/images/icons/small-right-arrow.svg';
import LeftArrowIcon from '@assets/images/icons/small-left-arrow.svg';

const TrendingBar = ({
  newsStore: {
    news: { data, isLoading },
    activeTabId,
    selectTab,
  },
}) => {
  const scrollTrack = useRef();

  const scrollContainerBy = direction => {
    const left = (scrollTrack.current.clientWidth / 2) * direction;
    scrollTrack.current.scrollBy({ left, behavior: 'smooth' });
  };

  return (
    <Styled.Wrapper>
      {isLoading && <Preloader center />}
      <Styled.TagsScroll ref={scrollTrack}>
        {data
          ? data.map(({ id, title }) => (
              <div key={`news-title_${id}`}>
                <Styled.Tag
                  active={id === activeTabId}
                  onClick={() => selectTab(id)}
                >
                  {title}
                </Styled.Tag>
              </div>
            ))
          : null}
        <Styled.DummyItem />
      </Styled.TagsScroll>
      {!isLoading ? (
        <Styled.TagsControls>
          <Styled.ScrollButton
            type="button"
            onClick={() => scrollContainerBy(-1)}
          >
            <img src={LeftArrowIcon} alt="" />
          </Styled.ScrollButton>
          <Styled.ScrollButton
            type="button"
            onClick={() => scrollContainerBy(1)}
          >
            <img src={RightArrowIcon} alt="" />
          </Styled.ScrollButton>
        </Styled.TagsControls>
      ) : null}
    </Styled.Wrapper>
  );
};

TrendingBar.propTypes = {
  newsStore: PropTypes.shape({
    activeTabId: PropTypes.number,
    news: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.array,
    }),
    selectTab: PropTypes.func,
  }).isRequired,
};

export default inject('newsStore')(observer(TrendingBar));
