import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

import Preloader from '@components/Preloader';

import Banner from '@components/Banner';

import NotFound from '@containers/NotFound';
import { PreviewWrapper } from '@components/BannersPreview/style';

const BannersPreview = ({
  bannersStore: {
    singleBanner: { data, isLoading, error },
    getSingleBanner,
  },
}) => {
  const { id } = useParams();

  useEffect(() => {
    if (id) getSingleBanner(id);
  }, [getSingleBanner, id]);

  if (isLoading) {
    return <Preloader center />;
  }

  if (error) {
    return <NotFound />;
  }

  const { title, description, image, button_text, button_url } = data;

  return (
    <PreviewWrapper>
      <Swiper slidesPerView={1} direction="vertical">
        <SwiperSlide>
          <Banner
            title={title}
            description={description}
            image={image?.url}
            buttonText={button_text}
            url={button_url}
          />
        </SwiperSlide>
      </Swiper>
    </PreviewWrapper>
  );
};

BannersPreview.propTypes = {
  bannersStore: PropTypes.shape({
    singleBanner: PropTypes.object,
    getSingleBanner: PropTypes.func,
  }).isRequired,
};

export default inject('bannersStore')(observer(BannersPreview));
