/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ahoy from 'ahoy.js';
import DownloadIcon from '@assets/images/icons/download-icon.svg';
import PlusIcon from '@assets/images/icons/plus-icon.svg';
import InfoIcon from '@assets/images/icons/info-icon.svg';
import ShareIcon from '@assets/images/icons/share-icon.svg';
import { APIRoutes } from '@app/api';
import * as Styled from '@components/TooltipSelect';
import Text, { VARIANTS } from '@components/Text';
import useClipboard from '@hooks/useClipboard';
import { XIcon } from '@components/ProjectTracksTable/components/style';
import toggleShareMenu from '@utils/toggleShareMenu';

const MobileActionsTooltipBody = ({
  row,
  isAuthenticated,
  isDownloadBlocked,
  openBlockedDownloadModal,
  onDeleteTrackFromProject,
  copyText,
  copyNotification,
  openTrackModal,
}) => {
  const { t } = useTranslation();

  const copyRef = useClipboard(copyText, copyNotification);

  const { id, name, external_id, file, file_wav } = row;

  const track = type =>
    isAuthenticated ? ahoy.track(`download_track_${type}`, { id }) : undefined;

  const downloadButtonProps = isAuthenticated
    ? isDownloadBlocked
      ? {
          as: 'button',
          type: 'button',
          onClick: openBlockedDownloadModal,
        }
      : {
          as: 'a',
        }
    : {
        as: 'button',
        type: 'button',
        disabled: true,
        title: t('Sign in to download'),
      };

  return (
    <>
      {file.url && external_id && (
        <>
          <Styled.MenuItem
            {...downloadButtonProps}
            href={APIRoutes.TRACK_DOWNLOAD(external_id, 'mp3')}
            onClick={() => track('mp3')}
          >
            <Styled.Icon src={DownloadIcon} alt="share" />
            <Text variant={VARIANTS.NAV}>{t('MP3')}</Text>
          </Styled.MenuItem>
          <Styled.Divider />
        </>
      )}
      {file_wav.url && external_id && (
        <>
          <Styled.MenuItem
            {...downloadButtonProps}
            href={APIRoutes.TRACK_DOWNLOAD(external_id, 'wav')}
            onClick={() => track('wav')}
          >
            <Styled.Icon src={DownloadIcon} alt="info" />
            <Text variant={VARIANTS.NAV}>{t('WAV')}</Text>
          </Styled.MenuItem>
          <Styled.Divider />
        </>
      )}
      <Styled.MenuItem
        type="button"
        onClick={navigator.share && (() => toggleShareMenu(copyText))}
        ref={navigator.share ? null : copyRef}
      >
        <Styled.Icon src={ShareIcon} alt="share" />
        <Text variant={VARIANTS.NAV}>{t('Share')}</Text>
      </Styled.MenuItem>
      <Styled.Divider />
      <Styled.MenuItem type="button" onClick={() => openTrackModal(id)}>
        <Styled.Icon src={InfoIcon} alt="info" />
        <Text variant={VARIANTS.NAV}>{t('Info')}</Text>
      </Styled.MenuItem>
      <Styled.Divider />
      <Styled.MenuItem
        {...{
          type: 'button',
          onClick: () => onDeleteTrackFromProject({ id, name }),
        }}
      >
        <Styled.Icon src={PlusIcon} alt="save" as={XIcon} />
        <Text variant={VARIANTS.NAV}>{t('Remove track')}</Text>
      </Styled.MenuItem>
    </>
  );
};

MobileActionsTooltipBody.defaultProps = {};

MobileActionsTooltipBody.propTypes = {
  row: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isDownloadBlocked: PropTypes.bool.isRequired,
  openBlockedDownloadModal: PropTypes.func.isRequired,
  onDeleteTrackFromProject: PropTypes.func.isRequired,
  openTrackModal: PropTypes.func.isRequired,
  copyText: PropTypes.string.isRequired,
  copyNotification: PropTypes.string.isRequired,
};

export default MobileActionsTooltipBody;
