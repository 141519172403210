import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from '@components/Avatar/style';

const Avatar = ({ image, altImage, children, onClick }) => (
  <Styled.Wrapper onClick={onClick}>
    <Styled.Image style={{ backgroundImage: `url(${image})` }}>
      {altImage && (
        <Styled.AltImage style={{ backgroundImage: `url(${altImage})` }} />
      )}
    </Styled.Image>
    {children}
  </Styled.Wrapper>
);

Avatar.defaultProps = {
  altImage: undefined,
};

Avatar.propTypes = {
  image: PropTypes.string.isRequired,
  altImage: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Avatar;
