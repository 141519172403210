import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import BurgerMenuIcon from '@assets/images/icons/burger-menu.svg';

import {
  Wrapper,
  Keywords,
  Buttons,
  MenuButton,
  ClickableArea,
  Placeholder,
} from '@components/MobileSearchTexts/style';
import Text, { VARIANTS } from '@components/Text';

const MobileSearchTexts = ({
  children,
  searchStore: { setMobileSearchVisibility, switchToAdvancedSearch },
}) => {
  const { t } = useTranslation();

  const openAdvancedSearch = () => {
    setMobileSearchVisibility(true);
    switchToAdvancedSearch();
  };

  return (
    <Wrapper>
      <ClickableArea onClick={() => setMobileSearchVisibility(true)} />
      {children.length > 0 ? (
        <Keywords>{children}</Keywords>
      ) : (
        <Placeholder mx={2}>
          <Text variant={VARIANTS.NAV}>{t('No search phrases...')}</Text>
        </Placeholder>
      )}
      <Buttons>
        <MenuButton type="button" onClick={openAdvancedSearch}>
          <img src={BurgerMenuIcon} alt="" height="100%" />
        </MenuButton>
      </Buttons>
    </Wrapper>
  );
};

MobileSearchTexts.defaultProps = {
  children: undefined,
};

MobileSearchTexts.propTypes = {
  searchStore: PropTypes.shape({
    setMobileSearchVisibility: PropTypes.func,
    switchToAdvancedSearch: PropTypes.func,
  }).isRequired,
  children: PropTypes.node,
};

export default inject('searchStore')(observer(MobileSearchTexts));
