import styled from '@emotion/styled';
import { Flex } from 'rebass';

export const Wrapper = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.body};
  position: relative;
  padding: 5px;
  box-shadow: 0 0 12px 0 rgba(13, 13, 13, 0.15);
  border: 1px solid rgb(208, 208, 207);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
`;

export const Image = styled.img`
  width: 100%;
  border-radius: 2px;
`;

export const DescriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

export const ButtonsBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid ${({ theme }) => theme.colors.stroke};
`;

export const Title = styled.p`
  font-family: ${props => props.theme.fonts.secondary.regular};
  margin: 10px;
  line-height: 1.31;
  text-align: right;
  align-self: center;
`;

export const Description = styled.p`
  font-weight: 300;
  margin-bottom: 10px;
  line-height: 1.31;
  height: 34px;
  overflow-y: hidden;
`;

export const ButtonsFromListContainer = styled(Flex)`
  flex-direction: row-reverse;
  margin-left: -10px;
`;
