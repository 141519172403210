import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import Text, { COLORS, VARIANTS } from '@components/Text';
import { API, APIRoutes } from '@app/api';
import Preloader from '@components/Preloader';
import * as Styled from '@components/SearchFilters/components/FilterTag/style';

import ClearIcon from '@assets/images/icons/close-icon.svg';

const API_ROUTES = {
  composers: APIRoutes.COMPOSER,
  keywords: APIRoutes.KEYWORD,
};

const FilterTag = ({
  category,
  showCategory,
  index,
  remove,
  tag,
  searchStore: { advancedSearchFilters },
  labelsStore: { labels },
  topicsStore: { topics },
  temposStore: { tempos },
  moodsStore: { moods },
  genresStore: { genres },
  instrumentationsStore: { instrumentations },
}) => {
  const { t } = useTranslation();

  const [text, setText] = useState(tag);
  const [isLoading, setIsLoading] = useState(false);

  const dataByKey = {
    labels,
    topics,
    tempo: tempos,
    moods,
    genres,
    instrumentations,
  };

  useEffect(() => {
    if (category === 'composers' || category === 'keywords') {
      const getDetailsByUuid = async () => {
        setIsLoading(true);
        try {
          const { data } = await API.get(API_ROUTES[category](tag));

          if (category === 'composers') {
            setText(`${data.composer.first_name} ${data.composer.last_name}`);
          } else {
            setText(data.keyword.name);
          }
        } catch (error) {
          console.warn(error); // eslint-disable-line no-console
        } finally {
          setIsLoading(false);
        }
      };

      getDetailsByUuid();
    }
  }, []);

  useEffect(() => {
    if (
      tag &&
      category &&
      category !== 'composers' &&
      category !== 'text' &&
      category !== 'keywords'
    ) {
      const categoryData = dataByKey[category]?.data || [];
      const tagName =
        categoryData.find(({ uuid }) => uuid === tag)?.name || tag;
      setText(tagName);
    }
  }, [
    tag,
    category,
    labels.data,
    topics.data,
    tempos.data,
    moods.data,
    genres.data,
    instrumentations.data,
    dataByKey,
  ]);

  let categoryLabel = '';
  if (category !== 'text') {
    categoryLabel =
      advancedSearchFilters.find(({ value }) => value === category)?.label ||
      category;
  } else {
    categoryLabel = t('Text');
  }

  return (
    <Styled.Wrapper>
      <Text variant={VARIANTS.SMALL_BODY} size={20} color={COLORS.BLACK}>
        {isLoading ? (
          <Preloader />
        ) : (
          <>
            {showCategory && <>{categoryLabel}:</>} {text}{' '}
          </>
        )}
      </Text>
      <Styled.Clear type="button" onClick={() => remove(category, index)}>
        <img src={ClearIcon} alt="" />
      </Styled.Clear>
    </Styled.Wrapper>
  );
};

FilterTag.defaultProps = {
  showCategory: true,
};

FilterTag.propTypes = {
  category: PropTypes.string.isRequired,
  showCategory: PropTypes.bool,
  tag: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  searchStore: PropTypes.shape({
    advancedSearchFilters: PropTypes.array,
  }).isRequired,
  labelsStore: PropTypes.shape({
    labels: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.array,
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
    getLabels: PropTypes.func,
  }).isRequired,
  temposStore: PropTypes.shape({
    tempos: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.array,
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
    getTempos: PropTypes.func,
  }).isRequired,
  moodsStore: PropTypes.shape({
    moods: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.array,
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
    getMoods: PropTypes.func,
  }).isRequired,
  genresStore: PropTypes.shape({
    genres: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.array,
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
    getGenres: PropTypes.func,
  }).isRequired,
  topicsStore: PropTypes.shape({
    topics: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.array,
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
    getTopics: PropTypes.func,
  }).isRequired,
  instrumentationsStore: PropTypes.shape({
    instrumentations: PropTypes.object,
    getInstrumentations: PropTypes.func,
  }).isRequired,
};

export default inject(
  'labelsStore',
  'topicsStore',
  'moodsStore',
  'temposStore',
  'genresStore',
  'instrumentationsStore',
  'searchStore',
)(observer(FilterTag));
