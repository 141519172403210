import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { breakpoint, size } from '@styles/breakpoints';

const SEPARATOR = () => css`
  border-bottom: 1px solid #858585;
  margin-bottom: 32px;

  @media ${breakpoint.md} {
    padding-bottom: 24px;
    margin-bottom: 16px;
  }
`;

export const Nav = styled.div`
  margin: 8px 0;
`;

export const Title = styled.div`
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
  font-weight: 900;
  font-size: 30px;
  color: ${props => props.theme.colors.primaryVariant};
  margin: 8px 0;
  margin-right: 16px;
`;

export const Header = styled.header`
  width: 100%;
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (min-width: ${size.md}px) {
    ${({ withSeparator }) => withSeparator && SEPARATOR()}
  }

  @media ${breakpoint.md} {
    flex-direction: column;
    padding: 30px 0;

    ${Nav} {
      margin-top: 20px;
      margin-left: -16px;
      width: 100%;
    }

    ${Title} {
      width: 100%;

      ${({ withSeparator }) => withSeparator && SEPARATOR()}
    }
  }
`;
