import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { flatten } from 'lodash-es';
import { CSSTransition } from 'react-transition-group';

import Text, { VARIANTS as TEXT_VARIANTS, COLORS } from '@components/Text';
import { VARIANTS } from '@components/Button';
import FilterTag from '@components/SearchFilters/components/FilterTag';
import FilterDropdown from '@components/SearchFilters/components/FilterDropdown';
import * as Styled from '@components/SearchFilters/style';
import Preloader from '@components/Preloader';
import useBreakpoint from '@hooks/useBreakpoint';
import ArrowDownIcon from '@assets/images/icons/arrow-down.svg';
import MobileSearchTexts from '@components/MobileSearchTexts';

const SearchFilters = ({
  searchStore: {
    applyingFilter,
    advancedSearchFilters,
    applySearchFilter,
    filters,
    resetFiltersButtonClickHandler,
    removeFilterTag,
  },
}) => {
  const { t } = useTranslation();

  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();

  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  const [isExpanded, setIsExpanded] = useState(false);

  const { text: texts = [], ...restFilters } = filters || {};

  const filtersCount = flatten(Object.values(restFilters)).length;

  if (isMobile) {
    return (
      <>
        <MobileSearchTexts>
          {texts.map((keyword, index) => (
            <FilterTag
              key={`filter-text_${index}_${keyword}`}
              category="text"
              showCategory={false}
              tag={keyword}
              index={index}
              remove={removeFilterTag}
            />
          ))}
        </MobileSearchTexts>
        <Styled.Tags>
          <Styled.TagsList>
            {filters &&
              Object.keys(filters).map((filterGroup, index) =>
                restFilters[filterGroup]?.map((tag, tagIndex) => (
                  <FilterTag
                    key={`filter-tag_${filterGroup}-${index}_${tag}`}
                    category={filterGroup}
                    tag={tag}
                    index={tagIndex}
                    remove={removeFilterTag}
                  />
                )),
              )}
          </Styled.TagsList>
        </Styled.Tags>
        <Styled.Wrapper>
          {applyingFilter && <Preloader cover center />}
          <Styled.MobileFilters>
            <Styled.FiltersListDropdown
              onClick={() => setIsExpanded(!isExpanded)}
              type="button"
              isExpanded={isExpanded}
            >
              <Text variant={TEXT_VARIANTS.H4_REGULAR}>{t('Filters')}</Text>{' '}
              <img src={ArrowDownIcon} alt="" />
            </Styled.FiltersListDropdown>
            <CSSTransition
              in={isExpanded}
              timeout={200}
              classNames="expanded-filters"
              unmountOnExit
            >
              <Styled.MobileFiltersDropdowns>
                {advancedSearchFilters.map(filter => {
                  if (filter.showInFilterBox) {
                    return (
                      <Styled.FilterDropdownWrapper
                        key={`filter-dropdown_${filter.label}`}
                      >
                        <FilterDropdown
                          filter={filter}
                          onChange={value =>
                            applySearchFilter(filter.dataKey, value)
                          }
                        />
                      </Styled.FilterDropdownWrapper>
                    );
                  }
                  return null;
                })}
              </Styled.MobileFiltersDropdowns>
            </CSSTransition>
          </Styled.MobileFilters>
        </Styled.Wrapper>
        {(filtersCount > 0 || texts.length > 0) && (
          <Styled.MobileReset
            type="button"
            onClick={resetFiltersButtonClickHandler}
          >
            {t('Reset filters')}
          </Styled.MobileReset>
        )}
      </>
    );
  }

  return (
    <>
      <Styled.Wrapper>
        {applyingFilter && <Preloader cover center />}
        <>
          <Styled.Filters noBorder={!filters || !Object.keys(filters).length}>
            <Styled.FiltersTitle>
              <Text
                variant={TEXT_VARIANTS.H4_REGULAR}
                color={COLORS.BLACK}
                size={16}
              >
                {t('Filters')}:
              </Text>
            </Styled.FiltersTitle>
            <Styled.FiltersDropdowns>
              {advancedSearchFilters.map(filter => {
                if (filter.showInFilterBox) {
                  return (
                    <Styled.FilterDropdownWrapper
                      key={`filter-dropdown_${filter.label}`}
                    >
                      <FilterDropdown
                        filter={filter}
                        onChange={value =>
                          applySearchFilter(filter.dataKey, value)
                        }
                      />
                    </Styled.FilterDropdownWrapper>
                  );
                }
                return null;
              })}
            </Styled.FiltersDropdowns>
          </Styled.Filters>

          {filters && Object.keys(filters).length > 0 ? (
            <Styled.Tags>
              <Styled.TagsList>
                {Object.keys(filters).map((filterGroup, index) =>
                  filters[filterGroup].map((filter, filterIndex) => (
                    <FilterTag
                      key={`filter-tag_${filterGroup}-${index}_${filter}`}
                      category={filterGroup}
                      tag={filter}
                      index={filterIndex}
                      remove={removeFilterTag}
                    />
                  )),
                )}
              </Styled.TagsList>

              {filters && Object.keys(filters).length > 0 && (
                <Styled.ResetButton
                  label={t('Reset')}
                  variant={VARIANTS.SECONDARY}
                  onClick={resetFiltersButtonClickHandler}
                />
              )}
            </Styled.Tags>
          ) : null}
        </>
      </Styled.Wrapper>
    </>
  );
};

SearchFilters.propTypes = {
  searchStore: PropTypes.shape({
    applyingFilter: PropTypes.bool,
    advancedSearchFilters: PropTypes.array,
    applySearchFilter: PropTypes.func,
    filters: PropTypes.object,
    resetFiltersButtonClickHandler: PropTypes.func,
    removeFilterTag: PropTypes.func,
  }).isRequired,
};

export default inject('searchStore')(observer(SearchFilters));
