import { makeAutoObservable, runInAction } from 'mobx';
import { API, APIRoutes } from '@app/api';

export class TrackStore {
  constructor() {
    makeAutoObservable(this);
  }

  isModalOpen = false;

  trackDetails = {
    isLoading: false,
    error: null,
    data: {},
  };

  getTrackDetails = async trackId => {
    try {
      this.trackDetails.isLoading = true;

      const {
        data: { track },
      } = await API(APIRoutes.TRACK_DETAILS(trackId));

      runInAction(() => {
        this.trackDetails.data = track;
      });
    } catch (error) {
      runInAction(() => {
        this.trackDetails.error = error;
      });
    } finally {
      runInAction(() => {
        this.trackDetails.isLoading = false;
      });
    }
  };

  openTrackModal = trackId => {
    this.isModalOpen = true;

    this.getTrackDetails(trackId);
  };

  closeTrackModal = () => {
    this.isModalOpen = false;
  };
}

export default new TrackStore();
