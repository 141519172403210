import styled from '@emotion/styled';

export const Text = styled.div`
  border-right: 1px solid black;
  padding-right: 1px;
  height: 16px;
  width: fit-content;
  max-width: 100%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @keyframes blink {
    from {
      border-color: black;
    }
    from {
      border-color: transparent;
    }
  }

  &.blink {
    animation: blink 0.5s linear alternate infinite;
  }
`;
