import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import { Flex } from 'rebass';
import Preloader from '@components/Preloader';
import * as Styled from '@components/TrustedBies/style';

SwiperCore.use([Autoplay]);

const TrustedBies = ({ trustedBiesStore: { getTrustedBies, trustedBies } }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const fetch = async () => {
      await getTrustedBies();
    };
    if (trustedBies.data.length === 0) fetch();
  }, []);

  return (
    (trustedBies.data.length || trustedBies.isLoading) && (
      <Styled.Container>
        <Styled.Subheader>{t('Trusted by')}</Styled.Subheader>
        {trustedBies.isLoading && <Preloader />}
        {trustedBies.data.length &&
          (trustedBies.data.length <= 6 ? (
            <Flex justifyContent="center">
              {trustedBies.data.map(({ name, image }, index) => (
                <Styled.Image
                  src={image?.url}
                  alt={name}
                  key={`trusted_${index}`}
                />
              ))}
            </Flex>
          ) : (
            <Swiper
              slidesPerView={6}
              loop
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 2,
                },
                450: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 5,
                },
                1200: {
                  slidesPerView: 6,
                },
              }}
            >
              {trustedBies.data.map(({ name, image }, index) => (
                <SwiperSlide key={`trusted_${index}`}>
                  <Styled.Image src={image?.url} alt={name} />
                </SwiperSlide>
              ))}
            </Swiper>
          ))}
      </Styled.Container>
    )
  );
};

TrustedBies.propTypes = {
  trustedBiesStore: PropTypes.shape({
    getTrustedBies: PropTypes.func,
    trustedBies: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          image: PropTypes.object,
        }),
      ),
      isLoading: PropTypes.bool,
    }),
  }).isRequired,
};

export default inject('trustedBiesStore')(observer(TrustedBies));
