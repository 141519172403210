import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash-es';

import { StyledInput } from '@containers/BriefingPage/components/style';
import editIon from '@assets/images/icons/edit-icon.svg';

const EditFileButton = ({ onChange }) => {
  const fieldId = useMemo(() => uniqueId(`file-input_`), []);

  return (
    <StyledInput>
      <label htmlFor={fieldId}>
        <img src={editIon} alt="download-icon" />
        <input
          id={fieldId}
          onChange={onChange}
          type="file"
          accept="audio/wav"
        />
      </label>
    </StyledInput>
  );
};

EditFileButton.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default EditFileButton;
