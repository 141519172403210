import styled from '@emotion/styled';
import { Flex } from 'rebass';
import Text from '@components/Text';
import FormButton from '@components/Form/FormButton';
import TextField from '@components/Form/TextField';
import { InputWrapper } from '@components/Form/TextField/style';
import { breakpoint } from '@styles/breakpoints';

export const StyledText = styled(Text)`
  text-decoration: underline;
`;

export const StyledInput = styled.div`
  img {
    cursor: pointer;
  }
  input {
    display: none;
  }
`;

export const FileWrapper = styled(Flex)`
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledFormButton = styled(FormButton)`
  min-width: unset;
`;

export const DescriptionTextarea = styled(TextField)`
  height: 100%;

  textarea {
    height: calc(100% - 15px);
  }

  ${InputWrapper} {
    flex: 1 0;
  }
`;

export const InputRow = styled(Flex)`
  width: 100%;

  @media ${breakpoint.md} {
    flex-direction: column;

    &:not(:last-of-type) {
      border-bottom: 1px solid #dcdcdc;
    }
  }
`;

export const MetaGrid = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: 0.4fr auto;

  @media ${breakpoint.md} {
    grid-template-columns: 1fr;
  }
`;

export const GridItem = styled.div`
  @media ${breakpoint.md} {
    ${({ reorder }) => reorder && `order: -1;`}
  }
`;

export const AltTrackGrid = styled.div`
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: 1fr 1fr 1fr 154px;
  align-items: center;

  @media ${breakpoint.md} {
    grid-template-columns: 1fr;
    margin-bottom: 32px;

    &:not(:last-of-type) {
      border-bottom: 1px solid #dcdcdc;
    }
  }
`;
