import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from '@components/CoverPlaceholder/style';

const CoverPlaceholder = ({ text, size, minWidth }) => {
  const firstChars = text
    .toUpperCase()
    .match(/\b(\w)/g)
    .slice(0, 2);
  return (
    <Styled.Wrapper minWidth={minWidth}>
      <Styled.Characters>
        <Styled.Character size={size}>{firstChars[0]}</Styled.Character>
        <Styled.Character size={size}>{firstChars[1]}</Styled.Character>
      </Styled.Characters>
    </Styled.Wrapper>
  );
};

CoverPlaceholder.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.string,
  minWidth: PropTypes.string,
};

CoverPlaceholder.defaultProps = {
  size: '7rem',
  minWidth: 'auto',
};

export default CoverPlaceholder;
