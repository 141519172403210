import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Flex, Box } from 'rebass';
import { useTranslation } from 'react-i18next';
import Select from '@components/Form/Select';
import { VARIANTS as BTN_VARIANTS } from '@components/Button';
import FormButton from '@components/Form/FormButton';
import TextField, { MARGINS } from '@components/Form/TextField';
import Checkbox from '@components/Form/Checkbox';
import routes from '@routes';
import PasswordField from '@components/Form/PasswordField';
import { StyledLink } from '@styles/globalStyles';
import { BUSINESS_TYPES } from '@root/stores/registrationStore';

const SignUpForm = ({ onSubmit, formStatus, countries, userCountry }) => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    errors: validationErrors,
  } = useForm();
  const { t } = useTranslation();

  const { errors: formErrors, isLoading } = formStatus;

  const errors = {
    ...validationErrors,
    ...formErrors,
  };

  const isOtherBusiness = watch('business_type')?.value === 'other';

  useEffect(() => {
    if (userCountry) {
      reset({
        country: userCountry,
      });
    }
  }, [userCountry]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input
        type="hidden"
        name="confirm_success_url"
        value={`${window.location.origin}${routes.auth.signIn}`}
        ref={register}
      />
      <Flex width={1} alignItems="center" flexDirection="column">
        <Box width={1}>
          <TextField
            name="first_name"
            label={t('First name')}
            placeholder={t('First name')}
            type="text"
            register={register}
            required
            errors={errors}
          />
          <TextField
            name="last_name"
            label={t('Last name')}
            placeholder={t('Last name')}
            type="text"
            register={register}
            required
            errors={errors}
          />
          <Select
            control={control}
            isSearchable
            name="business_type"
            label={t('Business type')}
            placeholder={t('Select business type')}
            options={BUSINESS_TYPES}
            errors={errors}
            required
          />
          <Box display={isOtherBusiness ? 'block' : 'none'}>
            <TextField
              type="text"
              register={register}
              errors={errors}
              name="business_type_other"
              label={t('If ‘other’, please specify')}
              placeholder={t('Details')}
              required={isOtherBusiness}
            />
          </Box>
          <Select
            control={control}
            isSearchable
            name="country"
            label={t('Country')}
            placeholder={t('Select country')}
            options={countries}
            errors={errors}
            required
          />
          <TextField
            name="phone_no"
            label={t('Phone number')}
            placeholder={t('Phone number')}
            type="text"
            register={register}
            errors={errors}
          />
          <TextField
            name="email"
            label={t('E-mail')}
            placeholder={t('E-mail')}
            type="text"
            register={register}
            required
            errors={errors}
          />
          <PasswordField
            name="password"
            placeholder={t('Password')}
            label={t('Password')}
            register={register}
            required
            errors={errors}
          />
          <PasswordField
            name="password_confirmation"
            placeholder={t('Password confirmation')}
            label={t('Password confirmation')}
            autoComplete="new-password"
            register={register}
            required
            errors={errors}
            mb={MARGINS.BIG}
          />
        </Box>

        <Flex mt={-1} width={1} justifyContent="space-between">
          <Checkbox
            name="newsletter_subscribed"
            label={t(
              'Yes, sign me up. Keep me up to date with your latest news',
            )}
            register={register}
            errors={errors}
          />
        </Flex>
        <Box width={1} mb={3}>
          <StyledLink to={routes.agb}>
            {t(
              'By proceeding to create your account and use Intervox you are agreeing to our Terms of Use and Privacy Policy',
            )}
          </StyledLink>
        </Box>
        <Box my={2} textAlign="center">
          <FormButton
            isLoading={isLoading}
            type="submit"
            variant={BTN_VARIANTS.GREEN}
            label={t('Sign up')}
          />
        </Box>
      </Flex>
    </form>
  );
};

SignUpForm.defaultProps = {
  countries: [],
  userCountry: null,
};

SignUpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formStatus: PropTypes.object.isRequired,
  userCountry: PropTypes.object,
  countries: PropTypes.array,
};

export default SignUpForm;
