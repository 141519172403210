import styled from '@emotion/styled';

export const Name = styled.div`
  font-size: 15px;
  margin-top: 15px;
  font-family: ${({ theme }) => theme.fonts.secondary.bold};
`;

export const Role = styled.div`
  font-size: 13px;
  margin-top: 10px;
  font-weight: 300;
`;
