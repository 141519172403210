import { makeAutoObservable, runInAction } from 'mobx';
import qs from 'query-string';
import { map, isEmpty, uniq, groupBy } from 'lodash-es';
import { API, APIRoutes } from '@app/api';
import { authStore, routerStore, sidebarStore } from '@stores';
import routes from '@routes';
import { getItem, KEYS, setItem } from '@utils/storage';
import i18n from '@utils/i18n';
import { SUGGESTION_TYPES } from '@components/SearchSuggestions/components/SuggestionsSection';

export class SearchStore {
  constructor() {
    makeAutoObservable(this);
  }

  applyingFilter = false;

  searchPhrases = {
    isLoading: false,
    data: [],
    error: null,
  };

  searchResults = {
    expandedTrack: null,
    expandedAlternatives: false,
    isLoading: false,
    data: {},
    error: null,
  };

  tracks = this.searchResults.data;

  searchParams = {};

  queryStringArrayFormat = 'comma';

  searchModalIsOpen = false;

  advancedSearch = false;

  showSearchPhrasesSelector = true;

  filters = null;

  suggestions = { isLoading: false, data: {}, error: null };

  currentPage = 1;

  perPage = 25;

  sort = '';

  albums = [];

  mobileSearchIsVisible = false;

  filterPlaceholder = {
    label: null,
    value: null,
    type: null,
    placeholder: null,
  };

  advancedSearchFilters = [
    {
      label: i18n.t('Tracks'),
      value: 'text',
      type: 'text',
      placeholder: i18n.t('Type track name...'),
      showInFilterBox: false,
    },
    {
      label: i18n.t('Keywords'),
      value: 'keywords',
      type: 'async-select',
      placeholder: i18n.t('Type keyword here...'),
      showInFilterBox: false,
    },
    {
      label: i18n.t('Composers'),
      value: 'composers',
      type: 'async-select',
      placeholder: i18n.t('Type composer name...'),
      showInFilterBox: false,
    },
    {
      label: i18n.t('Label'),
      value: 'labels',
      type: 'select',
      dataKey: 'labels',
      placeholder: i18n.t('Label'),
      showInFilterBox: true,
    },
    {
      label: i18n.t('Genre'),
      value: 'genres',
      type: 'select',
      dataKey: 'genres',
      placeholder: i18n.t('Genre'),
      showInFilterBox: true,
    },
    {
      label: i18n.t('Mood'),
      value: 'moods',
      type: 'select',
      dataKey: 'moods',
      placeholder: i18n.t('Mood'),
      showInFilterBox: true,
    },
    {
      label: i18n.t('Tempo'),
      value: 'tempo',
      type: 'select',
      dataKey: 'tempo',
      placeholder: i18n.t('Tempo'),
      showInFilterBox: true,
    },
    {
      label: i18n.t('Topic'),
      value: 'topics',
      type: 'select',
      dataKey: 'topics',
      placeholder: i18n.t('Topic'),
      showInFilterBox: true,
    },
    {
      label: i18n.t('Instrumentation'),
      value: 'instrumentations',
      type: 'select',
      dataKey: 'instrumentations',
      placeholder: i18n.t('Instrumentation'),
      showInFilterBox: true,
    },
  ];

  selectedFilters = [this.advancedSearchFilters[0]];

  setMobileSearchVisibility = isVisible => {
    if (sidebarStore.isMobileOpen) {
      sidebarStore.toggleMobileNavigation();
    }
    this.mobileSearchIsVisible = isVisible;

    if (this.mobileSearchIsVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
      this.advancedSearch = false;
    }
  };

  addFilter = () => {
    this.selectedFilters = [...this.selectedFilters, this.filterPlaceholder];
  };

  removeFilter = (index, unregister) => {
    if (this.selectedFilters.length > 1) {
      unregister(this.selectedFilters[index].value);
      this.selectedFilters[index] = null;
    }
  };

  selectFilter = (filter, index, unregister) => {
    const filters = [...this.selectedFilters];
    if (filters[index]) {
      unregister(filters[index].value);
    }
    filters[index] = filter;
    this.selectedFilters = filters;
  };

  switchToAdvancedSearch = () => {
    this.advancedSearch = true;
  };

  openSearchModal = (advanced = false) => {
    this.searchModalIsOpen = true;
    this.advancedSearch = advanced;
  };

  closeSearchModal = () => {
    setTimeout(() => {
      runInAction(() => {
        this.searchModalIsOpen = false;
      });
    }, 50);
  };

  getSearchPhrases = async () => {
    try {
      this.searchPhrases = { ...this.searchPhrases, isLoading: true };

      const {
        data: { search_phrases },
      } = await API(APIRoutes.SEARCH_PHRASES());
      runInAction(() => {
        this.searchPhrases = {
          ...this.searchPhrases,
          data: search_phrases,
        };
      });
    } catch (error) {
      runInAction(() => {
        this.searchPhrases.error = {
          ...this.searchPhrases,
          error,
        };
      });
    } finally {
      runInAction(() => {
        this.searchPhrases = {
          ...this.searchPhrases,
          isLoading: false,
        };
      });
    }
  };

  updateLocalRecentSearches = text => {
    try {
      if (text) {
        const recentSearches = getItem(KEYS.RECENT_SEARCHES);

        if (recentSearches) {
          let recentSearchesArray = JSON.parse(recentSearches);
          if (!recentSearchesArray.find(rs => rs.uuid === text)) {
            recentSearchesArray.unshift({ uuid: text, name: text });
            recentSearchesArray = recentSearchesArray.slice(0, 14);
            setItem(KEYS.RECENT_SEARCHES, JSON.stringify(recentSearchesArray));
          }
        } else {
          setItem(
            KEYS.RECENT_SEARCHES,
            JSON.stringify([{ uuid: text, name: text }]),
          );
        }
      }
    } catch (error) {
      console.warn(error); // eslint-disable-line
    }
  };

  redirect = filters => {
    try {
      const url = qs.stringifyUrl(
        {
          url: routes.search(''),
          query: filters,
        },
        { arrayFormat: this.queryStringArrayFormat },
      );

      routerStore.push(url);
    } catch (error) {
      console.warn(error); // eslint-disable-line no-console
    }
  };

  // eslint-disable-next-line consistent-return
  applySearchFilter = (filterName, value) => {
    try {
      const filters = { ...this.filters };
      if (filters[filterName]) {
        if (filters[filterName].includes(value)) {
          return null;
        }
        filters[filterName] = uniq([...filters[filterName], value]);
      } else {
        filters[filterName] = [value];
      }
      this.applyingFilter = true;

      this.redirect(filters);
    } catch (error) {
      console.warn(error); // eslint-disable-line no-console
      return null;
    }
  };

  applySearchSuggestion = filter => {
    try {
      const { text, type, uuid } = filter;
      const filters = { ...this.filters };

      if (text) {
        const textParams = text.split(',').map(p => p.trim());
        if (!filters.text) {
          filters.text = [];
        }
        filters.text = uniq([...filters.text, ...textParams]);

        // save text searches to recent searches
        if (!authStore.isAuthenticated) {
          this.updateLocalRecentSearches(text);
        }
      }

      if (type) {
        switch (type) {
          case SUGGESTION_TYPES.MOODS:
            if (filters.moods) {
              filters.moods = uniq([...filters.moods, uuid]);
            } else {
              filters.moods = [uuid];
            }
            break;
          case SUGGESTION_TYPES.RECENT_SEARCHES:
            if (filters.text) {
              filters.text = uniq([...filters.text, uuid]);
            } else {
              filters.text = [uuid];
            }
            break;
          case SUGGESTION_TYPES.KEYWORDS:
            if (filters.keywords) {
              filters.keywords = uniq([...filters.keywords, uuid]);
            } else {
              filters.keywords = [uuid];
            }
            break;
          case SUGGESTION_TYPES.GENRES:
            if (filters.genres) {
              filters.genres = uniq([...filters.genres, uuid]);
            } else {
              filters.genres = [uuid];
            }
            break;
          case SUGGESTION_TYPES.INSTRUMENTATIONS:
            if (filters.instrumentations) {
              filters.instrumentations = uniq([
                ...filters.instrumentations,
                uuid,
              ]);
            } else {
              filters.instrumentations = [uuid];
            }
            break;
          default:
            break;
        }
      }
      this.redirect(filters);
    } catch (error) {
      console.warn(error); // eslint-disable-line no-console
    }
  };

  getSearchSuggestions = async (query = '') => {
    this.suggestions.isLoading = true;

    try {
      const { data } = await API.get(APIRoutes.SEARCH_SUGGESTED(query));
      // use localstorage recent searches while user isn;t logged in
      if (!authStore.isAuthenticated && getItem(KEYS.RECENT_SEARCHES)) {
        data.recent_searches = JSON.parse(getItem(KEYS.RECENT_SEARCHES)) || [];
      }

      runInAction(() => {
        this.suggestions.data = data;
      });
    } catch (error) {
      runInAction(() => {
        this.suggestions.error = error;
      });
    } finally {
      runInAction(() => {
        this.suggestions.isLoading = false;
      });
    }
  };

  toggleShowSearchPhrasesSelector = isVisible => {
    this.showSearchPhrasesSelector = isVisible;
  };

  submitAdvancedSearch = formData => {
    const data = {};
    const filters = { ...this.filters };

    map(formData, (param, key) => {
      if (Array.isArray(param)) {
        data[key] = param.map(p => p.value);
      } else {
        const params = param.split(',');
        data[key] = params.map(p => p.trim());
      }
    });

    map(data, (values, key) => {
      if (filters[key]) {
        filters[key] = uniq([...filters[key], ...values]);
      } else {
        filters[key] = uniq(values);
      }
    });

    this.redirect(filters);
  };

  processSearch = async (searchParams, sort) => {
    this.showSearchPhrasesSelector = false;

    const parsedSearchParams = qs.parse(searchParams, {
      arrayFormat: this.queryStringArrayFormat,
    });

    const filters = {};

    if (!isEmpty(parsedSearchParams)) {
      // always use arrays
      map(parsedSearchParams, (sp, key) => {
        if (!Array.isArray(sp)) {
          filters[key] = [sp];
        } else {
          filters[key] = sp;
        }
      });

      this.filters = filters;
    }

    if (sort !== this.sort) {
      this.sort = sort;
      this.currentPage = 1;

      if (sort === 'albums') {
        this.perPage = 150;
      } else {
        this.perPage = 25;
      }
    }

    await this.fetchSearchResults();
  };

  searchParams = () => {
    let searchParams = [];
    map(this.filters, (values, filter) => {
      const paramName = `q[${filter}][]`;
      const params = values.map(p => `${paramName}=${p}`);
      searchParams = [...searchParams, ...params];
    });

    return searchParams.join('&');
  };

  fetchSearchResults = async () => {
    try {
      this.searchResults.isLoading = true;

      const { data } = await API.get(
        APIRoutes.SEARCH(
          this.currentPage,
          this.perPage,
          this.searchParams(),
          this.sort,
        ),
      );

      runInAction(() => {
        this.searchResults = { ...this.searchResults, data };

        if (this.sort === 'albums') {
          this.groupTracksByAlbums();
        }
      });
    } catch (error) {
      console.warn(error); // eslint-disable-line no-console
    } finally {
      runInAction(() => {
        this.searchResults.isLoading = false;
        this.applyingFilter = false;
      });
    }
  };

  fetchMore = async () => {
    try {
      this.searchResults.isLoading = true;
      this.currentPage += 1;

      const { data } = await API.get(
        APIRoutes.SEARCH(
          this.currentPage,
          this.perPage,
          this.searchParams(),
          this.sort,
        ),
      );

      runInAction(() => {
        this.searchResults = {
          ...this.searchResults,
          data: {
            tracks: [...this.searchResults.data.tracks, ...data.tracks],
            meta: data.meta,
          },
        };

        if (this.sort === 'albums') {
          this.groupTracksByAlbums();
        }
      });
    } catch (err) {
      console.warn(err); // eslint-disable-line
    } finally {
      runInAction(() => {
        this.searchResults.isLoading = false;
      });
    }
  };

  removeFilterTag = (group, index) => {
    try {
      this.filters[group].splice(index, 1);

      if (isEmpty(this.filters[group])) {
        delete this.filters[group];
      }

      this.redirect(this.filters);
    } catch (err) {
      console.warn(err); // eslint-disable-line no-console
    }
  };

  resetFilters = () => {
    this.filters = null;
  };

  resetFiltersButtonClickHandler = () => {
    this.resetFilters();
    routerStore.push(routes.search(''));
  };

  resetAdvancedForm = unregister => {
    this.selectedFilters.forEach(filter => {
      if (filter !== null && filter.value) unregister(filter.value);
    });
    this.selectedFilters = [this.advancedSearchFilters[0]];
  };

  toggleTrack = trackId => {
    const { expandedTrack } = this.searchResults;
    this.searchResults.expandedTrack =
      expandedTrack === trackId ? null : trackId;
  };

  toggleTrackAlternatives = trackId => {
    const { expandedTrack, expandedAlternatives } = this.searchResults;

    if (expandedTrack === trackId) {
      if (expandedAlternatives) {
        this.searchResults.expandedAlternatives = false;
      } else {
        this.searchResults.expandedAlternatives = true;
        this.getTrackAlternatives(trackId);
      }
    } else {
      this.searchResults.expandedTrack = trackId;
      this.searchResults.expandedAlternatives = true;
      this.getTrackAlternatives(trackId);
    }
  };

  getTrackAlternatives = async trackId => {
    const track = this.searchResults.data.tracks.find(
      ({ id }) => id === trackId,
    );

    if (!track.alternatives) {
      try {
        track.alternatives = { isLoading: true };

        const {
          data: { tracks },
        } = await API(APIRoutes.TRACK_ALTERNATIVES(trackId));

        const tracksWithAlbum = tracks.map(t => ({ ...t, album: track.album }));

        runInAction(() => {
          track.alternatives.data = tracksWithAlbum;
        });
      } catch (error) {
        runInAction(() => {
          track.alternatives.error = error;
        });
      } finally {
        runInAction(() => {
          track.alternatives.isLoading = false;
        });
      }
    }
  };

  groupTracksByAlbums = () => {
    const groupedTracks = groupBy(this.searchResults.data.tracks, el => {
      return el.album.directory_name;
    });

    this.albums = map(groupedTracks, tracks => ({
      ...tracks[0].album,
      tracksIds: tracks.map(({ id }) => id),
    }));
  };
}

export default new SearchStore();
