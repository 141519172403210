import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { uniqueId } from 'lodash-es';
import { Flex } from 'rebass';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { VARIANTS } from '@components/Button';
import { Header, MainContent } from '@styles/globalStyles';
import Tile from '@components/Tile';
import GridList from '@components/GridList';
import Preloader from '@components/Preloader';
import NotFound from '@containers/NotFound';
import FormButton from '@components/Form/FormButton';
import { ALBUMS_TYPES } from '@root/stores/albumsStore';

const Albums = ({
  albumsStore: { getAlbumsOf, updateAlbumsOf, albums },
  topicsStore: { getTopic },
  listOnly,
  label,
  type,
}) => {
  const isMounted = useRef(true);
  const [errors, setErrors] = useState(null);
  const { t } = useTranslation();
  const defaultTitle = t('Latest album releases');
  const [title, setTitle] = useState(defaultTitle);
  const { id: topic } = useParams();

  // eslint-disable-next-line no-return-assign
  useEffect(() => () => (isMounted.current = false), []);

  useEffect(() => {
    if (isMounted.current) {
      setErrors(null);
      if (
        albums.type !== type ||
        (albums.topic && albums.topic !== topic) ||
        (albums.label && albums.label !== label)
      ) {
        getAlbumsOf()[type]({ label, topic });
      }

      if (type === ALBUMS_TYPES.TOPICS) {
        setTitleFromTopic();
      } else {
        setTitle(defaultTitle);
      }
    }
  }, [getAlbumsOf, albums, type, topic, label]);

  const setTitleFromTopic = async () => {
    try {
      const { name } = await getTopic(topic);
      setTitle(name);
    } catch (error) {
      setErrors(error);
    }
  };

  const updateAlbumsStore = () => updateAlbumsOf()[type]({ topic, label });

  const list = (
    <Flex flexDirection="column" alignContent="center">
      {albums.isLoading && <Preloader center />}
      <GridList>
        {albums.data.map(album => (
          <li key={uniqueId()}>
            <Tile {...album} isAlbum />
          </li>
        ))}
      </GridList>
      {albums.data.length > 0 && !albums.last && (
        <Flex margin="auto">
          <FormButton
            isLoading={albums.isLoading}
            variant={VARIANTS.GREEN}
            label={t('Show more')}
            onClick={updateAlbumsStore}
          />
        </Flex>
      )}
    </Flex>
  );

  const page = (
    <MainContent>
      <Header>{title}</Header>
      {list}
    </MainContent>
  );

  const component = listOnly ? list : page;

  if (albums.error || errors) {
    return <NotFound />;
  }
  return component;
};

Albums.propTypes = {
  albumsStore: PropTypes.shape({
    getAlbums: PropTypes.func,
    getAlbumsOfTopic: PropTypes.func,
    albumsOfTopic: PropTypes.object,
    getAlbumsOfLabel: PropTypes.func,
    albumsOfLabel: PropTypes.object,
    albums: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          cover: PropTypes.object,
          uuid: PropTypes.string,
        }),
      ),
      isLoading: PropTypes.bool,
      error: PropTypes.any,
    }),
  }).isRequired,
  topicsStore: PropTypes.shape({
    getTopic: PropTypes.func,
  }).isRequired,
  listOnly: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.oneOf(Object.values(ALBUMS_TYPES)),
};

Albums.defaultProps = {
  listOnly: false,
  label: undefined,
  type: ALBUMS_TYPES.ALBUMS,
};

export default inject('albumsStore', 'topicsStore')(observer(Albums));
