import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import { useTranslation } from 'react-i18next';
import SectionTitle from '@components/SectionTitle';
import i18n from '@utils/i18n';
import Table from '@components/Table';

const COLUMNS = [
  {
    title: i18n.t('First name'),
    field: 'first_name',
  },
  {
    title: i18n.t('Last name'),
    field: 'last_name',
  },
  {
    title: i18n.t('IPI number'),
    field: 'cae_ipi',
  },
  {
    title: i18n.t('PRO'),
    field: 'pro',
  },
  {
    title: i18n.t('MRO'),
    field: 'mro',
  },
];

const ComposersTab = ({ customer }) => {
  const { t } = useTranslation();

  const {
    composers_customers,
    arrangers_customers,
    customers_lyricists,
  } = customer;

  const composersData = composers_customers.map(({ composer }) => composer);
  const arrangersData = arrangers_customers.map(({ arranger }) => arranger);
  const lyricistsData = customers_lyricists.map(({ lyricist }) => lyricist);

  return (
    <>
      <Box width={1}>
        <SectionTitle>{t('Composers')}</SectionTitle>
      </Box>
      <Table columns={COLUMNS} data={composersData} />

      <Box width={1} mt={5}>
        <SectionTitle>{t('Arrangers')}</SectionTitle>
      </Box>
      <Table columns={COLUMNS} data={arrangersData} />

      <Box width={1} mt={5}>
        <SectionTitle>{t('Lyricists')}</SectionTitle>
      </Box>
      <Table columns={COLUMNS} data={lyricistsData} />
    </>
  );
};

ComposersTab.defaultProps = {};

ComposersTab.propTypes = {
  customer: PropTypes.object.isRequired,
};

export default ComposersTab;
