import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import AsyncReactSelect from 'react-select/async';
import { Controller } from 'react-hook-form';
import { uniqueId } from 'lodash-es';
import { useTheme } from '@emotion/react';
import { Asterix, Error, FieldWrapper, Label } from '@components/Form/style';
import { InputWrapper } from '@components/Form/Select/style';
import i18n from '@utils/i18n';
import { API } from '@app/api';
import { variantStyles, components, VARIANTS } from '@components/Form/Select';
import { MARGINS } from '@styles/globalStyles';

const AsyncSelect = ({
  name,
  label,
  options,
  control,
  isSearchable,
  required,
  style,
  errors,
  defaultValue,
  mb,
  placeholder,
  isMulti,
  variant,
  optionsUrl,
  optionValueKey,
  optionLabelKey,
  overrides,
}) => {
  const theme = useTheme();
  const fieldId = useMemo(() => uniqueId(`input_${name}_`), []);
  const [loadingOptions, setLoadingOptions] = useState(false);

  const loadOptionsForDropdown = async value => {
    setLoadingOptions(true);
    try {
      const { data } = await API.get(optionsUrl(value));

      const optionsForDropdown = data[name].map(d => {
        let optionLabel;
        if (Array.isArray(optionLabelKey)) {
          optionLabel = optionLabelKey.map(key => d[key]);
          optionLabel = optionLabel.join(' ');
        } else {
          optionLabel = d[optionLabelKey];
        }

        return {
          value: d[optionValueKey],
          label: optionLabel,
        };
      });

      return optionsForDropdown;
    } catch (error) {
      console.warn(error); // eslint-disable-line no-console
    } finally {
      setLoadingOptions(false);
    }

    return null;
  };

  return (
    <FieldWrapper style={style} mb={mb}>
      {label ? (
        <Label htmlFor={fieldId}>
          {label} {required ? <Asterix>*</Asterix> : null}
        </Label>
      ) : null}
      <InputWrapper>
        <Controller
          {...{
            control,
            name,
            options,
            isSearchable,
            isMulti,
            components,
            defaultValue,
            placeholder,
            async: true,
            isLoading: loadingOptions,
            loadOptions: loadOptionsForDropdown,
            cacheOptions: true,
            isClearable: false,
            rules: {
              required: {
                value: required,
                message: i18n.t('Required'),
              },
            },
            styles: variantStyles[variant](theme, errors[name], overrides),
            as: AsyncReactSelect,
          }}
        />
      </InputWrapper>
      {errors[name] ? <Error>{errors[name].message}</Error> : null}
    </FieldWrapper>
  );
};

AsyncSelect.defaultProps = {
  isSearchable: true,
  required: false,
  label: '',
  mb: MARGINS.SMALL,
  style: {},
  errors: {},
  defaultValue: null,
  placeholder: null,
  isMulti: false,
  variant: VARIANTS.DEFAULT,
  overrides: {},
};

AsyncSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  control: PropTypes.object.isRequired,
  isSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
  required: PropTypes.bool,
  mb: PropTypes.oneOf(Object.values(MARGINS)),
  style: PropTypes.object,
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  errors: PropTypes.object,
  defaultValue: PropTypes.object,
  placeholder: PropTypes.string,
  optionsUrl: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
    .isRequired,
  optionValueKey: PropTypes.string.isRequired,
  optionLabelKey: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,
  overrides: PropTypes.shape({
    control: PropTypes.object,
    menu: PropTypes.object,
  }),
};

export default AsyncSelect;
