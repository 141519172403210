import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash-es';
import { Wrapper } from '@components/ListingHeaderWrapper/style';

const ListingHeaderWrapper = ({ children }) => {
  const [isFixed, setIsFixed] = useState(false);

  const handleScroll = throttle(() => {
    const { scrollY } = window;
    if (scrollY > 25) {
      setIsFixed(true);
    } else if (scrollY < 15) {
      setIsFixed(false);
    }
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Wrapper isFixed={isFixed}>
      {React.cloneElement(children, { isFixed })}
    </Wrapper>
  );
};

ListingHeaderWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ListingHeaderWrapper;
