import React from 'react';
import PropTypes from 'prop-types';
import {
  OptionDetails,
  OptionWrapper,
} from '@containers/LicenseApplication/components/style';

const TrackTitleOptionLabel = ({
  album: { directory_name },
  name,
  composers,
  asOption,
}) => {
  const composersNames = composers.map(
    ({ first_name, last_name }, index) =>
      `${first_name} ${last_name}${index < composers.length - 1 ? ', ' : ''}`,
  );

  return (
    <OptionWrapper asValue={!asOption}>
      <span>{name} </span>
      {asOption && <br />}
      <OptionDetails>{directory_name}</OptionDetails>{' '}
      <OptionDetails>({composersNames})</OptionDetails>
    </OptionWrapper>
  );
};

TrackTitleOptionLabel.defaultProps = {
  asOption: false,
};

TrackTitleOptionLabel.propTypes = {
  album: PropTypes.shape({
    directory_name: PropTypes.string,
  }).isRequired,
  name: PropTypes.string.isRequired,
  composers: PropTypes.arrayOf(
    PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
  ).isRequired,
  asOption: PropTypes.bool,
};

export default TrackTitleOptionLabel;
