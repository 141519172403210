import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { PROVIDERS } from '@stores/authStore';
import { Box, Row, Separator } from '@components/OAuthBox/style';
import FacebookButton from './FacebookButton';
import GoogleButton from './GoogleButton';

const OAuthBox = ({ authStore: { oAuthSignIn } }) => {
  const { t } = useTranslation();
  const responseFacebook = response =>
    oAuthSignIn(response, PROVIDERS.FACEBOOK);
  const responseGoogle = response => oAuthSignIn(response, PROVIDERS.GOOGLE);
  const oAuthFailure = () =>
    toast(t('There was a problem with login. Try again later.'));

  return (
    <Box>
      <Row>
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          fields="first_name, last_name, email"
          scope="public_profile"
          callback={responseFacebook}
          onFailure={oAuthFailure}
          render={({ onClick, isDisabled }) => (
            <FacebookButton onClick={onClick} disabled={isDisabled} />
          )}
          disableMobileRedirect
        />
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          scope="profile email"
          fields="first_name, last_name, email"
          onSuccess={responseGoogle}
          onFailure={oAuthFailure}
          cookiePolicy="single_host_origin"
          autoLoad={false}
          render={renderProps => (
            <GoogleButton
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            />
          )}
        />
      </Row>
      <Separator>
        <span>{t('OR')}</span>
      </Separator>
    </Box>
  );
};

OAuthBox.propTypes = {
  authStore: PropTypes.shape({ oAuthSignIn: PropTypes.func }).isRequired,
};

export default inject('authStore')(observer(OAuthBox));
