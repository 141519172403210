import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import { inject, observer } from 'mobx-react';

import i18n from '@utils/i18n';
import Card from '@components/Card';
import Step from '@containers/LicenseApplication/components/Step';
import StepsIndicator from '@components/StepsIndicators';

import ClientDetailsStep from '@containers/LicenseApplication/components/ClientDetailsStep';
import TypesUsageStep from '@containers/LicenseApplication/components/TypesUsageStep';
import TrackDetailsStep from '@containers/LicenseApplication/components/TrackDetailsStep';

export const LICENSE_APPLICATION_STEPS = [
  {
    name: i18n.t('Contact details'),
    Component: ClientDetailsStep,
  },
  {
    name: i18n.t('Types & usage'),
    Component: TypesUsageStep,
  },
  {
    name: i18n.t('Track details'),
    Component: TrackDetailsStep,
  },
];

const LicenseForm = ({
  licensesStore: {
    licenseForm: { formErrors, isLoading, currentStep, stepsData },
    submitLicenseApplication,
    setPreviousStep,
  },
  kind,
}) => {
  const ref = useRef(null);
  const StepComponent = LICENSE_APPLICATION_STEPS[currentStep].Component;

  useEffect(() => {
    if (ref.current) {
      const yOffset = -162; // navbar height

      const y =
        ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y });
    }
  }, [currentStep]);

  return (
    <div>
      <Card>
        <Box width={[1, 1, 1, 1 / 2]} margin="0 auto" ref={ref}>
          <StepsIndicator
            steps={LICENSE_APPLICATION_STEPS}
            currentStep={currentStep}
          />
        </Box>

        <Step
          {...{
            key: `license-app-${currentStep}`,
            formErrors,
            isLoading,
            currentStep,
            stepsLength: LICENSE_APPLICATION_STEPS.length,
            onSubmit: data => submitLicenseApplication(data, currentStep),
            onBack: setPreviousStep,
            initialData: stepsData[currentStep],
          }}
        >
          <StepComponent {...{ kind }} />
        </Step>
      </Card>
    </div>
  );
};

LicenseForm.defaultProps = {
  kind: '',
};

LicenseForm.propTypes = {
  kind: PropTypes.string,
  licensesStore: PropTypes.shape({
    licenseForm: PropTypes.shape({
      formErrors: PropTypes.object,
      isLoading: PropTypes.bool,
      currentStep: PropTypes.number,
      stepsData: PropTypes.array,
    }),
    submitLicenseApplication: PropTypes.func,
    setPreviousStep: PropTypes.func,
  }).isRequired,
};

export default inject('licensesStore')(observer(LicenseForm));
