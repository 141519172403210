import styled from '@emotion/styled';

export const Wrapper = styled.div`
  flex: 1;
  max-width: 704px;
  width: 70%;
  padding-right: 30px;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const SearchButton = styled.button`
  display: inline-flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 12px;
`;

export const MenuButton = styled.button`
  display: inline-flex;
  width: 42px;
  height: 42px;
  margin-right: 5px;
`;

export const SearchWrapper = styled.div`
  position: relative;
`;

export const DynamicContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: ${({ isSearchResultPage }) => (isSearchResultPage ? 95 : 200)}px;
  padding-left: 18px;

  transform: translateY(-50%);

  pointer-events: none;
`;

export const SearchDropDown = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  left: 0;
  z-index: 99;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 7px;
  box-shadow: ${({ theme }) => theme.shadows.primary};
`;
