import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import useBreakpoint from '@hooks/useBreakpoint';

const InputGrid = ({ children, cols, ...boxProps }) => {
  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();

  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  const layout = isMobile
    ? 'repeat(auto-fit)'
    : new Array(cols).fill('1fr').join(' ');

  return (
    <Box
      sx={{
        display: 'grid',
        gridGap: isMobile ? 2 : 3,
        gridTemplateColumns: layout,
      }}
      {...boxProps}
    >
      {children}
    </Box>
  );
};

InputGrid.defaultProps = {
  cols: 3,
};

InputGrid.propTypes = {
  cols: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default InputGrid;
