import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Text, { COLORS, VARIANTS } from '@components/Text';
import * as Styled from '@containers/NotFound/style';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <Styled.Wrapper>
      <Styled.Header>404</Styled.Header>
      <Styled.Description variant={VARIANTS.H4_REGULAR}>
        {t("Sorry, we couldn't find this page. ")}
        <Link to="/">
          <Text variant={VARIANTS.H4_REGULAR} color={COLORS.BLUE}>
            {t('Go to home page.')}
          </Text>
        </Link>
      </Styled.Description>
    </Styled.Wrapper>
  );
};

export default NotFound;
