import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import styled from '@emotion/styled';

import { components, variantStyles } from '@components/Form/Select';
import theme from '@styles/theme';
import { Label } from '@components/Form/style';
import { breakpoint } from '@styles/breakpoints';

const SelectWrapper = styled.div`
  width: 100%;
  min-width: 260px;
  max-width: 335px;

  @media ${breakpoint.md} {
    max-width: unset;
  }
`;

const TracksFilter = ({ onChange }) => {
  const { t } = useTranslation();

  const FILTERS = [
    {
      value: 'composers',
      label: t('Composers'),
    },
    { value: 'lyricists', label: t('Lyricists') },
    { value: 'arrangers', label: t('Arrangers') },
  ];

  return (
    <SelectWrapper>
      <Label>{t('Filter tracks')}</Label>
      <ReactSelect
        options={FILTERS}
        defaultValue={FILTERS[0]}
        onChange={v => onChange({ kind: v.value })}
        styles={variantStyles.DEFAULT(theme)}
        components={components}
        name="kind"
      />
    </SelectWrapper>
  );
};

TracksFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default TracksFilter;
