/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import { useTranslation } from 'react-i18next';

import {
  Header,
  Controls,
  Details,
  DescriptionBox,
} from '@components/ListingHeaderWrapper/style';
import Text from '@components/Text';
import ShareButton from '@components/IconButtons/ShareButton';
import PlayPauseButton, {
  PLAYER_CONTEXTS,
  STYLE,
} from '@components/PlayerControlButtons/PlayPause';
import Avatar from '@components/Avatar';

const ComposerHeader = ({
  coverUrl,
  name,
  description,
  isFixed,
  track,
  id,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        mr={3}
        width={isFixed ? 50 : 200}
        sx={{
          transition: 'width 0.3s',
        }}
      >
        <Avatar image={coverUrl} alt="composer avatar" />
      </Box>
      <Details
        isFixed={isFixed}
        sx={{
          width: isFixed ? 'auto' : '50% !important',
        }}
      >
        <DescriptionBox>
          <Header isFixed={isFixed}>{name}</Header>
          {!isFixed && <Text>{description}</Text>}
        </DescriptionBox>
        <Controls isFixed={isFixed}>
          {track ? (
            <PlayPauseButton
              context={PLAYER_CONTEXTS.PLAYLISTS}
              outlined={false}
              track={track}
              style={isFixed ? STYLE.DEFAULT : STYLE.CIRCLE}
              playlistId={id}
            />
          ) : null}
          <ShareButton
            copyText={window.location.href}
            notification={t("Playlist's url has been copied to clipboard")}
          />
        </Controls>
      </Details>
    </>
  );
};

ComposerHeader.defaultProps = {
  coverUrl: '',
  name: '',
  description: '',
  isFixed: false,
  track: null,
};

ComposerHeader.propTypes = {
  coverUrl: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  isFixed: PropTypes.bool,
  track: PropTypes.object,
};

export default ComposerHeader;
