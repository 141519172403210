import styled from '@emotion/styled';
import { Flex } from 'rebass';
import Text from '@components/Text';
import { breakpoint } from '@styles/breakpoints';

export const Toggle = styled.button`
  position: absolute;
  top: -12px;
  right: 48px;

  img {
    transform: rotate(135deg);
  }
`;

export const MobileToggle = styled.button`
  img {
    transform: rotate(135deg);
  }
`;

export const Wrapper = styled.div`
  position: relative;
  z-index: 10;

  .slide_up {
    &-enter {
      opacity: 0;
      bottom: -100px;
    }

    &-enter-active {
      opacity: 1;
      bottom: 0;
      transition: opacity 300ms, bottom 300ms;
    }

    &-enter-done {
      opacity: 1;
      bottom: 0;
    }

    &-exit {
      opacity: 1;
      bottom: 0;
    }

    &-exit-active {
      opacity: 0;
      bottom: -100px;
      transition: opacity 300ms, bottom 300ms;
    }

    &-exit-done {
      opacity: 0;
      bottom: -100px;
      pointer-events: none;
    }
  }
`;

export const DetailsContainerWrapper = styled.div`
  filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.1));
  position: relative;
`;

export const DetailsContainer = styled(Flex)`
  position: relative;
  width: 304px;
  padding: ${({ padding }) => padding};
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 35px, calc(100% - 35px) 0);
  background-color: #fff;
  height: 100%;
`;

export const ControlsContainer = styled(Flex)`
  flex-grow: 1;
  align-items: center;
`;

export const WaveContainer = styled(Flex)`
  flex-grow: 1;
  align-items: center;
  position: relative;
`;

export const Waveform = styled.div`
  flex: 1;
  margin: 0 10px;
`;

export const OptionsContainer = styled(Flex)`
  padding: 0 35px;
`;

export const Button = styled.div`
  padding: 5px;
`;

export const TooltipText = styled(Text)`
  text-align: center;
  width: 100%;
`;

export const Container = styled(Flex)`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 96px;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 99;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);

  @media ${breakpoint.md} {
    flex-direction: column;
    height: unset;
    border-radius: 0;

    ${DetailsContainer} {
      width: 100%;
    }

    ${ControlsContainer} {
      height: 80px;
    }

    ${WaveContainer} {
      display: none;
    }

    ${OptionsContainer} {
      flex: 1;
      justify-content: flex-end;
      padding: 0 16px;

      img:first-of-type {
        margin: 0 5px;
      }
    }

    ${DetailsContainerWrapper} {
      filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.15));
    }
  }
`;
