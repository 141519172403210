import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Box } from 'rebass';
import { Helmet } from 'react-helmet';
import ContactForm, { VARIANTS } from '@components/ContactForm';
import {
  PageContent,
  PageWrapper,
  FormWrapper,
} from '@components/ContactForm/style';
import Header from '@components/Header';
import { MainContent } from '@styles/globalStyles';
import Card from '@components/Card';
import Text, { VARIANTS as TEXT_VARIANTS } from '@components/Text';
import CmsBody, { VARIANTS as CMS_VARIANTS } from '@components/CmsBody';

const RequestMusic = ({
  customerStore: {
    customer: { data },
  },
  contactStore: {
    onRequestMusicSubmit,
    requestMusicForm,
    clearForms,
    getRequestMusicContent,
    requestMusicPage,
  },
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    getRequestMusicContent();

    return () => clearForms();
  }, []);

  const initialData = data
    ? {
        full_name: `${data.first_name} ${data.last_name}`,
        email: data.email,
        company: data.company || '',
      }
    : null;

  return (
    <MainContent>
      <Helmet>
        <title>{t('Request music')}</title>
      </Helmet>
      <Header title={t('Request music')} withSeparator />

      <PageWrapper>
        <PageContent>
          <CmsBody
            html={requestMusicPage.data.body}
            isLoading={requestMusicPage.isLoading}
            error={requestMusicPage.error}
            variant={CMS_VARIANTS.CONTACT}
          />
        </PageContent>
        <FormWrapper>
          <Card>
            <Box mb={4}>
              <Box mb={3}>
                <Text
                  variant={TEXT_VARIANTS.H4}
                  style={{ textTransform: 'initial' }}
                >
                  {t('Tell us what you’re looking for')}
                </Text>
              </Box>

              <Text variant={TEXT_VARIANTS.BODY}>
                {t(
                  // eslint-disable-next-line max-len
                  'Fill in the form with your project’s details (the more the better!) If you are already registered on our website, you will find our music selection in MY PROJECTS.',
                )}
              </Text>
            </Box>
            <ContactForm
              onSubmit={onRequestMusicSubmit}
              formStatus={toJS(requestMusicForm)}
              variant={VARIANTS.REQUEST_MUSIC}
              initialData={initialData}
            />
          </Card>
        </FormWrapper>
      </PageWrapper>
    </MainContent>
  );
};

RequestMusic.propTypes = {
  contactStore: PropTypes.object.isRequired,
  customerStore: PropTypes.object.isRequired,
};

export default inject('contactStore', 'customerStore')(observer(RequestMusic));
