import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import { useTranslation } from 'react-i18next';
import SectionTitle from '@components/SectionTitle';
import PasswordField from '@components/Form/PasswordField';

const PasswordTab = ({ register, errors }) => {
  const { t } = useTranslation();

  return (
    <Flex flexWrap="wrap" mx={-3} mt={3}>
      <Box width={[1, 1, 1, 1 / 2]} px={3}>
        <SectionTitle>{t('Password')}</SectionTitle>
        <Box width={1} mb={4}>
          <PasswordField
            name="current_password"
            label={t('Current password')}
            placeholder={t('Current password')}
            autoComplete="current-password"
            register={register}
            errors={errors}
          />
          <PasswordField
            name="password"
            label={t('Password')}
            placeholder={t('New password')}
            autoComplete="new-password"
            register={register}
            errors={errors}
          />
          <PasswordField
            name="password_confirmation"
            label={t('Password confirmation')}
            placeholder={t('Confirm new password')}
            autoComplete="new-password"
            register={register}
            errors={errors}
          />
        </Box>
      </Box>
    </Flex>
  );
};

PasswordTab.defaultProps = {
  errors: {},
};

PasswordTab.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

export default PasswordTab;
