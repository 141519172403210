/* eslint-disable import/no-extraneous-dependencies */

import React from 'react';
import { Global, css } from '@emotion/react';
import reset from 'emotion-reset';
import { Link } from 'react-router-dom';

import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

import PatternSideLeftTexture from '@assets/images/backgrounds/background-pattern-left-side.svg';
import XIcon from '@assets/images/backgrounds/x-vector.svg';
import PatternTopRight from '@assets/images/backgrounds/background-pattern-top-right.svg';
import PatternTopLeft from '@assets/images/backgrounds/background-pattern-top-left.svg';

const GlobalStyle = () => {
  return (
    <Global
      styles={css`
        @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

        ${reset}

        *,
        *:after,
        *:before {
          box-sizing: border-box;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        body {
          color: #000;
          font-family: 'Roboto', sans-serif;

          @media ${breakpoint.md} {
            padding-top: 53px;
          }
        }

        input,
        textarea {
          font-family: 'Roboto', sans-serif;
        }

        @font-face {
          font-family: 'PTMono-Bold';
          src: url('PTMono-Bold.eot');
          src: url('/fonts/PTMono-Bold.woff2') format('woff2'),
            url('/fonts/PTMono-Bold.woff') format('woff'),
            url('/fonts/PTMono-Bold.ttf') format('truetype'),
            url('/fonts/PTMono-Bold.eot?#iefix') format('embedded-opentype');
        }

        @font-face {
          font-family: 'PTMono-Regular';
          src: url('PTMono-Regular.eot');
          src: url('/fonts/PTMono-Regular.woff2') format('woff2'),
            url('/fonts/PTMono-Regular.woff') format('woff'),
            url('/fonts/PTMono-Regular.ttf') format('truetype'),
            url('/fonts/PTMono-Regular.eot?#iefix') format('embedded-opentype');
        }

        a {
          text-decoration: none;
          color: inherit;
        }

        button {
          padding: 0;
          margin: 0;
          background-color: transparent;
          border: none;
          cursor: pointer;
          font-family: 'Roboto', sans-serif;

          &:focus {
            outline: none;
          }

          &[disabled] {
            opacity: 0.5;
            cursor: default;
          }
        }

        .body-scroll-lock {
          position: fixed;
          width: 100%;
          left: 0;
        }

        .swiper-news-section {
          max-height: 330px;
          height: 100%;
          width: 100%;
          .swiper-wrapper {
            border-top: 1px solid rgba(133, 133, 133, 0.5);
            margin-top: 3px;
            .swiper-slide {
              height: auto !important;
            }
          }
        }

        .sticky-outer-wrapper {
          z-index: 6;
        }

        .portal-modal-wrapper {
          position: fixed;
          top: 0;
          left: 0;
        }

        .modal-visible.portal-modal-wrapper {
          display: flex;
          position: fixed;
          z-index: 900;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 50px 0;
          overflow: hidden auto;
        }

        .main-player-visible {
          padding-bottom: 87px;

          @media ${breakpoint.md} {
            padding-bottom: 80px;
          }
        }

        .fade_in-enter {
          opacity: 0;
        }
        .fade_in-enter-active {
          opacity: 1;
          transition: opacity 300ms;
        }
        .fade_in-exit {
          opacity: 1;
        }
        .fade_in-exit-active {
          opacity: 0;
          transition: opacity 300ms;
        }

        .jump_in-enter {
          opacity: 0;
          transform: translate3d(0, -10px, 0);
          margin-top: -10px;
        }
        .jump_in-enter-active {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          transition: opacity 300ms, transform 300ms, margin-top 300ms;
          margin-top: 0px;
        }
        .jump_in-exit {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          margin-top: 0px;
        }
        .jump_in-exit-active {
          opacity: 0;
          transform: translate3d(0, -10px, 0);
          transition: opacity 300ms, transform 300ms, margin-top 300ms;
          margin-top: -10px;
        }
      `}
    />
  );
};

export const BACKGROUND_VARIANTS = {
  PRIMARY: 'PRIMARY',
};

export const MainContent = styled.main`
  padding: ${({ theme }) => theme.page.mainContentPadding};
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  ${({ isHomepage, theme }) =>
    isHomepage
      ? `
          &:before {
            position: absolute;
            content: '';
            display: block;
            width: 100%;
            max-width: 700px;
            height: 1000px;
            max-height: 100%;
            top: 550px;
            left: 0;
            z-index: -1;
            background: transparent url(${PatternSideLeftTexture}) no-repeat 0 0 /
              contain;
            opacity: 0.5;
          }

          &:after {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            top: -162px;
            width: 100%;
            height: 400px;
            background: rgb(255, 255, 255);
            background: linear-gradient(
              13deg,
              rgba(255, 255, 255, 0.28) 60%,
              rgba(4, 157, 191, 1) 120%
            );
            z-index: -1;
          }

          @media ${breakpoint.md} {
            padding: ${theme.page.mainContentPaddingMobile};
          }

          @media ${breakpoint.sm} {
            padding: 0;
          }
        `
      : `
          &:before,
          &:after {
            position: absolute;
            right: 0;
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            max-width: 1084px;
            max-height: 1284px;
            opacity: 0.3;
            background-image: radial-gradient(
              circle at 100% 40%,
              #049dbfde,
              rgba(0, 213, 255, 0) 39%
            );
            z-index: -1;
            top: -162px;
            min-height: 750px;
          }

          &:after {
            top: calc(30% - 162px);
            left: 0;
            right: auto;
            background-image: radial-gradient(
              circle at 0% 55%,
              #049dbfde,
              rgba(0, 213, 255, 0) 39%
            );
          }

          @media ${breakpoint.md} {
            padding: ${theme.page.mainContentPaddingMobile};
          }
        `}

  ${({ isHomepage }) => {
    const mainContent = document.querySelector('main');
    if (mainContent && !isHomepage) {
      setTimeout(() => {
        const mainContentHeight = mainContent.offsetHeight;

        if (mainContentHeight < 800) {
          return `
                    &:before,
                    &:after {
                      display: none;
                    }
                  `;
        }
        if (mainContentHeight < 2000) {
          return `
                    &:after {
                      display: none;
                    }
                  `;
        }
        return null;
      }, 0);
    }
  }};
`;

export const Header = styled.header`
  width: 100%;
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
  font-weight: 900;
  font-size: 30px;
  margin: ${({ margin }) => margin || '45px 0'};
  align-self: flex-start;
  color: ${props => props.theme.colors.primaryVariant};
`;

export const Separator = styled.hr`
  width: 100%;
  height: 1px;
  background: ${props => props.theme.colors.textLight};
  border: none;
  margin-bottom: 33px;
`;

export const HomepageBackgroundTopRight = styled.span`
  display: block;
  position: absolute;
  top: -162px;
  right: 0;
  height: 50%;
  background: transparent url(${PatternTopRight}) no-repeat top 0 right -200px /
    120%;
  width: 540px;
  height: 680px;
  z-index: -1;

  &:before,
  &:after {
    content: '';
    display: block;
    height: 50%;
    width: 100%;
    position: absolute;
  }

  &:before {
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: transparent url(${XIcon}) no-repeat top -180px right -150px /
      120%;
    z-index: -1;
    opacity: 0.7;
  }

  &:after {
    bottom: 0;
    left: 0;
    background: ${({ theme }) => theme.backgrounds.pageContent};
    background: linear-gradient(
      212deg,
      rgba(237, 242, 247, 0) 60%,
      ${({ theme }) => theme.backgrounds.pageContent} 90%
    );
  }
`;
export const languageSelectorStyles = theme => ({
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'blue',
    width: state.selectProps.width,
    padding: 20,
    fontWeight: 400,
  }),
  control: (_, { selectProps: { marginRight, border } }) => ({
    width: 75,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    border,
    borderRadius: theme.buttons.clean.borderRadius,
    position: 'relative',
    marginRight,
  }),
  indicatorsContainer: () => ({
    position: 'absolute',
    right: 10,
    top: 11,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),

  singleValue: () => ({
    fontSize: 18,
    fontWeight: 600,
    padding: '5px 10px',
  }),
  menu: provided => ({
    ...provided,
    zIndex: 999,
  }),
});

export const primaryDropdownStyles = theme => ({
  option: (provided, state) => ({
    ...provided,
    color: theme.colors.primary,
    width: state.selectProps.width,
    display: 'block',
    cursor: 'pointer',
    backgroundColor: state.isFocused ? 'rgba(0,0,0,.05)' : 'transparent',
    fontFamily: 'PTMono-Regular',
    fontSize: 13,
    padding: '5px 18px',
    lineHeight: '30px',
    position: 'relative',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '18px',
      top: -1,
      width: 'calc(100% - 36px)',
      height: 1,
      backgroundColor: '#ddd',
    },
    '&:first-of-type': {
      '&:before': {
        display: 'none',
      },
    },
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,.05)',
    },
  }),
  container: provided => ({
    ...provided,
    width: '100%',
  }),
  control: (_, { selectProps: { marginRight, menuIsOpen } }) => ({
    width: '100%',
    height: 42,
    display: 'flex',
    alignItems: 'center',
    transition: 'background-color .3s ease',
    backgroundColor: menuIsOpen
      ? theme.dropdowns.primary.backgroundColor
      : theme.dropdowns.primary.openedBackgroundColor,
    borderRadius: theme.dropdowns.primary.borderRadius,
    position: 'relative',
    cursor: 'pointer',
    marginRight,
  }),
  indicatorsContainer: () => ({
    position: 'absolute',
    right: 10,
    top: '50%',
    transform: 'translateY(-50%)',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: provided => ({
    ...provided,
    top: '100%',
    marginTop: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
  }),
  singleValue: () => ({
    fontSize: 13,
    fontFamily: theme.fonts.secondary.regular,
    paddingLeft: 10,
    color: theme.dropdowns.primary.color,
  }),
});

export const MARGINS = {
  NONE: 'none',
  SMALL: 'small',
  BIG: 'big',
};

const GRADIENT_PATTERN = css`
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url('${PatternTopLeft}');
  background-size: 65%;
  background-repeat: no-repeat;
  z-index: 0;
`;

export const GRADIENT_BACKGROUND = theme => css`
  background: ${theme.colors.secondary};
  background: ${theme.backgrounds.patternGradient};
  color: #ffffff;

  @media (max-width: 768px) {
    border-radius: 0;
  }
  position: relative;

  &:after {
    ${GRADIENT_PATTERN}
  }

  &:before {
    ${GRADIENT_PATTERN}
    transform: scale(-1,-1);
  }
`;

export const StyledLink = styled(Link)`
  font-weight: 300;
  line-height: 1.4;
  font-size: 13px;

  &:hover {
    text-decoration: underline;
  }
`;

export default GlobalStyle;
