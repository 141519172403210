import { makeAutoObservable, runInAction } from 'mobx';
import { API, APIRoutes } from '@app/api';

export class PlaylistsStore {
  constructor() {
    makeAutoObservable(this);
  }

  collections = {
    name: '',
    isLoading: false,
    data: [],
    error: null,
    last: false,
    page: 1,
    per: 48,
  };

  getCollections = async uuid => {
    this.collections.error = null;
    this.collections.isLoading = true;
    this.collections.page = 1;
    try {
      const {
        data: {
          album_collection: { albums, name },
        },
      } = await API(APIRoutes.COLLECTIONS(uuid));

      runInAction(() => {
        this.collections.data = albums;
        this.collections.name = name;
      });
    } catch (error) {
      runInAction(() => {
        this.collections.error = error;
      });
    } finally {
      runInAction(() => {
        this.collections.isLoading = false;
      });
    }
  };
}

export default new PlaylistsStore();
