/* eslint-disable no-underscore-dangle */
import { makeAutoObservable, runInAction } from 'mobx';
import { API, APIRoutes } from '@app/api';
import { getItem, setItem, KEYS } from '@utils/storage';
import { currentLanguage } from '@utils/i18n';
import { COUNTRY_ANALYTIC_CODES } from '@utils/i18n/locationsContent';
import { initGA } from '@utils/googleAnalytics';

export class CookiesStore {
  constructor() {
    makeAutoObservable(this);
  }

  page = {
    isLoading: false,
    data: {},
    error: null,
  };

  getPage = async () => {
    try {
      this.page.isLoading = true;

      const {
        data: { page },
      } = await API(APIRoutes.COOKIES_PAGE);
      runInAction(() => {
        this.page.data = page;
      });
    } catch (error) {
      runInAction(() => {
        this.page.error = error;
      });
    } finally {
      runInAction(() => {
        this.page.isLoading = false;
      });
    }
  };

  handleCookies = disablePerformanceCookies => {
    window[
      `ga-disable-${COUNTRY_ANALYTIC_CODES[currentLanguage]}`
    ] = disablePerformanceCookies;

    if (!disablePerformanceCookies) {
      initGA();
    }

    if (!disablePerformanceCookies && window.mouseflow) {
      window.mouseflow.start();
    }
  };

  cookiesAccept = () => {
    if (!getItem(KEYS.DISABLE_PERFORMANCE_COOKIES)) {
      setItem(KEYS.DISABLE_PERFORMANCE_COOKIES, false);
    }

    const disablePerformanceCookies =
      getItem(KEYS.DISABLE_PERFORMANCE_COOKIES) === 'true';

    this.handleCookies(disablePerformanceCookies);
  };

  cookiesInit = () => {
    const disablePerformanceCookies = !(
      getItem(KEYS.DISABLE_PERFORMANCE_COOKIES) === 'false'
    );

    this.handleCookies(disablePerformanceCookies);
  };

  onChange = allowCookies => {
    setItem(KEYS.DISABLE_PERFORMANCE_COOKIES, !allowCookies);

    window[
      `ga-disable-${COUNTRY_ANALYTIC_CODES[currentLanguage]}`
    ] = !allowCookies;

    if (allowCookies) {
      if (window.mouseflow) {
        window.mouseflow.start();
      }
    } else if (window._mfq) {
      window._mfq.push(['stop']);
    }
  };
}

export default new CookiesStore();
