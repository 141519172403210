import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { VARIANTS as BTN_VARIANTS } from '@components/Button';
import FormButton from '@components/Form/FormButton';
import { Flex, Box } from 'rebass';
import TextField, { VARIANTS } from '@components/Form/TextField';
import { useTranslation } from 'react-i18next';

const PasswordForgottenForm = ({
  onSubmit,
  passwordErrors,
  isSuccess,
  isLoading,
}) => {
  const { register, handleSubmit, errors: formErrors } = useForm();
  const { t } = useTranslation();

  const errors = {
    ...formErrors,
    ...passwordErrors,
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex width={1} alignItems="center" flexDirection="column">
        <Box width={1} my={4}>
          <TextField
            variant={VARIANTS.DEFAULT}
            name="email"
            label={t('Your email address')}
            placeholder={t('E-mail')}
            type="text"
            register={register}
            required
            errors={errors}
          />
        </Box>

        <Box width={1} my={2} textAlign="left">
          <FormButton
            isLoading={isLoading}
            type="submit"
            variant={BTN_VARIANTS.GREEN}
            label={isSuccess ? t('Resend') : t('Send reminder')}
          />
        </Box>
      </Flex>
    </form>
  );
};

PasswordForgottenForm.defaultProps = {
  passwordErrors: {},
  isSuccess: false,
  isLoading: false,
};

PasswordForgottenForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  passwordErrors: PropTypes.object,
  isSuccess: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default PasswordForgottenForm;
