import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';
import Text from '@components/Text';

export const SectionTitle = styled(Text)`
  @media ${breakpoint.md} {
    text-align: center;
    width: 100%;
    position: sticky;
    top: 90px;
    z-index: 1;
  }
`;
