import React from 'react';
import PropTypes from 'prop-types';
import { TYPES, Wrapper } from '@components/Form/Notification/style';
import Text, { VARIANTS as TEXT_VARIANTS } from '@components/Text';

const Notification = ({ type, message }) => (
  <Wrapper p={2} mb={3} textAlign="center" type={type}>
    <Text variant={TEXT_VARIANTS.SMALL_BODY}>{message}</Text>
  </Wrapper>
);

Notification.propTypes = {
  type: PropTypes.oneOf(Object.values(TYPES)).isRequired,
  message: PropTypes.string.isRequired,
};

export default Notification;
