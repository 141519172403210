import styled from '@emotion/styled';
import { css } from '@emotion/react';
import BottomDottedPattern from '@assets/images/backgrounds/background-pattern-bottom-left.svg';

export const Wrapper = styled.div`
  display: flex;
`;

export const PageContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  transition: width ${({ theme }) => theme.sidebar.transitionTime}s ease;
  border-left: 1px solid ${({ theme }) => theme.colors.stroke};
  position: relative;
  padding-bottom: 100px;
  background-color: ${({ theme }) => theme.backgrounds.pageContent};
  z-index: 0;

  overflow: hidden;

  ${({ isSideBarOpened, theme }) =>
    isSideBarOpened
      ? css`
          width: calc(100vw - ${theme.sidebar.width.desktop.default}px);
        `
      : css`
          width: calc(100vw - ${theme.sidebar.width.desktop.collapsed}px);
        `};
`;

export const BottomPageBackground = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 390px;
  background-image: linear-gradient(
    165deg,
    rgba(255, 255, 255, 0) 50%,
    #049dbf 105%
  );

  &:before {
    content: '';
    display: block;
    height: calc(100% + 20px);
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -20px;
    background: transparent;
    background-image: url(${BottomDottedPattern});
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.7;
  }

  &:after {
    content: 'YOU TELL THE STORY, WE PLACE THE MUSIC';
    display: block;
    position: absolute;
    bottom: 43px;
    right: 43px;
    color: black;
    width: 500px;
    opacity: 0.09;
    font-size: 87px;
    font-weight: 900;
    text-align: right;
    font: ${({ theme }) => theme.fonts.secondary.blue};
  }
`;
