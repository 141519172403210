import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Preloader from '@components/Preloader';

import * as Styled from '@containers/Faqs/style';

const Faqs = ({
  faqsStore: {
    faqs: { data, isLoading },
    getFaqs,
  },
}) => {
  useEffect(() => {
    getFaqs();
  }, []);
  return isLoading ? (
    <Preloader center />
  ) : (
    <div>
      {data.map(({ question, answer, id }) => (
        <div key={`faq_${id}`}>
          <Styled.Question
            dangerouslySetInnerHTML={{
              __html: question,
            }}
          />
          <Styled.Answer
            dangerouslySetInnerHTML={{
              __html: answer,
            }}
          />
        </div>
      ))}
    </div>
  );
};

Faqs.propTypes = {
  faqsStore: PropTypes.shape({ faqs: PropTypes.array, getFaqs: PropTypes.func })
    .isRequired,
};

export default inject('faqsStore')(observer(Faqs));
