import { useRef, useState, useEffect } from 'react';
import Clipboard from 'clipboard';
import { toast } from 'react-toastify';

function useClipboard(copyText, notification) {
  const ref = useRef();
  const [clipboard, setClipboard] = useState(null);

  useEffect(() => {
    if (clipboard) {
      clipboard.destroy();
    }
    if (ref.current) {
      setClipboard(
        new Clipboard(ref.current, {
          text: () => copyText,
        }),
      );
    }
  }, [ref, copyText]);

  useEffect(() => {
    if (clipboard) {
      clipboard.on('success', () => {
        toast(notification);
      });
    }
  }, [clipboard, notification]);

  return ref;
}

export default useClipboard;
