import React from 'react';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import * as Styled from '@components/ButtonsFromList/style';
import { StyledButton } from '@components/IconButtons/style';
import ShareButton from '@components/IconButtons/ShareButton';
import * as ContextMenu from '@components/TooltipSelect';
import Text, { VARIANTS } from '@components/Text';
import useClipboard from '@hooks/useClipboard';
import PlusIcon from '@assets/images/icons/plus-icon.svg';
import ShareIcon from '@assets/images/icons/share-icon.svg';
import searchURL from '@utils/searchURL';
import toggleShareMenu from '@utils/toggleShareMenu';
import Tooltip from '@components/Tooltip';
import DisabledDownloadTooltipBody from '@components/IconButtons/DisabledDownloadTooltipBody';

const ButtonsFromList = ({
  authStore: { isAuthenticated },
  projectsStore: { openAddToProjectModal },
  clipboardData,
  trackName,
  trackId,
  isList,
}) => {
  const { t } = useTranslation();

  const copyText = searchURL(clipboardData);
  const notification = t("Track's url has been copied to clipboard");

  const copyRef = useClipboard(copyText, notification);

  return (
    <>
      {isList ? (
        <>
          <ContextMenu.MenuItem
            type="button"
            onClick={() =>
              openAddToProjectModal({ id: trackId, name: trackName })
            }
          >
            <ContextMenu.Icon src={PlusIcon} alt="add" />
            <Text variant={VARIANTS.NAV}>{t('Save track')}</Text>
          </ContextMenu.MenuItem>
          <ContextMenu.Divider />
          <ContextMenu.MenuItem
            type="button"
            onClick={
              navigator.share ? () => toggleShareMenu(copyText) : undefined
            }
            ref={navigator.share ? null : copyRef}
          >
            <ContextMenu.Icon src={ShareIcon} alt="share" />
            <Text variant={VARIANTS.NAV}>{t('Share')}</Text>
          </ContextMenu.MenuItem>
        </>
      ) : (
        <Styled.ButtonsFromList>
          <ShareButton {...{ copyText, notification }} />
          <Tooltip
            isActive={!isAuthenticated}
            tooltipBody={
              <DisabledDownloadTooltipBody
                message={t('Sign in to add to project')}
              />
            }
          >
            <div>
              <StyledButton
                onClick={() =>
                  openAddToProjectModal({ id: trackId, name: trackName })
                }
                disabled={!isAuthenticated}
              >
                <img src={PlusIcon} alt="plus-icon" />
              </StyledButton>
            </div>
          </Tooltip>
        </Styled.ButtonsFromList>
      )}
    </>
  );
};

ButtonsFromList.propTypes = {
  authStore: PropTypes.shape({ isAuthenticated: PropTypes.bool }).isRequired,
  clipboardData: PropTypes.string.isRequired,
  trackName: PropTypes.string.isRequired,
  trackId: PropTypes.number.isRequired,
  isList: PropTypes.bool,
  projectsStore: PropTypes.shape({ openAddToProjectModal: PropTypes.func })
    .isRequired,
};

ButtonsFromList.defaultProps = {
  isList: false,
};

export default inject('authStore', 'projectsStore')(observer(ButtonsFromList));
