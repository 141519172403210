import React from 'react';
import Scrollbar from '@components/Scrollbars/PerfectScrollbar/style';
import theme from '@styles/theme';

const PerfectScrollbar = React.forwardRef((props, ref) => (
  <Scrollbar
    options={{
      minScrollbarLength: theme.scrollbar.content.scrollbar.size,
      maxScrollbarLength: theme.scrollbar.content.scrollbar.size,
    }}
    {...props}
    ref={ref}
  />
));

export default PerfectScrollbar;
