import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { uniqueId } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import { Header, MainContent } from '@styles/globalStyles';
import Tile from '@components/Tile';
import GridList from '@components/GridList';
import Preloader from '@components/Preloader';
import NotFound from '@containers/NotFound';

const Collections = ({ collectionsStore: { getCollections, collections } }) => {
  const { id } = useParams();

  useEffect(() => {
    if (id) getCollections(id);
  }, [id]);

  if (collections.error) {
    return <NotFound />;
  }

  return (
    <MainContent>
      <Header>{collections.name}</Header>
      {collections.isLoading && <Preloader />}
      <GridList>
        {collections.data.map(album => (
          <li key={uniqueId()}>
            <Tile {...album} isAlbum />
          </li>
        ))}
      </GridList>
    </MainContent>
  );
};

Collections.propTypes = {
  collectionsStore: PropTypes.object.isRequired,
};

export default inject('collectionsStore')(observer(Collections));
