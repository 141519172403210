import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Text, { COLORS, VARIANTS } from '@components/Text';

const CurrentTrackTime = ({ playerStore: { trackCurrentTime } }) => (
  <Text color={COLORS.GREY} variant={VARIANTS.SMALL_BODY}>
    {trackCurrentTime}
  </Text>
);

CurrentTrackTime.propTypes = {
  playerStore: PropTypes.shape({
    trackCurrentTime: PropTypes.string,
  }).isRequired,
};

export default inject('playerStore')(observer(CurrentTrackTime));
