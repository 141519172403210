import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import ArrowDownIcon from '@assets/images/icons/arrow-down.svg';
import { ExpandIcon } from '@components/TracksTable/style';

const VersionCell = ({ versions, onClick, isOpen }) => {
  return (
    versions > 0 && (
      <Flex alignItems="center" justifyContent="center" onClick={onClick}>
        {versions}
        <ExpandIcon src={ArrowDownIcon} isOpen={isOpen} alt="down" />
      </Flex>
    )
  );
};

VersionCell.defaultProps = {
  versions: undefined,
};

VersionCell.propTypes = {
  versions: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default VersionCell;
