import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import {
  StyledRow,
  StyledTitle,
} from '@containers/MyProjects/components/style';
import { VARIANTS } from '@components/Text/style';
import { StyledButton } from '@components/IconButtons/style';
import Tooltip, { TRIGGER } from '@components/Tooltip';
import DownloadIcon from '@assets/images/icons/download-icon.svg';
import EditIcon from '@assets/images/icons/edit-icon.svg';
import EditProjectTooltipBody from '@containers/MyProjects/components/EditProjectTooltipBody';
import DownloadProjectTooltipBody from '@containers/MyProjects/components/DownloadProjectTooltipBody';
import Text from '@components/Text';
import Preloader from '@components/Preloader';
import { PLAYER_CONTEXTS } from '@components/PlayerControlButtons/PlayPause';
import ProjectTracksTable from '@components/ProjectTracksTable';

const ProjectRow = ({
  name,
  id,
  uuid,
  isExpanded,
  projectsStore: {
    onDeleteProject,
    openProjectModal,
    toggleProject,
    toggleTrack,
    projectTracks: { data, isLoading, expandedTrack, expandedAlternatives },
    toggleTrackAlternatives,
  },
  blockDownloadStore: { isDownloadBlocked, openBlockedDownloadModal },
}) => {
  const { t } = useTranslation();

  let expanded = null;

  if (isExpanded) {
    if (isLoading) {
      expanded = null;
    } else if (data.length > 0) {
      expanded = (
        <ProjectTracksTable
          {...{
            data,
            toggleTrackAlternatives,
            toggleTrack,
            expandedTrack,
            expandedAlternatives,
            isAuthenticated: true,
            playerContext: PLAYER_CONTEXTS.PROJECTS,
          }}
          showAlbumCovers
        />
      );
    } else {
      expanded = (
        <Box textAlign="center" pt={3}>
          <Text>{t("You don't have any tracks in this project")}</Text>
        </Box>
      );
    }
  }

  const onRowClick = e => {
    if (!(e.target.closest('button') || e.target.closest('a'))) {
      toggleProject(uuid);
    }
  };

  return (
    <>
      <StyledRow
        alignItems="center"
        onClick={onRowClick}
        isExpanded={isExpanded}
      >
        {isExpanded && isLoading && <Preloader cover center />}

        <Box flex={1}>
          <StyledTitle variant={VARIANTS.H4} isExpanded={isExpanded}>
            {name}
          </StyledTitle>
        </Box>
        <Box>
          <Flex>
            <Tooltip
              trigger={TRIGGER.CLICK}
              tooltipBody={<DownloadProjectTooltipBody {...{ uuid, name }} />}
              isActive={!isDownloadBlocked}
            >
              <StyledButton
                {...(isDownloadBlocked && {
                  onClick: openBlockedDownloadModal,
                })}
              >
                <img src={DownloadIcon} alt="download" />
              </StyledButton>
            </Tooltip>
            <Tooltip
              trigger={TRIGGER.CLICK}
              tooltipBody={
                <EditProjectTooltipBody
                  {...{ uuid, name, id, onDeleteProject, openProjectModal }}
                />
              }
            >
              <StyledButton>
                <img src={EditIcon} alt="edit" />
              </StyledButton>
            </Tooltip>
          </Flex>
        </Box>
      </StyledRow>
      {expanded}
    </>
  );
};

ProjectRow.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  uuid: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  projectsStore: PropTypes.shape({
    getProjects: PropTypes.func,
    onDeleteProject: PropTypes.func,
    openProjectModal: PropTypes.func,
    toggleProject: PropTypes.func,
    toggleTrack: PropTypes.func,
    toggleTrackAlternatives: PropTypes.func,
    projectTracks: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.array,
      expandedTrack: PropTypes.number,
      expandedAlternatives: PropTypes.bool,
    }),
  }).isRequired,
  blockDownloadStore: PropTypes.object.isRequired,
};

export default inject(
  'projectsStore',
  'blockDownloadStore',
)(observer(ProjectRow));
