import React from 'react';
import PropTypes from 'prop-types';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  PRODUCTION_TYPES,
  USAGE_TYPES,
  OFFLINE_USAGE_TYPES,
  LICENSE_KINDS,
  MEDIA_TYPES,
  TERRITORIES,
} from '@containers/LicenseApplication/constants';
import Text, { MARGINS, VARIANTS } from '@components/Text';
import TextField from '@components/Form/TextField';
import SectionTitle from '@components/SectionTitle';
import InputGrid from '@containers/LicenseApplication/components/InputGrid';
import { Section } from '@containers/LicenseApplication/components/style';
import Select from '@components/Form/Select';
import CheckboxGroup from '@components/Form/CheckboxGroup';
import Radio from '@components/Form/Radio';

const TypesUsageStep = ({ kind }) => {
  const { register, errors, control, watch, getValues } = useFormContext();
  const { t } = useTranslation();

  const commonInputProps = {
    register,
    errors,
  };

  const numberInputRules = {
    required: {
      value: true,
      message: t('Required'),
    },
    min: {
      value: 0,
      message: t('Minimum 0'),
    },
  };

  const isOtherType = watch('production_type')?.value === 'Other';
  const isOtherOnlineTerritory = watch('online_territory') === 'other';
  const isOtherOfflineTerritory = watch('offline_territory') === 'other';
  const isOtherUsage = watch('usage_type')?.includes('other') || false;

  const dateInputRules = (compareTo, isHigher = true) => ({
    required: {
      value: true,
      message: t('Required'),
    },
    validate: {
      validRange: v => {
        const compareToValue = getValues(compareTo);

        if (!compareToValue) {
          return true;
        }

        const date = new Date(v);
        const compareToDate = new Date(compareToValue);

        if (isHigher) {
          return date >= compareToDate || t('Must ends after start date');
        }

        return date <= compareToDate || t('Must starts before end date');
      },
    },
  });

  return (
    <div>
      <Section>
        <SectionTitle>{t('Production details')}</SectionTitle>
        <InputGrid cols={2}>
          <TextField
            {...{
              ...commonInputProps,
              name: 'production_title',
              label: t('Production title'),
              placeholder: t('Name of production'),
              required: true,
            }}
          />
          <TextField
            {...{
              ...commonInputProps,
              name: 'notes',
              label: t('Notes'),
              placeholder: t('Extra information'),
              as: 'textarea',
              required: false,
            }}
          />
        </InputGrid>
      </Section>
      <Section>
        <SectionTitle>{t('Production type')}</SectionTitle>
        <InputGrid>
          <Select
            {...{
              control,
              required: true,
              errors,
              name: 'production_type',
              label: t('Production type'),
              placeholder: t('Please select one'),
              options: PRODUCTION_TYPES[kind],
            }}
          />
          {isOtherType && (
            <TextField
              {...{
                ...commonInputProps,
                name: 'production_type_details',
                label: t('If ‘other’, please specify'),
                placeholder: t('Details'),
                required: isOtherType,
              }}
            />
          )}
        </InputGrid>
      </Section>
      <Section>
        <SectionTitle>{t('Versions')}</SectionTitle>
        <InputGrid>
          <TextField
            {...{
              ...commonInputProps,
              name: 'no_cut_versions',
              label: t('No. of cut versions'),
              placeholder: '0',
              type: 'number',
              rules: numberInputRules,
              required: true,
            }}
          />
          <TextField
            {...{
              ...commonInputProps,
              name: 'no_language_versions',
              label: t('No. of language versions'),
              placeholder: '0',
              type: 'number',
              rules: numberInputRules,
              required: true,
            }}
          />
        </InputGrid>
      </Section>
      <Section>
        <SectionTitle>{t('Online usage')}</SectionTitle>
        <CheckboxGroup
          {...{
            name: 'usage_type',
            label: t('Please select all those applicable'),
            required: kind !== LICENSE_KINDS.CORPORATE,
            register,
            errors,
            options: USAGE_TYPES[kind],
          }}
        />
        {isOtherUsage && (
          <InputGrid mt={3}>
            <TextField
              {...{
                ...commonInputProps,
                name: 'usage_type_details',
                label: t('If ‘other’, please specify'),
                placeholder: t('Details'),
                required: isOtherUsage,
              }}
            />
          </InputGrid>
        )}
        {kind !== LICENSE_KINDS.COMMERCIAL ? (
          <InputGrid>
            <TextField
              {...{
                ...commonInputProps,
                name: 'url',
                label: t('URL'),
                placeholder: t('www.webaddress.co.uk'),
                required:
                  kind === LICENSE_KINDS.COMMERCIAL &&
                  kind === LICENSE_KINDS.ONLINE,
              }}
            />
          </InputGrid>
        ) : (
          <>
            <Text variant={VARIANTS.H4_REGULAR} mb={MARGINS.SMALL}>
              {t('Media type (please select one)')}:
            </Text>
            <Radio
              {...{
                ...commonInputProps,
                name: 'media_type',
                options: MEDIA_TYPES,
                required: true,
              }}
            />
            <InputGrid my={4}>
              <TextField
                {...{
                  ...commonInputProps,
                  name: 'url',
                  label: t('URL'),
                  placeholder: t('www.webaddress.co.uk'),
                  required: true,
                }}
              />
              <TextField
                {...{
                  ...commonInputProps,
                  name: 'online_from',
                  label: t('Online from'),
                  placeholder: t('DD/MM/YYYY'),
                  type: 'date',
                  rules: dateInputRules('online_to', false),
                  required: true,
                }}
              />
              <TextField
                {...{
                  ...commonInputProps,
                  name: 'online_to',
                  label: t('Online to'),
                  placeholder: t('DD/MM/YYYY'),
                  type: 'date',
                  rules: dateInputRules('online_from'),
                  required: true,
                }}
              />
            </InputGrid>
            <Text variant={VARIANTS.H4_REGULAR} mb={MARGINS.SMALL}>
              {t('Territory (please select one)')}:
            </Text>
            <Radio
              {...{
                ...commonInputProps,
                name: 'online_territory',
                options: TERRITORIES,
                required: true,
              }}
            />
            {isOtherOnlineTerritory && (
              <InputGrid mt={3}>
                <TextField
                  {...{
                    ...commonInputProps,
                    name: 'online_territory_details',
                    label: t('If ‘other’, please specify'),
                    placeholder: t('Details'),
                    required: isOtherOnlineTerritory,
                  }}
                />
              </InputGrid>
            )}
          </>
        )}
      </Section>
      {(kind === LICENSE_KINDS.CORPORATE ||
        kind === LICENSE_KINDS.COMMERCIAL) && (
        <Section>
          <SectionTitle>{t('Offline usage')}</SectionTitle>
          <CheckboxGroup
            {...{
              name: 'offline_usage',
              label: t('Please select one'),
              required: kind !== LICENSE_KINDS.CORPORATE,
              register,
              errors,
              options: OFFLINE_USAGE_TYPES[kind],
            }}
          />
          {kind === LICENSE_KINDS.CORPORATE && (
            <InputGrid>
              <TextField
                {...{
                  ...commonInputProps,
                  name: 'no_of_copies',
                  label: t('No. of copies'),
                  placeholder: '0',
                  type: 'number',
                  required: kind !== LICENSE_KINDS.CORPORATE,
                }}
              />
            </InputGrid>
          )}
          {kind === LICENSE_KINDS.COMMERCIAL && (
            <>
              <InputGrid my={4}>
                <TextField
                  {...{
                    ...commonInputProps,
                    name: 'offline_from',
                    label: t('In use from'),
                    placeholder: t('DD/MM/YYYY'),
                    type: 'date',
                    rules: dateInputRules('offline_to', false),
                    required: true,
                  }}
                />
                <TextField
                  {...{
                    ...commonInputProps,
                    name: 'offline_to',
                    label: t('In use to'),
                    placeholder: t('DD/MM/YYYY'),
                    type: 'date',
                    rules: dateInputRules('offline_from'),
                    required: true,
                  }}
                />
              </InputGrid>
              <Text variant={VARIANTS.H4_REGULAR} mb={MARGINS.SMALL}>
                {t('Territory (please select one)')}:
              </Text>
              <Radio
                {...{
                  ...commonInputProps,
                  name: 'offline_territory',
                  options: TERRITORIES,
                  required: true,
                }}
              />
              {isOtherOfflineTerritory && (
                <InputGrid mt={3}>
                  <TextField
                    {...{
                      ...commonInputProps,
                      name: 'offline_territory_details',
                      label: t('If ‘other’, please specify'),
                      placeholder: t('Details'),
                      required: isOtherOfflineTerritory,
                    }}
                  />
                </InputGrid>
              )}
            </>
          )}
        </Section>
      )}
    </div>
  );
};

TypesUsageStep.propTypes = {
  kind: PropTypes.string.isRequired,
};

export default TypesUsageStep;
