import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { VARIANTS as BTN_VARIANTS } from '@components/Button';
import FormButton from '@components/Form/FormButton';
import { Flex, Box } from 'rebass';
import { useTranslation } from 'react-i18next';
import PasswordField from '@components/Form/PasswordField';

const PasswordForgottenForm = ({ onSubmit, passwordErrors }) => {
  const { register, handleSubmit, errors: formErrors } = useForm();
  const { t } = useTranslation();

  const errors = {
    ...formErrors,
    ...passwordErrors,
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex width={1} alignItems="center" flexDirection="column">
        <Box width={1} my={4}>
          <PasswordField
            name="password"
            label={t('New password')}
            placeholder={t('New password')}
            autoComplete="new-password"
            register={register}
            required
            errors={errors}
          />
          <PasswordField
            name="password_confirmation"
            label={t('Confirm password')}
            placeholder={t('Confirm password')}
            autoComplete="new-password"
            register={register}
            required
            errors={errors}
          />
        </Box>

        <Box width={1} my={2} textAlign="left">
          <FormButton
            type="submit"
            variant={BTN_VARIANTS.GREEN}
            label={t('Reset Password')}
          />
        </Box>
      </Flex>
    </form>
  );
};

PasswordForgottenForm.defaultProps = {
  passwordErrors: {},
};

PasswordForgottenForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  passwordErrors: PropTypes.object,
};

export default PasswordForgottenForm;
