import Text from '@components/Text';
import styled from '@emotion/styled';

export const DropzoneWrapper = styled.div`
  padding: 42px;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.colors.highlight};
  position: relative;
  border: 1px
    ${({ theme, withError }) =>
      withError ? theme.colors.error : 'transparent'}
    solid;

  &::before {
    content: '';
    user-select: none;
    position: absolute;
    top: 16px;
    bottom: 16px;
    left: 16px;
    right: 16px;
    border: 1px white;
    border: 1px
      ${({ theme, withError }) =>
        withError ? theme.colors.error : theme.colors.white}
      dashed;
    border-radius: 7px;
    transition: opacity 0.2s;
    opacity: ${({ isDragActive }) => (isDragActive ? '1' : '0.5')};
  }
`;

export const DropzoneTitle = styled(Text)`
  display: block;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 8px;
`;

export const FilesList = styled.ul`
  list-style: none;
  padding: 0;

  & > li {
    margin: 8px 0;
  }
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
  padding: 4px 0;
  text-align: right;
  width: 100%;
  &:first-letter {
    text-transform: capitalize;
  }
`;
