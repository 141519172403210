import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Footer from '@components/Footer';
import NavBar from '@components/NavBar';

import SideBar from '@components/SideBar';
import * as Styled from '@layouts/BaseLayout/style';

const BaseLayout = ({ children, sidebarStore: { isSideBarOpened } }) => (
  <>
    <Styled.Wrapper>
      <SideBar />
      <Styled.PageContent isSideBarOpened={isSideBarOpened} id="page-content">
        <NavBar />
        {children}
        <Styled.BottomPageBackground />
      </Styled.PageContent>
    </Styled.Wrapper>
    <Footer />
  </>
);

BaseLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  sidebarStore: PropTypes.shape({
    isSideBarOpened: PropTypes.bool,
  }).isRequired,
};

export default inject('sidebarStore')(observer(BaseLayout));
