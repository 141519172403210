import styled from '@emotion/styled';

export const Content = styled.div`
  position: absolute;
  bottom: -5px;
  right: 0;
  transform: translateY(100%);
  background-color: ${({ theme }) => theme.colors.white};
  width: 200px;
  border-radius: 4px;
  box-shadow: 0 0 7px 0 rgba(13, 13, 13, 0.18);
  overflow: hidden;
`;

export const Text = styled.span`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  padding: 0 8px;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'unset')};
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : 'unset')};
`;

export const Label = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -5px;
  margin-right: -5px;
`;

export const Indicator = styled.img`
  vertical-align: middle;
  transition: transform 0.3s ease-in-out;
  transform: rotate(${({ isOpen }) => (isOpen ? 0 : -180)}deg);
`;

export const Icon = styled.span`
  width: 20px;

  svg {
    vertical-align: middle;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: block;

  &:hover {
    ${Icon} {
      svg {
        * {
          fill: #ffffff;
          transition: fill 0.1s;
        }
      }
    }
  }
`;

export const Divider = styled.hr`
  border: none;
  height: 1px;
  background-color: #cedfe3;
  margin: 0;
`;
