import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { NavLink } from 'react-router-dom';
import { breakpoint } from '@styles/breakpoints';

export const HeaderTitleLinkWrapper = styled(NavLink)`
  font-size: 13px;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.stroke};
  display: block;
`;

export const LinkTitleWrapper = styled.div`

${({ desktopcollapsed, theme }) =>
  desktopcollapsed
    ? css`
        padding: ${theme.sidebar.header.padding};
        display: flex;
        min-width: 200px;
        align-items: center;
      `
    : css`
        padding: 15px 5px;
        display: block;
        text-align: center;
      `}

  @media ${breakpoint.md} {
    margin-left: 32px;
  }

  @media ${breakpoint.md} and (orientation : portrait) {
    height: calc((100vh - 53px - 145px) / 7);
    padding: 0;
  }
  @media ${breakpoint.md} and (orientation: landscape) {
    min-height: 57px;
    margin-left: 0;
  }
`;

export const HeaderIcon = styled.img`
  margin-right: ${({ desktopcollapsed }) => (desktopcollapsed ? '10px' : 0)};
`;

export const LinkHeaderTitle = styled.h3`
  font-family: ${({ active, theme }) =>
    active ? theme.fonts.secondary.bold : theme.fonts.secondary.regular};
  line-height: 17px;
`;
