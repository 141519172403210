import styled from '@emotion/styled';

export const InputWrapper = styled.div`
  position: relative;
  margin-bottom: ${({ theme, mb }) => `${theme.inputs.margins[mb]}px`};
`;

export const Indicator = styled.img`
  transition: transform 0.3s ease;
  transform: rotate(${({ menuIsOpen }) => (menuIsOpen ? 180 : 0)}deg);
`;
