import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from '@components/Banner/style';
import { VARIANTS } from '@components/Button';

const Banner = ({ title, description, image, buttonText, url }) => (
  <Styled.Wrapper>
    <Styled.Content>
      <div>
        <Styled.Header>{title}</Styled.Header>
        <Styled.Description>{description}</Styled.Description>
      </div>
      <Styled.LinkButton
        variant={VARIANTS.SECONDARY}
        label={buttonText}
        as="a"
        href={url}
      />
    </Styled.Content>
    <Styled.ImageWrapper>
      {image && <Styled.Image src={image} />}
    </Styled.ImageWrapper>
  </Styled.Wrapper>
);

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

Banner.defaultProps = {
  image: null,
};

export default Banner;
