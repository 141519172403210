export default {
  base: '/',
  auth: {
    signIn: '/sign-in',
    signUp: '/sign-up',
    composerSignUp: '/composer-sign-up',
    confirmation: '/registration/confirmation',
    delete: '/account/delete',
  },
  albums: `/albums`,
  album: (uuid = null, trackId = null) =>
    uuid
      ? `/albums/${uuid}${trackId ? `?trackId=${trackId}` : ''}`
      : '/albums/:id',
  cookies: `/cookies`,
  composerWebsites: `/composers`,
  composerWebsite: (id = null) => (id ? `/composers/${id}` : '/composers/:id'),
  credits: `/credits`,
  collections: (id = null) => (id ? `/collections/${id}` : '/collections/:id'),
  labels: '/labels',
  label: (id = null) => (id ? `/labels/${id}` : '/labels/:id'),
  topics: '/topics',
  topic: (id = null) => (id ? `/topics/${id}` : '/topics/:id'),
  playlists: '/playlists',
  playlistsOfCategory: (uuid = null) =>
    uuid ? `/playlists/${uuid}` : '/playlists/:id',
  playlist: (id = null) => (id ? `/playlist/${id}` : '/playlist/:id'),
  moods: id => `/search?moods=${id}`,
  info: '/info',
  license: '/license',
  licenseApplication: (uuid = null) =>
    uuid ? `/license/${uuid}` : '/license/:id',
  search: (sort = null) =>
    sort === null ? '/search/:sort?' : `/search/${sort}`,
  extra4you: '/extra-4-you',
  crew: '/crew',
  aboutUs: '/about-us',
  tutorials: '/tutorials',
  writeForUs: '/write-for-us',
  contactUs: '/contact-us',
  browse: '/browse',
  artists: (lang = 'en') => `https://${lang}.intervoxmusicgroup.com/`,
  agents: (id = null) => (id ? `/agents/${id}` : '/agents/:id'),
  about: '/about-intervox',
  password: {
    forgotten: '/password/forgotten',
    reset: '/password/reset',
  },
  account: '/account',
  statements: '/account/statements',
  requestMusic: '/request-music',
  briefings: '/briefings',
  uploadedTracks: '/uploaded-tracks',
  releasedTracks: '/released-tracks',
  briefing: (id = null) => (id ? `/briefings/${id}` : '/briefings/:id'),
  track: (id = null) =>
    id ? `/uploaded-tracks/${id}` : '/uploaded-tracks/:id',
  myAlbums: '/my-albums',
  myProjects: '/my-projects',
  myDownloads: '/my-downloads',
  myContracts: '/my-contracts',
  helloContracts: '/digital-contracts',
  uploadContract: '/upload-contract',
  dataProtection: '/data-protection',
  impressum: '/impressum',
  agb: '/agb',
  oAuthForm: '/o-auth',
  api: {
    banner: (id = null) => (id ? `/api/banner/${id}` : '/api/banner/:id'),
  },
  faqs: '/briefings_faq',
};
