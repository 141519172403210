import React from 'react';

const ProfileIcon = props => {
  return (
    <svg version="1.1" viewBox="0 0 20 19" {...props}>
      <g transform="translate(-12.375 -3.8698)" fill="none" fillRule="evenodd">
        <g transform="translate(2.3746 -3.1302)">
          <g transform="translate(14.09,7)">
            <rect
              fill="#858585"
              id="a"
              x=".909"
              width="9"
              height="9"
              rx="4.5"
            />
          </g>
          <path
            d="m20 19c-5.667 0-9 2.333-10 7h20c-1-4.667-4.333-7-10-7z"
            fill="#858585"
          />
        </g>
      </g>
    </svg>
  );
};

export default ProfileIcon;
