import React from 'react';
import routes from '@routes';
import { NavLink } from 'react-router-dom';
import { Flex } from 'rebass';
import * as Styled from '@components/Footer/style';
import { FOOTER_LOGO, COPY_TEXT } from '@utils/i18n/locationsContent';
import { size } from '@styles/breakpoints';
import useWindowWidth from '@hooks/useBreakpoint';
import Links from '@components/Footer/components/Links';
import ContactUs from '@components/Footer/components/ContactUs';
import SocialIcons from '@components/Footer/components/SocialIcons';
import LanguageSwitcher from '@components/LanguageSwitcher';
import { currentLanguage } from '@utils/i18n';
import { langsToExclude } from '@utils/i18n/locations';

const Footer = () => {
  const checkSize = useWindowWidth();

  return (
    <Styled.Wrapper>
      {window.innerWidth > size.md || checkSize > size.md ? (
        <Styled.MainFooter>
          <Styled.MainInfo>
            <Styled.FooterLogo as={NavLink} to={routes.base}>
              <img src={FOOTER_LOGO} alt="footer logo" />
            </Styled.FooterLogo>
            <Links />
            <ContactUs />
            <SocialIcons />
          </Styled.MainInfo>
          {!langsToExclude.includes(currentLanguage) && (
            <div>
              <Flex>
                <LanguageSwitcher isFooter />
              </Flex>
            </div>
          )}
        </Styled.MainFooter>
      ) : (
        <>
          <Styled.MainFooter>
            <Styled.FooterLogo>
              <img src={FOOTER_LOGO} alt="footer logo" />
            </Styled.FooterLogo>
            {!langsToExclude.includes(currentLanguage) && (
              <div>
                <Flex>
                  <LanguageSwitcher isFooter />
                </Flex>
              </div>
            )}
          </Styled.MainFooter>
          <div>
            <Links />
            <SocialIcons />
          </div>
        </>
      )}
      <Styled.SecondFooter>
        <Styled.Copy>{`${new Date().getFullYear()} © ${COPY_TEXT}`}</Styled.Copy>
      </Styled.SecondFooter>
    </Styled.Wrapper>
  );
};

export default Footer;
