import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from 'rebass';
import * as Styled from '@components/TooltipSelect';
import Text, { VARIANTS } from '@components/Text';

const EditProjectTooltipBody = ({
  name,
  uuid,
  id,
  onDeleteProject,
  openProjectModal,
}) => {
  const { t } = useTranslation();

  const projectData = {
    name,
    id,
    uuid,
  };

  return (
    <>
      <Styled.MenuItem
        type="button"
        onClick={() => openProjectModal(projectData)}
      >
        <Box px={2}>
          <Text variant={VARIANTS.NAV}>{t('Rename')}</Text>
        </Box>
      </Styled.MenuItem>
      <Styled.Divider />
      <Styled.MenuItem
        type="button"
        onClick={() => onDeleteProject(uuid, name)}
      >
        <Box px={2}>
          <Text variant={VARIANTS.NAV}>{t('Delete')}</Text>
        </Box>
      </Styled.MenuItem>
    </>
  );
};

EditProjectTooltipBody.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  onDeleteProject: PropTypes.func.isRequired,
  openProjectModal: PropTypes.func.isRequired,
};

export default EditProjectTooltipBody;
