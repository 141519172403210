import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';
import { useTranslation } from 'react-i18next';

import Modal from '@components/Modal';
import { VARIANTS as MODAL_VARIANTS } from '@components/Modal/style';
import { Header as CardHeader } from '@components/Card';
import Text, {
  ALIGNS,
  COLORS,
  VARIANTS as TEXT_VARIANTS,
} from '@components/Text';
import Button from '@components/Button';
import routes from '@routes';

const FakeAccountModal = ({
  blockDownloadStore: { isFakeAccountModalOpen, closeFakeAccountModal },
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      closeHandler={closeFakeAccountModal}
      isOpen={isFakeAccountModalOpen}
      variant={MODAL_VARIANTS.GRADIENT}
      portalId="fake-account-modal"
    >
      <Box p={4}>
        <Box p={3} textAlign="center">
          <CardHeader variant={TEXT_VARIANTS.H2} align={ALIGNS.CENTER}>
            {t('YOU ACCOUNT HAS BEEN DISABLED')}
          </CardHeader>
          <Box margin="0 auto">
            <Text
              variant={TEXT_VARIANTS.H3}
              color={COLORS.WHITE}
              align={ALIGNS.CENTER}
            >
              {t(
                'To gain back control of your account, please reach out to us here',
              )}
              :
            </Text>
          </Box>
          <Box mt={4}>
            <Button
              to={routes.contactUs}
              onClick={closeFakeAccountModal}
              label={t('Contact us')}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

FakeAccountModal.propTypes = {
  blockDownloadStore: PropTypes.object.isRequired,
};

export default inject('blockDownloadStore')(observer(FakeAccountModal));
