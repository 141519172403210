import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Helmet } from 'react-helmet';
import AccountForm from '@containers/Account/components/AccountForm';
import { MainContent } from '@styles/globalStyles';
import {
  COMPOSER_TABS,
  CUSTOMER_TABS,
  PRODUCER_TABS,
} from '@root/stores/customerStore';
import Tabs from '@components/Tabs';
import Notification from '@components/Form/Notification';
import Header from '@components/Header';

const Account = ({
  customerStore: { customer, customerForm, clearForm, onUpdate, setActiveTab },
  registrationStore: { countries, getCountries },
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    getCountries();

    return () => clearForm();
  }, []);

  const { formNotification, activeTab } = customerForm;

  const { data: customerData } = customer;

  const accountTabs = [
    ...CUSTOMER_TABS,
    ...(customerData?.is_composer ? COMPOSER_TABS : []),
    ...(!customerData?.is_composer && customerData?.is_producer
      ? PRODUCER_TABS
      : []),
  ];

  return (
    <MainContent>
      <Helmet>
        <title>{t('Account')}</title>
      </Helmet>
      <Header
        title={t('Account')}
        nav={
          <Tabs
            tabs={accountTabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        }
        withSeparator
      />
      {formNotification && <Notification {...formNotification} />}
      <AccountForm
        {...{
          onSubmit: onUpdate,
          formStatus: toJS(customerForm),
          countries: countries.data,
          customer: customerData,
          tabs: accountTabs,
        }}
      />
    </MainContent>
  );
};

Account.propTypes = {
  customerStore: PropTypes.object.isRequired,
  registrationStore: PropTypes.object.isRequired,
};

export default inject('customerStore', 'registrationStore')(observer(Account));
