/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { VARIANTS } from '@components/Text';
import * as Styled from '@components/Tutorial/style';

const Tutorial = ({ title, description, url }) => {
  const ref = useRef();
  return (
    <Styled.Wrapper>
      <Styled.Title variant={VARIANTS.H4}>{title}</Styled.Title>
      <Styled.Description variant={VARIANTS.BODY}>
        {description}
      </Styled.Description>
      <Styled.VideoContainer>
        <iframe ref={ref} title={title} src={url} allowFullScreen />
      </Styled.VideoContainer>
    </Styled.Wrapper>
  );
};

Tutorial.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default Tutorial;
