import styled from '@emotion/styled';
import { Flex } from 'rebass';

export const CoverContainer = styled(Flex)`
  width: 56px;
  height: 56px;
  border-radius: 4px;
  overflow: hidden;
`;

export const Cover = styled.img`
  width: 100%;
  object-fit: contain;
`;

export const TextInfo = styled.div`
  padding: 6px 0 6px 15px;
  font-family: Helvetica;
  line-height: 1.14;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-size: 15px;
  color: ${({ theme }) => theme.colors.primary};
  line-height: 1.29;
`;

export const Artist = styled.div`
  margin-top: 4px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.textLight};
`;

export const MoreInfoButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 34px;
  height: 34px;
  padding: 5px;
  cursor: pointer;
  transform: ${({ expanded }) => (expanded ? 'rotate(180deg)' : 'none')};
  transition: transform 0.5s;
  z-index: 2;
`;
