import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from 'rebass';

import { useForm, FormProvider } from 'react-hook-form';
import { VARIANTS as BTN_VARIANTS } from '@components/Button';
import { VARIANTS as TEXT_VARIANTS, COLORS } from '@components/Text';

import {
  StyledText,
  StyledFormButton,
} from '@containers/LicenseApplication/components/style';

const Step = ({
  initialData,
  formErrors,
  isLoading,
  onSubmit,
  onBack,
  currentStep,
  stepsLength,
  children,
}) => {
  const { t } = useTranslation();

  const methods = useForm({
    defaultValues: initialData,
  });

  const { handleSubmit, errors: validationErrors, getValues } = methods;

  const errors = {
    ...validationErrors,
    ...formErrors,
  };

  const isLast = currentStep === stepsLength - 1;

  return (
    <FormProvider {...{ ...methods, errors }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {children}
        <Box width={1} textAlign="center">
          <StyledFormButton
            isLoading={isLoading}
            variant={isLast ? BTN_VARIANTS.GREEN : BTN_VARIANTS.SECONDARY}
            label={isLast ? t('Submit License') : t('Next step')}
            type="submit"
          />
        </Box>
        {currentStep > 0 && (
          <Box width={1} mt={3} textAlign="center">
            <button
              type="button"
              onClick={() => onBack(getValues(), currentStep)}
            >
              <StyledText variant={TEXT_VARIANTS.H4} color={COLORS.BLUE}>
                {t('Back')}
              </StyledText>
            </button>
          </Box>
        )}
      </form>
    </FormProvider>
  );
};

Step.defaultProps = {
  initialData: {},
  isLoading: false,
};

Step.propTypes = {
  initialData: PropTypes.object,
  formErrors: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  currentStep: PropTypes.number.isRequired,
  stepsLength: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Step;
