import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import TrackCell from '@components/TracksTable/components/TrackCell';
import { SectionHeader, Separator, Section } from '@containers/Label/style';
import * as Styled from '@containers/Label/InfoBox/style';
import Text, { VARIANTS, COLORS } from '@components/Text';
import ShareButton from '@components/IconButtons/ShareButton';
import { PLAYER_CONTEXTS } from '@components/PlayerControlButtons/PlayPause';
import CoverPlaceholder from '@components/CoverPlaceholder';

const InfoBox = ({
  image,
  description,
  medley,
  medleyLength,
  shareLink,
  name,
}) => {
  const { t } = useTranslation();

  const medleyTrack = {
    album: {
      cover: {
        url: image,
        name: medley.name,
      },
      description,
      name: medley.name,
    },
    file: {
      ...medley,
    },
    id: medley.id,
    name: medley.name,
    medley: true,
    singleTrack: true,
  };

  return (
    <Section>
      <SectionHeader variant={VARIANTS.NAV_COLLAPSED}>
        {t('Information')}
      </SectionHeader>
      <Separator />
      <Styled.InfoBox>
        {image ? (
          <img src={image} alt="label logo" />
        ) : (
          <CoverPlaceholder text={name} minWidth="12rem" />
        )}
        {description}
      </Styled.InfoBox>
      <Styled.Medley>
        <TrackCell track={medleyTrack} playerContext={PLAYER_CONTEXTS.LABELS} />
        <Styled.MedleyRight>
          {medleyLength && (
            <Text color={COLORS.GREY} variant={VARIANTS.SMALL_BODY}>
              {medleyLength}
            </Text>
          )}
          <ShareButton
            copyText={shareLink}
            notification={t("Track's url has been copied to clipboard")}
          />
        </Styled.MedleyRight>
      </Styled.Medley>
    </Section>
  );
};

InfoBox.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  medley: PropTypes.shape({
    url: PropTypes.string,
    waveform_peak_data: PropTypes.shape({
      url: PropTypes.string,
    }),
    name: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  medleyLength: PropTypes.string,
  shareLink: PropTypes.string.isRequired,
};

InfoBox.defaultProps = {
  medleyLength: null,
  image: null,
  name: null,
  description: '',
};

export default InfoBox;
