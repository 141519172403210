import React, { useEffect, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import PropTypes from 'prop-types';

import {
  TooltipContainer,
  TooltipArrow,
  TooltipBody,
} from '@components/Tooltip/style';

export * from '@components/Tooltip/style';

export const PLACEMENT = {
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
};

export const TRIGGER = {
  CLICK: 'click',
  HOVER: 'hover',
  NONE: null,
};

const Tooltip = ({
  children,
  tooltipBody,
  trigger,
  isActive,
  placement,
  hookArguments,
  minWidth,
}) => {
  const [controlledVisible, setControlledVisible] = useState(false);

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    tooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    trigger: isActive ? trigger : null,
    interactive: trigger === TRIGGER.CLICK,
    placement,
    offset: [0, 12],
    delayHide: trigger === TRIGGER.CLICK ? 200 : 50,
    closeOnTriggerHidden: true,
    visible: controlledVisible,
    onVisibleChange: setControlledVisible,
    ...hookArguments,
  });

  useEffect(() => {
    const handleClick = e => {
      e.preventDefault();
      const target = e.target.closest('button') || e.target.closest('a');
      if (target) {
        target.click();
        setControlledVisible(false);
      }
    };

    if (tooltipRef) {
      tooltipRef.addEventListener('touchend', handleClick);
    }
    return () => {
      if (tooltipRef) {
        tooltipRef.removeEventListener('touchend', handleClick);
      }
    };
  }, [tooltipRef]);

  return (
    <>
      {React.cloneElement(children, { ref: setTriggerRef })}
      {visible && (
        <TooltipContainer ref={setTooltipRef} {...getTooltipProps()}>
          <TooltipBody minWidth={minWidth}>{tooltipBody}</TooltipBody>
          <TooltipArrow {...getArrowProps()} />
        </TooltipContainer>
      )}
    </>
  );
};

Tooltip.defaultProps = {
  placement: PLACEMENT.TOP,
  trigger: TRIGGER.HOVER,
  hookArguments: {},
  isActive: true,
  minWidth: '130px',
};

Tooltip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  tooltipBody: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  isActive: PropTypes.bool,
  trigger: PropTypes.oneOf(Object.values(TRIGGER)),
  placement: PropTypes.oneOf(Object.values(PLACEMENT)),
  hookArguments: PropTypes.object,
  minWidth: PropTypes.string,
};

export default Tooltip;
