import { makeAutoObservable, runInAction } from 'mobx';
import { API, APIRoutes } from '@app/api';
import i18n from '@utils/i18n';

export class TopicsStore {
  constructor() {
    makeAutoObservable(this);
  }

  topics = {
    isLoading: false,
    data: [],
    error: null,
  };

  getTopic = async uuid => {
    await this.getTopics();
    const t = this.topics.data.find(topic => topic.uuid === uuid);
    if (t !== undefined) return t;
    throw new Error('Topic not found');
  };

  getTopics = async () => {
    try {
      if (this.topics.data.length === 0) {
        this.topics.error = null;
        this.topics.isLoading = true;

        const {
          data: { topics },
        } = await API(APIRoutes.TOPICS);

        runInAction(() => {
          this.topics.data = [
            { id: 'all', name: i18n.t('All topics') },
            ...topics,
          ];
        });
      }
    } catch (error) {
      runInAction(() => {
        this.topics.error = error;
      });
    } finally {
      runInAction(() => {
        this.topics.isLoading = false;
      });
    }
  };
}

export default new TopicsStore();
