import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { locations } from '@utils/i18n/locations';

import EN from '@translations/en.json';
import DE from '@translations/de.json';
import ES from '@translations/es.json';
import IT from '@translations/it.json';
import PT from '@translations/pt.json';
import CZ from '@translations/cz.json';

import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/pt';

export { LANG_KEYS } from '@utils/i18n/locations';

export const currentLanguage = Object.keys(locations).find(
  key => locations[key] === window.location.origin,
);

export const isLanguage = lang => lang === currentLanguage;

const translations = {
  EN,
  DE,
  ES,
  IT,
  PT,
  CZ,
};

const resources = {};

Object.keys(translations).forEach(langCode => {
  resources[langCode] = { translation: translations[langCode] };
});

i18n.use(initReactI18next).init({
  resources,
  debug: process.env.NODE_ENV === 'development',
  fallbackLng: currentLanguage,
  saveMissing: true,
  initImmediate: false,
  react: {
    wait: true,
  },
  keySeparator: false,
});

moment.locale(currentLanguage.toLowerCase());

export default i18n;
