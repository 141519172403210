import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Header from '@components/Header';
import { MainContent } from '@styles/globalStyles';
import { ConfirmationText } from '@containers/Confirmation/style';

const SignInPage = () => {
  const { t } = useTranslation();

  return (
    <MainContent>
      <Helmet>
        <title>{t('Register')}</title>
      </Helmet>
      <Header title={t('Register')} withSeparator />

      <ConfirmationText as="p">
        {t('A confirmation email has been sent to your email address.')} <br />
        {t(
          'To activate your account, please click on the “confirm my account” link.',
        )}
        <br />
        {t(
          "If the email doesn't seem to arrive, please check your junk/spam folder before contacting info@intervox.de",
        )}
      </ConfirmationText>
    </MainContent>
  );
};

export default SignInPage;
