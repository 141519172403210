import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from '@components/License/LicenseTooltipbody/style';

const LicenseTooltipbody = ({ title, description }) => (
  <Styled.Wrapper>
    <Styled.Header>{title}</Styled.Header>
    <Styled.Description>{description}</Styled.Description>
  </Styled.Wrapper>
);

LicenseTooltipbody.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default LicenseTooltipbody;
