import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from '@components/Modal';
import { VARIANTS as MODAL_VARIANS } from '@components/Modal/style';
import Button, { VARIANTS } from '@components/Button';
import { VARIANTS as TEXT_VARIANT } from '@components/Text';
import * as Styled from '@components/Dialog/style';

const Dialog = ({ message, onAccept, isOpen, closeHandler }) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      closeHandler={closeHandler}
      variant={MODAL_VARIANS.DEFAULT}
    >
      <Styled.DialogInner>
        <Styled.Message variant={TEXT_VARIANT.H1}>{message}</Styled.Message>
        <Styled.Buttons>
          <Button
            onClick={onAccept}
            variant={VARIANTS.GREEN}
            label={t('Yes')}
          />
          <Button
            onClick={closeHandler}
            variant={VARIANTS.PRIMARY}
            label={t('No')}
          />
        </Styled.Buttons>
      </Styled.DialogInner>
    </Modal>
  );
};

Dialog.propTypes = {
  message: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
};

export default Dialog;
