import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { breakpoint } from '@styles/breakpoints';

export const SidebarCategory = styled.div`
  ${({ desktopcollapsed }) =>
    desktopcollapsed
      ? css`
          overflow-x: hidden;
        `
      : css`
          overflow-y: hidden;
        `}


  @media ${breakpoint.md} {
    height: calc(100vh - 53px);
  }
`;

export const Button = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
  width: 100%;

  button,
  a {
    display: block;
    width: fit-content;
    text-align: center;
    margin: 0 auto;
  }

  a {
    margin-bottom: 19px;
  }

  @media ${breakpoint.md} {
    background-color: ${({ theme }) => theme.backgrounds.secondary};
  }

  @media ${breakpoint.md} and (orientation: landscape) {
    display: flex;
    align-items: center;
    justify-content: center;

    a,
    button {
      margin: 0;
    }
    a {
      margin-right: 15px;
    }
  }
`;
