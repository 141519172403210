import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';

import App from '@root/App';
import * as stores from '@stores';
import * as serviceWorker from '@root/serviceWorker';

import '@root/utils/i18n';

ReactDOM.render(
  <Provider {...stores}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
