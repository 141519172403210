import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash-es';

import {
  Input,
  InputWrapper,
  VARIANTS,
  SIZES,
} from '@components/Form/TextField/style';
import {
  Asterix,
  Error,
  FieldWrapper,
  InputIndicatorWrapper,
  Label,
} from '@components/Form/style';
import i18n from '@utils/i18n';
import { MARGINS } from '@styles/globalStyles';
import resolvePath from '@utils/resolvePath';

export { VARIANTS, SIZES } from '@components/Form/TextField/style';
export { MARGINS } from '@styles/globalStyles';

const TextField = ({
  as,
  autofocus,
  name,
  label,
  register,
  type,
  required,
  style,
  errors,
  inputIndicator: InputIndicator,
  placeholder,
  variant,
  size,
  onChange,
  onFocus,
  fullWidth,
  mb,
  disabled,
  readOnly,
  className,
  rules,
  defaultValue,
  disableSpinners,
  rows,
  indicatorPadding,
  autoComplete,
}) => {
  const fieldId = useMemo(() => uniqueId(`input_${name}_`), []);

  const fieldError = resolvePath(errors, name);

  return (
    <FieldWrapper {...{ style, mb, className }}>
      {label ? (
        <Label htmlFor={fieldId}>
          {label} {required ? <Asterix>*</Asterix> : null}
        </Label>
      ) : null}
      <InputWrapper variant={variant} size={size}>
        <Input
          {...{
            as,
            id: fieldId,
            name,
            type,
            ref: register(
              rules || {
                required: {
                  value: required,
                  message: i18n.t('Required'),
                },
              },
            ),
            withError: fieldError ? 1 : 0,
            fullWidth,
            disableSpinners,
            autoComplete,
            placeholder,
            onChange,
            onFocus,
            autoFocus: autofocus,
            disabled,
            readOnly,
            defaultValue,
            rows,
            indicatorPadding,
          }}
        />
        {InputIndicator ? (
          <InputIndicatorWrapper>
            <InputIndicator />
          </InputIndicatorWrapper>
        ) : null}
      </InputWrapper>

      {fieldError ? <Error>{fieldError.message}</Error> : null}
    </FieldWrapper>
  );
};

TextField.defaultProps = {
  as: 'input',
  autofocus: false,
  type: 'text',
  autoComplete: 'off',
  required: false,
  style: {},
  errors: {},
  inputIndicator: null,
  variant: VARIANTS.DEFAULT,
  label: null,
  placeholder: null,
  size: null,
  onChange: null,
  onFocus: null,
  fullWidth: true,
  mb: MARGINS.SMALL,
  disabled: false,
  readOnly: false,
  className: undefined,
  rules: undefined,
  defaultValue: undefined,
  disableSpinners: false,
  rows: undefined,
  indicatorPadding: undefined,
};

TextField.propTypes = {
  as: PropTypes.oneOf(['input', 'textarea']),
  autofocus: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  autoComplete: PropTypes.string,
  register: PropTypes.func.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
  style: PropTypes.object,
  errors: PropTypes.object,
  inputIndicator: PropTypes.elementType,
  variant: PropTypes.oneOf(Object.keys(VARIANTS)),
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(SIZES)),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  fullWidth: PropTypes.bool,
  mb: PropTypes.oneOf(Object.values(MARGINS)),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.string,
  disableSpinners: PropTypes.bool,
  rows: PropTypes.number,
  indicatorPadding: PropTypes.number,
};

export default TextField;
