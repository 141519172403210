import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash-es';

import Preloader from '@components/Preloader';
import Briefing from '@components/Briefing';
import * as Styled from '@components/Briefings/style';
import ItemsNotFound from '@components/ItemsNotFound';

const Briefings = ({ briefingsStore: { getBriefings, briefings } }) => {
  useEffect(() => {
    getBriefings();
  }, [getBriefings]);

  return briefings.isLoading ? (
    <Preloader />
  ) : (
    <Styled.Briefings>
      {briefings.data.map(briefing => (
        <li key={uniqueId()}>
          <Briefing {...briefing} />
        </li>
      ))}
      {briefings.data.length === 0 && <ItemsNotFound name="briefings" />}
    </Styled.Briefings>
  );
};

Briefings.propTypes = {
  briefingsStore: PropTypes.shape({
    briefings: PropTypes.object,
    getBriefings: PropTypes.func,
  }).isRequired,
};

export default inject('briefingsStore')(observer(Briefings));
