import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';
import Text from '@components/Text';

export const Wrapper = styled.div`
  position: relative;
  padding: 5px;
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  &:hover {
    box-shadow: 0 0 5px 0 rgba(13, 13, 13, 0.15),
      0 0 12px 1px rgba(13, 13, 13, 0.25);
  }
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  border: 3px solid #eee;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);

  ${({ withBackground }) =>
    withBackground &&
    `
    box-shadow: 0 0 11px 0 rgba(13, 13, 13, 0.15);
    border: 1px solid rgb(208, 208, 207);
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 1);
    transition: box-shadow 0.2s;
    &:hover {
      box-shadow: 0 0 5px 0 rgba(13, 13, 13, 0.15),
        0 0 12px 1px rgba(13, 13, 13, 0.25);
    }
  `}
`;

export const WrapperWithoutBackground = styled.div`
  position: relative;
  padding: 5px;
  display: flex;
  flex-direction: column;
  margin: 8px 0;
`;

export const Link = styled(RouterLink)`
  display: contents;
`;
export const Image = styled.img`
  width: 100%;
  border-radius: 4px;
`;

export const TitleBar = styled.div`
  display: inline-flex;
  margin: 10px 0 5px 10px;
`;

export const Title = styled(Text)`
  font-family: ${props => props.theme.fonts.secondary.regular};
  width: 159px;
  height: 34px;
  overflow: hidden;
`;

export const ButtonWrapper = styled.div`
  display: inline-flex;
  position: relative;
  margin: auto 0 auto auto;
`;

export const MenuButton = styled.button`
  position: relative;
  border: none;
  height: 24px;
  padding: 0 6px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
`;

export const PlayButton = styled.div`
  margin-left: 4px;
`;
