import styled from '@emotion/styled';

export const Button = styled.button`
  background-color: white;
  position: fixed;
  bottom: 110px;
  right: 10px;
  padding: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  z-index: 9;
  opacity: ${({ hidden }) => (hidden ? '0' : '0.8')};

  img {
    transform: scale(2, 2);
  }
`;
