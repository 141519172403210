import PropTypes from 'prop-types';

export const Detail = PropTypes.shape({
  name: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
});

export const Section = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  button_text: PropTypes.string.isRequired,
  button_url: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  details: PropTypes.arrayOf(Detail),
};

export const License = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  sections: PropTypes.array,
};
