import styled from '@emotion/styled';
import Text from '@components/Text';
import { Link as GlobalLink } from 'react-router-dom';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  box-shadow: 0 0 12px 0 rgba(13, 13, 13, 0.15);
  border-radius: 4px;
  padding: 22px;
  height: 450px;
  position: relative;
`;

export const Title = styled(Text)`
  margin-top: 20px;
`;

export const Description = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.body};
  margin: 10px 0 20px 0;
  font-weight: 300;
`;

export const ImageContainer = styled.div`
  display: flex;
  height: 171px;
  > img {
    max-width: 100%;
    max-height: 110px;
    margin: auto;
  }
`;

export const Link = styled(GlobalLink)`
  position: absolute;
  bottom: 22px;
  left: 22px;
  font-size: ${({ theme }) => theme.fontSizes.body};
  font-weight: 900;
  color: ${({ theme }) => theme.colors.secondary};
  font-family: ${({ theme }) => theme.fonts.secondary.bold};
  text-decoration: underline;
`;
