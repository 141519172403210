import { makeAutoObservable, runInAction } from 'mobx';
import { API, APIRoutes } from '@app/api';

export class BannersStore {
  constructor() {
    makeAutoObservable(this);
  }

  banners = {
    isLoading: false,
    data: [],
    error: null,
  };

  singleBanner = {
    isLoading: false,
    data: {},
    error: null,
  };

  getBanners = async () => {
    try {
      this.banners.isLoading = true;

      const {
        data: { banners },
      } = await API(APIRoutes.BANNERS);
      runInAction(() => {
        this.banners.data = banners;
      });
    } catch (error) {
      runInAction(() => {
        this.banners.error = error;
      });
    } finally {
      runInAction(() => {
        this.banners.isLoading = false;
      });
    }
  };

  getSingleBanner = async id => {
    try {
      this.singleBanner.isLoading = true;

      const {
        data: { banner },
      } = await API(APIRoutes.BANNER(id));
      runInAction(() => {
        this.singleBanner.data = banner;
        this.singleBanner.error = null;
      });
    } catch (error) {
      runInAction(() => {
        this.singleBanner.error = error;
      });
    } finally {
      runInAction(() => {
        this.singleBanner.isLoading = false;
      });
    }
  };
}

export default new BannersStore();
