import styled from '@emotion/styled';
import Text from '@components/Text';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 300px;
  font-weight: 900;
  align-self: center;
  margin-top: 50px;

  @media ${breakpoint.sm} {
    font-size: 150px;
  }
`;

export const Description = styled(Text)`
  align-self: center;
  text-align: center;
`;
