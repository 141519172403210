import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Box, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';

import { VARIANTS as BTN_VARIANTS } from '@components/Button';
import { StepWrapper } from '@containers/ComposerSignUp/components/style';
import StepsIndicator from '@components/StepsIndicators';
import { SIGN_IN_STEPS } from '@root/stores/registrationStore';
import FormButton from '@components/Form/FormButton';

const ComposerSignUpForm = ({
  onSubmit,
  formStatus,
  countries,
  setPreviousStep,
  userCountry,
}) => {
  const { t } = useTranslation();
  const formRef = useRef(null);

  const {
    watch,
    register,
    handleSubmit,
    control,
    errors: validationErrors,
    clearErrors,
    trigger,
    getValues,
    reset,
  } = useForm({
    mode: 'onChange', // required to clear errors from 'trigger'
  });

  const { currentStep, errors: formErrors, isLoading } = formStatus;

  const errors = {
    ...validationErrors,
    ...formErrors,
  };

  const onNextStep = async () => {
    const isStepValid = await trigger(SIGN_IN_STEPS[currentStep].fields);

    if (isStepValid) {
      const values = getValues();
      onSubmit(values);
    }
  };

  const onPreviousStep = () => {
    clearErrors();
    setPreviousStep();
  };

  useEffect(() => {
    if (userCountry) {
      reset({
        country: userCountry,
        country_of_residence: userCountry,
        bank_country: userCountry,
      });
    }
  }, [userCountry]);

  return (
    <form
      id="composer-sign-up-form"
      ref={formRef}
      onSubmit={handleSubmit(onSubmit)}
    >
      <StepsIndicator steps={SIGN_IN_STEPS} currentStep={currentStep} />
      <Flex width={1} alignItems="center" flexDirection="column">
        {SIGN_IN_STEPS.map(({ Component }, index) => (
          <StepWrapper
            key={`step-${index}`}
            step={index}
            currentStep={currentStep}
          >
            <Component {...{ watch, register, control, errors, countries }} />
          </StepWrapper>
        ))}
        <Flex width={1} mt={4} mb={3} justifyContent="center">
          {currentStep > 0 && (
            <FormButton
              variant={BTN_VARIANTS.PRIMARY}
              label={t('Previous step')}
              onClick={onPreviousStep}
            />
          )}
          <Box flex={1} textAlign="right">
            {currentStep < SIGN_IN_STEPS.length - 1 ? (
              <FormButton
                isLoading={isLoading}
                variant={BTN_VARIANTS.SECONDARY}
                label={t('Next step')}
                onClick={onNextStep}
              />
            ) : (
              <FormButton
                isLoading={isLoading}
                type="submit"
                variant={BTN_VARIANTS.GREEN}
                label={t('Sign up')}
              />
            )}
          </Box>
        </Flex>
      </Flex>
    </form>
  );
};

ComposerSignUpForm.defaultProps = {
  countries: [],
  userCountry: null,
};

ComposerSignUpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formStatus: PropTypes.object.isRequired,
  userCountry: PropTypes.object,
  countries: PropTypes.array,
  setPreviousStep: PropTypes.func.isRequired,
};

export default ComposerSignUpForm;
