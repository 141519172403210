import axios from 'axios';
import ahoy from 'ahoy.js';
import { currentLanguage } from '@utils/i18n';
import { getAuthHeaders } from '@utils/authStorage';
import { authStore } from '@stores';

export const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const setAuthenticationHeaders = ({ token, client, uid, expiry }) => {
  if ((token, client, uid, expiry)) {
    API.defaults.headers.common['token-type'] = `Bearer`;
    API.defaults.headers.common['access-token'] = `${token}`;
    API.defaults.headers.common.client = client;
    API.defaults.headers.common.uid = uid;
    API.defaults.headers.common.expiry = expiry;
  }
};

export const clearAuthenticationHeaders = () => {
  delete API.defaults.headers.common['token-type'];
  delete API.defaults.headers.common['access-token'];
  delete API.defaults.headers.common.client;
  delete API.defaults.headers.common.uid;
  delete API.defaults.headers.common.expiry;
};

setAuthenticationHeaders({ ...getAuthHeaders() });

API.defaults.headers['accept-language'] = currentLanguage;

API.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      authStore.logout();
    }
    return Promise.reject(error.response.data);
  },
);

ahoy.configure({
  urlPrefix: process.env.REACT_APP_API_URL,
  visitsUrl: '/ahoy/visits',
  eventsUrl: '/ahoy/events',
  page: null,
  platform: 'Web',
  useBeacon: true,
  startOnReady: true,
  trackVisits: true,
  cookies: true,
  cookieDomain: null,
  headers: API.defaults.headers.common,
  visitParams: {},
  withCredentials: false,
});

const pathVersion = {
  basic: 'v1',
  updated: 'v2',
};

export const APIRoutes = {
  SIGN_IN: `/${pathVersion.updated}/auth/sign_in`,
  SIGN_UP: `/${pathVersion.updated}/auth`,
  PASSWORD: `/${pathVersion.updated}/auth/password/`,
  DELETE_ACCOUNT: `/${pathVersion.updated}/auth`,
  TOPICS: `/${pathVersion.basic}/topics`,
  LABELS: `/${pathVersion.basic}/labels?kind=intervox`,
  PLAYLISTS_CATEGORIES: `/${pathVersion.basic}/playlists/categories`,
  ALBUMS: (page = 1, per = 48) =>
    `/${pathVersion.updated}/albums?page=${page}&per=${per}`,
  ALBUM_DETAILS: uuid => `/${pathVersion.updated}/albums/${uuid}`,
  ALBUM_TRACKS: uuid => `/${pathVersion.updated}/albums/${uuid}/tracks`,
  ALBUM_DOWNLOAD: (id, kind = 'wav') =>
    `/${pathVersion.basic}/albums/${id}/download_zip?kind=${kind}`,
  ALBUM_CUESHEET: uuid => `/${pathVersion.updated}/albums/${uuid}/cuesheet.csv`,
  SEARCH_SUGGESTED: (query = '') =>
    `/${pathVersion.updated}/searches/suggestions${query ? `?q=${query}` : ''}`,
  SEARCH_PHRASES: (page = 1, per = 99) =>
    `/${pathVersion.updated}/homepage/search_phrases?page=${page}&per=${per}`,
  SEARCH: (page = 1, per = 25, params = '', sort = '') =>
    `/${pathVersion.updated}/tracks/search?page=${page}&per=${per}${
      params ? `&${params}` : ''
    }${sort ? `&sort=${sort}` : ''}`,
  CURATED_PLAYLISTS: `/${pathVersion.updated}/homepage/curated_playlists`,
  CREW: `/${pathVersion.basic}/crew`,
  CREDITS: `/${pathVersion.updated}/credits`,
  NEWS: `/${pathVersion.updated}/homepage/news`,
  BANNERS: `/${pathVersion.updated}/homepage/banners`,
  BANNER: id => `/${pathVersion.updated}/banners/${id}`,
  TRUSTED_BIES: `/${pathVersion.updated}/homepage/trusted_bies`,
  EXTRA_FOR_YOU: (page = 1, per = 4) =>
    `${pathVersion.updated}/tracks/extra_for_you?page=${page}&per=${per}`,
  CUSTOMER: `/${pathVersion.basic}/customer`,
  COUNTRIES: `/${pathVersion.basic}/countries`,
  MOODS: `/${pathVersion.basic}/moods`,
  TEMPOS: `/${pathVersion.basic}/tempos`,
  GENRES: `/${pathVersion.basic}/genres`,
  INSTRUMENTATIONS: `/${pathVersion.basic}/instrumentations`,
  KEYWORD: uuid => `/${pathVersion.basic}/keywords/${uuid}`,
  KEYWORDS: query => `/${pathVersion.updated}/keywords?q=${query}`,
  KEYWORDS_SUGGESTIONS: (keyword, page = 1, per = 25) =>
    `/${pathVersion.updated}/keywords?q=${keyword}&page=${page}&per=${per}`,
  WRITE_FOR_US: `/${pathVersion.updated}/write_for_us`,
  WRITE_FOR_US_PAGE: `/${pathVersion.basic}/pages/v2_write_for_us`,
  TUTORIALS: (page = 1, per = 6) =>
    `/${pathVersion.updated}/tutorials?page=${page}&per=${per}`,
  AGENTS: `/${pathVersion.basic}/agents`,
  LAST_RELEASES: `/${pathVersion.updated}/homepage/last_releases`,
  COMPOSER: uuid => `/${pathVersion.basic}/composers/${uuid}`,
  COMPOSER_CUSTOMERS: `/${pathVersion.basic}/composers_customers`,
  COMPOSERS_SUGGESTIONS: (composer, page = 1, per = 25) =>
    `/${pathVersion.updated}/composers?q=${composer}&page=${page}&per=${per}`,
  CONTACT_US: `/${pathVersion.updated}/contact_us`,
  CONTACT_US_PAGE: `/${pathVersion.basic}/pages/v2_web_contacts_page`,
  COLLECTIONS: uuid => `/${pathVersion.updated}/album_collections/${uuid}`,
  REQUEST_MUSIC: `/${pathVersion.basic}/music_consultations `,
  REQUEST_MUSIC_PAGE: `/${pathVersion.basic}/pages/v2_web_music_consult_page`,
  PLAYLISTS: (page = 1, per = 48, category_uuid) =>
    category_uuid
      ? `/${pathVersion.updated}/playlists?playlist_category_uuid=${category_uuid}&page=${page}&per=${per}`
      : `/${pathVersion.updated}/playlists?page=${page}&per=${per}`,
  PLAYLIST_DETAILS: uuid => `/${pathVersion.updated}/playlists/${uuid}`,
  PLAYLIST_TRACKS: uuid => `/${pathVersion.updated}/playlists/${uuid}/tracks`,
  TRACK_DOWNLOAD: (externalId, format) =>
    `${process.env.REACT_APP_API_URL}/${pathVersion.basic}/tracks/${externalId}/download?file_format=${format}`,
  TRACK_CUESHEET: (uuid, trackId) =>
    `/${pathVersion.updated}/albums/${uuid}/cuesheet.csv?track_id=${trackId}`,
  TRACK_ALTERNATIVES: trackId =>
    `/${pathVersion.updated}/tracks/${trackId}/alternatives `,
  LICENSES: `/${pathVersion.updated}/licenses`,
  BRIEFINGS: `/${pathVersion.basic}/briefings`,
  GET_BRIEFING_TRACK: trackId =>
    `/${pathVersion.basic}/briefings/${trackId}/composer_track`,
  SUBMIT_BRIEFING_TRACK: uuid =>
    `/${pathVersion.updated}/briefings/${uuid}/tracks`,
  UPDATE_BRIEFING_TRACK_FORM: (uuid, id) =>
    `/${pathVersion.updated}/briefings/${uuid}/tracks/${id}`,
  UPLOAD_FULL_TRACK: (uuid, id) =>
    `/${pathVersion.updated}/briefings/${uuid}/tracks/${id}`,
  UPDATE_ALTERNATIVE_TRACK: uuid =>
    `/${pathVersion.updated}/briefings/${uuid}/tracks/update_alternatives`,
  ADD_ALTERNATIVE_TACK: (uuid, id) =>
    `/${pathVersion.updated}/briefings/${uuid}/tracks/${id}/create_alternatives`,
  VALIDATE_TRACK_NAME: name =>
    `${pathVersion.basic}/briefings/validate_track_name?name=${name}`,
  CHECK_COMPOSERS: (type, ipi) =>
    `/${pathVersion.updated}/composers/check_ipis?${type}[][cae_ipi]=${ipi}`,
  TRACK_DETAILS: trackId => `/${pathVersion.updated}/tracks/${trackId}`,
  FAVORITE_ALBUMS: `/${pathVersion.basic}/albums/favourited`,
  ADD_TO_FAVORITES: id => `/${pathVersion.basic}/albums/${id}/toggle`,
  RELEASED_TRACKS: (page = 1, per = 12, kind) =>
    `/${pathVersion.updated}/customer/tracks?per=${per}&page=${page}${
      kind ? `&kind=${kind}` : ''
    }`,
  UPLOADED_TRACKS: (page = 1, per = 12) =>
    `/${pathVersion.updated}/briefings/composer_tracks?per=${per}&page=${page}`,
  TRACKS_DOCKS: (format, uuid) =>
    `/${pathVersion.basic}/tracks/customer_tracks_${format}?uuid=${uuid}`,
  PROJECTS: (page = 1, per = 25) =>
    `/${pathVersion.updated}/projects?page=${page}&per=${per}`,
  DOWNLOAD_PROJECT: uuid => `/${pathVersion.updated}/projects/${uuid}/download`,
  DOWNLOAD_PROJECT_FILE: (uuid, format) =>
    `/${pathVersion.updated}/projects/${uuid}.${format}`,
  DELETE_PROJECT: uuid => `/${pathVersion.updated}/projects/${uuid}`,
  EDIT_PROJECT: uuid => `/${pathVersion.updated}/projects/${uuid}`,
  ADD_PROJECT: `/${pathVersion.updated}/projects`,
  TRACK_UPLOADED: (briefingUuid, id) =>
    `/${pathVersion.updated}/briefings/${briefingUuid}/tracks/${id}`,
  ALBUMS_OF_TOPIC: (page = 1, per = 12, topic) =>
    `/${pathVersion.updated}/topics/${topic}/albums?page=${page}&per=${per}`,
  PROJECT_TRACKS: uuid => `/${pathVersion.updated}/projects/${uuid}/tracks`,
  DELETE_TRACK_FROM_PROJECT: (projectUuid, trackId) =>
    `/${pathVersion.updated}/projects/${projectUuid}/tracks/${trackId}`,
  CONTRACTS: (page = 1, per = 50) =>
    `/${pathVersion.updated}/contracts?page=${page}&per=${per}`,
  LABEL_CONTRACTS: `/${pathVersion.updated}/customer/labels`,
  DOWNLOAD_CONTRACT: id => `/${pathVersion.updated}/contracts/${id}/download`,
  DOWNLOAD_CONTRACTS_DATA: format =>
    `/${pathVersion.updated}/contracts.${format}`,
  HELLO_SIGN_CONTRACTS: (page = 1, per = 50) =>
    `/${pathVersion.updated}/hello_sign_contracts?page=${page}&per=${per}`,
  EMBEDDED_SIGN: (id, clientId) =>
    `/${pathVersion.updated}/hello_sign_contracts/${id}/embedded_sign_url?client_id=${clientId}`,
  DOWNLOAD_HELLO_CONTRACT: id =>
    `/${pathVersion.updated}/hello_sign_contracts/${id}/download`,
  DOWNLOAD_CUSTOMER_TRACKS: uuid =>
    `/${pathVersion.basic}/tracks/customer_tracks_pdf?uuid=${uuid}`,
  UPLOAD_CONTRACT: `/${pathVersion.updated}/contracts/upload_contract`,
  STAY_TUNED: `${pathVersion.updated}/homepage/stay_tuned`,
  LABEL: uuid => `/${pathVersion.basic}/labels/${uuid}`,
  ALBUMS_OF_LABEL: (page = 0, per = 48, label) =>
    `/${pathVersion.updated}/labels/${label}/albums?page=${page}&per=${per}`,
  COMPOSERS_OF_LABEL: label =>
    `/${pathVersion.updated}/labels/${label}/composers`,
  TRACKS_OF_LABEL: label =>
    `/${pathVersion.updated}/labels/${label}/popular_tracks`,
  DOWNLOADS_LIST: (page = 1, per = 20) =>
    `${pathVersion.basic}/customer/track_downloads?page=${page}&per=${per}`,
  DATA_PROTECTION_PAGE: `/${pathVersion.basic}/pages/v2_web_data_protection_page`,
  IMPRESSUM_PAGE: `/${pathVersion.basic}/pages/v2_web_impressum_page`,
  AGB_PAGE: `/${pathVersion.basic}/pages/v2_agb`,
  ABOUT_CONTENT: `/${pathVersion.basic}/pages/v2_web_about_us`,
  ABOUT_STATISTICS: `/${pathVersion.updated}/about_us`,
  COOKIES_PAGE: `/${pathVersion.basic}/pages/v2_cookie`,
  O_AUTH: {
    FACEBOOK: `/${pathVersion.basic}/oauth/authorizations/facebook/callback`,
    GOOGLE: `/${pathVersion.basic}/oauth/authorizations/google/callback`,
  },
  LICENSE_APPLICATION: uuid =>
    `/${pathVersion.updated}/licenses/${uuid}/license_applications`,
  SINGLE_LICENSE: uuid => `/${pathVersion.updated}/licenses/${uuid}`,
  LICENSE_TRACK: (q = '', per = 7) =>
    `/${pathVersion.updated}/licenses/tracks?q=${q}&per=${per}`,
  FAQS: `${pathVersion.basic}/faqs`,
  DOCUMENTS: `${pathVersion.updated}/documents`,
  DOCUMENT_DOWNLOAD: id => `${pathVersion.updated}/documents/${id}/download`,
  COMPOSER_WEBSITES: `/${pathVersion.updated}/composer_websites`,
  COMPOSER_WEBSITE: id => `/${pathVersion.updated}/composer_websites/${id}`,
  COMPOSER_WEBSITE_TRACKS: id =>
    `/${pathVersion.updated}/composer_websites/${id}/tracks`,
};
