import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';

import Text, { VARIANTS as TEXT_VARIANTS } from '@components/Text';
import TextField from '@components/Form/TextField';
import DeleteButton from '@components/IconButtons/DeleteButton';
import useBreakpoint from '@hooks/useBreakpoint';

import { InputRow } from '@containers/BriefingPage/components/style';

const AuthorRow = ({
  index,
  item,
  commonTextFieldProps,
  dataKey,
  onRemove,
  type,
  register,
  setValue,
  validateUniqueIpi,
  validateIpi,
  validateShares,
  disableRemove,
}) => {
  const { t } = useTranslation();

  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  useEffect(() => {
    register(`${dataKey}.isNew`);
    setValue(`${dataKey}.isNew`, item.isNew);
  }, [register, index]);

  return (
    <InputRow mx={-1} mb={3} alignItems="center">
      {isMobile && (
        <Flex width={1} alignItems="center" justifyContent="space-between">
          <Box px={1}>
            <Text variant={TEXT_VARIANTS.H4_REGULAR}>
              {(index + 1).toString().padStart(2, '0')}
            </Text>
          </Box>
          <Box px={1}>
            <Text variant={TEXT_VARIANTS.H4_REGULAR}>
              <DeleteButton
                disabled={disableRemove}
                callback={() => onRemove(type, index)}
              />
            </Text>
          </Box>
        </Flex>
      )}
      {!isMobile && (
        <Box width={100} px={1}>
          <Text variant={TEXT_VARIANTS.H4_REGULAR}>
            {(index + 1).toString().padStart(2, '0')}
          </Text>
        </Box>
      )}
      <Box width={1} px={1}>
        <TextField
          {...{
            name: `${dataKey}.first_name`,
            label: t('First name'),
            placeholder: t('First name'),
            ...commonTextFieldProps,
            required: false,
            defaultValue: item.first_name,
          }}
        />
      </Box>
      <Box width={1} px={1}>
        <TextField
          {...{
            name: `${dataKey}.last_name`,
            label: t('Last name'),
            placeholder: t('Last name'),
            ...commonTextFieldProps,
            required: true,
            defaultValue: item.last_name,
          }}
        />
      </Box>
      <Box width={1} px={1}>
        <TextField
          {...{
            name: `${dataKey}.shares`,
            label: t('Shares'),
            placeholder: t('Shares'),
            type: 'number',
            defaultValue: item.shares,

            ...commonTextFieldProps,
            required: true,
            rules: {
              required: {
                value: true,
                message: t('Required'),
              },
              max: {
                value: 100,
                message: t('Max 100'),
              },
              min: {
                value: 1,
                message: t('Min 1'),
              },
              validate: {
                sum: () =>
                  validateShares() || t('Shares sum must be equal to 100'),
              },
            },
          }}
        />
      </Box>
      <Box width={1} px={1}>
        <TextField
          {...{
            name: `${dataKey}.cae_ipi`,
            label: t('IPI number'),
            placeholder: t('IPI number'),
            type: 'number',
            ...commonTextFieldProps,
            required: true,
            defaultValue: item.cae_ipi,
            disableSpinners: true,

            rules: {
              validate: {
                required: v => v?.length > 0 || t('Required'),
                // eslint-disable-next-line no-return-await
                exists: async v =>
                  (await validateIpi(type, v)) || t('IPI not found'),
                unique: v =>
                  validateUniqueIpi(type, v) || t('IPI already added'),
              },
            },
          }}
        />
      </Box>
      {!isMobile && (
        <Box width={100} px={1}>
          <Text variant={TEXT_VARIANTS.H4_REGULAR}>
            <DeleteButton
              disabled={disableRemove}
              callback={() => onRemove(type, index)}
            />
          </Text>
        </Box>
      )}
    </InputRow>
  );
};

AuthorRow.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  commonTextFieldProps: PropTypes.object.isRequired,
  dataKey: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  validateIpi: PropTypes.func.isRequired,
  validateUniqueIpi: PropTypes.func.isRequired,
  validateShares: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  disableRemove: PropTypes.bool.isRequired,
};

export default AuthorRow;
