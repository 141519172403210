import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Preloader from '@components/Preloader';
import Table from '@components/Table';
import i18n from '@utils/i18n';
import TrackCell from '@components/TracksTable/components/TrackCell';
import UploadButton from '@components/IconButtons/UploadButton';
import DeleteButton from '@components/IconButtons/DeleteButton';
import * as Styled from '@components/UploadedTracks/style';
import Dialog from '@components/Dialog';
import Paginator from '@components/Paginator';
import ItemsNotFound from '@components/ItemsNotFound';
import { history } from '@app/history';
import routes from '@routes';
import { PLAYER_CONTEXTS } from '@components/PlayerControlButtons/PlayPause';

/* eslint-disable react/prop-types */
const COLUMNS = [
  {
    title: <span style={{ marginLeft: '40px' }}>{i18n.t('Track')}</span>,
    field: 'name',
    style: {
      width: '30%',
    },
    render: ({ row }) => (
      <TrackCell track={row} playerContext={PLAYER_CONTEXTS.UPLOADED} />
    ),
  },
  {
    title: i18n.t('Briefing'),
    field: 'briefing',
    style: {
      width: '20%',
    },
    render: ({ cell: { name } }) => name,
  },
  {
    title: i18n.t('Date uploaded'),
    field: 'created_at',
    style: {
      width: '20%',
    },
    render: ({ cell }) => moment(cell).format('DD/MM/YYYY'),
  },
  {
    title: i18n.t('Status'),
    field: 'state',
    style: {
      width: '20%',
    },
    // eslint-disable-next-line react/prop-types
    render: ({ cell }) =>
      cell ? (
        <Styled.StatusAccepted>{cell}</Styled.StatusAccepted>
      ) : (
        <Styled.StatusPending>pending</Styled.StatusPending>
      ),
  },
  {
    title: i18n.t(''),
    field: 'action',
    style: {
      width: '20%',
    },
    render: ({ cell }) => (
      <Styled.FloatRight>
        {(() => {
          if (cell === null) {
            return null;
            // eslint-disable-next-line
          } else if (cell.name === 'delete') {
            return <DeleteButton callback={cell.callback} />;
            // eslint-disable-next-line
          } else if (cell.name === 'upload') {
            return <UploadButton callback={cell.callback} />;
          }
          return null;
        })()}
      </Styled.FloatRight>
    ),
  },
];
/* eslint-disable */

const UploadedTracks = ({
  uploadedTracksStore: {
    getUploadedTracks,
    uploadedTracks,
    deleteTrack,
    isModalOpen,
    setTracksModalOpen,
  },
}) => {
  const { t } = useTranslation();
  const [tracks, setTracks] = useState([]);
  const [deleted, setDeleted] = useState({});

  const openDeleteModal = (briefingUuid, id, name) => {
    setDeleted({ briefingUuid, id, name });
    setTracksModalOpen(true);
  };

  useEffect(() => {
    getUploadedTracks();
  }, [getUploadedTracks]);

  const paginate = async page => {
    getUploadedTracks(page, 12);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (!uploadedTracks.data || uploadedTracks.data.length === 0) return;
    const actions = uploadedTracks.data.map(({ id, name, briefing, state }) => {
      if (state === 'accepted') {
        return {
          callback: () => {
            history.push(routes.track(id));
          },
          name: 'upload',
        };
      } else if (state !== 'accepted' && !briefing.expired) {
        return {
          callback: () => openDeleteModal(briefing.uuid, id, name),
          name: 'delete',
        };
      } else {
        return null;
      }
    });
    const tracksWithActions = uploadedTracks.data.map((track, index) => ({
      ...track,
      action: actions[index],
    }));
    setTracks(tracksWithActions);
  }, [uploadedTracks.data]);

  return (
    <>
      {uploadedTracks.isLoading && <Preloader />}
      {tracks.length > 0 && !uploadedTracks.isLoading ? (
        <Table data={tracks} columns={COLUMNS} />
      ) : (
        !uploadedTracks.isLoading && (
          <ItemsNotFound name={t('uploaded tracks')} />
        )
      )}
      <Dialog
        message={t('Do you want to remove {{name}}', {
          name: deleted.name || '',
        })}
        onAccept={() =>
          deleteTrack(deleted.briefingUuid, deleted.id, deleted.name)
        }
        isOpen={isModalOpen}
        closeHandler={() => setTracksModalOpen(false)}
      />
      <Paginator length={uploadedTracks.pagesAmount} onPageChange={paginate} />
    </>
  );
};

UploadedTracks.propTypes = {
  uploadedTracksStore: PropTypes.shape({
    getUploadedTracks: PropTypes.func,
    deleteTrack: PropTypes.func,
    isModalOpen: PropTypes.bool,
    setTracksModalOpen: PropTypes.func,
    uploadedTracks: PropTypes.object,
  }).isRequired,
};

export default inject('uploadedTracksStore')(observer(UploadedTracks));
