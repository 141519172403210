import 'swiper/swiper.scss';
import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled.div`
  position: relative;
  margin: 90px auto;
  width: 100%;
  max-width: 1610px;

  @media ${breakpoint.md} {
    margin: 90px 16px;
    width: auto;
  }

  .swiper-container {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.backgrounds.banner};
  }
`;

export const Pagination = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  top: unset;
  bottom: 10px;
  right: 0;
  left: 0;
  justify-content: center;

  > .swiper-pagination-bullet {
    cursor: pointer;
    border: 1px solid #fff;
    border-radius: 100%;
    margin-bottom: 10px;
    margin-right: 10px;
    width: 10px;
    height: 10px;
    z-index: 10;

    &-active {
      background: #fff;
    }
  }
`;
