import React from 'react';
import PropTypes from 'prop-types';
import Paginate from 'react-paginate';
import { useResizeDetector } from 'react-resize-detector';
import * as Styled from '@components/Paginator/style';
import useBreakpoint from '@hooks/useBreakpoint';
import RIGHT_ARROW from '@assets/images/icons/small-right-arrow.svg';
import LEFT_ARROW from '@assets/images/icons/small-left-arrow.svg';

const BUTTON_SPACE = 45;
const COMMON_CELLS = 6;

const Paginator = ({ length, onPageChange }) => {
  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  const { ref, width } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 100,
  });

  let pageRangeDisplayed = 5;

  if (width > 1200) {
    pageRangeDisplayed = 9;
  } else if (width > 1000) {
    pageRangeDisplayed = 7;
  } else if (isMobile && width < 720) {
    pageRangeDisplayed = Math.round(width / BUTTON_SPACE) - COMMON_CELLS;
  } else if (width < 550) {
    pageRangeDisplayed = 3;
  }

  return length > 1 ? (
    <Styled.Paginator ref={ref}>
      <Paginate
        containerClassName="paginator"
        pageClassName="paginator__inner paginator__inner__link"
        marginPagesDisplayed="1"
        pageRangeDisplayed={pageRangeDisplayed}
        pageCount={length}
        activeClassName="paginator__inner__link--active"
        breakClassName="paginator__inner paginator__inner__break"
        previousLabel={<Styled.ArrowImage src={LEFT_ARROW} alt="previous" />}
        nextLabel={<Styled.ArrowImage src={RIGHT_ARROW} alt="previous" />}
        onPageChange={({ selected }) => onPageChange(selected + 1)}
      />
    </Styled.Paginator>
  ) : null;
};

Paginator.propTypes = {
  length: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
};

Paginator.defaultProps = {
  length: 0,
};

export default Paginator;
