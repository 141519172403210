import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import routes from '@routes';
import * as Styled from '@components/Footer/components/Links/style';
import { isLanguage, LANG_KEYS } from '@utils/i18n';
import { KINDS } from '@containers/Agents';

const Links = ({ searchStore: { openSearchModal } }) => {
  const { t } = useTranslation();

  return (
    <Styled.LinksWrapper>
      <ul>
        <li>
          <Styled.SearchButton
            type="button"
            onClick={() => openSearchModal(false)}
          >
            {t('Browse')}
          </Styled.SearchButton>
        </li>
        <li>
          <Link to={routes.albums}>{t('Latest')}</Link>
        </li>
        <li>
          <Link to={routes.playlists}>{t('Playlists')}</Link>
        </li>
        <li>
          <Link to={routes.labels}>{t('Labels')}</Link>
        </li>
        {!isLanguage(LANG_KEYS.EN) && (
          <li>
            <Link to={routes.license}>{t('License-footer')}</Link>
          </li>
        )}
      </ul>
      <ul>
        <li>
          {isLanguage(LANG_KEYS.CZ) ? (
            <a
              href={routes.artists('en')}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Artists')}
            </a>
          ) : (
            <Link to={routes.composerWebsites}>{t('Artists')}</Link>
          )}
        </li>
        <li>
          <Link to={routes.crew}>{t('Meet the crew')}</Link>
        </li>
        {!isLanguage(LANG_KEYS.CZ) && (
          <li>
            <Link to={routes.agents(KINDS[0]?.value)}>{t('Agents')}</Link>
          </li>
        )}
        <li>
          <Link to={routes.contactUs}>{t('Contact us')}</Link>
        </li>
        <li>
          <Link to={routes.aboutUs}>{t('About Intervox')}</Link>
        </li>
      </ul>
    </Styled.LinksWrapper>
  );
};

Links.propTypes = {
  searchStore: PropTypes.shape({
    openSearchModal: PropTypes.func,
  }).isRequired,
};

export default inject('searchStore')(observer(Links));
