import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';
import { useTranslation } from 'react-i18next';

import Card, { VARIANTS as CARD_VARIANTS } from '@components/Card';
import Dropzone, { FilesList } from '@components/Dropzone';
import FormButton from '@components/Form/FormButton';
import { VARIANTS as BUTTON_VARIANTS } from '@components/Button';
import formatBytes from '@utils/formatBytes';

const UploadContract = ({
  contractsStore: { contractsFiles, addContractsFiles, uploadContract },
}) => {
  const { t } = useTranslation();

  const filesList = contractsFiles.map(file => (
    <li key={file.path}>
      {file.path} - {formatBytes(file.size)}
    </li>
  ));

  const onSubmit = e => {
    e.preventDefault();
    uploadContract();
  };

  return (
    <Box>
      <Card variant={CARD_VARIANTS.WHITE}>
        <form onSubmit={onSubmit}>
          <Dropzone
            {...{
              accept: '.pdf',
              onDrop: files => addContractsFiles(files),
              text: t('Drag and drop pdf files here, or click to select files'),
            }}
          />
          {filesList.length > 0 && (
            <Box my={3}>
              <h4>{t('Files')}</h4>
              <FilesList>{filesList}</FilesList>
            </Box>
          )}
          <Box mt={4} textAlign="center">
            <FormButton
              type="submit"
              variant={BUTTON_VARIANTS.GREEN}
              label={t('Submit contract')}
            />
          </Box>
        </form>
      </Card>
    </Box>
  );
};

UploadContract.propTypes = {
  contractsStore: PropTypes.shape({
    contractsFiles: PropTypes.array,
    addContractsFiles: PropTypes.func,
    uploadContract: PropTypes.func,
  }).isRequired,
};

export default inject('contractsStore')(observer(UploadContract));
