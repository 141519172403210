import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const SelectWrapper = styled.div`
  max-width: 335px;
  min-width: 240px;
  flex: 1;

  @media ${breakpoint.lg} {
    max-width: unset;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  direction: row;
  gap: 16px;

  @media ${breakpoint.lg} {
    flex: 1;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 16px;

  @media ${breakpoint.lg} {
    button {
      width: 100%;
    }
  }

  @media ${breakpoint.md} {
    // flex-direction: column;
    width: 100%;

    ${SelectWrapper} {
      max-width: unset;
    }

    ${ButtonWrapper} {
      justify-content: space-between;

      button {
        width: 100%;
      }
    }
  }
`;
