import React from 'react';
import PropTypes from 'prop-types';
import useClipboard from '@hooks/useClipboard';
import toggleShareMenu from '@utils/toggleShareMenu';
import { StyledButton } from '@components/IconButtons/style';
import ShareIcon from '@assets/images/icons/share-icon.svg';

const ShareButton = ({ copyText, notification }) => {
  const copyRef = useClipboard(copyText, notification);

  return (
    <StyledButton
      onClick={navigator.share && (() => toggleShareMenu(copyText))}
      ref={navigator.share ? null : copyRef}
    >
      <img src={ShareIcon} alt="share-icon" />
    </StyledButton>
  );
};

ShareButton.propTypes = {
  copyText: PropTypes.string.isRequired,
  notification: PropTypes.string.isRequired,
};

export default ShareButton;
