import React from 'react';
import PropTypes from 'prop-types';

import { Button, Text } from 'rebass';
import { useTranslation } from 'react-i18next';

const FacebookButton = ({ onClick, disabled }) => {
  const { t } = useTranslation();

  return (
    <Button
      type="button"
      onClick={onClick}
      disabled={disabled}
      sx={{
        borderRadius: '999px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#1877f2',
        px: '12px',
        fontFamily:
          '-apple-system, BlinkMacSystemFont, Roboto, Arial, Helvetica, sans-serif',
        fontWeight: 'bold',
        letterSpacing: '0.6px',
      }}
    >
      <svg
        viewBox="0 0 213 213"
        width="24"
        height="24"
        preserveAspectRatio="xMinYMin"
      >
        <path
          d="M90,212v-75h-27v-31h27v-25q0,-40 40,-40q15,0 24,2v26h-14q-16,0 -16,16v21h30l-5,31h-27v75a106 106,0,1,0,-32 0"
          className="f_logo_circle"
          fill="white"
        />
        <path
          d="M90,212v-75h-27v-31h27v-25q0,-40 40,-40q15,0 24,2v26h-14q-16,0 -16,16v21h30l-5,31h-27v75a106 106,1,0,1,-32 0"
          className="f_logo_f"
          fill="#1877f2"
        />
      </svg>
      <Text as="span" ml="8px" flex={1}>
        {/* {t('Continue with Facebook')} */}
        Continue with Facebook
      </Text>
    </Button>
  );
};

FacebookButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default FacebookButton;
