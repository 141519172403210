import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Styled from '@components/SideBar/components/SidebarCategories/style';
import { inject, observer } from 'mobx-react';
import useBreakpoint from '@hooks/useBreakpoint';
import routes from '@routes';
import Button from '@components/Button';
import SideBarItemsList from '@components/SideBar/components/SideBarItemList';
import SideBarItemLink from '@components/SideBar/components/SideBarItemLink';
import MailIcon from '@assets/images/icons/mail-icon.svg';

const SidebarCategories = ({
  isMobileOpen,
  sidebarStore: { isSideBarOpened, sidebarCategories },
}) => {
  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  const { t } = useTranslation();

  return (
    <Styled.SidebarCategory
      isMobileOpen={isMobileOpen}
      desktopcollapsed={isSideBarOpened ? 1 : 0}
    >
      {sidebarCategories.map(item => {
        if (item.type === 'list') {
          return (
            <SideBarItemsList
              key={`sidebar-category_${item.id}`}
              item={item}
              isSideBarOpened={isSideBarOpened}
            />
          );
        }
        if (item.type === 'link') {
          return (
            <SideBarItemLink
              label={item.label}
              key={`sidebar-category_${item.id}`}
              icon={item.icon}
              to={item.link}
            />
          );
        }

        return null;
      })}
      {isMobile && (
        <SideBarItemLink
          label={t('Request music')}
          to={routes.requestMusic}
          icon={MailIcon}
        />
      )}

      {isSideBarOpened && !isMobile && (
        <Styled.Button isMobileOpen={isMobileOpen}>
          <Button to={routes.requestMusic} label={t('Request music')} />
        </Styled.Button>
      )}
    </Styled.SidebarCategory>
  );
};

SidebarCategories.defaultProps = {
  isMobileOpen: false,
};

SidebarCategories.propTypes = {
  isMobileOpen: PropTypes.bool,
  sidebarStore: PropTypes.shape({
    isSideBarOpened: PropTypes.bool,
    sidebarCategories: PropTypes.array,
  }).isRequired,
};

export default inject('sidebarStore')(observer(SidebarCategories));
