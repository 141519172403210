/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash-es';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Flex, Box } from 'rebass';

import SwiperCore, { Navigation, Scrollbar } from 'swiper';
import { useResizeDetector } from 'react-resize-detector';

import Preloader from '@components/Preloader';
import Button from '@components/Button';
import * as Styled from '@components/ItemsCarousel/style';
import { size } from '@styles/breakpoints';

SwiperCore.use([Navigation, Scrollbar]);

export const { POSITIONS } = Styled;

const ItemsCarousel = ({
  buttonLabel,
  items,
  title,
  link,
  isLoading,
  itemComponent,
  itemProps,
  itemPropsFromItem,
  buttonPrev,
  buttonNext,
  scrollbar,
  buttonsPositionForSM,
  ofComposers,
}) => {
  const carouselId = uniqueId();
  const [initialized, setInitialized] = useState(false);
  const [slidesPerView, setSlidesPerView] = useState(7);
  const { ref, width } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 100,
  });

  useEffect(() => {
    setInitialized(true);
    if (width && ofComposers) {
      if (width >= 2100) setSlidesPerView(8);
      else if (width >= 1400) setSlidesPerView(6);
      else if (width >= 1140) setSlidesPerView(5);
      else if (width >= 860) setSlidesPerView(4);
      else if (width >= 640) setSlidesPerView(3);
      else if (width >= 420) setSlidesPerView(2);
      else setSlidesPerView(1);
    }
  }, [width, ofComposers]);

  const allowTouchMoves = width ? width <= size.md : true;

  return (
    <Styled.Wrapper ref={ref} ofComposers={ofComposers}>
      <Flex>
        <Box width="100%">
          {title && (
            <Styled.Header margin="15px 0 20px 0">{title}</Styled.Header>
          )}
        </Box>
        {link && (
          <Styled.LinkWrapper>
            <Styled.Link to={link}>
              <Button label={buttonLabel} />
            </Styled.Link>
          </Styled.LinkWrapper>
        )}
      </Flex>

      {isLoading || !initialized ? <Preloader center /> : null}

      {items.length > 0 && (
        <Swiper
          spaceBetween={20}
          centeredSlidesBounds={width > size.sm || ofComposers}
          navigation={{
            nextEl: `#swiper-button-next-${carouselId}`,
            prevEl: `#swiper-button-previous-${carouselId}`,
          }}
          scrollbar={{
            draggable: true,
            el: `#swiper-scrollbar-${carouselId}`,
            dragSize: '13px',
          }}
          slidesPerView={ofComposers ? slidesPerView : 'auto'}
          centeredSlides={width <= size.sm && !ofComposers}
          allowTouchMove={allowTouchMoves}
        >
          {items.map(prop => (
            <SwiperSlide key={uniqueId()}>
              {() =>
                React.createElement(
                  itemComponent,
                  {
                    ...itemProps,
                    ...Object.entries(itemPropsFromItem).reduce(
                      (val, [key, value]) => ({
                        ...val,
                        [key]: { ...prop }[value],
                      }),
                      {},
                    ),
                  },
                  null,
                )
              }
            </SwiperSlide>
          ))}
          {({ scrollbar: scroll }) => <p>{scroll.toString()}</p>}
        </Swiper>
      )}
      <Styled.ButtonWrapper
        buttonsPositionForSM={buttonsPositionForSM}
        hideOnMobile={!!link}
      >
        <Styled.Button
          type="button"
          id={`swiper-button-previous-${carouselId}`}
          buttonsPositionForSM={buttonsPositionForSM}
          className="button-prev"
        >
          {buttonPrev}
        </Styled.Button>
        <Styled.Button
          type="button"
          id={`swiper-button-next-${carouselId}`}
          buttonsPositionForSM={buttonsPositionForSM}
          className="button-next"
        >
          {buttonNext}
        </Styled.Button>
        {React.cloneElement(scrollbar, {
          id: `swiper-scrollbar-${carouselId}`,
        })}
      </Styled.ButtonWrapper>
    </Styled.Wrapper>
  );
};

ItemsCarousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
  link: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.string,
  itemComponent: PropTypes.elementType.isRequired,
  itemProps: PropTypes.object,
  itemPropsFromItem: PropTypes.object.isRequired,
  buttonPrev: PropTypes.object.isRequired,
  buttonNext: PropTypes.object.isRequired,
  scrollbar: PropTypes.object.isRequired,
  buttonsPositionForSM: PropTypes.oneOf(Object.values(POSITIONS)),
  ofComposers: PropTypes.bool,
};

ItemsCarousel.defaultProps = {
  itemProps: {},
  title: undefined,
  link: undefined,
  buttonLabel: undefined,
  buttonsPositionForSM: POSITIONS.RELATIVE,
  ofComposers: false,
};

export default ItemsCarousel;
