import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Flex, Box } from 'rebass';
import { useTranslation } from 'react-i18next';

import formatBytes from '@utils/formatBytes';
import DeleteButton from '@components/IconButtons/DeleteButton';
import { COLORS, VARIANTS as TEXT_VARIANTS } from '@components/Text';
import { StyledText } from '@containers/BriefingPage/components/style';
import { FORM_TYPE, FILE_STATES } from '@containers/BriefingPage/constants';
import EditFileButton from '@containers/BriefingPage/components/EditFileButton';
import successIcon from '@assets/images/icons/upload-success-icon.svg';
import failIcon from '@assets/images/icons/upload-fail-icon.svg';
import FormButton from '@components/Form/FormButton';
import { VARIANTS as BTN_VARIANTS } from '@components/Button';
import ProgressBar from '@components/ProgressBar';
import Dropzone from '@components/Dropzone';

import PlayPauseButton, {
  PLAYER_CONTEXTS,
} from '@components/PlayerControlButtons/PlayPause';

const FullTrackRow = ({
  action,
  trackFile: { name, size, state },
  register,
  errors,
  addFullTrackFile,
  onUpload,
  fullTrackProgress,
  track,
  briefing,
}) => {
  const { t } = useTranslation();

  let fileActions = <DeleteButton callback={() => addFullTrackFile([])} />;

  if (action === FORM_TYPE.UPDATE) {
    if (state === FILE_STATES.NEW) {
      fileActions = (
        <FormButton
          variant={BTN_VARIANTS.SECONDARY}
          label={t('Upload')}
          onClick={onUpload}
        />
      );
    } else if (state === FILE_STATES.UPLOADING) {
      fileActions = (
        <FormButton
          isLoading
          variant={BTN_VARIANTS.SECONDARY}
          label={t('Upload')}
        />
      );
    } else if (state === FILE_STATES.ERROR) {
      fileActions = (
        <FormButton variant={BTN_VARIANTS.SECONDARY} label={t('Re-upload')} />
      );
    } else {
      fileActions = (
        <>
          <Box mr={2}>
            <PlayPauseButton
              context={PLAYER_CONTEXTS.UPLOADED}
              track={{
                ...track,
                name: `${track?.name || ''}`,
                album: briefing,
                singleTrack: true,
                isBriefing: true,
              }}
              outlined
            />
          </Box>
          <EditFileButton
            onChange={e => addFullTrackFile(e.target.files, FILE_STATES.NEW)}
          />
        </>
      );
    }
  }

  return (
    <>
      <input
        type="hidden"
        name="file_control"
        value={name || ''}
        ref={register({
          required: {
            value: true,
            message: t('Required'),
          },
        })}
      />
      <Box mb={4}>
        {name ? (
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center">
              {state === FILE_STATES.UPLOADED && (
                <img src={successIcon} alt="success-icon" />
              )}
              {state === FILE_STATES.ERROR && (
                <img src={failIcon} alt="fail-icon" />
              )}
              <StyledText variant={TEXT_VARIANTS.H4} color={COLORS.BLUE}>
                {name}
                {size && <>- {formatBytes(size)}</>}
              </StyledText>
            </Flex>
            <Flex justifyContent="flex-end" alignItems="center">
              {fileActions}
            </Flex>
          </Flex>
        ) : (
          <Dropzone
            {...{
              accept: '.wav',
              onDrop: files => addFullTrackFile(files),
              text: t('Drag and drop wav files here, or click to select files'),
              multiple: false,
              error: errors.file_control,
            }}
          />
        )}

        <ProgressBar
          progress={fullTrackProgress}
          my={3}
          prefix={`${t('Uploading')} 1 ${t('file')} `}
        />
      </Box>
    </>
  );
};

FullTrackRow.defaultProps = {
  trackFile: {},
  track: {},
};

FullTrackRow.propTypes = {
  action: PropTypes.oneOf(Object.values(FORM_TYPE)).isRequired,
  trackFile: PropTypes.shape({
    name: PropTypes.string,
    size: PropTypes.number,
    state: PropTypes.oneOf(Object.values(FILE_STATES)),
  }),
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  addFullTrackFile: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  fullTrackProgress: PropTypes.number.isRequired,
  track: PropTypes.object,
  briefing: PropTypes.object.isRequired,
};

export default observer(FullTrackRow);
