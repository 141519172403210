import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Box, Flex } from 'rebass';
import { MainContent } from '@styles/globalStyles';
import Preloader from '@components/Preloader';
import CustomerTabs from '@components/CustomerTabs';
import ProjectModal from '@containers/MyProjects/components/ProjectModal';
import ProjectRow from '@containers/MyProjects/components/ProjectRow';
import Button, { VARIANTS } from '@components/Button';
import ConfirmDialog from '@containers/MyProjects/components/ConfirmDialog';
import ItemsNotFound from '@components/ItemsNotFound';
import Header from '@components/Header';
import FormButton from '@components/Form/FormButton';

const MyProjects = ({
  projectsStore: { getProjects, getMoreProjects, projects, openProjectModal },
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    getProjects();
  }, [getProjects]);

  const { isLoading, data, expandedProject } = projects;

  return (
    <MainContent>
      <Helmet>
        <title>{t('My projects')}</title>
      </Helmet>
      <Header
        title={
          <Flex width={1} alignItems="center">
            <Box mr={4}>{t('My projects')}</Box>
            <Button
              variant={VARIANTS.GREEN}
              label={t('New project')}
              onClick={() => openProjectModal(null)}
            />
          </Flex>
        }
        nav={<CustomerTabs />}
      />
      {isLoading && <Preloader center />}
      {data.length > 0
        ? data.map(({ id, uuid, name }) => (
            <ProjectRow
              {...{
                key: id,
                id,
                uuid,
                name,
                isExpanded: expandedProject === uuid,
              }}
            />
          ))
        : !isLoading && <ItemsNotFound name={t('projects')} />}
      {data.length > 0 && !projects.last && (
        <Flex margin="auto" marginTop="30px">
          <FormButton
            isLoading={projects.isLoading}
            variant={VARIANTS.GREEN}
            label={t('Show more')}
            onClick={() => getMoreProjects()}
          />
        </Flex>
      )}
      <ProjectModal />
      <ConfirmDialog />
    </MainContent>
  );
};

MyProjects.propTypes = {
  projectsStore: PropTypes.shape({
    getProjects: PropTypes.func,
    getMoreProjects: PropTypes.func,
    onDeleteProject: PropTypes.func,
    openProjectModal: PropTypes.func,
    projects: PropTypes.shape({
      data: PropTypes.array,
      isLoading: PropTypes.bool,
      expandedProject: PropTypes.string,
      last: PropTypes.bool,
    }),
  }).isRequired,
};

export default inject('projectsStore')(observer(MyProjects));
