import React, { useEffect, useState, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { uniqueId, clamp } from 'lodash-es';
import WaveSurfer from 'wavesurfer.js';
import axios from 'axios';
import { Wrapper, Wave } from '@components/Waveform/style';
import getPointerPosition from '@utils/getPointerPosition';

const Waveform = ({
  waveformDataUrl,
  trackID,
  playerStore: { activeTrackId, trackPlayedPart, seekTo },
}) => {
  const isMounted = useRef(false);
  const [waveId] = useState(uniqueId(`track-waveform_`));
  const [waveformData, setWaveformData] = useState(null);
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    isMounted.current = true;

    const fetchWaveformJson = async () => {
      const { data } = await axios.get(waveformDataUrl);
      if (isMounted.current) {
        setWaveformData(data.data);
      }
    };
    fetchWaveformJson();

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (player) {
      if (activeTrackId === trackID) {
        player.seekTo(clamp(trackPlayedPart, 0, 1));
      }
    }
  }, [trackPlayedPart]);

  useEffect(() => {
    if (player) {
      if (activeTrackId !== trackID && player.gerCurrentTime() !== 0) {
        player.seekTo(0);
      }
    }
  }, [activeTrackId]);

  useEffect(() => {
    if (waveformData) {
      const p = WaveSurfer.create({
        container: `#${waveId}`,
        progressColor: '#049dbf',
        waveColor: '#c5c5c5',
        barWidth: 2,
        barRadius: 0,
        cursorWidth: 0,
        height: 40,
        barGap: 3,
        hideScrollbar: true,
        normalize: true,
        backend: 'MediaElement',
        responsive: true,
        removeMediaElementOnDestroy: false,
        interact: false,
      });

      p.backend.peaks = waveformData;
      p.drawBuffer();
      setPlayer(p);
    }
  }, [waveformData]);

  const seekPlayer = e => {
    if (activeTrackId !== trackID) return;
    const {
      percent: { x },
    } = getPointerPosition(e);

    seekTo(x);
  };

  return (
    <Wrapper onMouseDown={seekPlayer}>
      <Wave id={waveId} />
    </Wrapper>
  );
};

Waveform.propTypes = {
  waveformDataUrl: PropTypes.string.isRequired,
  trackID: PropTypes.number.isRequired,
  playerStore: PropTypes.object.isRequired,
};

export default inject('playerStore')(observer(Waveform));
