import { makeAutoObservable, runInAction } from 'mobx';
import { API, APIRoutes } from '@app/api';
import i18n from '@utils/i18n';

const DEFAULT_CATEGORY = { uuid: '', name: i18n.t('All playlists') };

export class PlaylistsStore {
  constructor() {
    makeAutoObservable(this);
  }

  curatedPlaylists = {
    isLoading: false,
    data: [],
    error: null,
  };

  playlistsCategories = {
    isLoading: false,
    data: [],
    error: null,
  };

  playlists = {
    categoryName: 'All playlists',
    categoryUuid: undefined,
    isLoading: false,
    data: [],
    error: null,
    last: false,
    page: 1,
    per: 48,
  };

  playlistDetails = {
    isLoading: false,
    data: {},
    error: null,
  };

  playlistTracks = {
    expandedTrack: null,
    expandedAlternatives: false,
    isLoading: false,
    data: [],
    error: null,
  };

  getPlaylists = async category => {
    this.playlists.error = null;
    this.playlists.isLoading = true;
    this.playlists.page = 1;
    try {
      if (this.playlistsCategories.data.length === 0) {
        await this.getPlaylistsCategories();
      }
      const correctCategory =
        this.playlistsCategories.data.find(({ uuid }) => uuid === category) ||
        DEFAULT_CATEGORY;

      const {
        data: {
          playlists,
          meta: { last },
        },
      } = await API(APIRoutes.PLAYLISTS(1, this.playlists.per, category));
      if (playlists.length === 0) {
        throw new Error(404, 'Playlists not found');
      }
      runInAction(() => {
        this.playlists.last = last;
        this.playlists.categoryName = correctCategory.name;
        this.playlists.categoryUuid = correctCategory.uuid;
        this.playlists.data = playlists;
      });
    } catch (error) {
      runInAction(() => {
        this.playlists.error = error;
      });
    } finally {
      runInAction(() => {
        this.playlists.isLoading = false;
      });
    }
  };

  getMorePlaylists = async category => {
    this.playlists.error = null;
    this.playlists.isLoading = true;
    const page = this.playlists.page + 1;

    try {
      const {
        data: {
          playlists,
          meta: { last, self },
        },
      } = await API(APIRoutes.PLAYLISTS(page, this.playlists.per, category));
      if (playlists.length === 0) {
        throw new Error(404, 'Playlists not found');
      }
      runInAction(() => {
        this.playlists.last = last;
        this.playlists.page = self;
        this.playlists.data = [...this.playlists.data, ...playlists];
      });
    } catch (error) {
      runInAction(() => {
        this.playlists.error = error;
      });
    } finally {
      runInAction(() => {
        this.playlists.isLoading = false;
      });
    }
  };

  getPlaylistsCategories = async () => {
    this.playlistsCategories.error = null;
    this.playlistsCategories.isLoading = true;

    try {
      const {
        data: { playlist_categories },
      } = await API(APIRoutes.PLAYLISTS_CATEGORIES);
      runInAction(() => {
        this.playlistsCategories.data = [
          DEFAULT_CATEGORY,
          ...playlist_categories,
        ];
      });
    } catch (error) {
      runInAction(() => {
        this.playlistsCategories.error = error;
      });
    } finally {
      runInAction(() => {
        this.playlistsCategories.isLoading = false;
      });
    }
  };

  getCuratedPlaylists = async () => {
    this.curatedPlaylists.error = null;
    this.curatedPlaylists.isLoading = true;

    try {
      const {
        data: { playlists },
      } = await API(APIRoutes.CURATED_PLAYLISTS);
      runInAction(() => {
        this.curatedPlaylists.data = playlists;
      });
    } catch (error) {
      runInAction(() => {
        this.curatedPlaylists.error = error;
      });
    } finally {
      runInAction(() => {
        this.curatedPlaylists.isLoading = false;
      });
    }
  };

  /* Single playlist */

  getPlaylistDetails = async uuid => {
    this.playlistDetails.error = null;
    this.playlistDetails.isLoading = true;

    try {
      const {
        data: { playlist },
      } = await API(APIRoutes.PLAYLIST_DETAILS(uuid));

      runInAction(() => {
        this.playlistDetails.data = playlist;
      });
    } catch (error) {
      runInAction(() => {
        this.playlistDetails.error = error;
      });
    } finally {
      runInAction(() => {
        this.playlistDetails.isLoading = false;
      });
    }
  };

  getPlaylistTracks = async uuid => {
    this.playlistTracks.error = null;
    this.playlistTracks.isLoading = true;

    try {
      const {
        data: { tracks },
      } = await API(APIRoutes.PLAYLIST_TRACKS(uuid));

      runInAction(() => {
        this.playlistTracks.data = tracks;
      });
    } catch (error) {
      runInAction(() => {
        this.playlistTracks.error = error;
      });
    } finally {
      runInAction(() => {
        this.playlistTracks.isLoading = false;
      });
    }
  };

  toggleTrack = trackId => {
    const { expandedTrack } = this.playlistTracks;
    this.playlistTracks.expandedTrack =
      expandedTrack === trackId ? null : trackId;
    this.playlistTracks.expandedAlternatives = false;
  };

  toggleTrackAlternatives = trackId => {
    const { expandedTrack, expandedAlternatives } = this.playlistTracks;

    if (expandedTrack === trackId) {
      if (expandedAlternatives) {
        this.playlistTracks.expandedAlternatives = false;
      } else {
        this.playlistTracks.expandedAlternatives = true;
        this.getTrackAlternatives(trackId);
      }
    } else {
      this.playlistTracks.expandedTrack = trackId;
      this.playlistTracks.expandedAlternatives = true;
      this.getTrackAlternatives(trackId);
    }
  };

  getTrackAlternatives = async trackId => {
    const track = this.playlistTracks.data.find(({ id }) => id === trackId);

    if (!track.alternatives) {
      try {
        track.alternatives = { isLoading: true };

        const {
          data: { tracks },
        } = await API(APIRoutes.TRACK_ALTERNATIVES(trackId));

        const tracksWithAlbum = tracks.map(t => ({ ...t, album: track.album }));

        runInAction(() => {
          track.alternatives.data = tracksWithAlbum;
        });
      } catch (error) {
        runInAction(() => {
          track.alternatives.error = error;
        });
      } finally {
        runInAction(() => {
          track.alternatives.isLoading = false;
        });
      }
    }
  };
}

export default new PlaylistsStore();
