import styled from '@emotion/styled';
import Scrollbar from 'react-perfect-scrollbar';

export default styled(Scrollbar)`
  position: relative;
  height: 100%;

  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;

  .ps__rail-y {
    display: none;
    opacity: 1;
    transition: background-color 0.2s linear, opacity 0.2s linear;
    -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
    width: 15px;
    /* there must be 'right' or 'left' for ps__rail-y */
    right: 10px;
    /* please don't change 'position' */
    position: absolute;

    width: 1px;
    height: 100%;
    background: ${({ theme }) => theme.scrollbar.content.track} !important;
    left: auto !important;
  }

  &.ps--active-y {
    padding-right: 25px;
  }

  &.ps--active-y > .ps__rail-y {
    display: block;
    background-color: transparent;
  }

  /* MS supports */
  @supports (-ms-overflow-style: none) {
    .ps {
      overflow: auto !important;
    }
  }

  .ps__thumb-y {
    /* please don't change 'position' */
    position: absolute;

    border-radius: 50%;
    width: ${({ theme }) => theme.scrollbar.content.scrollbar.size}px;
    height: ${({ theme }) => theme.scrollbar.content.scrollbar.size}px;
    background-color: black;
    right: ${({ theme }) =>
      (theme.scrollbar.content.scrollbar.size / 2) * -1 + 0.5}px;
  }
`;
