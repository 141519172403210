import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { VARIANTS } from '@components/Text';
import {
  DropzoneWrapper,
  DropzoneTitle,
  DropzoneText,
} from '@containers/BriefingPage/components/TrackDropzone/style';

const TrackDropzone = ({ accept, onDrop, file, multiple, isDragStarted }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    onDrop,
    multiple,
  });

  const { t } = useTranslation();

  return (
    <>
      <DropzoneWrapper
        {...getRootProps({ isDragActive })}
        isDragStarted={isDragStarted}
      >
        <input {...getInputProps()} autoComplete="off" />
        <DropzoneTitle>
          {isDragStarted ? (
            <DropzoneText variant={VARIANTS.NAV}>
              {t("Drag 'n' drop some files here")}
            </DropzoneText>
          ) : (
            file
          )}
        </DropzoneTitle>
      </DropzoneWrapper>
    </>
  );
};

TrackDropzone.defaultProps = {
  accept: '',
  file: undefined,
  multiple: false,
  isDragStarted: false,
};

TrackDropzone.propTypes = {
  accept: PropTypes.string,
  onDrop: PropTypes.func.isRequired,
  isDragStarted: PropTypes.bool,
  file: PropTypes.node,
  multiple: PropTypes.bool,
};

export default TrackDropzone;
