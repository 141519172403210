import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import ahoy from 'ahoy.js';
import { API, APIRoutes } from '@app/api';
import i18n from '@utils/i18n';

export const MODAL_MODES = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
};

export const CONFIRM_TYPES = {
  PROJECT: 'PROJECT',
  TRACK: 'TRACK',
};

export class ProjectsStore {
  constructor() {
    makeAutoObservable(this);
  }

  projects = {
    expandedProject: null,
    isLoading: false,
    data: [],
    error: null,
    last: false,
    page: 1,
    per: 25,
  };

  projectModal = {
    isOpen: false,
    mode: MODAL_MODES.CREATE,
    data: {},
    form: {
      isLoading: false,
      errors: {},
    },
  };

  projectTracks = {
    expandedTrack: null,
    expandedAlternatives: false,
    isLoading: false,
    data: [],
    error: null,
  };

  addToProjectModal = {
    isOpen: false,
    data: {},
    form: {
      isLoading: false,
      errors: {},
    },
  };

  confirmDialog = {
    isOpen: false,
    message: '',
    type: '',
    data: {},
  };

  tracks = [];

  getProjects = async () => {
    this.projects.page = 1;
    try {
      this.projects.isLoading = true;

      const {
        data: {
          projects,
          meta: { last },
        },
      } = await API(APIRoutes.PROJECTS(1, this.projects.per));
      runInAction(() => {
        this.projects.last = last;
        this.projects.data = projects;
      });
    } catch (error) {
      runInAction(() => {
        this.projects.error = error;
      });
    } finally {
      runInAction(() => {
        this.projects.isLoading = false;
      });
    }
  };

  getMoreProjects = async () => {
    this.projects.error = null;
    this.projects.isLoading = true;
    const page = this.projects.page + 1;

    try {
      const {
        data: {
          projects,
          meta: { last, self },
        },
      } = await API(APIRoutes.PROJECTS(page, this.projects.per));
      runInAction(() => {
        this.projects.last = last;
        this.projects.page = self;
        this.projects.data = [...this.projects.data, ...projects];
      });
    } catch (error) {
      runInAction(() => {
        this.projects.error = error;
      });
    } finally {
      runInAction(() => {
        this.projects.isLoading = false;
      });
    }
  };

  deleteProject = async ({ deleteUuid, name }) => {
    try {
      await API.delete(APIRoutes.DELETE_PROJECT(deleteUuid));

      const newProjects = this.projects.data.filter(
        ({ uuid }) => uuid !== deleteUuid,
      );

      runInAction(() => {
        this.projects.data = newProjects;
        this.closeConfirmDialog();
      });

      toast(i18n.t('Project {{name}} has been removed', { name }));
    } catch (error) {
      console.warn(error); // eslint-disable-line no-console
    }
  };

  onDeleteProject = (deleteUuid, name) => {
    this.confirmDialog = {
      isOpen: true,
      message: i18n.t('Do you want to remove {{name}} project?', { name }),
      data: { deleteUuid, name },
      type: CONFIRM_TYPES.PROJECT,
    };
  };

  closeProjectModal = () => {
    this.projectModal.isOpen = false;
    this.projectModal.data = {};
    this.projectModal.form.errors = {};
  };

  openProjectModal = (data = null) => {
    this.projectModal.isOpen = true;

    if (data) {
      this.projectModal.mode = MODAL_MODES.EDIT;
      this.projectModal.data = data;
    } else {
      this.projectModal.mode = MODAL_MODES.CREATE;
    }
  };

  submitProject = async (formData, mode = MODAL_MODES.CREATE) => {
    let project = {};

    try {
      runInAction(() => {
        this.projectModal.form.isLoading = true;
        this.projectModal.mode = mode;
      });

      if (this.projectModal.mode === MODAL_MODES.CREATE) {
        const {
          data: { project: newProject },
        } = await API.post(APIRoutes.ADD_PROJECT, formData);
        project = newProject;

        toast(i18n.t('Project has been added'));
      } else {
        const { uuid } = this.projectModal.data;

        const {
          data: { project: newProject },
        } = await API.put(APIRoutes.EDIT_PROJECT(uuid), formData);
        project = newProject;

        toast(i18n.t('Project has been updated'));
      }

      this.getProjects();

      runInAction(() => {
        this.closeProjectModal();
      });
    } catch (e) {
      const formErrors = { ...e.errors };
      if (formErrors.name) {
        formErrors.name = formData.name;
      }

      runInAction(() => {
        this.projectModal.form.errors = formErrors;
      });
    } finally {
      runInAction(() => {
        this.projectModal.form.isLoading = false;
      });
    }

    return project;
  };

  addTrackToNewProject = async data => {
    const project = await this.submitProject(data);
    if (project) this.submitAddToProject(project);
  };

  toggleProject = uuid => {
    const { expandedProject } = this.projects;

    if (expandedProject === uuid) {
      this.projects.expandedProject = null;
    } else {
      this.projects.expandedProject = uuid;
      this.getProjectTracks(uuid);
    }
  };

  getProjectTracks = async uuid => {
    try {
      this.projectTracks.isLoading = true;

      const {
        data: { tracks },
      } = await API(APIRoutes.PROJECT_TRACKS(uuid));

      runInAction(() => {
        this.projectTracks.data = tracks;
        this.tracks = tracks;
      });
    } catch (error) {
      runInAction(() => {
        this.projectTracks.error = error;
      });
    } finally {
      runInAction(() => {
        this.projectTracks.isLoading = false;
      });
    }
  };

  toggleTrack = trackId => {
    const { expandedTrack } = this.projectTracks;
    this.projectTracks.expandedTrack =
      expandedTrack === trackId ? null : trackId;
  };

  toggleTrackAlternatives = trackId => {
    const { expandedTrack, expandedAlternatives } = this.projectTracks;

    if (expandedTrack === trackId) {
      if (expandedAlternatives) {
        this.projectTracks.expandedAlternatives = false;
      } else {
        this.projectTracks.expandedAlternatives = true;
        this.getTrackAlternatives(trackId);
      }
    } else {
      this.projectTracks.expandedTrack = trackId;
      this.projectTracks.expandedAlternatives = true;
      this.getTrackAlternatives(trackId);
    }
  };

  getTrackAlternatives = async trackId => {
    const track = this.projectTracks.data.find(({ id }) => id === trackId);

    if (!track.alternatives) {
      try {
        track.alternatives = { isLoading: true };

        const {
          data: { tracks },
        } = await API(APIRoutes.TRACK_ALTERNATIVES(trackId));

        const tracksWithAlbum = tracks.map(t => ({ ...t, album: track.album }));

        runInAction(() => {
          track.alternatives.data = tracksWithAlbum;
        });
      } catch (error) {
        runInAction(() => {
          track.alternatives.error = error;
        });
      } finally {
        runInAction(() => {
          track.alternatives.isLoading = false;
        });
      }
    }
  };

  closeAddToProjectModal = () => {
    this.addToProjectModal.isOpen = false;
    this.addToProjectModal.data = {};
    this.addToProjectModal.form.errors = {};
  };

  openAddToProjectModal = data => {
    this.addToProjectModal.isOpen = true;
    this.addToProjectModal.data = data;
  };

  submitAddToProject = async ({ uuid, id }) => {
    const payload = {
      id: this.addToProjectModal.data.id,
    };

    try {
      await API.post(APIRoutes.PROJECT_TRACKS(uuid), payload);

      toast(i18n.t('Track has been added'));

      runInAction(() => {
        this.closeAddToProjectModal();
      });
      ahoy.track('add_track_to_project', {
        id,
        track_id: payload.id,
      });
    } catch (e) {
      let formErrors = {};
      if (e.errors.track) {
        const alreadyTaken =
          this.addToProjectModal.form.errors.projectsTaken || [];
        formErrors = {
          projectsTaken: [...alreadyTaken, uuid],
        };
      }

      runInAction(() => {
        this.addToProjectModal.form.errors = formErrors;
      });
    } finally {
      runInAction(() => {
        this.addToProjectModal.form.isLoading = false;
      });
    }
  };

  deleteTrackFromProject = async ({ id: trackId, name }) => {
    try {
      await API.delete(
        APIRoutes.DELETE_TRACK_FROM_PROJECT(
          this.projects.expandedProject,
          trackId,
        ),
      );

      const newTracks = this.projectTracks.data.filter(
        ({ id }) => id !== trackId,
      );

      runInAction(() => {
        this.projectTracks.data = newTracks;
        this.closeConfirmDialog();
      });

      toast(i18n.t('Track {{name}} has been removed', { name }));
    } catch (error) {
      console.warn(error); // eslint-disable-line no-console
    }
  };

  onDeleteTrackFromProject = ({ id, name }) => {
    this.confirmDialog = {
      isOpen: true,
      message: `Do you want to remove ${name} from project?`,
      data: { id, name },
      type: CONFIRM_TYPES.TRACK,
    };
  };

  closeConfirmDialog = () => {
    this.confirmDialog = {
      isOpen: false,
      message: '',
      type: '',
      data: {},
    };
  };
}

export default new ProjectsStore();
