import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Preloader from '@components/Preloader';
import * as Styled from '@components/SearchPhrases/style';
import RightArrowIcon from '@assets/images/icons/small-right-arrow.svg';
import LeftArrowIcon from '@assets/images/icons/small-left-arrow.svg';

const SearchPhrases = ({ searchPhrases, onSelect }) => {
  const scrollTrack = useRef();

  const scrollContainerBy = direction => {
    const left = (scrollTrack.current.clientWidth / 2) * direction;
    scrollTrack.current.scrollBy({ left, behavior: 'smooth' });
  };

  return (
    <Styled.Wrapper>
      {searchPhrases.isLoading && <Preloader center />}
      <Styled.TagsScroll ref={scrollTrack}>
        {searchPhrases.data
          ? searchPhrases.data.map(({ phrase }, index) => (
              <Styled.Tag
                key={`search-phrase_${index}`}
                onClick={() => onSelect(phrase)}
              >
                {phrase}
              </Styled.Tag>
            ))
          : null}
        <Styled.DummyItem />
      </Styled.TagsScroll>
      {!searchPhrases.isLoading ? (
        <Styled.TagsControls>
          <Styled.ScrollButton
            type="button"
            onClick={() => scrollContainerBy(-1)}
          >
            <img src={LeftArrowIcon} alt="" />
          </Styled.ScrollButton>
          <Styled.ScrollButton
            type="button"
            onClick={() => scrollContainerBy(1)}
          >
            <img src={RightArrowIcon} alt="" />
          </Styled.ScrollButton>
        </Styled.TagsControls>
      ) : null}
    </Styled.Wrapper>
  );
};

SearchPhrases.propTypes = {
  searchPhrases: PropTypes.shape({
    isLoading: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.object),
    error: PropTypes.string,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default SearchPhrases;
