import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { currentLanguage } from '@utils/i18n';
import * as Styled from '@components/Footer/components/ContactUs/style';
import routes from '@routes';

import { MAIL, PHONE } from '@utils/i18n/locationsContent';

export const ContactUs = () => {
  const { t } = useTranslation();

  return (
    <Styled.ContactUsWrapper>
      <Styled.ContactUsMain>
        <p>{t('Contact us')}</p>
        <p>
          <a href={`mailto:${MAIL[currentLanguage]}`}>
            {MAIL[currentLanguage]}
          </a>
        </p>
        <p>
          <a href={`tel:${PHONE[currentLanguage]}`}>{PHONE[currentLanguage]}</a>
        </p>
      </Styled.ContactUsMain>
      <Styled.ContactUsSecondary>
        <Link to={routes.agb}>{t('AGB')}</Link>
        {` / `}
        <Link to={routes.impressum}>{t('Imprint')}</Link>
        {` / `}
        <Link to={routes.dataProtection}>{t('Data Protection')}</Link>
        {` / `}
        <Link to={routes.cookies}>{t('Cookies')}</Link>
      </Styled.ContactUsSecondary>
    </Styled.ContactUsWrapper>
  );
};

export default ContactUs;
