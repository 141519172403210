import React from 'react';
import PropTypes from 'prop-types';
import ahoy from 'ahoy.js';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import Text, { VARIANTS } from '@components/Text';
import routes from '@routes';

import * as Styled from '@components/TooltipSelect';
import DOWNLOAD_ICON from '@assets/images/icons/download-icon.svg';
import STAR_ICON from '@assets/images/icons/star-icon.svg';
import STAR_ICON_SAVED from '@assets/images/icons/star-icon-saved.svg';
import SHARE_ICON from '@assets/images/icons/share-icon.svg';
import useClipboard from '@hooks/useClipboard';
import { APIRoutes } from '@app/api';
import toggleShareMenu from '@utils/toggleShareMenu';

const AlbumTooltipBody = ({
  favoritesStore: { checkIsFavorite, toggleFavorites },
  customerStore: { customer },
  authStore: { isAuthenticated },
  blockDownloadStore: { isDownloadBlocked, openBlockedDownloadModal },
  id,
  uuid,
}) => {
  const { t } = useTranslation();
  const copyText = `${window.location.origin}${routes.album(uuid)}`;
  const copyRef = useClipboard(
    copyText,
    t("Album's url has been copied to clipboard"),
  );

  const isFavorite = checkIsFavorite(id);

  const downloadItemProps = {
    type: 'button',
    disabled: !isAuthenticated,
    ...(!isAuthenticated && { title: t('Sign in to download') }),
    as: isAuthenticated && !isDownloadBlocked ? 'a' : 'button',
  };

  return (
    <>
      <Styled.MenuItem
        href={
          customer && isAuthenticated
            ? `${process.env.REACT_APP_API_URL}${APIRoutes.ALBUM_DOWNLOAD(
                id,
                'wav',
              )}`
            : '#'
        }
        onClick={
          isAuthenticated && !isDownloadBlocked
            ? () => ahoy.track('download_album_zip', { id })
            : openBlockedDownloadModal
        }
        {...downloadItemProps}
      >
        <Styled.Icon src={DOWNLOAD_ICON} alt="download" />
        <Text variant={VARIANTS.NAV}>{t('Download album')} WAV</Text>
      </Styled.MenuItem>
      <Styled.Divider />
      <Styled.MenuItem
        href={
          customer && isAuthenticated
            ? `${process.env.REACT_APP_API_URL}${APIRoutes.ALBUM_DOWNLOAD(
                id,
                'mp3',
              )}`
            : '#'
        }
        onClick={
          isAuthenticated && !isDownloadBlocked
            ? () => ahoy.track('download_album_zip', { id })
            : openBlockedDownloadModal
        }
        {...downloadItemProps}
      >
        <Styled.Icon src={DOWNLOAD_ICON} alt="download" />
        <Text variant={VARIANTS.NAV}>{t('Download album')} MP3</Text>
      </Styled.MenuItem>
      <Styled.Divider />
      <Styled.MenuItem
        {...{
          type: 'button',
          onClick: () => toggleFavorites(id),
          disabled: !isAuthenticated,
          title: isAuthenticated ? null : t('Sign in to save album'),
        }}
      >
        {isFavorite ? (
          <>
            <Styled.Icon src={STAR_ICON_SAVED} alt="remove" />
            <Text variant={VARIANTS.NAV}>{t('Remove Album')}</Text>
          </>
        ) : (
          <>
            <Styled.Icon src={STAR_ICON} alt="save" />
            <Text variant={VARIANTS.NAV}>{t('Save Album')}</Text>
          </>
        )}
      </Styled.MenuItem>
      <Styled.Divider />
      <Styled.MenuItem
        type="button"
        onClick={navigator.share && (() => toggleShareMenu(copyText))}
        ref={navigator.share ? null : copyRef}
      >
        <Styled.Icon src={SHARE_ICON} alt="share" />
        <Text variant={VARIANTS.NAV}>{t('Share')}</Text>
      </Styled.MenuItem>
      <Styled.Divider />
      <Styled.MenuItem
        href={
          customer && isAuthenticated
            ? `${process.env.REACT_APP_API_URL}${APIRoutes.ALBUM_CUESHEET(
                uuid,
              )}`
            : '#'
        }
        {...downloadItemProps}
        {...(isDownloadBlocked && { onClick: openBlockedDownloadModal })}
      >
        <Styled.Icon src={DOWNLOAD_ICON} alt="download" />
        <Text variant={VARIANTS.NAV}>{t('Download cuesheet')}</Text>
      </Styled.MenuItem>
    </>
  );
};

AlbumTooltipBody.propTypes = {
  id: PropTypes.number.isRequired,
  uuid: PropTypes.string.isRequired,
  customerStore: PropTypes.object.isRequired,
  authStore: PropTypes.object.isRequired,
  favoritesStore: PropTypes.shape({
    checkIsFavorite: PropTypes.func,
    toggleFavorites: PropTypes.func,
  }).isRequired,
  blockDownloadStore: PropTypes.object.isRequired,
};

export default inject(
  'favoritesStore',
  'customerStore',
  'authStore',
  'blockDownloadStore',
)(observer(AlbumTooltipBody));
