import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)`
  padding: 0 8px;
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: bold;
  white-space: nowrap;

  &:hover {
    color: #ffffff;
  }

  @media ${breakpoint.md} {
    display: block;
    padding: 8px 0;
  }
`;

export const StyledText = styled.div`
  font-weight: 300;
`;
