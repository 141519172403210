import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass';

import Header from '@components/Header';
import { MainContent } from '@styles/globalStyles';
import GridList from '@components/GridList';
import Preloader from '@components/Preloader';
import ComposerItem from '@components/ComposerItem';
import { Link } from 'react-router-dom';
import routes from '@routes';
import FormButton from '@components/Form/FormButton';
import { VARIANTS } from '@components/Button';

const ComposerWebsites = ({
  composersWebsiteStore: { getComposers, getMoreComposers, composers },
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    getComposers();
  }, [getComposers]);

  return (
    <MainContent>
      <Header title={t('Composer Websites')} withSeparator />

      {composers.data.length === 0 && composers.isLoading && <Preloader />}
      <GridList>
        {composers.data.map(
          ({ composer: { first_name, last_name }, image, id }) => (
            <li key={id}>
              <Link to={routes.composerWebsite(id)}>
                <ComposerItem
                  firstName={first_name}
                  lastName={last_name}
                  image={image}
                />
              </Link>
            </li>
          ),
        )}
      </GridList>
      {composers.data.length > 0 && !composers.meta.last && (
        <Flex margin="auto">
          <FormButton
            isLoading={composers.isLoading}
            variant={VARIANTS.GREEN}
            label={t('Show more')}
            onClick={() => getMoreComposers(composers.meta.next)}
          />
        </Flex>
      )}
    </MainContent>
  );
};

ComposerWebsites.propTypes = {
  composersWebsiteStore: PropTypes.shape({
    composers: PropTypes.object,
    getComposers: PropTypes.func.isRequired,
    getMoreComposers: PropTypes.func.isRequired,
  }).isRequired,
};

export default inject('composersWebsiteStore')(observer(ComposerWebsites));
