import React from 'react';
import { Link } from 'react-router-dom';
import { Flex, Box } from 'rebass';
import moment from 'moment';
import 'moment-timezone';

import { useTranslation } from 'react-i18next';
import { uniqueId } from 'lodash-es';
import { PropTypes } from 'prop-types';

import Text, { VARIANTS as TEXT_VARIANTS } from '@components/Text';
import { VARIANTS } from '@components/Button';
import * as Styled from '@components/Briefing/style';
import BriefingCell from '@components/Briefing/BriefingCell';
import routes from '@routes';

import LOGO from '@assets/images/logos/logo-white@3x.jpg';

const timezone = moment.tz.guess();

const Briefing = ({
  name,
  moods,
  links,
  uuid,
  description,
  deadline,
  cover,
}) => {
  const { t } = useTranslation();

  return (
    <Styled.Wrapper>
      <Flex width={1} flexWrap="wrap" justifyContent="space-between">
        <Flex>
          <Styled.Image src={cover.url || LOGO} alt="logo" />
          <Box margin="auto">
            <Text variant={TEXT_VARIANTS.H3}>{`${t('Brief')}: ${name}`}</Text>
          </Box>
        </Flex>
        <Box>
          <Styled.Button
            as={Link}
            to={routes.briefing(uuid)}
            variant={VARIANTS.GREEN}
            label={t('Submit track')}
          />
        </Box>
      </Flex>
      <Styled.Grid>
        <BriefingCell title={t('Description')} fillAvaliable>
          {description}
        </BriefingCell>
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          sx={{ rowGap: '20px' }}
        >
          <BriefingCell title={t('Deadline')}>
            {moment(deadline).format('DD-MM-YYYY HH:mm')}
            {timezone && <Styled.Timezone>({timezone})</Styled.Timezone>}
          </BriefingCell>
          {moods.length > 0 && (
            <BriefingCell title={t('Track Mood')}>
              {moods.reduce(
                (acc, mood, index) =>
                  index !== 0 ? `${acc} / ${mood.name}` : acc,
                moods[0].name,
              )}
            </BriefingCell>
          )}
        </Flex>
        <BriefingCell title={t('Sugested links')} fillAvaliable>
          <Styled.Links>
            {links.map(({ url }) => (
              <Styled.Link key={uniqueId()}>
                <a href={url} rel="noopener noreferrer" target="_blank">
                  {url}
                </a>
              </Styled.Link>
            ))}
          </Styled.Links>
        </BriefingCell>
      </Styled.Grid>
    </Styled.Wrapper>
  );
};

Briefing.propTypes = {
  name: PropTypes.string.isRequired,
  moods: PropTypes.array.isRequired,
  links: PropTypes.array.isRequired,
  uuid: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  deadline: PropTypes.string.isRequired,
  cover: PropTypes.object,
};

Briefing.defaultProps = {
  cover: { url: null },
};

export default Briefing;
