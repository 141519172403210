import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';

export const Dots = styled.div`
  display: flex;
`;

const moveit = keyframes`
  0% {
    transform: translateY(0px);
  }
  35% {
    transform: translateY(0px);
    opacity: 0.3;
  }
  50% {
    transform: translateY(-10px);
    opacity: 0.8;
  }
  70% {
    transform: translateY(3px);
    opacity: 0.8;
  }
  85% {
    transform: translateY(-3px);
  }
`;

export const Dot = styled.div`
  display: inline-block;
  background: ${({ theme }) => theme.colors.preloader};
  height: 4px;
  width: 4px;
  margin: 0 1px;
  opacity: 0.3;
  border-radius: 50%;
  animation: ${moveit} 1.8s infinite;

  &:nth-of-type(2) {
    animation-delay: 0.15s;
  }

  &:nth-of-type(3) {
    animation-delay: 0.3s;
  }

  &:nth-of-type(4) {
    animation-delay: 0.45s;
  }

  &:nth-of-type(5) {
    animation-delay: 0.6s;
  }
`;

export const PreloaderWrapper = styled.div`
  display: inline-flex;
  align-items: center;

  ${({ center }) =>
    center
      ? css`
          width: 100%;
          height: 100%;
          justify-content: center;
        `
      : null}

  ${({ cover }) =>
    cover
      ? css`
          position: absolute;
          top: 0;
          left: 0;
          z-index: 5;
          background-color: rgba(255, 255, 255, 0.5);
        `
      : null}

  ${({ light, theme }) =>
    light
      ? css`
          ${Dot} {
            background: ${theme.colors.white};
          }
        `
      : null}
`;
