import { API, APIRoutes } from '@app/api';
import { makeAutoObservable, runInAction } from 'mobx';
import moment from 'moment';
import { toast } from 'react-toastify';

import getFileName from '@utils/getFileName';
import downloadFile from '@utils/downloadFile';
import customerStore from '@stores/customerStore';
import i18n from '@utils/i18n';

const formatContract = (
  { file, created_at, uploader_name, id },
  offset,
  idx,
) => ({
  no: (offset + idx + 1).toString().padStart(3, '0'),
  file_name: getFileName(file.url),
  created_at: moment(created_at).format('hh:mmA / dddd Do MMMM YYYY'),
  uploader_name,
  file: { id },
});

const formatLabelContract = ({ name, uuid }, idx) => ({
  no: (idx + 1).toString().padStart(3, '0'),
  uploader_name: 'Intervox',
  created_at: moment(new Date()).format('dddd Do MMMM YYYY'),
  file_name: `${name} - TRACKS`,
  file: { uuid },
});

const PER_PAGE = 50;
export class LabelsStore {
  constructor() {
    makeAutoObservable(this);
  }

  contracts = {
    isLoading: false,
    data: [],
    meta: {},
    error: null,
  };

  labelContractsLength = 0;

  contractsFiles = [];

  getContracts = async (page = 1) => {
    try {
      this.contracts.isLoading = true;

      let formattedLabelContracts = [];

      if (page === 1) {
        const {
          data: { labels },
        } = await API(APIRoutes.LABEL_CONTRACTS);

        formattedLabelContracts = labels.map(formatLabelContract);

        this.labelContractsLength = formattedLabelContracts.length;
      }

      const {
        data: { contracts, meta },
      } = await API(APIRoutes.CONTRACTS(page, PER_PAGE));

      const offset = (page - 1) * PER_PAGE + this.labelContractsLength;

      const formattedContracts = contracts.map((c, idx) =>
        formatContract(c, offset, idx),
      );

      const contractsData = [...formattedLabelContracts, ...formattedContracts];

      const totalPages = Math.ceil(meta.total_count / PER_PAGE);

      runInAction(() => {
        this.contracts.data = contractsData;
        this.contracts.meta = {
          ...meta,
          totalPages,
        };
      });
    } catch (error) {
      runInAction(() => {
        this.contracts.error = error;
      });
    } finally {
      runInAction(() => {
        this.contracts.isLoading = false;
      });
    }
  };

  formatFileName = fileName =>
    `${customerStore.customer.data.last_name}_${fileName
      .toLowerCase()
      .replace(' - ', '_')
      .replace(' ', '-')}`;

  downloadLabelContract = (uuid, fileName) =>
    downloadFile(
      APIRoutes.DOWNLOAD_CUSTOMER_TRACKS(uuid),
      this.formatFileName(fileName),
      'pdf',
    );

  downloadContract = (id, fileName) =>
    downloadFile(APIRoutes.DOWNLOAD_CONTRACT(id), fileName, 'pdf');

  addContractsFiles = files => {
    this.contractsFiles = files;
  };

  uploadContract = async () => {
    try {
      const formData = new FormData();

      this.contractsFiles.forEach(file => formData.append(`file`, file));

      await API.post(APIRoutes.UPLOAD_CONTRACT, formData);

      toast(i18n.t('Contract uploaded successfully'));
      this.contractsFiles = [];
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };
}

export default new LabelsStore();
