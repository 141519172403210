import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Box } from 'rebass';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';

import Header from '@components/Header';
import { MainContent } from '@styles/globalStyles';
import SectionTitle from '@components/SectionTitle';
import ItemsNotFound from '@components/ItemsNotFound';
import Text from '@components/Text';
import DownloadIcon from '@assets/images/icons/download-icon.svg';
import downloadFile from '@utils/downloadFile';
import { APIRoutes } from '@app/api';

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &:hover span {
    text-decoration: underline;
  }
`;

export const StyledIcon = styled.img`
  display: inline-block;
  margin: 0 5px;
  min-width: 20px;
`;

const Statements = ({
  statementsStore: {
    documents: { data, isLoading },
    getDocuments,
  },
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    getDocuments();
  }, []);

  const onDownload = ({ display_name, id }) => {
    const [fileName, fileType] = display_name.split('.');

    downloadFile(APIRoutes.DOCUMENT_DOWNLOAD(id), fileName, fileType);
  };

  return (
    <MainContent>
      <Helmet>
        <title>{t('My statements')}</title>
      </Helmet>
      <Header title={t('My statements')} />

      <Box width={1} mt={2}>
        {data.length > 0 ? (
          <Box>
            {data.map(section => (
              <Box
                key={section.title}
                mb={4}
                sx={{ textTransform: 'capitalize' }}
              >
                <SectionTitle>{section.title}</SectionTitle>
                <Box>
                  {section.files.map(file => (
                    <StyledButton
                      type="button"
                      onClick={() => onDownload(file)}
                    >
                      <Text>{file.display_name}</Text>
                      <StyledIcon src={DownloadIcon} alt="download-icon" />
                    </StyledButton>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        ) : (
          !isLoading && <ItemsNotFound name={t('statements')} />
        )}
      </Box>
    </MainContent>
  );
};

Statements.propTypes = {
  statementsStore: PropTypes.shape({
    documents: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.array,
      meta: PropTypes.object,
    }),
    getDocuments: PropTypes.func,
  }).isRequired,
};

export default inject('statementsStore')(observer(Statements));
