import React from 'react';
import styled from '@emotion/styled';

const StyledSvg = styled.svg`
  &:hover {
    g {
      fill: #0d0d0d;
    }
  }
`;

const ContextMenuIcon = () => {
  return (
    <StyledSvg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="LATEST"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Homepage---Mobile"
          transform="translate(-249.000000, -549.000000)"
          stroke="#0D0D0D"
        >
          <g id="Group" transform="translate(-115.000000, 362.000000)">
            <g id="Album-Card" transform="translate(211.000000, 0.000000)">
              <g
                id="More-info-icon"
                transform="translate(153.000000, 187.000000)"
              >
                <circle
                  transform="translate(12.500000, 5.357143) rotate(-270.000000) translate(-12.500000, -5.357143) "
                  cx="12.5"
                  cy="5.35714286"
                  r="2"
                />
                <circle
                  transform="translate(12.500000, 12.357143) rotate(-270.000000) translate(-12.500000, -12.357143) "
                  cx="12.5"
                  cy="12.3571429"
                  r="2"
                />
                <circle
                  transform="translate(12.500000, 19.357143) rotate(-270.000000) translate(-12.500000, -19.357143) "
                  cx="12.5"
                  cy="19.3571429"
                  r="2"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </StyledSvg>
  );
};

export default ContextMenuIcon;
