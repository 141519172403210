import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import ItemsCarousel from '@components/ItemsCarousel';
import routes from '@routes';
import { MainContent, HomepageBackgroundTopRight } from '@styles/globalStyles';
import News from '@components/News';
import BannersSlider from '@components/BannersSlider';
import TrustedBies from '@components/TrustedBies';
import Tile from '@components/Tile';
import Scrollbar from '@components/Scrollbars/Swiper';

import ARROW from '@assets/images/icons/left-arrow-large.svg';

const CONTROLS = {
  buttonPrev: <img src={ARROW} alt="arrow left" />,
  buttonNext: (
    <img
      style={{ transform: 'rotate(180deg)' }}
      src={ARROW}
      alt="arrow right"
    />
  ),
  scrollbar: <Scrollbar />,
};

const Home = ({
  albumsStore: {
    getAlbumsOf,
    lastReleasesAlbums: { data, isLoading },
  },
  searchStore: { toggleShowSearchPhrasesSelector },
  playlistsStore: { getCuratedPlaylists, curatedPlaylists },
}) => {
  useEffect(() => {
    if (data.length === 0) getAlbumsOf().LAST_RELEASES();
    if (curatedPlaylists.data.length === 0) getCuratedPlaylists();
    toggleShowSearchPhrasesSelector(true);
  }, []);

  const { t } = useTranslation();

  return (
    <MainContent isHomepage>
      <HomepageBackgroundTopRight />
      <ItemsCarousel
        items={data}
        title={t('Latest album releases')}
        link={routes.albums}
        isLoading={isLoading}
        itemComponent={Tile}
        itemProps={{
          display: 'inline',
          isAlbum: true,
        }}
        itemPropsFromItem={{
          name: 'name',
          description: 'description',
          cover: 'cover',
          uuid: 'uuid',
          id: 'id',
          medley: 'medley',
        }}
        buttonLabel={t('All albums')}
        {...CONTROLS}
      />
      <News />
      <ItemsCarousel
        items={curatedPlaylists.data}
        title={t('Curated playlists')}
        link={routes.playlists}
        isLoading={curatedPlaylists.isLoading}
        itemComponent={Tile}
        itemProps={{
          display: 'inline',
          isAlbum: false,
          withBackground: false,
        }}
        itemPropsFromItem={{
          name: 'name',
          cover: 'image',
          uuid: 'uuid',
          id: 'id',
          medley: 'medley',
        }}
        buttonLabel={t('All playlists')}
        {...CONTROLS}
      />
      <BannersSlider />
      <TrustedBies />
    </MainContent>
  );
};

Home.propTypes = {
  albumsStore: PropTypes.shape({
    getAlbumsOf: PropTypes.func,
    lastReleasesAlbums: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          cover: PropTypes.object,
          uuid: PropTypes.string,
          id: PropTypes.number,
        }),
      ),
      isLoading: PropTypes.bool,
    }),
  }).isRequired,
  playlistsStore: PropTypes.shape({
    getCuratedPlaylists: PropTypes.func,
    curatedPlaylists: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          image: PropTypes.object,
          uuid: PropTypes.string,
        }),
      ),
      isLoading: PropTypes.bool,
    }),
  }).isRequired,
  searchStore: PropTypes.shape({
    toggleShowSearchPhrasesSelector: PropTypes.func,
  }).isRequired,
};

export default inject(
  'albumsStore',
  'searchStore',
  'playlistsStore',
)(observer(Home));
