import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Header from '@components/Header';
import { MainContent } from '@styles/globalStyles';
import Preloader from '@components/Preloader';
import TracksTable from '@components/TracksTable';
import Paginator from '@components/Paginator';
import ItemsNotFound from '@components/ItemsNotFound';
import { PLAYER_CONTEXTS } from '@components/PlayerControlButtons/PlayPause';

const MyDownloads = ({
  downloadsStore: {
    getDownloadsTracks,
    toggleTrack,
    toggleTrackAlternatives,
    downloadsTracks: {
      data,
      pagesAmount,
      isLoading,
      expandedTrack,
      expandedAlternatives,
    },
  },
  authStore: { isAuthenticated },
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    getDownloadsTracks();
  }, [getDownloadsTracks]);

  const paginate = async page => {
    getDownloadsTracks(page);
    window.scrollTo(0, 0);
  };

  return (
    <MainContent>
      <Helmet>
        <title>{t('My Downloads')}</title>
      </Helmet>
      <Header title={t('My Downloads')} />
      {isLoading && <Preloader center />}
      {!isLoading && data.length > 0 ? (
        <TracksTable
          {...{
            data,
            toggleTrackAlternatives,
            toggleTrack,
            expandedTrack,
            expandedAlternatives,
            isAuthenticated,
            showAlbumCovers: true,
            playerContext: PLAYER_CONTEXTS.DOWNLOADS,
          }}
        />
      ) : (
        !isLoading && <ItemsNotFound name={t('downloads')} />
      )}

      <Paginator length={pagesAmount} onPageChange={paginate} />
    </MainContent>
  );
};

MyDownloads.propTypes = {
  authStore: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
  }).isRequired,
  downloadsStore: PropTypes.shape({
    getDownloadsTracks: PropTypes.func,
    toggleTrack: PropTypes.func,
    toggleTrackAlternatives: PropTypes.func,
    downloadsTracks: PropTypes.shape({
      data: PropTypes.array,
      pagesAmount: PropTypes.number,
      isLoading: PropTypes.bool,
      expandedTrack: PropTypes.number,
      expandedAlternatives: PropTypes.bool,
    }),
  }).isRequired,
};

export default inject('downloadsStore', 'authStore')(observer(MyDownloads));
