/* eslint-disable */
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const VARIANTS = {
  H1: 'H1',
  H1_BOLD: 'H1_BOLD',
  H2: 'H2',
  H3: 'H3',
  H4: 'H4',
  H4_REGULAR: 'H4_REGULAR',
  BODY: 'BODY',
  BODY_LIGHT: 'BODY_LIGHT',
  SMALL_BODY: 'SMALL_BODY',
  NAV: 'NAV',
  NAV_COLLAPSED: 'NAV_COLLAPSED',
};

export const COLORS = {
  BLACK: 'BLACK',
  WHITE: 'WHITE',
  BLUE: 'BLUE',
  GREY: 'GREY',
  ERROR: 'ERROR',
};

export const ALIGNS = {
  CENTER: 'CENTER',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

const H1 = (theme, fs) => css`
  font-size: ${fs ? `${fs}px` : theme.fontSizes.h1};
  line-height: 1.13;
  letter-spacing: 1.03px;
`;

const H1_BOLD = (theme, fs) => css`
  font-size: ${fs ? `${fs}px` : theme.fontSizes.h1};
  line-height: 1.13;
  letter-spacing: 1.03px;
  font-weight: 900;
`;

const H2 = (theme, fs) => css`
  font-size: ${fs ? `${fs}px` : theme.fontSizes.h2};
  line-height: 1.17;
  font-weight: 500;
`;

const H3 = (theme, fs) => css`
  font-family: ${theme.fonts.secondary.bold};
  font-size: ${fs ? `${fs}px` : theme.fontSizes.h3};
  line-height: 1.33;
`;

const H4 = (theme, fs) => css`
  font-family: ${theme.fonts.secondary.bold};
  font-size: ${fs ? `${fs}px` : theme.fontSizes.h4};
  line-height: 19px;
`;

const H4_REGULAR = (theme, fs) => css`
  font-family: ${theme.fonts.secondary.regular};
  font-size: ${fs ? `${fs}px` : theme.fontSizes.h4};
  line-height: 19px;
`;

const BODY = (theme, fs) => css`
  font-size: ${fs ? `${fs}px` : theme.fontSizes.body};
  line-height: 1.29;
`;

const BODY_LIGHT = (theme, fs) => css`
  font-size: ${fs ? `${fs}px` : theme.fontSizes.body};
  line-height: 1.29;
  font-weight: 300;
`;

const SMALL_BODY = (theme, fs) => css`
  font-size: ${fs ? `${fs}px` : theme.fontSizes.bodySmall};
  line-height: 1.5;
`;

const NAV = (theme, fs) => css`
  font-size: ${fs ? `${fs}px` : theme.fontSizes.nav};
  line-height: 1.31;
  font-weight: 300;
`;

const NAV_COLLAPSED = (theme, fs) => css`
  font-size: ${fs ? `${fs}px` : theme.fontSizes.navCollapsed};
  line-height: 1.55;
  font-family: ${theme.fonts.secondary.regular};
`;

const VARIANT = {
  [VARIANTS.H1]: H1,
  [VARIANTS.H1_BOLD]: H1_BOLD,
  [VARIANTS.H2]: H2,
  [VARIANTS.H3]: H3,
  [VARIANTS.H4]: H4,
  [VARIANTS.H4_REGULAR]: H4_REGULAR,
  [VARIANTS.BODY]: BODY,
  [VARIANTS.BODY_LIGHT]: BODY_LIGHT,
  [VARIANTS.SMALL_BODY]: SMALL_BODY,
  [VARIANTS.NAV]: NAV,
  [VARIANTS.NAV_COLLAPSED]: NAV_COLLAPSED,
};

const BLACK = theme => css`
  color: ${theme.colors.textPrimary};
`;

const WHITE = theme => css`
  color: ${theme.colors.white};
`;

const BLUE = theme => css`
  color: ${theme.colors.secondary};
`;

const GREY = theme => css`
  color: ${theme.colors.textLight};
`;

const ERROR = theme => css`
  color: ${theme.colors.error};
`;

const COLOR = {
  [COLORS.BLACK]: BLACK,
  [COLORS.WHITE]: WHITE,
  [COLORS.BLUE]: BLUE,
  [COLORS.GREY]: GREY,
  [COLORS.ERROR]: ERROR,
};

const CENTER = () => css`
  text-align: center;
`;

const LEFT = () => css`
  text-align: left;
`;

const RIGHT = () => css`
  text-align: right;
`;

const ALIGN = {
  [ALIGNS.CENTER]: CENTER,
  [ALIGNS.LEFT]: LEFT,
  [ALIGNS.RIGHT]: RIGHT,
};

export const Component = styled.span`
  display: inline-block;

  ${({ variant, theme, fs }) => VARIANT[variant](theme, fs)};
  ${({ tint, theme }) => COLOR[tint](theme)};
  ${({ align }) => ALIGN[align]()};
  ${({ theme, mb }) =>
    mb
      ? css`
          margin-bottom: ${theme.spacing[mb]}px;
        `
      : null}
`;
