import { API, APIRoutes } from '@app/api';
import { makeAutoObservable, runInAction } from 'mobx';
import i18n from '@utils/i18n';

export class MoodsStore {
  constructor() {
    makeAutoObservable(this);
  }

  moods = {
    isLoading: false,
    data: [],
    error: null,
  };

  getMoods = async () => {
    try {
      this.moods.isLoading = true;

      const {
        data: { moods },
      } = await API(APIRoutes.MOODS);

      runInAction(() => {
        this.moods.data = [{ id: 'all', name: i18n.t('All moods') }, ...moods];
      });
    } catch (error) {
      runInAction(() => {
        this.moods.error = error;
      });
    } finally {
      runInAction(() => {
        this.moods.isLoading = false;
      });
    }
  };
}

export default new MoodsStore();
