import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from '@components/Text/style';
import { MARGINS } from '@styles/globalStyles';

export { COLORS, VARIANTS, ALIGNS } from '@components/Text/style';
export { MARGINS } from '@styles/globalStyles';

const Text = ({ align, as, children, variant, color, className, mb, size }) => (
  <Styled.Component
    align={align}
    as={as}
    variant={variant}
    tint={color}
    className={className}
    mb={mb}
    fs={size}
  >
    {children}
  </Styled.Component>
);

Text.defaultProps = {
  align: Styled.ALIGNS.LEFT,
  as: 'span',
  color: Styled.COLORS.BLACK,
  variant: Styled.VARIANTS.BODY,
  className: '',
  mb: MARGINS.NONE,
  size: null,
};

Text.propTypes = {
  align: PropTypes.oneOf(Object.keys(Styled.ALIGNS)),
  as: PropTypes.oneOf(['p', 'span', 'h1', 'h2', 'h3', 'h4']),
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(Object.keys(Styled.VARIANTS)),
  color: PropTypes.oneOf(Object.keys(Styled.COLORS)),
  className: PropTypes.string,
  mb: PropTypes.oneOf(Object.values(MARGINS)),
  size: PropTypes.number,
};

export default Text;
