import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import Modal, { VARIANTS as MODAL_VARIANTS } from '@components/Modal';
import { ALIGNS, VARIANTS as TEXT_VARIANTS } from '@components/Text';
import { Header as CardHeader } from '@components/Card';
import SelectProjectForm from '@components/AddToProjectModal/SelectProjectForm';
import AddToNewProjectForm from '@components/AddToProjectModal/AddToNewProjectForm';
import { FormBox, Forms } from '@components/AddToProjectModal/style';

const AddToProjectModal = ({
  projectsStore: {
    addToProjectModal: {
      data,
      isOpen,
      form: { errors, isLoading },
    },
    projectModal: {
      form: { errors: projectErrors, isLoading: projectIsLoading },
    },
    closeAddToProjectModal,
    submitAddToProject,
    addTrackToNewProject,
    projects,
    getProjects,
  },
  authStore: { isAuthenticated },
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isAuthenticated && isOpen) {
      getProjects();
    }
  }, [isAuthenticated, isOpen]);

  return (
    <Modal
      closeHandler={closeAddToProjectModal}
      isOpen={isOpen}
      variant={MODAL_VARIANTS.GRADIENT}
    >
      <Box p={4}>
        <Box p={3} textAlign="center">
          <CardHeader variant={TEXT_VARIANTS.H2} align={ALIGNS.CENTER}>
            {t('Add to a project')}
          </CardHeader>
          <Forms>
            <FormBox>
              <SelectProjectForm
                errors={errors}
                submit={({ project }) => submitAddToProject(project)}
                options={projects.data}
                isLoading={isLoading}
                projectName={data.name}
              />
            </FormBox>
            <FormBox>
              <AddToNewProjectForm
                errors={projectErrors}
                isLoading={isLoading || projectIsLoading}
                submit={addTrackToNewProject}
              />
            </FormBox>
          </Forms>
        </Box>
      </Box>
    </Modal>
  );
};

AddToProjectModal.propTypes = {
  projectsStore: PropTypes.shape({
    closeAddToProjectModal: PropTypes.func,
    submitAddToProject: PropTypes.func,
    addTrackToNewProject: PropTypes.func,
    submitProject: PropTypes.func,
    getProjects: PropTypes.func,
    addToProjectModal: PropTypes.shape({
      data: PropTypes.object,
      isLoading: PropTypes.bool,
      isOpen: PropTypes.bool,
      form: PropTypes.shape({
        errors: PropTypes.object,
        isLoading: PropTypes.bool,
      }),
    }),
    projectModal: PropTypes.object.isRequired,
    projects: PropTypes.shape({
      data: PropTypes.array,
      isLoading: PropTypes.bool,
      expandedProject: PropTypes.string,
    }),
  }).isRequired,
  authStore: PropTypes.shape({ isAuthenticated: PropTypes.bool }).isRequired,
};

export default inject(
  'projectsStore',
  'authStore',
)(observer(AddToProjectModal));
