import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { inject, observer } from 'mobx-react';
import Modal from '@components/Modal';
import TrackModalBody from '@components/TrackModal/components/TrackModalBody';

const TrackModal = ({
  authStore: { isAuthenticated },
  trackStore: { isModalOpen, trackDetails, closeTrackModal },
}) => {
  const location = useLocation();

  useEffect(() => {
    closeTrackModal();
  }, [location.pathname, location.search]);

  return (
    <Modal closeHandler={closeTrackModal} isOpen={isModalOpen}>
      {isModalOpen && (
        <TrackModalBody
          trackData={trackDetails.data}
          isLoading={trackDetails.isLoading}
          isAuthenticated={isAuthenticated}
        />
      )}
    </Modal>
  );
};

TrackModal.propTypes = {
  authStore: PropTypes.shape({ isAuthenticated: PropTypes.bool }).isRequired,
  trackStore: PropTypes.shape({
    isModalOpen: PropTypes.bool,
    trackDetails: PropTypes.object,
    closeTrackModal: PropTypes.func,
  }).isRequired,
};

export default inject('authStore', 'trackStore')(observer(TrackModal));
