import { Link as RouteLink } from 'react-router-dom';
import styled from '@emotion/styled';

export const Link = styled(RouteLink)`
  width: 100%;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 12px 0 rgba(13, 13, 13, 0.15);
  display: flex;
  flex-direction: column;
  padding: 5px;
`;

export const Image = styled.img`
  width: 100%;
  margin: auto;
  border-radius: 4px;
`;

export const Title = styled.p`
  font-family: ${props => props.theme.fonts.secondary.regular};
  font-size: ${props => props.theme.fontSizes.body};
  margin: 12px;
`;
