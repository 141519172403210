import styled from '@emotion/styled';

export const Wrapper = styled.div`
  padding: 12px;
  width: 250px;
`;

export const Header = styled.p`
  font-family: ${({ theme }) => theme.buttons.fontFamily};
  font-size: 13px;
`;

export const Description = styled.p`
  font-size: 12px;
  font-weight: 300;
  margin-top: 20px;
`;
