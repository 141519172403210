import React, { useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import PropTypes from 'prop-types';

import * as Styled from '@components/Dropdown/style';
import Button from '@components/Button';
import ArrowUpIcon from '@assets/images/icons/arrow-up.svg';
import {
  PLACEMENT,
  TRIGGER,
  TooltipContainer,
  TooltipBody,
  TooltipArrow,
} from '@components/Tooltip';

const Dropdown = ({ label, icon, maxTextWidth, minTextWidth, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    trigger: TRIGGER.CLICK,
    interactive: true,
    placement: PLACEMENT.BOTTOM,
    offset: [0, 12],
    delayHide: 250,
    visible: isOpen,
    onVisibleChange: setIsOpen,
  });

  const ButtonLabel = (
    <Styled.Label withIcon={icon ? 1 : 0}>
      {icon && <Styled.Icon>{icon}</Styled.Icon>}
      <Styled.Text maxWidth={maxTextWidth} minWidth={minTextWidth}>
        {label}
      </Styled.Text>
      <Styled.Indicator src={ArrowUpIcon} isOpen={isOpen} />
    </Styled.Label>
  );

  return (
    <>
      <Styled.Wrapper ref={setTriggerRef}>
        <Button label={ButtonLabel} onClick={() => setIsOpen(!isOpen)} />
      </Styled.Wrapper>
      {visible && (
        <TooltipContainer ref={setTooltipRef} {...getTooltipProps()}>
          <TooltipBody>{children}</TooltipBody>
          <TooltipArrow {...getArrowProps()} />
        </TooltipContainer>
      )}
    </>
  );
};

Dropdown.defaultProps = {
  icon: null,
  maxTextWidth: undefined,
  minTextWidth: undefined,
};

Dropdown.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.node,
  maxTextWidth: PropTypes.number,
  minTextWidth: PropTypes.number,
};

export default Dropdown;
