import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { MainContent } from '@styles/globalStyles';
import Preloader from '@components/Preloader';

import { PLAYER_CONTEXTS } from '@components/PlayerControlButtons/PlayPause';
import ListingHeaderWrapper from '@components/ListingHeaderWrapper';

import TracksTable from '@components/TracksTable';
import NotFound from '@containers/NotFound';
import ComposerHeader from '@containers/ComposerWebsite/components/ComposerHeader';
import Paginator from '@components/Paginator';
import { isEmpty } from 'lodash-es';

const ComposerWebsite = ({
  composersWebsiteStore: {
    getComposer,
    getComposerTracks,
    toggleTrack,
    toggleTrackAlternatives,
    composer: { data, isLoading, error },
    composerTracks: {
      data: tracksData,
      meta,
      isLoading: isTracksLoading,
      expandedTrack,
      expandedAlternatives,
      error: tracksError,
    },
  },
  authStore: { isAuthenticated },
}) => {
  const { id: composerId } = useParams();

  useEffect(() => {
    getComposer(composerId);
    getComposerTracks(composerId);
  }, [getComposer, getComposerTracks, composerId]);

  const { composer, description, id, image } = data || {};

  const { first_name, last_name } = composer || {};

  const pagesAmount = !isEmpty(meta) ? Math.ceil(meta?.total_count / 10) : 0;

  const paginate = async page => {
    await getComposerTracks(composerId, page);
    window.scrollTo(0, 0);
  };

  if (error || tracksError) {
    return <NotFound />;
  }
  return (
    <MainContent>
      {isLoading ? (
        <Preloader center />
      ) : (
        <>
          <ListingHeaderWrapper>
            <ComposerHeader
              {...{
                coverUrl: image?.url,
                name: `${first_name} ${last_name}`,
                description,
                id,
                track: tracksData && tracksData.length ? tracksData[0] : null,
              }}
            />
          </ListingHeaderWrapper>
          {isTracksLoading ? (
            <Preloader center />
          ) : (
            <TracksTable
              {...{
                data: tracksData,
                toggleTrackAlternatives,
                toggleTrack,
                expandedTrack,
                expandedAlternatives,
                isAuthenticated,
                showAlbumCovers: true,
                playerContext: PLAYER_CONTEXTS.COMPOSERS,
              }}
            />
          )}
          {pagesAmount > 1 && (
            <Paginator length={pagesAmount} onPageChange={paginate} />
          )}
        </>
      )}
    </MainContent>
  );
};

ComposerWebsite.propTypes = {
  authStore: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
  }).isRequired,
  composersWebsiteStore: PropTypes.shape({
    getComposer: PropTypes.func,
    getComposerTracks: PropTypes.func,
    toggleTrack: PropTypes.func,
    toggleTrackAlternatives: PropTypes.func,
    composer: PropTypes.shape({
      data: PropTypes.object,
      isLoading: PropTypes.bool,
      error: PropTypes.any,
    }),
    composerTracks: PropTypes.shape({
      data: MobxPropTypes.observableArray,
      meta: PropTypes.object,
      isLoading: PropTypes.bool,
      expandedTrack: PropTypes.number,
      expandedAlternatives: PropTypes.bool,
      error: PropTypes.any,
    }),
  }).isRequired,
};

export default inject(
  'composersWebsiteStore',
  'authStore',
)(observer(ComposerWebsite));
