import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash-es';

import {
  Input,
  InputWrapper,
  VARIANTS,
  SIZES,
} from '@components/Form/TextField/style';
import { FieldWrapper, InputIndicatorWrapper } from '@components/Form/style';
import { MARGINS } from '@styles/globalStyles';

const SearchField = forwardRef(
  (
    {
      autofocus,
      name,
      inputIndicator: InputIndicator,
      placeholder,
      onChange,
      onFocus,
      onClick,
      onBlur,
      mb,
      className,
      value,
      readOnly,
    },
    ref,
  ) => {
    const fieldId = useMemo(() => uniqueId(`input_${name}_`), []);

    return (
      <FieldWrapper {...{ mb, className }}>
        <InputWrapper variant={VARIANTS.SEARCH}>
          <Input
            {...{
              ref,
              id: fieldId,
              name,
              type: 'text',
              autoComplete: 'off',
              placeholder,
              onChange,
              onFocus,
              onClick,
              onBlur,
              autoFocus: autofocus,
              value,
              readOnly,
            }}
          />
          {InputIndicator ? (
            <InputIndicatorWrapper>
              <InputIndicator />
            </InputIndicatorWrapper>
          ) : null}
        </InputWrapper>
      </FieldWrapper>
    );
  },
);

SearchField.defaultProps = {
  autofocus: false,
  type: 'text',
  required: false,
  style: {},
  errors: {},
  inputIndicator: null,
  variant: VARIANTS.DEFAULT,
  label: null,
  placeholder: null,
  size: null,
  onChange: null,
  onFocus: null,
  onBlur: null,
  onClick: undefined,
  fullWidth: true,
  mb: MARGINS.SMALL,
  disabled: false,
  readOnly: false,
  className: undefined,
  rules: undefined,
  defaultValue: undefined,
  disableSpinners: false,
  rows: undefined,
  register: () => {},
  value: undefined,
};

SearchField.propTypes = {
  autofocus: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  register: PropTypes.func,
  type: PropTypes.string,
  required: PropTypes.bool,
  style: PropTypes.object,
  errors: PropTypes.object,
  inputIndicator: PropTypes.elementType,
  variant: PropTypes.oneOf(Object.keys(VARIANTS)),
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(SIZES)),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  fullWidth: PropTypes.bool,
  mb: PropTypes.oneOf(Object.values(MARGINS)),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.string,
  disableSpinners: PropTypes.bool,
  rows: PropTypes.number,
  value: PropTypes.string,
};

export default SearchField;
