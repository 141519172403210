import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  max-width: 880px;
  margin: 0 auto 90px;
  text-align: center;
`;

export const Subheader = styled.h3`
  margin-bottom: 25px;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.secondary.bold};
  font-size: ${props => props.theme.fontSizes.h4};
  font-weight: 700;
  line-height: 1.27;
`;

export const Image = styled.img`
  height: 68px;
  margin: 0 35px;
  filter: grayscale(100%);
  opacity: 0.4;
  transition: opacity 0.3s ease-in-out, filter 0.3s ease-in-out;
  &:hover {
    filter: grayscale(0%);
    opacity: 1;
  }
`;
