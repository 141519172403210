/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Box, Flex, Image, Text } from 'rebass';
import { useTranslation } from 'react-i18next';
import HelloSign from 'hellosign-embedded';
import styled from '@emotion/styled';

import useBreakpoint from '@hooks/useBreakpoint';

import Table from '@components/Table';
import { API, APIRoutes } from '@app/api';
import ItemsNotFound from '@components/ItemsNotFound';
import downloadIcon from '@assets/images/icons/download-icon.svg';
import editIcon from '@assets/images/icons/edit-icon.svg';
import INFO_ICON from '@assets/images/icons/info-icon.svg';
import Tooltip from '@components/Tooltip';

import { currentLanguage } from '@utils/i18n';
import { HELLO_SIGN_CLIENT_ID } from '@utils/i18n/locationsContent';
import Paginator from '@components/Paginator';

const StatusIndicator = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: ${({ status }) => {
    switch (status) {
      case 'completed':
        return '#00C48C';
      case 'error':
        return '#FF0000';
      case 'waiting':
        return '#FFC107';
      default:
        return '#FFC107';
    }
  }};
`;

const CLIENT_ID = HELLO_SIGN_CLIENT_ID[currentLanguage];

const client = new HelloSign({
  clientId: CLIENT_ID,
  skipDomainVerification:
    process.env.REACT_APP_HELLO_SKIP_DOMAIN_VERIFICATION === 'true',
  testMode: process.env.REACT_APP_HELLO_SIGN_TEST_MODE === 'true',
});

const HelloContracts = ({
  helloContractsStore: {
    helloContracts: { data, isLoading, meta },
    getContracts,
    downloadHelloContract,
  },
}) => {
  const { t } = useTranslation();
  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();

  let isMobile = false;

  if (matchBreakpoint(BREAKPOINTS.MD)) {
    isMobile = true;
  }

  useEffect(() => {
    getContracts();
  }, []);

  const paginate = async page => {
    await getContracts(page);
    window.scrollTo(0, 0);
  };
  const onSign = async contractId => {
    try {
      const {
        data: { embedded_sign_url },
      } = await API.get(APIRoutes.EMBEDDED_SIGN(contractId, CLIENT_ID));

      client.open(embedded_sign_url);
    } catch (error) {
      console.warn(error);
    }
  };

  /* eslint-disable react/prop-types */
  const COLUMNS = useMemo(
    () => [
      {
        title: t('No.'),
        field: 'no',
        render: ({ cell }) => <span>{cell}</span>,
      },
      {
        title: t('Subject'),
        field: 'subject',
        style: { width: '40%' },
      },
      {
        title: t('Status'),
        field: 'hello_sign_status',
        render: ({ row }) => {
          const { has_error, is_completed } = row;

          return (
            <div>
              <StatusIndicator
                status={
                  has_error ? 'error' : is_completed ? 'completed' : 'waiting'
                }
              />
              {has_error ? (
                <span>{t('Error')}</span>
              ) : is_completed ? (
                <span>{t('Completed')}</span>
              ) : (
                <span>{t('Awaiting signature')}</span>
              )}
            </div>
          );
        },
      },
      {
        title: t('Signed'),
        field: 'hello_sign_contract_signatures',
        render: ({ cell }) => {
          const signed = cell.filter(
            signature => signature?.status_code === 'signed',
          ).length;

          return (
            <Flex alignItems="center">
              <Text lineHeight={1}>
                {signed}/{cell.length}{' '}
              </Text>
              {cell.length > 0 && (
                <Tooltip
                  tooltipBody={
                    <Box p={2}>
                      {cell.map(signature => (
                        <Flex
                          key={signature.order_no}
                          sx={{
                            gap: '4px',
                          }}
                        >
                          <Text lineHeight={1} fontWeight="bold">
                            {signature.order_no + 1}. {signature.signer_name}
                          </Text>
                          <span>-</span>
                          <Text lineHeight={1}>
                            {signature.status_code === 'signed'
                              ? t('Signed')
                              : t('Waiting')}
                          </Text>
                        </Flex>
                      ))}
                    </Box>
                  }
                >
                  <Image src={INFO_ICON} alt="info" width={20} />
                </Tooltip>
              )}
            </Flex>
          );
        },
      },
      {
        title: t('Action'),
        field: 'file',
        render: ({ row }) => {
          if (row.is_completed) {
            return (
              <button
                type="button"
                onClick={() => downloadHelloContract(row.id, row.subject)}
              >
                <img src={downloadIcon} alt="download" />
              </button>
            );
          }

          const canSign =
            row.hello_sign_contract_signatures &&
            row.hello_sign_contract_signatures.length > 0 &&
            row.hello_sign_contract_signatures.find(
              signature =>
                signature.order_no === 0 || signature.order_no === null,
            )?.status_code === 'awaiting_signature';

          if (canSign) {
            return (
              <button type="button" onClick={() => onSign(row.id)}>
                <img src={editIcon} alt="sign" />
              </button>
            );
          }

          return null;
        },
        style: { textAlign: isMobile ? 'right' : 'center' },
      },
    ],
    [isMobile],
  );
  /* eslint-enable react/prop-types */

  return (
    <div>
      {data.length > 0 ? (
        <Table data={data} columns={COLUMNS} />
      ) : (
        !isLoading && <ItemsNotFound name={t('contracts')} />
      )}

      <Paginator length={meta.totalPages} onPageChange={paginate} />
    </div>
  );
};

HelloContracts.propTypes = {
  helloContractsStore: PropTypes.shape({
    helloContracts: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.array,
      meta: PropTypes.object,
    }),
    getContracts: PropTypes.func,
    getMoreContracts: PropTypes.func,
    downloadHelloContract: PropTypes.func,
  }).isRequired,
};

export default inject(
  'authStore',
  'helloContractsStore',
)(observer(HelloContracts));
