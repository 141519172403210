import React from 'react';
import PropTypes from 'prop-types';
import ahoy from 'ahoy.js';
import { Box } from 'rebass';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import {
  Cover,
  Header,
  Controls,
  Details,
  DescriptionBox,
} from '@components/ListingHeaderWrapper/style';
import Text, { VARIANTS } from '@components/Text';
import { StyledButton } from '@components/IconButtons/style';
import DownloadIcon from '@assets/images/icons/download-icon.svg';
import Tooltip from '@components/Tooltip';
import DisabledDownloadTooltipBody from '@components/IconButtons/DisabledDownloadTooltipBody';
import ShareButton from '@components/IconButtons/ShareButton';
import { APIRoutes } from '@app/api';
import FavoritesButton from '@components/IconButtons/FavoritesButton';
import PlayPauseButton, {
  PLAYER_CONTEXTS,
  STYLE,
} from '@components/PlayerControlButtons/PlayPause';
import { MenuItem, Icon, Divider } from '@components/TooltipSelect';

const AlbumHeader = ({
  coverUrl,
  name,
  id,
  description,
  isAuthenticated,
  isFixed,
  uuid,
  medley,
  albumUuid,
  blockDownloadStore: { isDownloadBlocked, openBlockedDownloadModal },
}) => {
  const { t } = useTranslation();

  const medleyTrack = {
    album: {
      cover: {
        url: coverUrl,
        name,
      },
      description,
      name,
      uuid,
    },
    file: {
      ...medley,
    },
    id,
    name,
    medley: true,
    singleTrack: true,
  };

  return (
    <>
      <Box mr={3}>
        <Cover src={coverUrl} alt="album cover" isFixed={isFixed} />
      </Box>
      <Details isFixed={isFixed}>
        <DescriptionBox>
          <Header isFixed={isFixed}>{name}</Header>
          {!isFixed && <Text>{description}</Text>}
        </DescriptionBox>
        <Controls isFixed={isFixed}>
          <PlayPauseButton
            context={PLAYER_CONTEXTS.ALBUMS}
            outlined={false}
            track={medleyTrack}
            style={isFixed ? STYLE.DEFAULT : STYLE.CIRCLE}
          />

          <Tooltip
            isActive={!isDownloadBlocked}
            tooltipBody={
              isAuthenticated ? (
                <>
                  <MenuItem
                    as="a"
                    href={`${
                      process.env.REACT_APP_API_URL
                    }${APIRoutes.ALBUM_DOWNLOAD(id, 'wav')}`}
                    onClick={
                      isAuthenticated
                        ? () => ahoy.track('download_album_zip', { id })
                        : undefined
                    }
                    rel="noopener noreferrer"
                    justifyContent="space-between"
                  >
                    <Text variant={VARIANTS.NAV}>ZIP WAV</Text>
                    <Icon src={DownloadIcon} />
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    as="a"
                    href={`${
                      process.env.REACT_APP_API_URL
                    }${APIRoutes.ALBUM_DOWNLOAD(id, 'mp3')}`}
                    onClick={
                      isAuthenticated
                        ? () => ahoy.track('download_album_zip', { id })
                        : undefined
                    }
                    rel="noopener noreferrer"
                    justifyContent="space-between"
                  >
                    <Text variant={VARIANTS.NAV}>ZIP MP3</Text>
                    <Icon src={DownloadIcon} />
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    as="a"
                    href={`${
                      process.env.REACT_APP_API_URL
                    }${APIRoutes.ALBUM_CUESHEET(albumUuid)}`}
                    rel="noopener noreferrer"
                    justifyContent="space-between"
                  >
                    <Text variant={VARIANTS.NAV}>CUESHEET</Text>
                    <Icon src={DownloadIcon} />
                  </MenuItem>
                </>
              ) : (
                <DisabledDownloadTooltipBody />
              )
            }
            trigger="click"
          >
            <span>
              <StyledButton
                {...(isDownloadBlocked && {
                  onClick: openBlockedDownloadModal,
                })}
              >
                <img src={DownloadIcon} alt="download-icon" />
              </StyledButton>
            </span>
          </Tooltip>
          <FavoritesButton isAuthenticated={isAuthenticated} albumId={id} />
          <ShareButton
            copyText={window.location.href}
            notification={t("Album's url has been copied to clipboard")}
          />
        </Controls>
      </Details>
    </>
  );
};

AlbumHeader.defaultProps = {
  id: null,
  coverUrl: '',
  name: '',
  description: '',
  isFixed: false,
  medley: null,
  uuid: '',
};

AlbumHeader.propTypes = {
  id: PropTypes.number,
  coverUrl: PropTypes.string,
  uuid: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  isAuthenticated: PropTypes.bool.isRequired,
  isFixed: PropTypes.bool,
  medley: PropTypes.shape({
    medley: PropTypes.shape({
      url: PropTypes.string,
      waveform_peak_data: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
  albumUuid: PropTypes.string.isRequired,
  blockDownloadStore: PropTypes.object.isRequired,
};

export default inject(
  'playerStore',
  'blockDownloadStore',
)(observer(AlbumHeader));
