import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Box } from 'rebass';

import Header from '@components/Header';
import { MainContent } from '@styles/globalStyles';
import CmsBody from '@components/CmsBody';
import Checkbox from '@components/Form/Checkbox';
import { getItem, KEYS } from '@utils/storage';

const CookiesPage = ({
  cookiesStore: { page, getPage, onChange },
  customerStore: {
    customer: { data: customerData },
    updateCookies,
  },
}) => {
  const { t } = useTranslation();
  const { register, reset } = useForm({
    defaultValues: {
      cookie_necessary_technical: true,
      cookie_performance_analytics: !JSON.parse(
        getItem(KEYS.DISABLE_PERFORMANCE_COOKIES),
      ),
    },
  });

  useEffect(() => {
    getPage();
  }, [getPage]);

  useEffect(() => {
    if (customerData) {
      const { cookie_performance_analytics } = customerData;

      reset({
        cookie_necessary_technical: true,
        cookie_performance_analytics,
      });
    }
  }, [customerData]);

  const handleChange = e => {
    const { checked } = e.target;
    onChange(checked);
    updateCookies(checked);
  };

  return (
    <MainContent>
      <Header title={t('Cookies')} withSeparator />

      <CmsBody
        html={page.data?.body}
        isLoading={page.isLoading}
        error={page.error}
      />

      <Box mt={2}>
        <form>
          <Checkbox
            name="cookie_necessary_technical"
            label={t('Strictly necessary cookies (Technical Cookies)')}
            register={register}
            disabled
          />
          <Checkbox
            name="cookie_performance_analytics"
            label={t('Performance Cookies (Analytics)')}
            register={register}
            onChange={handleChange}
          />
        </form>
      </Box>
    </MainContent>
  );
};

CookiesPage.propTypes = {
  cookiesStore: PropTypes.object.isRequired,
  customerStore: PropTypes.object.isRequired,
};

export default inject('cookiesStore', 'customerStore')(observer(CookiesPage));
