import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Flex, Box } from 'rebass';
import { Helmet } from 'react-helmet';
import SignInForm from '@containers/SignIn/components/SignInForm';
import Header from '@components/Header';
import { MainContent } from '@styles/globalStyles';
import Card, { Header as CardHeader, VARIANTS } from '@components/Card';
import FormButton from '@components/Form/FormButton';
import routes from '@routes';
import { ALIGNS, VARIANTS as TEXT_VARIANTS } from '@components/Text';
import OAuthBox from '@components/OAuthBox';

const SignInPage = ({
  authStore: { signIn, getSignInMessage, signInStatus, clearSignInForm },
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    getSignInMessage();

    return () => {
      clearSignInForm();
    };
  }, []);

  return (
    <MainContent>
      <Helmet>
        <title>{t('Sign in')}</title>
      </Helmet>
      <Header title={t('Sign in')} withSeparator />

      <OAuthBox />

      <Flex flexWrap="wrap" mx={-3} mt={3} mb={[0, 0, 0, 6]}>
        <Box width={[1, 1, 1, 1 / 2]} px={3} mb={[5, 5, 5, 0]}>
          <Card>
            <CardHeader variant={TEXT_VARIANTS.H2}>
              {t('Have an account? Sign in')}
            </CardHeader>

            <SignInForm onSubmit={signIn} formStatus={toJS(signInStatus)} />
          </Card>
        </Box>
        <Box width={[1, 1, 1, 1 / 2]} px={[0, 0, 0, 3]}>
          <Card variant={VARIANTS.GRADIENT}>
            <Box p={3} textAlign="center">
              <CardHeader variant={TEXT_VARIANTS.H2} align={ALIGNS.CENTER}>
                {t('New here?')}
              </CardHeader>
              <Box>
                <FormButton to={routes.auth.signUp} label={t('Sign up')} />
              </Box>
            </Box>
          </Card>
        </Box>
      </Flex>
    </MainContent>
  );
};

SignInPage.propTypes = {
  authStore: PropTypes.object.isRequired,
};

export default inject('authStore')(observer(SignInPage));
