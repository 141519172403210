import styled from '@emotion/styled';
import Text from '@components/Text';

export const DialogInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: auto;
  align-items: center;
`;

export const Message = styled(Text)`
  margin: 100px auto 50px auto;
`;

export const Buttons = styled.div`
  display: flex;
  gap: 10px;
`;
