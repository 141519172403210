import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';
import { useTranslation } from 'react-i18next';

import Modal from '@components/Modal';
import { VARIANTS as MODAL_VARIANTS } from '@components/Modal/style';
import { Header as CardHeader } from '@components/Card';
import Text, {
  ALIGNS,
  COLORS,
  VARIANTS as TEXT_VARIANTS,
} from '@components/Text';
import Button from '@components/Button';
import routes from '@routes';

const GeoBlockingModal = ({
  blockDownloadStore: { isGeoBlockingModalOpen, closeGeoBlockingModal },
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      closeHandler={closeGeoBlockingModal}
      isOpen={isGeoBlockingModalOpen}
      variant={MODAL_VARIANTS.GRADIENT}
      portalId="fake-account-modal"
    >
      <Box p={4}>
        <Box p={3} textAlign="center">
          <CardHeader variant={TEXT_VARIANTS.H2} align={ALIGNS.CENTER}>
            {t('DOWNLOAD BLOCKED')}
          </CardHeader>
          <Box margin="0 auto">
            <Text
              variant={TEXT_VARIANTS.H3}
              color={COLORS.WHITE}
              align={ALIGNS.CENTER}
            >
              {t(
                'It seems you are connecting from a country where we are represented by other publishers and your account has been suspended. Please get in contact with us if you have questions about your account.',
              )}
            </Text>
          </Box>
          <Box mt={4}>
            <Button
              to={routes.contactUs}
              onClick={closeGeoBlockingModal}
              label={t('Contact us')}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

GeoBlockingModal.propTypes = {
  blockDownloadStore: PropTypes.object.isRequired,
};

export default inject('blockDownloadStore')(observer(GeoBlockingModal));
