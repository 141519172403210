import React from 'react';
import PropTypes from 'prop-types';
import { Dots, Dot, PreloaderWrapper } from '@components/Preloader/style';

const Preloader = ({ center, cover, light }) => (
  <PreloaderWrapper {...{ center, cover, light }} className="preloader">
    <Dots>
      <Dot />
      <Dot />
      <Dot />
      <Dot />
      <Dot />
    </Dots>
  </PreloaderWrapper>
);

Preloader.defaultProps = {
  center: false,
  cover: false,
  light: false,
};

Preloader.propTypes = {
  center: PropTypes.bool,
  cover: PropTypes.bool,
  light: PropTypes.bool,
};

export default Preloader;
