import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import Avatar from '@components/Avatar';
import * as Styled from '@containers/Label/Artist/style';

const Artist = ({
  searchStore: { applySearchFilter },
  uuid,
  image,
  firstName,
  lastName,
  isSideBarOpened,
}) => (
  <Styled.Wrapper isSideBarOpened={isSideBarOpened}>
    <Avatar
      image={image.url}
      onClick={() => applySearchFilter('composers', uuid)}
    >
      <Styled.Text>
        {firstName} {lastName}
      </Styled.Text>
    </Avatar>
  </Styled.Wrapper>
);

Artist.propTypes = {
  searchStore: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
  uuid: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  isSideBarOpened: PropTypes.bool.isRequired,
};

export default inject('searchStore')(observer(Artist));
