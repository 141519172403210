import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Text, { VARIANTS } from '@components/Text';
import * as Styled from '@components/Label/style';
import routes from '@app/routes';
import CoverPlaceholder from '@components/CoverPlaceholder';

const Label = ({ name, description, image, uuid }) => {
  const { t } = useTranslation();
  return (
    <Styled.Wrapper>
      <Link to={routes.label(uuid)}>
        <Styled.ImageContainer>
          {image ? (
            <img src={image} alt="company logo" />
          ) : (
            <CoverPlaceholder text={name} />
          )}
        </Styled.ImageContainer>
      </Link>
      <Styled.Title variant={VARIANTS.H4}>{name}</Styled.Title>
      <Text variant={VARIANTS.BODY}>{description}</Text>
      <Styled.Link to={routes.label(uuid)}>{t('Read more')}</Styled.Link>
    </Styled.Wrapper>
  );
};

Label.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
  uuid: PropTypes.string.isRequired,
};

Label.defaultProps = {
  image: null,
};

export default Label;
