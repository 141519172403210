import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Typewriter from '@components/TypewriterText/Typewriter';
import { Text } from '@components/TypewriterText/style';

const TypewriterText = ({ texts }) => {
  const ref = useRef();

  // eslint-disable-next-line consistent-return
  useLayoutEffect(() => {
    if (ref.current) {
      const typewriter = new Typewriter({
        element: ref.current,
        texts,
        loop: false,
      });

      typewriter.init();

      return () => {
        typewriter.destroy();
      };
    }
  }, []);

  return <Text ref={ref} />;
};

TypewriterText.propTypes = {
  texts: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TypewriterText;
