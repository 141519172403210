import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { Helmet } from 'react-helmet';
import { Router, Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { install } from 'resize-observer';

import { history } from '@app/history';
import routes from '@routes';
import { currentLanguage } from '@utils/i18n';

import GlobalStyle from '@styles/globalStyles';
import theme from '@styles/theme';

import GoogleTagManager, {
  COUNTRY_TAG_CODES,
} from '@components/GoogleTagManager';

import AppRoutes from '@containers/AppRoutes';
import BannersPreview from '@components/BannersPreview';
import { PAGE_TITLE } from '@utils/i18n/locationsContent';

install();

const App = () => {
  const { t } = useTranslation();

  const mouseFlow = () => {
    window.mouseflowAutoStart = false;

    const urlByLang = () => {
      switch (currentLanguage) {
        case 'IT':
          return '//cdn.mouseflow.com/projects/5c52348e-3d8a-4786-bc71-1745dfa4b078.js';
        case 'DE':
          return '//cdn.mouseflow.com/projects/8b6ad0e5-1669-4a9e-a78b-fcd85f1a4c2f.js';
        case 'EN':
          return '//cdn.mouseflow.com/projects/7281b654-a2a5-4616-b47c-79ed469be467.js';
        default:
          return null;
      }
    };

    if (urlByLang()) {
      return (
        <script type="text/javascript">
          {/* eslint-disable-next-line max-len */}
          {`window._mfq = window._mfq || [];(function() {var mf = document.createElement("script");mf.type = "text/javascript"; mf.defer = true;mf.src = "${urlByLang()}";document.getElementsByTagName("head")[0].appendChild(mf);})();`}
        </script>
      );
    }
    return null;
  };

  return (
    <ThemeProvider theme={theme}>
      <Helmet defaultTitle={PAGE_TITLE} titleTemplate={`${PAGE_TITLE} - %s`}>
        <meta name="og:title" content={PAGE_TITLE} />
        <meta name="og:site_name" content={PAGE_TITLE} />
        <meta name="og:description" content={t('meta-description')} />
        <meta name="description" content={t('meta-description')} />
        <meta name="keywords" content="Application keywords" />
        <meta
          name="Copyright"
          content="Intervox Production Music Publishing GmbH"
        />
        <meta
          name="Publisher"
          content="Intervox Production Music Publishing GmbH"
        />
        {mouseFlow()}
      </Helmet>
      <GoogleTagManager gtmId={COUNTRY_TAG_CODES[currentLanguage]} />
      <GlobalStyle />
      <Router history={history}>
        <Switch>
          <Route exact path={routes.api.banner()} component={BannersPreview} />
          <Route render={() => <AppRoutes />} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;
