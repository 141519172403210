import { makeAutoObservable, runInAction } from 'mobx';
import { API, APIRoutes } from '@app/api';

export class TracksStore {
  constructor() {
    makeAutoObservable(this);
  }

  isModalOpen = false;

  uploadedTracks = {
    data: [],
    isLoading: false,
    error: null,
    pagesAmount: 0,
  };

  getUploadedTracks = async (page = 1, per = 12) => {
    this.uploadedTracks.isLoading = true;
    try {
      const {
        data: {
          briefing_tracks,
          meta: { total_count },
        },
      } = await API(APIRoutes.UPLOADED_TRACKS(page, per));

      const formattedTracks = briefing_tracks.map(
        ({ briefing, ...trackData }) => ({
          ...trackData,
          album: briefing,
          briefing,
          isBriefing: true,
        }),
      );

      runInAction(() => {
        this.uploadedTracks.pagesAmount = Math.ceil(total_count / per);
        this.uploadedTracks.data = formattedTracks;
      });
    } catch (error) {
      runInAction(() => {
        this.uploadedTracks.error = error;
      });
    } finally {
      runInAction(() => {
        this.uploadedTracks.isLoading = false;
      });
    }
  };

  deleteTrack = async (briefingID, trackID) => {
    try {
      await API.delete(APIRoutes.TRACK_UPLOADED(briefingID, trackID));
      await this.getUploadedTracks();
    } catch (error) {
      runInAction(() => {
        this.uploadedTracks.error = error;
      });
    } finally {
      this.setTracksModalOpen(false);
    }
  };

  setTracksModalOpen = isOpen => {
    this.isModalOpen = isOpen;
  };
}

export default new TracksStore();
