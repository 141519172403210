import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AltImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-position: center;

  opacity: 0;
  transition: opacity 0.4s ease;
`;

export const Image = styled.div`
  width: 90%;
  height: 0px;
  padding: calc(45% - 5px) 0;
  background-size: 100%;
  background-clip: border-box;
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  border: 5px solid #fff;
  box-shadow: 0 0 2px 0 rgba(13, 13, 13, 0.15);

  position: relative;
  overflow: hidden;

  &:hover {
    ${AltImage} {
      opacity: 1;
    }
  }
`;
