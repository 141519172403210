import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash-es';
import { PLAYER_CONTEXTS } from '@components/PlayerControlButtons/PlayPause';

import {
  TableWrapper,
  StyledTable,
  HeaderRow,
  HeaderCell,
  Cell,
} from '@components/Table/style';

import Row from '@components/Table/components/Row';

const Table = ({
  columns,
  data,
  onRowClick,
  renderExpandedRow,
  shouldExpand,
  highlight,
  shouldFocus,
  playerContext, // eslint-disable-line
}) => {
  return (
    <TableWrapper>
      <StyledTable>
        <thead>
          <HeaderRow>
            {columns.map(({ title, style }, index) => (
              <HeaderCell key={`th-${title}-${index}`} style={style}>
                {title}
              </HeaderCell>
            ))}
          </HeaderRow>
        </thead>
        <tbody>
          {data.map((row, rowId) => (
            <React.Fragment key={`tr-${rowId}`}>
              <Row
                isClickable={isFunction(onRowClick)}
                isExpanded={shouldExpand({ row, rowId })}
                highlight={highlight({ row, rowId })}
                focus={shouldFocus({ row, rowId })}
                {...(isFunction(onRowClick) && {
                  onClick: e => onRowClick(e, { row, rowId }),
                })}
              >
                {columns.map(({ field, render, style }, colId) => (
                  <Cell style={style} key={`td-${rowId}-${colId}`}>
                    {isFunction(render)
                      ? React.createElement(render, {
                          cell: row[field],
                          row,
                          rowId,
                        })
                      : row[field]}
                  </Cell>
                ))}
              </Row>
              {shouldExpand({ row, rowId }) &&
                isFunction(renderExpandedRow) &&
                React.createElement(renderExpandedRow, {
                  row,
                  rowId,
                  columns: columns.length,
                })}
            </React.Fragment>
          ))}
        </tbody>
      </StyledTable>
    </TableWrapper>
  );
};

Table.defaultProps = {
  data: [],
  onRowClick: null,
  renderExpandedRow: null,
  shouldExpand: () => false,
  highlight: () => undefined,
  shouldFocus: () => false,
  playerContext: undefined,
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  onRowClick: PropTypes.func,
  renderExpandedRow: PropTypes.func,
  shouldExpand: PropTypes.func,
  highlight: PropTypes.func,
  shouldFocus: PropTypes.func,
  playerContext: PropTypes.oneOf(Object.values(PLAYER_CONTEXTS)),
};

export default Table;
