/* eslint-disable no-console */
import { makeAutoObservable, runInAction } from 'mobx';
import WaveSurfer from 'wavesurfer.js';
import axios from 'axios';
import { get } from 'lodash-es';
import ahoy from 'ahoy.js';
import { getSecondsFromTimeString, playerTime } from '@utils/formatters';
import * as Stores from '@stores';
import DemoMedleyWaveform from '@assets/mp3/initial_intervox_medley.json';

const DemoMedley =
  'https://storage.googleapis.com/rsm-intervox-staging/backups/initial_intervox_medley.mp3';

const TRACKS_FOR_CONTEXT = {
  projectsStore: 'projectTracks.data',
  searchStore: 'searchResults.data.tracks',
  newsStore: 'tracks',
  albumsStore: 'albumTracks.tracks',
  playlistsStore: 'playlistTracks.data',
  labelsStore: 'tracks.data',
  downloadsStore: 'downloadsTracks.data',
  uploadedTracksStore: 'uploadedTracks.data',
  releasedTracksStore: 'releasedTracks.data',
  composersWebsiteStore: 'composerTracks.data',
};

const EVENTS_FOR_CONTEXT = {
  newsStore: 'play_news',
};

export class PlayerStore {
  constructor() {
    makeAutoObservable(this);
  }

  mainPlayerIsVisible = false;

  reducedPlayerIsVisible = false;

  context = null;

  contextKey = null;

  trackAudioUrl = null;

  waveformUrl = null;

  player = null;

  playPromisePending = false;

  isPlaying = false;

  isLoading = false;

  trackCurrentTime = '00:00';

  trackPlayedPart = 0;

  trackDuration = 0;

  trackTotalTime = '01:00';

  volume = 80;

  isMuted = false;

  isInitialized = false;

  playingTrackId = null;

  activeTrackId = null;

  activeTrack = null;

  nextTrackButtonIsActive = true;

  prevTrackButtonIsActive = true;

  singleTrack = false;

  playingPlaylistId = null;

  getTimeFromWafesurfer = false;

  loadingTrack = false;

  initPlayer = () => {
    if (!this.isInitialized) {
      this.mainPlayerIsVisible = true;

      setTimeout(() => {
        document.body.classList.add('main-player-visible');

        this.player = WaveSurfer.create({
          container: '.waveform',
          progressColor: '#049dbf',
          waveColor: '#c5c5c5',
          barWidth: 2,
          barRadius: 0,
          cursorWidth: 0,
          height: 60,
          barGap: 3,
          hideScrollbar: true,
          normalize: true,
          backend: 'MediaElement',
          responsive: true,
          removeMediaElementOnDestroy: false,
          interact: false,
        });

        // render waveform before song is loaded
        this.drawWaveform(DemoMedleyWaveform.data);

        // load demo medley
        this.player.load(DemoMedley, DemoMedleyWaveform.data, 'metadata');
        this.player.setVolume(this.volume / 100);

        this.singleTrack = true;

        this.player.on('ready', () => {
          const trackDuration = this.player.getDuration() || '1:00';

          runInAction(() => {
            this.isLoading = false;
            this.loadingTrack = false;
            this.isInitialized = true;
            // eslint-disable-next-line no-restricted-globals
            if (!isNaN(trackDuration)) {
              this.trackDuration = trackDuration;
              this.trackTotalTime = playerTime(trackDuration);
              this.getTimeFromWafesurfer = false;
            }
          });
        });

        this.player.on('audioprocess', () => {
          const trackDuration = this.player.getDuration();

          if (this.player.isPlaying()) {
            // eslint-disable-next-line no-restricted-globals
            if (this.getTimeFromWafesurfer && !isNaN(trackDuration)) {
              runInAction(() => {
                this.trackDuration = trackDuration;
                this.trackTotalTime = playerTime(trackDuration);
                this.getTimeFromWafesurfer = false;
              });
            }

            runInAction(() => {
              this.trackCurrentTime = playerTime(this.player.getCurrentTime());
              this.trackPlayedPart =
                this.player.getCurrentTime() / this.trackDuration;
            });
          }
        });

        this.player.on('seek', progress => {
          runInAction(() => {
            this.trackCurrentTime = playerTime(
              progress * getSecondsFromTimeString(this.trackTotalTime),
            );
          });
        });

        this.player.on('play', () => {
          runInAction(() => {
            this.isPlaying = true;
          });
        });

        this.player.on('pause', () => {
          runInAction(() => {
            this.isPlaying = false;
          });
        });

        this.player.on('finish', () => {
          runInAction(() => {
            this.isPlaying = false;
          });
        });

        this.player.on('redraw', () => {
          runInAction(() => {
            this.isLoading = false;
          });
        });

        window.addEventListener('keydown', this.keyboardControlHandler);
      }, 300);
    }
  };

  resetPlayer = () => {
    try {
      this.drawWaveform(DemoMedleyWaveform.data);

      this.player.load(DemoMedley, DemoMedleyWaveform.data);

      this.singleTrack = true;

      this.activeTrackId = null;

      this.activeTrack = null;
    } catch (error) {
      console.warn(error);
    }
  };

  keyboardControlHandler = ev => {
    if (
      ev.which === 32 &&
      ev.target.tagName.toUpperCase() !== 'INPUT' &&
      ev.target.tagName.toUpperCase() !== 'TEXTAREA'
    ) {
      ev.preventDefault();
      this.playPause();
    }
  };

  fetchWaveform = async url => {
    try {
      const {
        data: { data: waveformData },
      } = await axios.get(url);

      this.drawWaveform(waveformData);
    } catch (error) {
      console.warn(error);
    }
  };

  drawWaveform = waveformData => {
    try {
      this.waveformData = waveformData;
      this.player.backend.peaks = waveformData;
      this.player.drawBuffer();
    } catch (error) {
      console.warn(error);
    }
  };

  setActiveTrack = track => {
    if (track) {
      this.activeTrack = track;
      const {
        file: { url, waveform_peak_data },
      } = this.activeTrack;

      this.waveformUrl = waveform_peak_data.url;
      this.trackAudioUrl = url;
    }
  };

  setContext = context => {
    this.isLoading = true;
    try {
      this.contextKey = context;
      this.context = Stores[context];
    } catch (error) {
      console.warn(error);
    }
  };

  playPause = () => {
    if (this.isPlaying) {
      this.pause();
    } else {
      this.play();
    }
  };

  seekTo = part => {
    try {
      if (Number.isNaN(this.player.getDuration())) return;
      this.player.seekTo(part);
      runInAction(() => {
        this.trackPlayedPart = part;
      });
    } catch (error) {
      console.warn(error);
    }
  };

  play = async () => {
    this.playPromisePending = true;
    try {
      await this.player.play();
      runInAction(() => {
        this.playPromisePending = false;
        this.isPlaying = true;
        if (this.activeTrack) {
          this.playingTrackId = this.activeTrack.id;
        }

        this.controlPlaylist('play');
      });
    } catch (error) {
      console.warn(error);
    }
  };

  pause = () => {
    try {
      if (!this.playPromisePending) {
        this.player.pause();
        this.isPlaying = false;
        this.playingTrackId = null;
      }
    } catch (error) {
      console.warn(error);
    }
  };

  controlPlaylist = action => {
    try {
      if (!this.singleTrack) {
        const tracks = get(this.context, TRACKS_FOR_CONTEXT[this.contextKey]);

        const currentTrackIndex = tracks.findIndex(
          track => track.id === this.activeTrackId,
        );
        const nextTrack =
          tracks.length > currentTrackIndex + 1
            ? tracks[currentTrackIndex + 1]
            : null;
        const prevTrack =
          currentTrackIndex !== 0 ? tracks[currentTrackIndex - 1] : null;

        if (!nextTrack) {
          this.nextTrackButtonIsActive = false;
        } else {
          this.nextTrackButtonIsActive = true;
        }

        if (!prevTrack) {
          this.prevTrackButtonIsActive = false;
        } else {
          this.prevTrackButtonIsActive = true;
        }

        switch (action) {
          case 'next':
            this.setActiveTrack(nextTrack);
            this.loadAndPlayTrack({ track: nextTrack });
            break;
          case 'prev':
            this.setActiveTrack(prevTrack);
            this.loadAndPlayTrack({ track: prevTrack });
            break;
          default:
            break;
        }
      } else {
        this.nextTrackButtonIsActive = false;
        this.prevTrackButtonIsActive = false;
      }
    } catch (error) {
      console.warn(error);
    }
  };

  nextTrack = () => {
    this.controlPlaylist('next');
  };

  prevTrack = () => {
    this.controlPlaylist('prev');
  };

  setVolume = volume => {
    try {
      [this.volume] = volume;
      this.player.setVolume(this.volume / 100);
    } catch (error) {
      console.warn(error);
    }
  };

  toggleMute = () => {
    try {
      this.isMuted = !this.isMuted;
      this.player.setMute(this.isMuted);
    } catch (error) {
      console.warn(error);
    }
  };

  loadAndPlayTrack = async (
    { track, context = null, playlistId },
    retry = true,
  ) => {
    if (this.loadingTrack) return;
    try {
      this.loadingTrack = true;
      this.setActiveTrack(track);
      await this.fetchWaveform(this.waveformUrl);
      await this.player.load(this.trackAudioUrl, this.waveformData);

      if (playlistId) {
        runInAction(() => {
          this.playingPlaylistId = playlistId;
        });
      }

      if (track.singleTrack) {
        runInAction(() => {
          this.singleTrack = true;
        });
      } else {
        if (context) {
          this.setContext(context);
        }

        runInAction(() => {
          this.singleTrack = false;
        });
      }

      runInAction(() => {
        this.activeTrackId = track.id;
        if (track.length) {
          this.trackTotalTime = track.length;
          this.trackDuration = getSecondsFromTimeString(track.length);
        } else {
          this.getTimeFromWafesurfer = true;
        }
      });

      this.play();

      const isMedley = Boolean(track.medley);

      const eventName =
        EVENTS_FOR_CONTEXT[context] || isMedley ? 'play_medley' : 'play_track';

      ahoy.track(eventName, { id: this.activeTrack.id });
    } catch (error) {
      console.warn(error);
      this.loadingTrack = false;
      if (retry) {
        this.loadAndPlayTrack({ track, context, playlistId }, false);
      }
    }
  };

  togglePlayer = () => {
    this.mainPlayerIsVisible = !this.mainPlayerIsVisible;
    this.reducedPlayerIsVisible = !this.reducedPlayerIsVisible;
    document.body.classList.toggle('main-player-visible');
  };
}

export default new PlayerStore();
