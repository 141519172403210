import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';
import { Flex } from 'rebass';

export const ResultsWrapper = styled.div`
  position: relative;
`;

export const Meta = styled.div`
  margin-top: -50px;
  margin-bottom: 30px;

  @media ${breakpoint.lg} {
    margin-top: -15px;
  }

  @media ${breakpoint.md} {
    margin-top: -15px;
  }
`;

export const ButtonWrapper = styled(Flex)`
  justify-content: center;
  margin-top: 30px;
`;

export const SortLinkWrapper = styled.span`
  border-bottom: 2px solid
    ${({ isActive, theme }) =>
      isActive ? theme.colors.textPrimary : 'transparent'};
`;
