import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { isFunction } from 'lodash-es';

export const STYLE = {
  DEFAULT: 'DEFAULT',
  CIRCLE: 'CIRCLE',
  SMALL_CIRCLE: 'SMALL_CIRCLE',
  OUTLINED: 'OUTLINED',
};

const CIRCLE = (size = 40) => css`
  border-radius: 100%;
  border: 1px solid #606060;
  width: ${size}px;
  height: ${size}px;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: background 0.2s;
  &:hover {
    background-color: rgba(4, 196, 217, 0.1);
  }

  svg {
    width: 90%;
    height: 90%;
    margin-left: 5px;

    g {
      fill: #04c4d9;
      stroke: #04c4d9;
    }
  }

  .pause {
    margin-left: 0;
  }
`;

const SMALL_CIRCLE = () => CIRCLE(28);

const DEFAULT = () => css`
  svg {
    g {
      &:hover {
        opacity: 1;
        fill: rgba(4, 196, 217, 0.2);
      }
    }
  }
`;

const KIND = {
  [STYLE.CIRCLE]: CIRCLE,
  [STYLE.SMALL_CIRCLE]: SMALL_CIRCLE,
};

export const Button = styled.button`
  svg {
    g {
      opacity: 0.8;
      stroke: #049dbf;
      stroke-width: 1.5px;
      transition: all 0.3s ease-in-out;
    }
  }

  ${({ outlined, theme }) =>
    !outlined
      ? css`
          svg {
            g {
              opacity: 1;
              fill: ${theme.colors.secondary};
            }
          }
        `
      : null}

  ${({ kind }) => {
    if (kind && isFunction(KIND[kind])) {
      return KIND[kind]();
    }
    return DEFAULT();
  }}
`;
