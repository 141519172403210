import { makeAutoObservable, runInAction } from 'mobx';
import { API, APIRoutes } from '@app/api';

const DEFAULT_STORE_DATA = {
  isLoading: false,
  error: null,
  data: {},
};

export const STORE_TYPES = {
  dataProtectionPage: 'dataProtectionPage',
  impressumPage: 'impressumPage',
  agbPage: 'agbPage',
  cookiesPage: 'cookiesPage',
};

export class CmsPageStore {
  constructor() {
    makeAutoObservable(this);
  }

  [STORE_TYPES.dataProtectionPage] = {
    url: APIRoutes.DATA_PROTECTION_PAGE,
    ...DEFAULT_STORE_DATA,
  };

  [STORE_TYPES.impressumPage] = {
    url: APIRoutes.IMPRESSUM_PAGE,
    ...DEFAULT_STORE_DATA,
  };

  [STORE_TYPES.agbPage] = {
    url: APIRoutes.AGB_PAGE,
    ...DEFAULT_STORE_DATA,
  };

  [STORE_TYPES.cookiesPage] = {
    url: APIRoutes.COOKIES_PAGE,
    ...DEFAULT_STORE_DATA,
  };

  getPage = async storePage => {
    storePage.isLoading = true;

    try {
      const {
        data: { page },
      } = await API(storePage.url);
      runInAction(() => {
        storePage.data = page;
      });
    } catch (error) {
      runInAction(() => {
        storePage.error = error;
      });
    } finally {
      runInAction(() => {
        storePage.isLoading = false;
      });
    }
  };
}

export default new CmsPageStore();
