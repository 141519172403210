import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Flex } from 'rebass';
import Text from '@components/Text';
import { breakpoint } from '@styles/breakpoints';

export const InfoButton = styled(Flex)`
  margin: 0 3px 0 9px;
  align-items: center;
  height: 40px;
`;

export const Track = styled.div`
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(133, 133, 133, 0.5);
  box-shadow: ${({ active }) =>
    active ? '0 0 7px 0 rgba(13, 13, 13, 0.18);' : 'none'};
  &:first-of-type {
    border-top: 1px rgba(133, 133, 133, 0.5) solid;
  }
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
  min-height: ${({ theme }) => theme.track.listItemHeight}px;

  &:hover {
    background-color: ${({ active }) =>
      active ? 'transparent' : 'rgba(0, 0, 0, 0.02)'};
  }
`;

export const TrackListItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TrackDetails = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  flex: 1;
`;

export const TrackNo = styled.h4`
  font-family: ${({ theme }) => theme.fonts.secondary.regular};
  color: ${({ theme }) => theme.colors.primaryVariant};
  font-size: 13px;
  line-height: 17px;
  margin: 0 10px;
`;

export const PlayButton = styled.button`
  margin-right: 17px;
  display: flex;

  svg {
    g {
      opacity: 0.3;
      stroke: #049dbf;
      transition: all 0.3s ease-in-out;
    }
  }

  ${({ active }) =>
    active
      ? css`
          svg {
            g {
              opacity: 1;
              fill: #049dbf;
            }
          }
        `
      : null}
`;

export const MobileImage = styled.img`
  max-width: 33px;
  margin-right: 5px;
`;

export const MobileContextMenuTrigger = styled.button`
  width: 24px;
`;

export const TrackComposers = styled.div``;

export const TrackInfo = styled(Flex)`
  align-items: center;
  justify-content: space-between;
`;

export const TrackInfoText = styled.div`
  &:first-of-type {
    text-align: right;
  }

  & + & {
    width: 70px;
    margin-left: 40px;
  }
`;

export const Title = styled(Flex)`
  flex-direction: column;
  margin-left: ${({ active }) => (active ? '15px' : '5px')};
`;

export const TrackName = styled(Text)`
  flex-direction: column;
  font-size: 14px;
  font-weight: ${({ active }) => (active ? 'bold' : 'regular')};
`;

export const ScrollContainer = styled.div`
  flex: 1;
  max-height: ${({ theme }) => theme.track.listItemHeight * 6}px;
  @media ${breakpoint.md} {
    height: ${({ theme }) => theme.track.listItemHeight * 6}px;
    max-height: none;
  }
`;
