import React, { useEffect, useState } from 'react';
import * as Styled from '@components/ScrollTopButton/style';
import ARROW from '@assets/images/icons/arrow-up.svg';
// For safari
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

const ScrollTopButton = () => {
  const [hidden, setHidden] = useState(true);
  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  const onScroll = () => {
    const { pageYOffset } = window;

    if (pageYOffset < 100) setHidden(true);
    else setHidden(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);
  return (
    <Styled.Button hidden={hidden} onClick={scrollToTop}>
      <img src={ARROW} alt="arrow" />
    </Styled.Button>
  );
};

export default ScrollTopButton;
