import { setItem, getItem, KEYS, removeItem } from '@utils/storage';

export const saveAuthHeaders = ({ token, client, uid, expiry }) => {
  setItem(KEYS.AUTH_TOKEN, token);
  setItem(KEYS.AUTH_CLIENT, client);
  setItem(KEYS.AUTH_UID, uid);
  setItem(KEYS.AUTH_EXPIRY, expiry);
};

export const getAuthHeaders = () => {
  return {
    token: getItem(KEYS.AUTH_TOKEN),
    client: getItem(KEYS.AUTH_CLIENT),
    uid: getItem(KEYS.AUTH_UID),
    expiry: getItem(KEYS.AUTH_EXPIRY),
  };
};

export const removeAuthHeaders = () => {
  removeItem(KEYS.AUTH_TOKEN);
  removeItem(KEYS.AUTH_CLIENT);
  removeItem(KEYS.AUTH_UID);
  removeItem(KEYS.AUTH_EXPIRY);
};
