import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';

import {
  API,
  APIRoutes,
  setAuthenticationHeaders,
  clearAuthenticationHeaders,
} from '@app/api';
import {
  saveAuthHeaders,
  removeAuthHeaders,
  getAuthHeaders,
} from '@utils/authStorage';
import { locations } from '@utils/i18n/locations';
import { history } from '@app/history';
import customerStore from '@stores/customerStore';
import i18n from '@utils/i18n';
import { TYPES } from '@components/Form/Notification/style';
import { routerStore } from '@stores';
import routes from '@routes';

export const PROVIDERS = {
  FACEBOOK: 'FACEBOOK',
  GOOGLE: 'GOOGLE',
};
export class AuthStore {
  isAuthenticated = false;

  isValidated = false;

  oAuth = {
    body: null,
    provider: null,
  };

  signInStatus = {
    isLoading: false,
    formNotification: null,
  };

  accountDeleteStatus = {
    isLoading: false,
    formNotification: null,
  };

  constructor() {
    makeAutoObservable(this);
  }

  signIn = async ({ email, password, remember }) => {
    const { action: prevAction } = history;

    this.signInStatus.isLoading = true;

    try {
      const {
        data: { customer },
        headers,
      } = await API.post(APIRoutes.SIGN_IN, {
        email,
        password,
        remember,
      });

      const redirectUrl = this.getCustomerRedirectUrl(customer);

      if (!redirectUrl) {
        const { 'access-token': token, client, uid, expiry } = headers;

        saveAuthHeaders({ token, client, uid, expiry });
        setAuthenticationHeaders({ token, client, uid, expiry });

        runInAction(() => {
          this.authenticationToken = token;
          this.isAuthenticated = true;
          customerStore.setCustomerData(customer);
        });
      }

      this.redirectionHandler(redirectUrl, prevAction);
    } catch ({ errors }) {
      const errorMessage =
        errors?.general || i18n.t('An error occurred. Please try again later');

      if (errorMessage) {
        runInAction(() => {
          this.signInStatus.formNotification = {
            type: TYPES.ERROR,
            message: errorMessage,
          };
        });
      }
    } finally {
      runInAction(() => {
        this.signInStatus.isLoading = false;
      });
    }

    return null;
  };

  oAuthSignIn = async (data, provider, isFormatted = false) => {
    const { action: prevAction } = history;

    this.signInStatus.isLoading = true;

    try {
      const body = isFormatted
        ? data
        : {
            access_token:
              provider === PROVIDERS.FACEBOOK
                ? data.accessToken
                : data.tokenObj.id_token,
            first_name:
              provider === PROVIDERS.FACEBOOK
                ? data.first_name
                : data.profileObj.givenName,
            last_name:
              provider === PROVIDERS.FACEBOOK
                ? data.last_name
                : data.profileObj.familyName,
            uid:
              provider === PROVIDERS.FACEBOOK
                ? data.id
                : data.profileObj.googleId,
            email:
              provider === PROVIDERS.FACEBOOK
                ? data.email
                : data.profileObj.email,
          };
      runInAction(() => {
        this.oAuth.body = body;
        this.oAuth.provider = provider;
      });

      const {
        headers,
        data: { customer },
      } = await API.post(APIRoutes.O_AUTH[provider], {
        ...body,
        terms_and_conditions_accepted: true,
      });
      const redirectUrl = this.getCustomerRedirectUrl(customer);

      if (!redirectUrl) {
        const { 'access-token': token, client, uid, expiry } = headers;

        saveAuthHeaders({ token, client, uid, expiry });
        setAuthenticationHeaders({ token, client, uid, expiry });

        runInAction(() => {
          this.authenticationToken = headers.token;
          this.isAuthenticated = true;
          customerStore.setCustomerData(customer);
        });
      }
      this.redirectionHandler(redirectUrl, prevAction);
    } catch (error) {
      if (error.errors === undefined) {
        runInAction(() => {
          this.signInStatus.formNotification = {
            type: TYPES.ERROR,
            message: i18n.t('Unexpected error. Please try again later.'),
          };
        });
      } else {
        routerStore.push(routes.oAuthForm);
      }
    } finally {
      runInAction(() => {
        this.signInStatus.isLoading = false;
      });
    }
  };

  clearSignInForm = () => {
    this.signInStatus = {
      isLoading: false,
      formNotification: null,
    };
  };

  setIsAuthenticated = isAuthenticated => {
    this.isAuthenticated = isAuthenticated;
    this.isValidated = true;
  };

  validateCustomerSession = () => {
    if (!this.isAuthenticated) {
      const { token } = getAuthHeaders();
      if (token) {
        customerStore.getCustomer(this.setIsAuthenticated);
      } else {
        this.isValidated = true;
      }
    }
  };

  logout = () => {
    removeAuthHeaders();
    clearAuthenticationHeaders();
    this.isAuthenticated = false;
    customerStore.setCustomerData(null);
  };

  getCustomerRedirectUrl = customer => {
    let customerCountryCode = null;

    switch (customer.country) {
      case 'GB':
        customerCountryCode = 'EN';
        break;
      case 'BR':
        customerCountryCode = 'PT';
        break;
      case 'AT':
      case 'CH':
        customerCountryCode = 'DE';
        break;
      case 'SK':
      case 'CZ':
        if (!customer.is_composer && window.location.origin === locations.CZ) {
          customerCountryCode = 'CZ';
        } else {
          customerCountryCode = 'EN';
        }
        break;
      default:
        customerCountryCode = customer.country;
    }

    const validCustomerDomain = locations[customerCountryCode] || locations.EN;
    let redirectUrl;

    if (window.location.origin !== validCustomerDomain) {
      redirectUrl = validCustomerDomain;
    }

    return redirectUrl;
  };

  redirectionHandler = (redirectUrl, prevAction) => {
    if (redirectUrl) {
      this.logout();
      window.location.href = redirectUrl;
    } else if (prevAction === 'PUSH') {
      history.goBack();
    } else {
      history.push('/');
    }
  };

  getSignInMessage = () => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const isAccountSuccess = url.searchParams.get(
      'account_confirmation_success',
    );
    const isPasswordSuccess = url.searchParams.get('password_change_success');
    const error = url.searchParams.get('error');

    let newNotification = null;

    if (isAccountSuccess === 'true') {
      newNotification = {
        type: TYPES.SUCCESS,
        message: i18n.t('Account confirmed'),
      };
    } else if (isPasswordSuccess === 'true') {
      newNotification = {
        type: TYPES.SUCCESS,
        message: i18n.t('Password changed'),
      };
    } else if (error) {
      if (error === 'account_already_confirmed') {
        newNotification = {
          type: TYPES.SUCCESS,
          message: i18n.t('Email was already confirmed, please try signing in'),
        };
      } else if (error === 'invalid_confirmation_token') {
        newNotification = {
          type: TYPES.ERROR,
          message: i18n.t('Confirmation token is not valid'),
        };
      }
    }

    this.formNotification = newNotification;
  };

  deleteAccount = async formData => {
    try {
      this.accountDeleteStatus.isLoading = true;
      await API.delete(APIRoutes.DELETE_ACCOUNT, { data: formData });

      this.logout();
      toast(i18n.t('Account deleted successfully'));
    } catch ({ errors }) {
      const errorMessage = errors?.id || errors?.password;

      if (errorMessage && errorMessage[0]) {
        runInAction(() => {
          this.accountDeleteStatus.formNotification = {
            type: TYPES.ERROR,
            message: errorMessage[0],
          };
        });
      }
    } finally {
      runInAction(() => {
        this.accountDeleteStatus.isLoading = false;
      });
    }
  };
}

export default new AuthStore();
