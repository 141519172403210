import 'swiper/swiper.scss';
import { css } from '@emotion/react';
import { Link as RouterLink } from 'react-router-dom';
import { Flex } from 'rebass';
import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';
import { Header as GlobalHeader } from '@styles/globalStyles';

export const POSITIONS = {
  ABSOLUTE: 'ABSOLUTE',
  RELATIVE: 'RELATIVE',
  HIDDEN: 'HIDDEN',
};

const ABSOLUTE = css`
  position: absolute;
  top: 35%;
  z-index: 2;

  &.button-prev {
    left: 0;
  }
  &.button-next {
    right: 0;
  }
`;

const RELATIVE = css`
  margin: auto;
`;

const HIDDEN = css`
  display: none;
`;

const POSITION = {
  [POSITIONS.ABSOLUTE]: ABSOLUTE,
  [POSITIONS.RELATIVE]: RELATIVE,
  [POSITIONS.HIDDEN]: HIDDEN,
};

export const Header = styled(GlobalHeader)`
  @media ${breakpoint.sm} {
    text-align: center;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  font-size: 100px;

  ${({ ofComposers }) =>
    !ofComposers &&
    css`
      .swiper-slide {
        width: 260px;

        @media ${breakpoint.xl} {
          width: 240px;
        }

        @media ${breakpoint.md} {
          width: 200px;
        }
      }
    `}
`;

export const Button = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;

  @media ${breakpoint.sm} {
    ${({ buttonsPositionForSM }) => POSITION[buttonsPositionForSM]}
  }
`;

export const LinkWrapper = styled.span`
  margin: 10px 0 20px auto;
  display: flex;

  @media ${breakpoint.sm} {
    margin: auto;
    width: 100%;
    position: absolute;
    bottom: 10px;
  }
`;

export const Link = styled(RouterLink)`
  margin: auto 0 auto auto;
  display: flex;

  @media ${breakpoint.sm} {
    margin: auto;
  }
`;

export const ButtonWrapper = styled(Flex)`
  @media ${breakpoint.sm} {
    ${({ buttonsPositionForSM }) =>
      buttonsPositionForSM === POSITIONS.RELATIVE && 'margin: 23px 25% 0 25%;'}
    ${({ hideOnMobile }) => hideOnMobile && 'visibility: hidden;'}
  }

  div {
    height: 0;
    border-bottom: 1px solid #095f73;
  }
`;
