import styled from '@emotion/styled';
import { Flex } from 'rebass';

export const Wrapper = styled.div`
  padding: ${({ theme }) => theme.page.mainContentPadding};
  padding-top: 42px;
  padding-bottom: 15px;
  transition: background-position 0.3s ease;
  position: sticky;
  background-position: -100%;
  top: 0;
  z-index: 5;
`;

export const RightSection = styled.div`
  display: flex;
  padding-top: 6px;

  & > button,
  & > a {
    margin: 0 0 0 8px;
  }
`;

export const Navbar = styled(Flex)`
  justify-content: space-between;
  align-items: flex-start;
`;
