import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Redirect, Route as RouteRaw } from 'react-router-dom';

import routes from '@routes';

const AuthRoute = ({ authStore: { isAuthenticated }, children, ...rest }) => {
  useEffect(() => {
    // scroll to top on route change
    window.scrollTo(0, 0);
  }, [rest.location.pathname]);

  let redirectTo = '';

  if (isAuthenticated) {
    redirectTo = routes.base;
  }

  return (
    <RouteRaw
      {...rest}
      render={() => (redirectTo ? <Redirect to={redirectTo} /> : children)}
    />
  );
};

AuthRoute.propTypes = {
  authStore: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default inject('authStore')(observer(AuthRoute));
