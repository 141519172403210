import styled from '@emotion/styled';

export const ContactUsWrapper = styled.div`
  border-top: 1px solid white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const ContactUsMain = styled.div`
  padding-top: 20px;
  p {
    margin-bottom: 8px;
    line-height: 15px;
  }
`;

export const ContactUsSecondary = styled.div`
  a {
    text-decoration: underline;
    line-height: 15px;
  }
`;
