import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import Tooltip, { TRIGGER } from '@components/Tooltip';
import routes from '@routes';
import AlbumTooltipBody from '@components/Tile/AlbumTooltipBody';

import * as Styled from '@components/Tile/style';
import PlayPauseButton, {
  PLAYER_CONTEXTS,
  STYLE,
} from '@components/PlayerControlButtons/PlayPause';
import ContextMenuIcon from '@components/IconButtons/ContextMenuIcon';

const Tile = ({
  name,
  description,
  medley,
  cover: { url },
  isAlbum,
  withBackground,
  uuid,
  id,
  tracksIds,
}) => {
  const coverLinkUrl = useMemo(
    () =>
      isAlbum ? routes.album(uuid, tracksIds.join(',')) : routes.playlist(uuid),
    [isAlbum],
  );

  const medleyTrack = {
    album: {
      cover: {
        url,
        name,
      },
      description,
      name,
      uuid,
    },
    file: {
      ...medley,
    },
    id,
    name,
    medley: true,
    singleTrack: true,
  };

  return (
    <Styled.Wrapper withBackground={withBackground}>
      <Styled.Link to={coverLinkUrl}>
        <Styled.Image src={url} alt={name} />
      </Styled.Link>
      {isAlbum && (
        <Styled.TitleBar>
          <Styled.Link
            to={isAlbum ? routes.album(uuid) : routes.playlists(uuid)}
          >
            <Styled.Title>{name}</Styled.Title>
          </Styled.Link>
          <Styled.ButtonWrapper>
            <Styled.PlayButton>
              <PlayPauseButton
                context={PLAYER_CONTEXTS.ALBUMS}
                track={medleyTrack}
                style={STYLE.SMALL_CIRCLE}
                outlined
              />
            </Styled.PlayButton>

            <Tooltip
              trigger={TRIGGER.CLICK}
              tooltipBody={<AlbumTooltipBody uuid={uuid} id={id} />}
            >
              <Styled.MenuButton>
                <ContextMenuIcon />
              </Styled.MenuButton>
            </Tooltip>
          </Styled.ButtonWrapper>
        </Styled.TitleBar>
      )}
    </Styled.Wrapper>
  );
};
Tile.defaultProps = {
  isAlbum: false,
  withBackground: true,
  medley: {
    url: null,
  },
  id: null,
  tracksIds: [],
  description: undefined,
};

Tile.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  cover: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  isAlbum: PropTypes.bool,
  withBackground: PropTypes.bool,
  uuid: PropTypes.string.isRequired,
  id: PropTypes.number,
  medley: PropTypes.shape({
    url: PropTypes.string,
  }),
  tracksIds: PropTypes.array,
};

export default memo(Tile);
