import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useParams } from 'react-router-dom';
import { uniqueId } from 'lodash-es';

import { MainContent } from '@styles/globalStyles';
import SearchFilters from '@components/SearchFilters';
import Preloader from '@components/Preloader';
import Text, { VARIANTS } from '@components/Text';
import TracksTable from '@components/TracksTable';
import Button, { VARIANTS as BUTTON_VARIANTS } from '@components/Button';
import NoResultsBox from '@containers/SearchResults/components/NoResults';
import { PLAYER_CONTEXTS } from '@components/PlayerControlButtons/PlayPause';
import * as Styled from '@containers/SearchResults/style';
import routes from '@routes';
import Tile from '@components/Tile';
import GridList from '@components/GridList';
import ScrollTopButton from '@components/ScrollTopButton';
import Tabs from '@components/Tabs';
import { history } from '@app/history';
import Header from '@components/Header';

export const KINDS = [
  { name: 'Tracks', value: 'tracks' },
  { name: 'Albums', value: 'albums' },
];

const SearchResultsPage = ({
  authStore: { isAuthenticated },
  searchStore: {
    processSearch,
    searchResults: {
      isLoading,
      data: { meta, tracks }, // eslint-disable-line
      expandedTrack,
      expandedAlternatives,
    },
    toggleTrackAlternatives,
    fetchMore,
    toggleTrack,
    resetFilters,
    albums,
  },
}) => {
  const { search } = useLocation();
  const { sort } = useParams();

  const { t } = useTranslation();
  useEffect(() => {
    processSearch(search, sort);
  }, [search, sort]);

  useEffect(
    () => () => {
      resetFilters();
    },
    [],
  );

  return (
    <>
      <MainContent>
        <Helmet>
          <title>{t('Search results')}</title>
        </Helmet>

        <SearchFilters />

        <Header
          title={t('Search results')}
          nav={
            <Tabs
              tabs={KINDS}
              activeTab={KINDS[sort === 'albums' ? 1 : 0].value}
              setActiveTab={value =>
                history.push({
                  pathname: routes.search(value === 'albums' ? 'albums' : ''),
                  search,
                })
              }
            />
          }
        />

        <Styled.Meta>
          {meta && (
            <Text variant={VARIANTS.H3}>
              {isLoading ? (
                <Preloader />
              ) : (
                <>
                  <Styled.SortLinkWrapper isActive={sort !== 'albums'}>
                    <Link
                      to={{
                        pathname: routes.search(''),
                        search,
                      }}
                    >
                      {t('{{tracks}} TRACKS', {
                        tracks: meta.total_count,
                      })}
                    </Link>
                  </Styled.SortLinkWrapper>{' '}
                  /{' '}
                  <Styled.SortLinkWrapper isActive={sort === 'albums'}>
                    <Link
                      to={{
                        pathname: routes.search('albums'),
                        search,
                      }}
                    >
                      {t('{{albums}} ALBUMS', { albums: meta.albums_count })}
                    </Link>
                  </Styled.SortLinkWrapper>
                </>
              )}
            </Text>
          )}
        </Styled.Meta>
        <Styled.ResultsWrapper>
          {isLoading && <Preloader center cover />}

          {tracks?.length > 0 && sort === 'albums' ? (
            <GridList>
              {albums.map(album => (
                <li key={uniqueId()}>
                  <Tile {...album} isAlbum />
                </li>
              ))}
            </GridList>
          ) : (
            <TracksTable
              {...{
                data: tracks,
                toggleTrackAlternatives,
                toggleTrack,
                expandedTrack,
                expandedAlternatives,
                isAuthenticated,
                playerContext: PLAYER_CONTEXTS.SEARCH,
              }}
              showAlbumCovers
            />
          )}
        </Styled.ResultsWrapper>
        {tracks && tracks.length > 0 && meta && meta.next ? (
          <Styled.ButtonWrapper>
            <Button
              variant={BUTTON_VARIANTS.GREEN}
              label={t('Show more')}
              onClick={fetchMore}
              loading={isLoading}
            />
          </Styled.ButtonWrapper>
        ) : null}
        {tracks && tracks.length === 0 && !isLoading && <NoResultsBox />}
      </MainContent>
      <ScrollTopButton />
    </>
  );
};

SearchResultsPage.propTypes = {
  authStore: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
  }).isRequired,
  searchStore: PropTypes.shape({
    processSearch: PropTypes.func,
    searchResults: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.shape({
        meta: PropTypes.object,
        tracks: PropTypes.array,
      }),
      error: PropTypes.object,
      expandedTrack: PropTypes.number,
      expandedAlternatives: PropTypes.bool,
    }),
    fetchMore: PropTypes.func,
    toggleTrack: PropTypes.func,
    toggleTrackAlternatives: PropTypes.func,
    resetFilters: PropTypes.func,
    albums: PropTypes.array,
  }).isRequired,
};

export default inject('authStore', 'searchStore')(observer(SearchResultsPage));
