import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';
import Select from '@components/Form/Select';
import TextField from '@components/Form/TextField';
import SectionTitle from '@components/SectionTitle';
import Checkbox from '@components/Form/Checkbox';
import {
  BUSINESS_TYPES,
  MRO_ATTRIBUTES,
  PRO_ATTRIBUTES,
} from '@root/stores/registrationStore';
import routes from '@routes';
import { StyledLink } from '@styles/globalStyles';

const DetailsTab = ({
  register,
  watch,
  control,
  errors,
  countries,
  isComposer,
}) => {
  const { t } = useTranslation();

  const isOtherBusiness = watch('business_type')?.value === 'other';

  return (
    <Box>
      <Box
        sx={{
          display: 'grid',
          gridGap: 4,
          gridTemplateColumns: ['1fr', '1fr', '1fr', '1fr 1fr'],
        }}
      >
        <Box width={1}>
          <SectionTitle>{t('Profile')}</SectionTitle>
          <TextField
            name="first_name"
            label={t('First name')}
            placeholder={t('First name')}
            register={register}
            errors={errors}
          />
          <TextField
            name="last_name"
            label={t('Last name')}
            placeholder={t('Last name')}
            register={register}
            errors={errors}
          />
          <Select
            control={control}
            isSearchable
            name="business_type"
            label={t('Business type')}
            placeholder={t('Select business type')}
            options={BUSINESS_TYPES}
            errors={errors}
            required
          />
          <Box display={isOtherBusiness ? 'block' : 'none'}>
            <TextField
              type="text"
              register={register}
              errors={errors}
              name="business_type_other"
              label={t('If ‘other’, please specify')}
              placeholder={t('Details')}
              required={isOtherBusiness}
            />
          </Box>

          <TextField
            name="email"
            label={t('E-mail')}
            placeholder={t('E-mail')}
            register={register}
            errors={errors}
            disabled
          />
        </Box>
        <Box width={1}>
          <SectionTitle>{t('Contact')}</SectionTitle>
          <TextField
            name="phone_no"
            label={t('Phone number')}
            placeholder={t('Phone number')}
            register={register}
            errors={errors}
          />
          <TextField
            name="mobile_phone_no"
            label={t('Mobile phone number')}
            placeholder={t('Mobile phone number')}
            register={register}
            errors={errors}
          />
          <TextField
            name="fax"
            label={t('Fax')}
            placeholder={t('Fax')}
            register={register}
            errors={errors}
          />
          <TextField
            name="website"
            label={t('Website')}
            placeholder={t('Website')}
            register={register}
            errors={errors}
          />
        </Box>
        <Box width={1}>
          <SectionTitle>{t('Address')}</SectionTitle>
          <TextField
            name="street"
            label={t('Street and house number')}
            placeholder={t('Street and house number')}
            register={register}
            errors={errors}
          />
          <TextField
            name="city"
            label={t('City')}
            placeholder={t('City')}
            register={register}
            errors={errors}
          />
          <TextField
            name="zip_code"
            label={t('Zip Code')}
            placeholder={t('Zip Code')}
            register={register}
            errors={errors}
          />
          <Select
            control={control}
            isSearchable
            name="country"
            label={t('Country')}
            options={countries}
            errors={errors}
          />
        </Box>
        {isComposer && (
          <Box width={1}>
            <SectionTitle>{t('Composer')}</SectionTitle>
            <TextField
              name="composer_first_name"
              label={t('Composer first name')}
              placeholder={t('First name')}
              register={register}
              errors={errors}
            />
            <TextField
              name="composer_last_name"
              label={t('Composer last name')}
              placeholder={t('Last name')}
              register={register}
              required
              errors={errors}
            />
            <TextField
              name="ipi_no"
              label={t('IPI number')}
              placeholder={t('IPI number')}
              register={register}
              required
              errors={errors}
              type="number"
              disableSpinners
            />
            <Flex mx={-3}>
              <Box width={1} px={3}>
                <Select
                  control={control}
                  isSearchable
                  name="pro"
                  label={t('PRO')}
                  options={PRO_ATTRIBUTES}
                  errors={errors}
                  required
                />
              </Box>
              <Box width={1} px={3}>
                <Select
                  control={control}
                  isSearchable
                  name="mro"
                  required
                  label={t('MRO')}
                  options={MRO_ATTRIBUTES}
                  errors={errors}
                />
              </Box>
            </Flex>
          </Box>
        )}
        <Box width={1} mb={4}>
          <SectionTitle>{t('Privacy')}</SectionTitle>
          <Box pt={1}>
            {!isComposer && (
              <Checkbox
                name="newsletter_subscribed"
                label={t(
                  'Yes, sign me up. Keep me up to date with your latest news',
                )}
                register={register}
                errors={errors}
              />
            )}
            <Checkbox
              name="website_updates_subscribed"
              label={t('Keep me updated with your website news')}
              register={register}
              errors={errors}
            />
            <Box pt={3}>
              <StyledLink to={routes.auth.delete}>
                {t('Delete my account')}
              </StyledLink>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

DetailsTab.defaultProps = {
  countries: [],
  errors: {},
};

DetailsTab.propTypes = {
  register: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  isComposer: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  countries: PropTypes.array,
};

export default DetailsTab;
