import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Text, { ALIGNS } from '@components/Text';
import {
  IndicatorsWrapper,
  StepIndicator,
} from '@components/StepsIndicators/style';

const StepsIndicators = ({ steps, currentStep }) => {
  const { t } = useTranslation();

  return (
    <IndicatorsWrapper justifyContent="space-around" mb={4}>
      {steps.map(({ name }, index) => (
        <StepIndicator
          key={`step-${index}-indicator`}
          isCurrent={index === currentStep}
          isPassed={index < currentStep}
        >
          <Text align={ALIGNS.CENTER}>{`${t('Step')} ${index +
            1}: ${name}`}</Text>
        </StepIndicator>
      ))}
    </IndicatorsWrapper>
  );
};

StepsIndicators.propTypes = {
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.array.isRequired,
};

export default StepsIndicators;
