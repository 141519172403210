import styled from '@emotion/styled';
import { Box } from 'rebass';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled(Box)`
  transition: opacity 0.3s ease-in-out;

  opacity: ${({ disabled, isLoading }) => (disabled && !isLoading ? 0.4 : 1)};
  border-left: 2px solid ${({ theme }) => theme.colors.stroke};
  padding-left: 10px;
  min-height: 100px;
  position: relative;

  @media ${breakpoint.md} {
    width: 100%;
    border-left: none;
    border-top: 1px solid ${({ theme }) => theme.colors.stroke};
    padding-left: 0;
    padding-top: 7px;
    margin-bottom: 20px;
  }
`;

export const Header = styled.h3`
  font-family: ${({ theme }) => theme.fonts.secondary.regular};
  font-size: 13px;
`;

export const List = styled.ul`
  padding-top: 10px;
`;

export const Item = styled.li`
  button,
  a {
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    text-align: left;
  }
`;
