import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Range } from 'react-range';
import * as Styled from '@containers/MainPlayer/components/VolumeControl/style';

import SpeakerLoud from '@assets/images/icons/speaker-icon-loud.svg';
import SpeakerLoudest from '@assets/images/icons/speaker-icon-loudest.svg';
import SpeakerQuiet from '@assets/images/icons/speaker-icon-quiet.svg';
import SpeakerMute from '@assets/images/icons/speaker-icon-mute.svg';

const VolumeControl = ({
  playerStore: { volume, setVolume, toggleMute, isMuted },
}) => {
  let volumeIcon = SpeakerLoudest;

  if (isMuted) {
    volumeIcon = SpeakerMute;
  } else if (volume < 33) {
    volumeIcon = SpeakerQuiet;
  } else if (volume < 66) {
    volumeIcon = SpeakerLoud;
  }

  return (
    <Styled.Wrapper>
      <Styled.Icon onClick={toggleMute} isMuted={isMuted}>
        <img src={volumeIcon} alt="volume" />
      </Styled.Icon>
      <Range
        step={1}
        min={0}
        max={100}
        values={[volume]}
        onChange={setVolume}
        renderTrack={({ props, children }) => (
          <Styled.VolumeTrack {...props}>{children}</Styled.VolumeTrack>
        )}
        renderThumb={({ props }) => <Styled.VolumeThumb {...props} />}
      />
    </Styled.Wrapper>
  );
};

VolumeControl.propTypes = {
  playerStore: PropTypes.shape({
    volume: PropTypes.number,
    setVolume: PropTypes.func,
    toggleMute: PropTypes.func,
    isMuted: PropTypes.bool,
  }).isRequired,
};

export default inject('playerStore')(observer(VolumeControl));
