import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { uniqueId } from 'lodash-es';

import Preloader from '@components/Preloader';
import Text, { VARIANTS } from '@components/Text';
import { MainContent } from '@styles/globalStyles';
import License from '@components/License';
import * as Styled from '@containers/Licenses/style';
import ContactTile from '@containers/Licenses/ContactTile';

import ACCEPT_ICON from '@assets/images/icons/accept-icon.svg';

const Licenses = ({ licensesStore: { getLicenses, licenses } }) => {
  const { t } = useTranslation();

  useEffect(() => {
    getLicenses();
  }, [getLicenses]);

  return (
    <MainContent>
      <Styled.HeaderSection>
        <Styled.Header>{t('License')}</Styled.Header>
        <Styled.Description variant={VARIANTS.NAV_COLLAPSED}>
          {t(
            // eslint-disable-next-line max-len
            'Download a licence application, fill it out, sign it and send it to info@intervox.de. Call us on +49 (0) 89 189 4090, we will be happy to help you. We will send you an invoice, which is also your licensing agreement, and will take care of your GEMA registration for you.',
          )}
        </Styled.Description>
      </Styled.HeaderSection>
      <Text variant={VARIANTS.H4}>{t('Price list')}</Text>
      <Styled.Separator />
      {licenses.isLoading && <Preloader />}
      <Styled.GridList>
        {licenses.data.map(el => (
          <li key={uniqueId()}>
            <License {...el} />
          </li>
        ))}
      </Styled.GridList>
      <Styled.Separator />
      <Text variant={VARIANTS.H4}>{t('With all memberships')}</Text>
      <Styled.Benefits>
        <Styled.Benefit>
          <Styled.Icon src={ACCEPT_ICON} alt="accept icon" />
          {t('Thousands of tracks with new ones added daily')}
        </Styled.Benefit>
        <Styled.Benefit>
          <Styled.Icon src={ACCEPT_ICON} alt="accept icon" />
          {t('Songs written by curated  artists and writers')}
        </Styled.Benefit>
        <Styled.Benefit>
          <Styled.Icon src={ACCEPT_ICON} alt="accept icon" />
          {t('Save your favourite tracks as projects')}
        </Styled.Benefit>
      </Styled.Benefits>
      <ContactTile />
    </MainContent>
  );
};

Licenses.propTypes = {
  licensesStore: PropTypes.shape({
    getLicenses: PropTypes.func,
    licenses: PropTypes.object,
  }).isRequired,
};

export default inject('licensesStore')(observer(Licenses));
