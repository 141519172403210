import React from 'react';
import PropTypes from 'prop-types';
import TracksTable from '@components/TracksTable';
import ActionsCell from '@components/ProjectTracksTable/components/ActionsCell';
import { PLAYER_CONTEXTS } from '@components/PlayerControlButtons/PlayPause';

const ProjectTracksTable = props => {
  return (
    <TracksTable
      {...{
        ...props,
        isAuthenticated: true,
        playerContext: PLAYER_CONTEXTS.PROJECTS,
        renderActionsCell: ActionsCell,
      }}
    />
  );
};

ProjectTracksTable.defaultProps = {
  expandedTrack: null,
  data: [],
  expandedAlternatives: false,
  toggleTrackAlternatives: () => {},
};

ProjectTracksTable.propTypes = {
  data: PropTypes.array,
  toggleTrack: PropTypes.func.isRequired,
  toggleTrackAlternatives: PropTypes.func,
  expandedTrack: PropTypes.number,
  expandedAlternatives: PropTypes.bool,
};

export default ProjectTracksTable;
