import { makeAutoObservable, runInAction } from 'mobx';
import { API, APIRoutes } from '@app/api';

export class TrustedBiesStore {
  constructor() {
    makeAutoObservable(this);
  }

  trustedBies = {
    isLoading: false,
    data: [],
    error: null,
  };

  getTrustedBies = async () => {
    try {
      this.trustedBies.isLoading = true;

      const {
        data: { trusted_bies },
      } = await API(APIRoutes.TRUSTED_BIES);
      runInAction(() => {
        this.trustedBies.data = trusted_bies;
      });
    } catch (error) {
      runInAction(() => {
        this.trustedBies.error = error;
      });
    } finally {
      runInAction(() => {
        this.trustedBies.isLoading = false;
      });
    }
  };
}

export default new TrustedBiesStore();
