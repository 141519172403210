import styled from '@emotion/styled';

export const Question = styled.h3`
  font-size: 14px;
  line-height: 1.4;
  font-family: ${({ theme }) => theme.fonts.secondary.bold};
  padding-bottom: 0.5em;
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeVariant};
  margin-bottom: 1em;
`;

export const Answer = styled.p`
  font-size: 13px;
  line-height: 1.3;
  margin-bottom: 3em;
`;
