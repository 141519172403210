import styled from '@emotion/styled';
import { Flex } from 'rebass';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled(Flex)`
  padding: 10px 10px 10px 15px;
  background-color: ${({ theme }) => theme.filters.tag.backgroundColor};
  border-radius: ${({ theme }) => theme.filters.tag.borderRadius}px;
  text-transform: capitalize;
  align-items: center;
  margin: 4px;

  transition: background 0.2s;
  &:hover {
    background-color: ${({ theme }) => theme.filters.tag.hoverBackgroundColor};
  }

  @media ${breakpoint.md} {
    padding: 5px 5px 5px 10px;

    span {
      font-size: 12px;
      line-height: 1;
    }
  }
`;

export const Clear = styled.button`
  margin-left: 20px;
  img {
    display: block;
    width: 20px;
    height: 20px;
  }

  @media ${breakpoint.md} {
    margin-left: 10px;
  }
`;
