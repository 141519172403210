import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { NavLink } from 'react-router-dom';
import { Box, Flex } from 'rebass';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled.div`
  padding: 40px;

  @media ${breakpoint.md} {
    padding: 0;
  }
`;

export const SearchButton = styled.button`
  display: inline-flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 12px;
`;

export const Extra4You = styled(NavLink)`
  display: block;
  margin-right: 2px;
  img {
    display: block;
  }
`;

export const Row = styled(Flex)`
  flex-wrap: wrap;
  flex: 1;
  /* max-height: 80vh;
  overflow: auto; */

  @media ${breakpoint.md} {
    ${({ mobileScroll }) =>
      mobileScroll
        ? css`
            overflow-y: auto;
            height: calc(100vh - 183px);
          `
        : null}
  }
`;

export const Col = styled(Box)`
  display: flex;
  flex-direction: column;

  ${({ border, theme }) =>
    border
      ? css`
          border-left: 2px solid ${theme.colors.stroke};
          padding-left: 15px;
        `
      : null};
`;

export const Button = styled.button`
  display: flex;
  border-radius: 4px;
  margin-right: 3px;
  background-color: #eaeaea;
  white-space: nowrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  font-family: ${({ theme }) => theme.fonts.secondary.regular};
  font-size: 13px;
  border: 1px transparent solid;

  > img {
    display: block;
    height: 46px;
  }

  @media ${breakpoint.md} {
    margin-right: 0;

    img {
      height: 40px;
    }
  }
`;

export const ButtonsRow = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  width: 100%;

  > a,
  > button {
    flex: 1;
  }

  > a {
    margin-right: 4px;
  }

  > button {
    margin-left: 4px;
  }
`;
