import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Flex, Box } from 'rebass';
import { VARIANTS as BTN_VARIANTS } from '@components/Button';
import FormButton from '@components/Form/FormButton';
import TextField from '@components/Form/TextField';
import Checkbox from '@components/Form/Checkbox';
import Notification from '@components/Form/Notification';
import { TYPES } from '@components/Form/Notification/style';
import routes from '@routes';
import { StyledLink } from '@components/ContactForm/style';

export const VARIANTS = {
  CONTACT_US: 'CONTACT_US',
  WRITE_FOR_US: 'WRITE_FOR_US',
  REQUEST_MUSIC: 'REQUEST_MUSIC',
};

const ContactForm = ({ onSubmit, formStatus, variant, initialData }) => {
  const { register, handleSubmit, errors: validationErrors, reset } = useForm();
  const { t } = useTranslation();

  const { errors: formErrors, formNotification, isLoading } = formStatus;

  const errors = {
    ...validationErrors,
    ...formErrors,
  };

  useEffect(() => {
    if (formNotification?.type === TYPES.SUCCESS) {
      reset();
    }
  }, [formNotification]);

  useEffect(() => {
    if (initialData) {
      reset(initialData);
    }
  }, [initialData]);

  let messagePlaceholder = t('Write here your message...');

  if (variant === VARIANTS.WRITE_FOR_US) {
    messagePlaceholder = t(
      'Tell us about you and send us some links to listen to you music',
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {formNotification && <Notification {...formNotification} />}
      <Flex width={1} alignItems="center" flexDirection="column">
        <Box width={1}>
          <TextField
            name="full_name"
            label={t('Name')}
            placeholder={t('Your full name')}
            type="text"
            register={register}
            required
            errors={errors}
          />
          <TextField
            name="email"
            label={t('E-mail')}
            placeholder={t('E-mail address')}
            type="text"
            register={register}
            required
            errors={errors}
          />
          {variant === VARIANTS.REQUEST_MUSIC && (
            <TextField
              name="phone"
              label={t('Phone number')}
              placeholder={t('Phone number')}
              type="text"
              register={register}
              required
              errors={errors}
            />
          )}
          <TextField
            name="message"
            label={t('Message')}
            placeholder={messagePlaceholder}
            type="text"
            as="textarea"
            register={register}
            required
            errors={errors}
          />
          {variant === VARIANTS.REQUEST_MUSIC && (
            <>
              <TextField
                name="company"
                label={t('Company')}
                placeholder={t('Your company')}
                type="text"
                register={register}
                required
                errors={errors}
              />
              <TextField
                name="project_information"
                label={t('Project information')}
                placeholder={t('Project information placeholder')}
                type="text"
                as="textarea"
                register={register}
                required
                errors={errors}
              />
            </>
          )}
        </Box>

        <Box width={1} mb={3}>
          <Checkbox
            name="terms_and_conditions_accepted"
            label={
              <span>
                {t(
                  'By ticking this box you consent to us contacting you and agree to our',
                )}{' '}
                <StyledLink to={routes.agb}>
                  {t('Terms of Use and Privacy Policy')}
                </StyledLink>
              </span>
            }
            register={register}
            required
            errors={errors}
          />
        </Box>
        <Box width={1} my={2} textAlign="left">
          <FormButton
            isLoading={isLoading}
            type="submit"
            variant={BTN_VARIANTS.GREEN}
            label={t('Submit')}
          />
        </Box>
      </Flex>
    </form>
  );
};

ContactForm.defaultProps = {
  initialData: undefined,
};

ContactForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formStatus: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(Object.values(VARIANTS)).isRequired,

  initialData: PropTypes.shape({
    full_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    company: PropTypes.string,
  }),
};

export default ContactForm;
