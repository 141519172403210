import styled from '@emotion/styled';

export const Table = styled.table`
  width: 100%;
`;

export const ScrollContainer = styled.div`
  margin-top: 20px;
  flex: 1;
  max-height: ${({ theme }) => theme.track.listItemHeight * 6}px;
  overflow: hidden;
`;
