import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { useForm, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Flex, Box } from 'rebass';
import { Link } from 'react-router-dom';

import routes from '@routes';
import Select from '@components/Form/Select';
import Button, { VARIANTS as BTN_VARIANTS } from '@components/Button';
import FormButton from '@components/Form/FormButton';
import TextField from '@components/Form/TextField';
import SectionTitle from '@components/SectionTitle';
import AuthorRow from '@containers/BriefingPage/components/AuthorRow';
import Dropzone from '@components/Dropzone';
import ProgressBar from '@components/ProgressBar';
import Text, { COLORS, VARIANTS as TEXT_VARIANTS } from '@components/Text';
import { Separator } from '@styles/globalStyles';
import {
  StyledText,
  DescriptionTextarea,
  GridItem,
  MetaGrid,
} from '@containers/BriefingPage/components/style';
import FullTrackRow from '@containers/BriefingPage/components/FullTrackRow';
import { FILE_STATES, FORM_TYPE } from '@containers/BriefingPage/constants';
import AlternativeTrackRow from '@containers/BriefingPage/components/AlternativeTrackRow';

const BriefingForm = ({
  param,
  action,
  briefingsStore: {
    briefingTrack: {
      data,
      errors: formErrors,
      isLoading: formLoading,
      briefing,
    },
    submitBriefingTrack,
    submitBriefingTrackUpdate,
    validateTrackName,
    validateIpi,
    addFullTrackFile,
    fullTrackFile,
    fullTrackProgress,
    removeComposer,
    removeLyricist,
    clearForm,
    uploadFullTrack,
    alternativeTracksProgress,
    loadings,
  },
  genresStore: { genres, getGenres },
  moodsStore: { moods, getMoods },
  topicsStore: { topics, getTopics },
  instrumentationsStore: { instrumentations, getInstrumentations },
  customerStore: {
    customer: { data: customerData },
  },
}) => {
  const {
    register,
    handleSubmit,
    control,
    errors: validationErrors,
    clearErrors,
    reset,
    getValues,
    setError,
    watch,
    setValue,
    trigger,
  } = useForm({
    mode: 'onChange',
  });

  const {
    fields: composerFields,
    append: composerAppend,
    remove: composerRemove,
  } = useFieldArray({
    control,
    name: 'briefing_tracks_composers',
    keyName: 'key',
  });

  const {
    fields: lyricistFields,
    append: lyricistAppend,
    remove: lyricistRemove,
  } = useFieldArray({
    control,
    name: 'briefing_tracks_lyricists',
    keyName: 'key',
  });

  const {
    fields: altFields,
    append: altAppend,
    remove: altRemove,
  } = useFieldArray({
    control,
    name: 'alternative_tracks',
    keyName: 'key',
  });

  const { t } = useTranslation();

  const errors = {
    ...formErrors,
    ...validationErrors,
  };

  const isUpdate = action === FORM_TYPE.UPDATE;

  useEffect(() => {
    return () => clearForm();
  }, []);

  useEffect(() => {
    getGenres();
    getMoods();
    getTopics();
    getInstrumentations();
    return () => {};
  }, [getGenres, getMoods, getTopics, getInstrumentations]);

  useEffect(() => {
    clearErrors('file_control');
  }, [fullTrackFile]);

  const [producerData, setProducerData] = useState(null);

  useEffect(() => {
    if (isUpdate) {
      reset(toJS(data));
    } else {
      let dataSource = {};

      if (customerData?.is_producer && customerData?.composer) {
        dataSource = customerData.composer;
      } else if (customerData?.composers_customers?.length) {
        const { composer } = customerData?.composers_customers[0];
        dataSource = composer;
      } else if (customerData) {
        const { last_name, first_name, ipi_no } = customerData;
        dataSource = { last_name, first_name, cae_ipi: ipi_no };
      }

      const initialData = {
        ...dataSource,
        shares: '100',
      };

      if (customerData?.is_producer) {
        setProducerData({
          first_name: initialData.first_name,
          last_name: initialData.last_name,
          cae_ipi: initialData.cae_ipi,
          shares: initialData.shares,
        });
      }

      reset({
        briefing_tracks_composers: [initialData],
      });
    }
  }, [customerData, action, data]);

  const [isDragStarted, setIsDragStarted] = useState(false);

  useEffect(() => {
    const onDragEnter = () => {
      if (!isDragStarted) {
        setIsDragStarted(true);
      }
    };

    const onDragLeave = e => {
      if (e.target.nodeName?.toUpperCase() === 'HTML') {
        setIsDragStarted(false);
      }
    };

    const onDrop = () => {
      setIsDragStarted(false);
    };

    document.addEventListener('dragover', onDragEnter);
    document.addEventListener('dragleave', onDragLeave);
    document.addEventListener('drop', onDrop);

    return () => {
      document.removeEventListener('dragover', onDragEnter);
      document.removeEventListener('dragleave', onDragLeave);
      document.removeEventListener('drop', onDrop);
    };
  }, []);

  const validateShares = (
    defaultData = {
      briefing_tracks_composers: [],
      briefing_tracks_lyricists: [],
    },
  ) => {
    const {
      briefing_tracks_composers = defaultData.briefing_tracks_composers,
      briefing_tracks_lyricists = defaultData.briefing_tracks_lyricists,
    } = getValues();

    const composersFields = briefing_tracks_composers.map(
      (_item, index) => `briefing_tracks_composers[${index}].shares`,
    );
    const lyricistsFields = briefing_tracks_lyricists.map(
      (_item, index) => `briefing_tracks_lyricists[${index}].shares`,
    );
    const validateFields = [...composersFields, ...lyricistsFields];

    const shareSum = [
      ...(briefing_tracks_composers || []),
      ...(briefing_tracks_lyricists || []),
    ]?.reduce((acc, { shares }) => acc + parseInt(shares, 0), 0);

    const isValid = shareSum === 100;

    if (isValid) {
      clearErrors(validateFields);
    } else {
      validateFields.forEach(field =>
        setError(field, {
          type: 'sum',
          message: t('Shares sum must be equal to 100'),
        }),
      );
    }

    return isValid;
  };

  const validateUniqueIpi = (type, value) => {
    if (!value) return true;
    const formData = getValues();
    const authors = formData[`briefing_tracks_${type}`];
    const ipis = authors.map(({ cae_ipi }) => cae_ipi);

    return ipis.filter(cae_ipi => cae_ipi === value).length < 2;
  };

  const onRemoveAuthor = (type, id) => {
    const formData = getValues();
    const {
      briefing_tracks_composers = [],
      briefing_tracks_lyricists = [],
    } = formData;

    if (type === 'composers') {
      const [removedComposer] = briefing_tracks_composers.splice(id, 1);
      removeComposer(removedComposer);
      composerRemove(id);
    } else if (type === 'lyricists') {
      const [removedLyricist] = briefing_tracks_lyricists.splice(id, 1);
      removeLyricist(removedLyricist);
      lyricistRemove(id);
    }

    validateShares({
      briefing_tracks_composers,
      briefing_tracks_lyricists,
    });
  };

  const commonTextFieldProps = {
    register,
    errors,
    required: false,
  };

  const commonSelectProps = (maxOptions = 99) => ({
    control,
    errors,
    getOptionLabel: ({ name }) => name,
    getOptionValue: ({ id }) => id,
    isSearchable: true,
    required: isUpdate,
    isMulti: true,
    rules: {
      validate: {
        required: v => (isUpdate ? v?.length !== 0 || t('Required') : true),
        maxLength: v => {
          if (v === null) {
            return true;
          }
          return (
            v?.length <= maxOptions || `${t('Max options')}: ${maxOptions}`
          );
        },
      },
    },
  });

  const appendFiles = files => {
    const mappedFiles = files.map(file => {
      return {
        file,
        state: FILE_STATES.NEW,
      };
    });

    altAppend(mappedFiles);
  };

  const onSubmit = () => {
    const formData = getValues(); // read form data with disabled fields

    if (producerData) {
      formData.briefing_tracks_composers = [producerData];
    }

    return isUpdate
      ? submitBriefingTrackUpdate(formData, setValue)
      : submitBriefingTrack(formData);
  };

  const isLoading = formLoading || Object.values(loadings).includes(true);

  const onAppendLyricist = () => {
    const dataSource = {};

    if (
      lyricistFields.length === 0 &&
      customerData?.customers_lyricists?.length > 0
    ) {
      const defaultLyricist = customerData?.customers_lyricists[0].lyricist;

      dataSource.cae_ipi = defaultLyricist.cae_ipi;
      dataSource.first_name = defaultLyricist.first_name;
      dataSource.last_name = defaultLyricist.last_name;
    }
    lyricistAppend({ ...dataSource, isNew: true });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" name="uuid" value={param} ref={register} />
      <SectionTitle>{t('Track data')}</SectionTitle>
      <Box width={[1, 1, 1 / 2]}>
        <TextField
          {...{
            name: `name`,
            label: t('Track title'),
            placeholder: t('Track title'),
            ...commonTextFieldProps,
            required: true,
            rules: {
              validate: {
                required: v => v?.length > 0 || t('Required'),
                // eslint-disable-next-line no-return-await
                unique: async v =>
                  (await validateTrackName(v)) ||
                  t('Track title already exists'),
              },
            },
            disabled: isUpdate,
          }}
        />
      </Box>

      {!customerData?.is_producer && (
        <>
          <Box width={1} mt={4}>
            <Flex justifyContent="space-between" alignItems="center">
              <Text variant={TEXT_VARIANTS.H4}>{t('Composers')}</Text>
              <Button
                variant={BTN_VARIANTS.SECONDARY}
                label={t('Add composer')}
                onClick={() => composerAppend({ isNew: true })}
              />
            </Flex>
            <Separator />
          </Box>

          <div>
            {composerFields?.map((item, index) => (
              <AuthorRow
                {...{
                  key: item.key,
                  item,
                  dataKey: `briefing_tracks_composers[${index}]`,
                  index,
                  commonTextFieldProps,
                  commonSelectProps,
                  onRemove: onRemoveAuthor,
                  type: 'composers',
                  register,
                  setValue,
                  validateIpi,
                  validateUniqueIpi,
                  validateShares,
                  disableRemove: composerFields.length === 1,
                }}
              />
            ))}
          </div>

          <Box width={1} mt={4}>
            <Flex justifyContent="space-between" alignItems="center">
              <Text variant={TEXT_VARIANTS.H4}>{t('Lyricists')}</Text>
              <Button
                variant={BTN_VARIANTS.SECONDARY}
                label={t('Add lyricist')}
                onClick={onAppendLyricist}
              />
            </Flex>
            <Separator />
          </Box>
          <div>
            {lyricistFields.length > 0 ? (
              lyricistFields.map((item, index) => (
                <AuthorRow
                  {...{
                    key: item.key,
                    item,
                    dataKey: `briefing_tracks_lyricists[${index}]`,
                    index,
                    commonTextFieldProps,
                    commonSelectProps,
                    onRemove: onRemoveAuthor,
                    type: 'lyricists',
                    register,
                    setValue,
                    watch,
                    validateIpi,
                    validateUniqueIpi,
                    validateShares,
                    disableRemove: false,
                  }}
                />
              ))
            ) : (
              <Box textAlign="center">
                <Text variant={TEXT_VARIANTS.SMALL_BODY}>
                  {t('No lyricists')}
                </Text>
              </Box>
            )}
          </div>
        </>
      )}

      <SectionTitle mt={4}>{t('Meta Data')}</SectionTitle>

      <MetaGrid>
        <GridItem reorder>
          <Select
            {...{
              name: 'genres',
              label: t('Genres'),
              placeholder: t('Select genres'),
              options: genres.data.slice(1),
              ...commonSelectProps(2),
            }}
          />
        </GridItem>
        <GridItem>
          <DescriptionTextarea
            {...{
              name: `description_en`,
              label: t('Description (English)'),
              placeholder: t('Description (English)'),
              ...commonTextFieldProps,
              required: isUpdate,
              as: 'textarea',
              rows: 1,
            }}
          />
        </GridItem>

        <GridItem reorder>
          <Select
            {...{
              name: 'moods',
              label: t('Moods'),
              placeholder: t('Select moods'),
              options: moods.data.slice(1),
              ...commonSelectProps(3),
            }}
          />
        </GridItem>
        <GridItem>
          <DescriptionTextarea
            {...{
              name: `description_de`,
              label: t('Description (German)'),
              placeholder: t('Description (German)'),
              ...commonTextFieldProps,
              as: 'textarea',
              rows: 1,
            }}
          />
        </GridItem>
        <GridItem reorder>
          <Select
            {...{
              name: 'topics',
              label: t('Topics'),
              placeholder: t('Select topics'),
              options: topics.data.slice(1),
              ...commonSelectProps(3),
            }}
          />
        </GridItem>
        <GridItem>
          <DescriptionTextarea
            {...{
              name: `description_it`,
              label: t('Description (Italian)'),
              placeholder: t('Description (Italian)'),
              ...commonTextFieldProps,
              as: 'textarea',
              rows: 1,
            }}
          />
        </GridItem>
        <GridItem reorder>
          <Select
            {...{
              name: 'instrumentations',
              label: t('Instrumentations'),
              placeholder: t('Select instrumentations'),
              options: instrumentations.data,
              ...commonSelectProps(15),
            }}
          />
        </GridItem>
        <GridItem>
          <DescriptionTextarea
            {...{
              name: `description_es`,
              label: t('Description (Spanish)'),
              placeholder: t('Description (Spanish)'),
              ...commonTextFieldProps,
              as: 'textarea',
              rows: 1,
            }}
          />
        </GridItem>
        <GridItem reorder>
          <TextField
            {...{
              name: `bpm`,
              label: t('BPM'),
              placeholder: t('BPM'),
              type: 'number',
              ...commonTextFieldProps,
              required: isUpdate,
              rules: {
                required: {
                  value: isUpdate,
                  message: t('Required'),
                },
                max: {
                  value: 999,
                  message: t('Max 999'),
                },
                min: {
                  value: 1,
                  message: t('Min 1'),
                },
              },
            }}
          />
        </GridItem>
        <GridItem>
          <DescriptionTextarea
            {...{
              name: `description_pt`,
              label: t('Description (Portugal)'),
              placeholder: t('Description (Portugal)'),
              ...commonTextFieldProps,
              as: 'textarea',
              rows: 1,
            }}
          />
        </GridItem>
      </MetaGrid>

      <Box width={1}>
        <TextField
          {...{
            name: `lyrics_original`,
            label: t('Lyrics original'),
            placeholder: t('Lyrics original'),
            as: 'textarea',
            ...commonTextFieldProps,
          }}
        />
      </Box>

      <SectionTitle mt={4}>{t('Full Track')}</SectionTitle>

      <FullTrackRow
        {...{
          trackFile: fullTrackFile,
          register,
          errors,
          addFullTrackFile,
          action,
          onUpload: uploadFullTrack,
          fullTrackProgress,
          track: data.track,
          briefing,
        }}
      />

      {isUpdate && (
        <>
          <SectionTitle mt={4}>{t('Track versions')}</SectionTitle>

          <Box mb={3}>
            {altFields.map((item, index) => (
              <AlternativeTrackRow
                {...{
                  key: item.key,
                  item,
                  dataKey: `alternative_tracks[${index}]`,
                  index,
                  control,
                  errors,
                  watch,
                  commonTextFieldProps,
                  register,
                  remove: altRemove,
                  setValue,
                  reset,
                  getValues,
                  trigger,
                  isDragStarted,
                  briefing,
                }}
              />
            ))}
          </Box>

          <ProgressBar
            progress={alternativeTracksProgress.value}
            prefix={`${t('Uploading')} ${alternativeTracksProgress.files} ${
              alternativeTracksProgress.files > 1 ? t('files') : t('file')
            } `}
            my={4}
          />

          <Dropzone
            {...{
              accept: '.wav',
              onDrop: files => appendFiles(files),
              text: t('Drag and drop wav files here, or click to select files'),
              multiple: true,
              error: errors.file_control,
            }}
          />
        </>
      )}

      <Box width={1} mt={3} textAlign="center">
        <FormButton
          type="submit"
          variant={BTN_VARIANTS.GREEN}
          label={t('Submit data')}
          isLoading={isLoading}
        />
      </Box>
      <Box width={1} mt={3} textAlign="center">
        <Link to={routes.briefings}>
          <StyledText variant={TEXT_VARIANTS.H4} color={COLORS.BLUE}>
            {t('Cancel')}
          </StyledText>
        </Link>
      </Box>
    </form>
  );
};

BriefingForm.propTypes = {
  param: PropTypes.string.isRequired,
  action: PropTypes.oneOf(Object.values(FORM_TYPE)).isRequired,
  briefingsStore: PropTypes.shape({
    briefingTrack: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.object,
      errors: PropTypes.object,
      briefing: PropTypes.object,
    }),
    getBriefingTrack: PropTypes.func,
    submitBriefingTrack: PropTypes.func,
    submitBriefingTrackUpdate: PropTypes.func,
    validateTrackName: PropTypes.func,
    validateIpi: PropTypes.func,
    addFullTrackFile: PropTypes.func,
    fullTrackFile: PropTypes.object,
    removeComposer: PropTypes.func,
    removeLyricist: PropTypes.func,
    clearForm: PropTypes.func,
    uploadFullTrack: PropTypes.func,
    alternativeTrackFiles: PropTypes.array,
    uploadAlternativeTrack: PropTypes.func,
    fullTrackProgress: PropTypes.number,
    alternativeTracksProgress: PropTypes.shape({
      files: PropTypes.number,
      value: PropTypes.number,
    }),
    loadings: PropTypes.object,
  }).isRequired,
  genresStore: PropTypes.shape({
    genres: PropTypes.object,
    getGenres: PropTypes.func,
  }).isRequired,
  moodsStore: PropTypes.shape({
    moods: PropTypes.object,
    getMoods: PropTypes.func,
  }).isRequired,
  topicsStore: PropTypes.shape({
    topics: PropTypes.object,
    getTopics: PropTypes.func,
  }).isRequired,
  instrumentationsStore: PropTypes.shape({
    instrumentations: PropTypes.object,
    getInstrumentations: PropTypes.func,
  }).isRequired,
  customerStore: PropTypes.shape({
    customer: PropTypes.shape({
      data: PropTypes.object,
    }),
  }).isRequired,
};

export default inject(
  'briefingsStore',
  'genresStore',
  'moodsStore',
  'topicsStore',
  'instrumentationsStore',
  'customerStore',
)(observer(BriefingForm));
