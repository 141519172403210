import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Flex } from 'rebass';

import * as Styled from '@components/SideBar/style';
import SidebarCategories from '@components/SideBar/components/SidebarCategories';
import useBreakpoint from '@hooks/useBreakpoint';

import CloseNavIconDesktop from '@assets/images/icons/nav-close.svg';
import OpenNavIconMobile from '@assets/images/icons/menu-icon.svg';
import CloseNavIconMobile from '@assets/images/icons/close-icon.svg';
import SearchIcon from '@assets/images/icons/search-icon.svg';
import AccountDropdownBody from '@components/AccountDropdownBody';
import Tooltip, { TRIGGER } from '@components/Tooltip';

import {
  LOGO_FULL,
  LOGO_MOBILE,
  SIDEBAR_CLOSED,
} from '@utils/i18n/locationsContent';

import routes from '@routes';
import ProfileIcon from '@components/ProfileIcon';

const SideBar = ({
  sidebarStore: {
    isMobileOpen,
    isSideBarOpened,
    toggleSideBar,
    toggleMobileNavigation,
  },
  searchStore: { setMobileSearchVisibility, mobileSearchIsVisible },
  customerStore: { customer },
  authStore: { logout, isAuthenticated, isValidated },
}) => {
  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();

  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  let logoUrl;
  if (isMobile) {
    logoUrl = LOGO_MOBILE;
  } else if (isSideBarOpened) {
    logoUrl = LOGO_FULL;
  } else {
    logoUrl = SIDEBAR_CLOSED;
  }

  return (
    <Styled.Wrapper collapsed={isSideBarOpened ? 0 : 1}>
      {isMobile ? (
        <Flex
          width={1}
          justifyContent="space-between"
          sx={{ position: 'absolute' }}
        >
          <Styled.MobileButton
            active={isMobileOpen}
            onClick={toggleMobileNavigation}
          >
            <img
              src={isMobileOpen ? CloseNavIconMobile : OpenNavIconMobile}
              alt=""
            />
          </Styled.MobileButton>
          <Flex justifyContent="space-between">
            {(isValidated || isAuthenticated) && (
              <Tooltip
                tooltipBody={
                  <AccountDropdownBody
                    isComposer={customer.data?.is_composer || false}
                    isProducer={customer.data?.is_producer || false}
                    logout={logout}
                    isAuthenticated={isAuthenticated}
                  />
                }
                trigger={TRIGGER.CLICK}
              >
                <Styled.MobileProfileButton
                  isAuthenticated={isAuthenticated}
                  onClick={() => setMobileSearchVisibility(false)}
                >
                  <ProfileIcon />
                </Styled.MobileProfileButton>
              </Tooltip>
            )}
            <Styled.MobileButton
              active={mobileSearchIsVisible}
              onClick={() => {
                setMobileSearchVisibility(!mobileSearchIsVisible);
              }}
            >
              <img
                src={mobileSearchIsVisible ? CloseNavIconMobile : SearchIcon}
                alt={
                  mobileSearchIsVisible
                    ? 'X for closing search layer'
                    : 'magnifier for opening search layer'
                }
              />
            </Styled.MobileButton>
          </Flex>
        </Flex>
      ) : null}

      <Styled.Sidebar isMobileOpen={isMobileOpen}>
        {!isMobile ? (
          <Styled.ToggleButton
            onClick={toggleSideBar}
            collapsed={isSideBarOpened ? 0 : 1}
          >
            <img src={CloseNavIconDesktop} alt="" />
          </Styled.ToggleButton>
        ) : null}

        <Styled.LogoWrapper to={routes.base} isSideBarOpened={isSideBarOpened}>
          <img src={logoUrl} alt="Intervox" />
        </Styled.LogoWrapper>
        {isMobile ? (
          <Styled.MobileContainer>
            <SidebarCategories isMobileOpen={isMobileOpen} />
          </Styled.MobileContainer>
        ) : (
          <Styled.DesktopScrollContainer collapsed={isSideBarOpened ? 0 : 1}>
            <SidebarCategories isMobileOpen={isMobileOpen} />
          </Styled.DesktopScrollContainer>
        )}
      </Styled.Sidebar>
    </Styled.Wrapper>
  );
};

SideBar.propTypes = {
  sidebarStore: PropTypes.shape({
    isMobileOpen: PropTypes.bool,
    isSideBarOpened: PropTypes.bool,
    toggleSideBar: PropTypes.func,
    toggleMobileNavigation: PropTypes.func,
  }).isRequired,
  searchStore: PropTypes.shape({
    setMobileSearchVisibility: PropTypes.func,
    mobileSearchIsVisible: PropTypes.bool,
  }).isRequired,
  customerStore: PropTypes.object.isRequired,
  authStore: PropTypes.object.isRequired,
};

export default inject(
  'sidebarStore',
  'searchStore',
  'customerStore',
  'authStore',
)(observer(SideBar));
