import React, { useEffect } from 'react';
import { uniqueId } from 'lodash-es';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Header from '@components/Header';
import { MainContent } from '@styles/globalStyles';
import Tile from '@components/Tile';
import GridList from '@components/GridList';
import Preloader from '@components/Preloader';
import CustomerTabs from '@components/CustomerTabs';
import ItemsNotFound from '@components/ItemsNotFound';

const MyAlbums = ({ favoritesStore: { getFavorites, favoritesList } }) => {
  const { t } = useTranslation();

  useEffect(() => {
    getFavorites();
  }, [getFavorites]);

  return (
    <MainContent>
      <Helmet>
        <title>{t('My albums')}</title>
      </Helmet>
      <Header title={t('My albums')} nav={<CustomerTabs />} />

      {favoritesList.isLoading ? (
        <Preloader center />
      ) : (
        favoritesList.data.length === 0 && <ItemsNotFound name={t('Albums')} />
      )}
      <GridList>
        {favoritesList.data.map(album => (
          <li key={uniqueId()}>
            <Tile {...album} isAlbum />
          </li>
        ))}
      </GridList>
    </MainContent>
  );
};

MyAlbums.propTypes = {
  favoritesStore: PropTypes.shape({
    getFavorites: PropTypes.func,
    favoritesList: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          cover: PropTypes.object,
          uuid: PropTypes.string,
        }),
      ),
      isLoading: PropTypes.bool,
    }),
  }).isRequired,
};

export default inject('favoritesStore')(observer(MyAlbums));
