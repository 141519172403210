import React from 'react';
import { PropTypes } from 'prop-types';

import { StyledButton } from '@components/IconButtons/style';
import DELETE_ICON from '@assets/images/icons/delete-icon.svg';

const DeleteButton = ({ callback, ...props }) => (
  <StyledButton onClick={callback} {...props} type="button">
    <img src={DELETE_ICON} alt="download-icon" />
  </StyledButton>
);

DeleteButton.propTypes = {
  callback: PropTypes.func.isRequired,
};

export default DeleteButton;
