import styled from '@emotion/styled';

export const StyledButton = styled.button`
  position: relative;
  img {
    display: inline-block;
    margin: 0 5px;
    min-width: 29px;
  }
`;
