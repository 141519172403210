import { makeAutoObservable, runInAction } from 'mobx';
import { API, APIRoutes } from '@app/api';
import { toast } from 'react-toastify';

import { history } from '@app/history';
import routes from '@routes';
import i18n from '@utils/i18n';

import { LICENSE_APPLICATION_STEPS } from '@containers/LicenseApplication/components/LicenseForm';

const DEFAULT_LICENSE_FORM = {
  formErrors: {},
  isLoading: false,
  stepsData: [],
  currentStep: 0,
};

const DEFAULT_SINGLE_LICENSE = {
  isLoading: false,
  data: {},
  error: null,
};
export class LicensesStore {
  constructor() {
    makeAutoObservable(this);
  }

  licenses = {
    isLoading: false,
    error: false,
    data: [],
  };

  singleLicense = {
    ...DEFAULT_SINGLE_LICENSE,
  };

  licenseForm = { ...DEFAULT_LICENSE_FORM };

  getLicenses = async () => {
    this.licenses.isLoading = true;
    try {
      const {
        data: { licenses },
      } = await API(APIRoutes.LICENSES);
      runInAction(() => {
        this.licenses.data = licenses;
      });
    } catch (error) {
      runInAction(() => {
        this.licenses.error = error;
      });
    } finally {
      runInAction(() => {
        this.licenses.isLoading = false;
      });
    }
  };

  getSingleLicense = async uuid => {
    this.singleLicense.isLoading = true;
    try {
      const {
        data: { license },
      } = await API(APIRoutes.SINGLE_LICENSE(uuid));
      runInAction(() => {
        this.singleLicense.data = license;
      });
    } catch (error) {
      runInAction(() => {
        this.singleLicense.error = error;
      });
    } finally {
      runInAction(() => {
        this.singleLicense.isLoading = false;
      });
    }
  };

  clearForm = () => {
    this.licenseForm = { ...DEFAULT_LICENSE_FORM };
    this.singleLicense = { ...DEFAULT_SINGLE_LICENSE };
  };

  formatData = (formData, step) => {
    switch (step) {
      case 1: {
        const {
          production_type: { value: production_type_value },
          production_type_details,
          online_territory,
          online_territory_details,
          offline_territory,
          offline_territory_details,
          usage_type,
          usage_type_details,
          ...rest
        } = formData;

        return {
          ...rest,
          production_type:
            production_type_value === 'Other'
              ? production_type_details
              : production_type_value,
          online_territory:
            online_territory === 'other'
              ? online_territory_details
              : online_territory,
          offline_territory:
            offline_territory === 'other'
              ? offline_territory_details
              : offline_territory,
          usage_type: usage_type.includes('other')
            ? usage_type.map(value =>
                value === 'other' ? usage_type_details : value,
              )
            : usage_type,
        };
      }
      case 2: {
        const { license_application_tracks_attributes } = formData;

        return {
          ...this.formatData(this.licenseForm.stepsData[0], 0),
          ...this.formatData(this.licenseForm.stepsData[1], 1),
          license_application_tracks_attributes: license_application_tracks_attributes.map(
            ({ track_id, ...rest }) => ({
              track_id: track_id.value,
              ...rest,
            }),
          ),
        };
      }

      default:
        return formData;
    }
  };

  submitLicenseApplication = async (formData, step) => {
    try {
      this.licenseForm.isLoading = true;

      const { uuid } = this.singleLicense.data;

      const formattedData = this.formatData(formData, step);

      const payload = {
        license_application: {
          ...formattedData,
          step: step + 1,
        },
      };

      await API.post(APIRoutes.LICENSE_APPLICATION(uuid), payload);

      const { currentStep } = this.licenseForm;

      runInAction(() => {
        this.licenseForm.isLoading = false;
        this.licenseForm.formErrors = {};

        if (currentStep < LICENSE_APPLICATION_STEPS.length - 1) {
          this.licenseForm.stepsData[currentStep] = formData;
          this.licenseForm.currentStep = currentStep + 1;
        } else {
          toast(i18n.t('License has been sent successfully'));
          history.push(routes.license);
        }
      });
    } catch (e) {
      const formErrors = { ...e.errors };
      Object.keys(formErrors).forEach(key => {
        formErrors[key] = { message: formErrors[key] };
      });

      runInAction(() => {
        this.licenseForm.formErrors = formErrors;
      });
    } finally {
      runInAction(() => {
        this.licenseForm.isLoading = false;
      });
    }
  };

  setPreviousStep = (data, step) => {
    if (this.licenseForm.currentStep > 0) {
      this.licenseForm.currentStep -= 1;
      this.licenseForm.stepsData[step] = data;
    }
  };
}

export default new LicensesStore();
