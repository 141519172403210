import styled from '@emotion/styled';
import FormButton from '@components/Form/FormButton';
import Text from '@components/Text';

export const Section = styled.section`
  padding: 16px 0 32px;
`;

export const StyledText = styled(Text)`
  text-decoration: underline;
`;
export const StyledFormButton = styled(FormButton)`
  min-width: 240px;
`;

export const DurationIndicator = styled.span`
  display: inline-block;
  padding: 0 10px;

  color: #aaaaaa;
`;

export const OptionDetails = styled.span`
  font-size: 0.75em;
  color: #aaaaaa;
`;

export const OptionWrapper = styled.div`
  ${({ asValue }) =>
    asValue &&
    `
  padding-right: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  `}
`;
