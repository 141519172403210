import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import { useTranslation } from 'react-i18next';
import Select from '@components/Form/Select';
import TextField, { MARGINS } from '@components/Form/TextField';
import SectionTitle from '@components/SectionTitle';
import PasswordField from '@components/Form/PasswordField';

const AccountStep = ({ register, control, errors, countries }) => {
  const { t } = useTranslation();

  return (
    <>
      <SectionTitle>{t('Account')}</SectionTitle>
      <Box width={1}>
        <TextField
          name="first_name"
          label={t('First name')}
          placeholder={t('First name')}
          register={register}
          required
          errors={errors}
        />
        <TextField
          name="last_name"
          label={t('Last name')}
          placeholder={t('Last name')}
          register={register}
          required
          errors={errors}
        />
        <TextField
          name="website"
          label={t('Website')}
          placeholder={t('Website')}
          register={register}
          errors={errors}
        />
        <TextField
          name="phone_no"
          label={t('Phone number')}
          placeholder={t('Phone number')}
          type="text"
          register={register}
          errors={errors}
        />
        <TextField
          name="email"
          label={t('E-mail')}
          placeholder={t('E-mail')}
          register={register}
          required
          errors={errors}
        />
        <PasswordField
          name="password"
          placeholder={t('Password')}
          label={t('Password')}
          register={register}
          required
          errors={errors}
        />
        <PasswordField
          name="password_confirmation"
          placeholder={t('Password confirmation')}
          label={t('Password confirmation')}
          autoComplete="new-password"
          register={register}
          required
          errors={errors}
          mb={MARGINS.BIG}
        />
      </Box>
      <SectionTitle>{t('Address')}</SectionTitle>
      <Box width={1}>
        <TextField
          name="street"
          label={t('Street and house number')}
          placeholder={t('Street and house number')}
          register={register}
          required
          errors={errors}
        />
        <TextField
          name="city"
          label={t('City')}
          placeholder={t('City')}
          register={register}
          required
          errors={errors}
        />
        <TextField
          name="zip_code"
          label={t('Zip Code')}
          placeholder={t('Zip Code')}
          register={register}
          required
          errors={errors}
        />
        <Select
          control={control}
          isSearchable
          name="country"
          label={t('Country')}
          placeholder={t('Select country')}
          options={countries}
          errors={errors}
          required
        />
      </Box>
    </>
  );
};

AccountStep.defaultProps = {
  countries: [],
  errors: {},
};

AccountStep.propTypes = {
  register: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  countries: PropTypes.array,
  errors: PropTypes.object,
};

export default AccountStep;
