import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Flex, Box } from 'rebass';
import { Helmet } from 'react-helmet';
import PasswordForgottenForm from '@containers/PasswordForgotten/components/PasswordForgottenForm';
import Header from '@components/Header';
import { MainContent } from '@styles/globalStyles';
import Card, { VARIANTS as CARD_VARIANTS } from '@components/Card';
import Text, { VARIANTS as TEXT_VARIANTS } from '@components/Text';
import Notification from '@components/Form/Notification';

const PasswordForgotten = ({
  passwordStore: {
    remindPassword,
    passwordErrors,
    isReminderSend,
    isLoading,
    clearErrors,
  },
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    return () => clearErrors();
  }, []);

  return (
    <MainContent>
      <Helmet>
        <title>{t('Password reset')}</title>
      </Helmet>

      <Header title={t('Password reset')} withSeparator />

      <Flex flexWrap="wrap" mx={-3} mt={3} mb={[0, 0, 0, 6]}>
        <Box width={[1, 1, 1, 1 / 2]} px={3} mb={[5, 5, 5, 0]}>
          <Card variant={CARD_VARIANTS.TRANSPARENT}>
            {isReminderSend && (
              <Box mb={4}>
                <Notification
                  type="SUCCESS"
                  message={t('Password reminder sent! Check your inbox.')}
                />
              </Box>
            )}
            <Box mb={3}>
              <Text
                variant={TEXT_VARIANTS.H4}
                style={{ textTransform: 'initial' }}
              >
                {t('Obtain a new password')}
              </Text>
            </Box>

            <Text variant={TEXT_VARIANTS.BODY}>
              {t(
                'Please enter the email address you have registered. Your password will be sent to your email address shortly.',
              )}
            </Text>
            <PasswordForgottenForm
              onSubmit={remindPassword}
              passwordErrors={passwordErrors}
              isSuccess={isReminderSend}
              isLoading={isLoading}
            />
          </Card>
        </Box>
      </Flex>
    </MainContent>
  );
};

PasswordForgotten.propTypes = {
  passwordStore: PropTypes.object.isRequired,
};

export default inject('passwordStore')(observer(PasswordForgotten));
