import React from 'react';
import routes from '@routes';
import Text, { VARIANTS as TEXT_VARIANTS } from '@components/Text';
import { Extra4You } from '@components/Button/style';

const Extra4YouButton = () => (
  <Extra4You to={routes.extra4you}>
    <Text variant={TEXT_VARIANTS.H4_REGULAR} size={13}>
      Extra 4 you
    </Text>
  </Extra4You>
);

export default Extra4YouButton;
