import { makeAutoObservable, runInAction } from 'mobx';
import { API, APIRoutes } from '@app/api';
import i18n from '@utils/i18n';
import { TYPES } from '@components/Form/Notification/style';

const DEFAULT_FORM_DATA = {
  isLoading: false,
  errors: {},
  formNotification: null,
};

const DEFAULT_PAGE_DATA = {
  isLoading: false,
  data: {},
  error: null,
};

export const PROJECTS = [
  'TV Show',
  'Documentary',
  'Movie',
  'Corporate Film',
  'Advertising',
  'Radio Show',
  'Online-Video',
  'Other',
];

export const PROJECTS_CZ = [
  'celovečerní film',
  'dokument',
  'televizní seriál',
  'reklamní spot',
  'korporátní video',
  'online video',
  'audio kniha',
  'jiné',
];

export class ContactStore {
  constructor() {
    makeAutoObservable(this);
  }

  /* Write for us */
  writeForUsForm = {
    url: APIRoutes.WRITE_FOR_US,
    ...DEFAULT_FORM_DATA,
  };

  writeForUsPage = {
    url: APIRoutes.WRITE_FOR_US_PAGE,
    ...DEFAULT_PAGE_DATA,
  };

  /* Contact us */
  contactUsForm = {
    url: APIRoutes.CONTACT_US,
    ...DEFAULT_FORM_DATA,
  };

  contactUsPage = {
    url: APIRoutes.CONTACT_US_PAGE,
    ...DEFAULT_PAGE_DATA,
  };

  /* Request music */
  requestMusicForm = {
    url: APIRoutes.REQUEST_MUSIC,
    ...DEFAULT_FORM_DATA,
  };

  requestMusicPage = {
    url: APIRoutes.REQUEST_MUSIC_PAGE,
    ...DEFAULT_PAGE_DATA,
  };

  getPageContent = async storePage => {
    try {
      storePage.isLoading = true;

      const {
        data: { page },
      } = await API(storePage.url);
      runInAction(() => {
        storePage.data = page;
      });
    } catch (error) {
      runInAction(() => {
        storePage.error = error;
      });
    } finally {
      runInAction(() => {
        storePage.isLoading = false;
      });
    }
  };

  handleSubmit = async (formData, storeForm) => {
    try {
      storeForm.isLoading = true;

      await API.post(storeForm.url, formData);

      runInAction(() => {
        storeForm.formNotification = {
          type: TYPES.SUCCESS,
          message: i18n.t('Message has been sent.'),
        };
      });
    } catch (e) {
      const submitErrors = { ...e.errors };
      Object.keys(submitErrors).forEach(key => {
        submitErrors[key] = { message: submitErrors[key] };
      });

      runInAction(() => {
        storeForm.errors = submitErrors;
      });
    } finally {
      runInAction(() => {
        storeForm.isLoading = false;
      });
    }
  };

  clearForms = () => {
    this.writeForUsForm = {
      ...this.writeForUsForm,
      ...DEFAULT_FORM_DATA,
    };

    this.contactUsForm = {
      ...this.contactUsForm,
      ...DEFAULT_FORM_DATA,
    };

    this.requestMusicForm = {
      ...this.requestMusicForm,
      ...DEFAULT_FORM_DATA,
    };
  };

  onWriteForUsSubmit = formData => {
    const payload = { customer: formData };
    this.handleSubmit(payload, this.writeForUsForm);
  };

  getWriteForUsContent = () => {
    if (Object.keys(this.writeForUsPage.data).length === 0) {
      this.getPageContent(this.writeForUsPage);
    }
  };

  onContactUsSubmit = formData => {
    const payload = { customer: formData };
    this.handleSubmit(payload, this.contactUsForm);
  };

  getContactUsContent = () => {
    if (Object.keys(this.contactUsPage.data).length === 0) {
      this.getPageContent(this.contactUsPage);
    }
  };

  onRequestMusicSubmit = formData => {
    const payload = {
      music_consultation: {
        ...formData,
        language: i18n.language.toLowerCase(),
      },
    };

    this.handleSubmit(payload, this.requestMusicForm);
  };

  getRequestMusicContent = () => {
    if (Object.keys(this.requestMusicPage.data).length === 0) {
      this.getPageContent(this.requestMusicPage);
    }
  };
}

export default new ContactStore();
