import styled from '@emotion/styled';
import { Header } from '@styles/globalStyles';
import TextField from '@components/Form/TextField';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled.div`
  margin: 32px 0;
  padding: ${({ theme }) => theme.page.mainContentPadding};

  @media ${breakpoint.md} {
    padding: ${({ theme }) => theme.page.mainContentPaddingMobile};
  }
`;

export const StyledHeader = styled(Header)`
  margin-bottom: 16px;
`;

export const SubmitButton = styled.button`
  height: calc(100% - 6px);
  background: ${({ theme }) => theme.colors.quaternary};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  padding: 0 16px;
  margin-right: 3px;
`;

export const StyledInput = styled(TextField)`
  input {
    ${({ isMobile }) => !isMobile && 'padding-right: 100px'};
    border: 1px ${({ theme }) => theme.colors.highlight} solid;
  }
`;
