import { makeAutoObservable, runInAction } from 'mobx';
import { API, APIRoutes } from '@app/api';
import routes from '@routes';

export class PasswordStore {
  constructor() {
    makeAutoObservable(this);
  }

  isLoading = false;

  isReminderSend = false;

  passwordErrors = {};

  remindPassword = async ({ email }) => {
    try {
      this.isLoading = true;
      this.isReminderSend = false;

      await API.post(APIRoutes.PASSWORD, {
        email,
        redirect_url: `${window.location.origin}${routes.password.reset}`,
      });

      runInAction(() => {
        this.isReminderSend = true;
        this.passwordErrors = {};
      });
    } catch (e) {
      const passwordErrors = { ...e.errors };
      Object.keys(passwordErrors).forEach(key => {
        passwordErrors[key] = { message: passwordErrors[key] };
      });

      runInAction(() => {
        this.passwordErrors = passwordErrors;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  resetPassword = async ({ password, password_confirmation }) => {
    try {
      this.isLoading = true;
      const url_string = window.location.href;
      const url = new URL(url_string);

      const payload = {
        password,
        password_confirmation,
      };

      url.searchParams.forEach((value, key) => {
        payload[key] = value;
      });

      await API.put(APIRoutes.PASSWORD, payload);

      window.location.href = `${window.location.origin}${routes.auth.signIn}?password_change_success=true`;
    } catch (e) {
      const passwordErrors = { ...e.errors };
      Object.keys(passwordErrors).forEach(key => {
        passwordErrors[key] = { message: passwordErrors[key] };
      });

      runInAction(() => {
        this.passwordErrors = passwordErrors;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  clearErrors = () => {
    this.passwordErrors = {};
  };
}

export default new PasswordStore();
