import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import {
  Progress,
  Close,
  Bars,
  Wrapper,
  TooltipBody,
  Container,
} from '@containers/MainPlayer/components/ReducedPlayer/style';
import Tooltip, { TRIGGER, PLACEMENT } from '@components/Tooltip';
import CloseIcon from '@assets/images/icons/close-icon-white.svg';

const ReducedPlayer = ({
  playerStore: { isPlaying, togglePlayer, trackPlayedPart, activeTrack },
}) => {
  const radius = 35;
  const diameter = Math.PI * 2 * radius;
  const progressStyle = {
    stroke: '#04c4d9',
    strokeDasharray: `${diameter}px ${diameter}px`,
    strokeDashoffset: `${diameter * (trackPlayedPart - 1)}px`,
  };

  const bars = [];

  for (let i = 0; i < 10; i += 1) {
    bars.push(<div key={`bar-${i}`} className="bar" />);
  }

  return (
    <Wrapper>
      <Tooltip
        trigger={activeTrack ? TRIGGER.HOVER : TRIGGER.NONE}
        placement={PLACEMENT.LEFT}
        tooltipBody={
          <TooltipBody>
            <strong>{activeTrack?.name}</strong>
            <br />
            {activeTrack?.directory_with_number}
          </TooltipBody>
        }
      >
        <Container>
          <Progress>
            <svg
              viewBox="0 0 70 70"
              overflow="visible"
              style={{ overflow: 'visible' }}
            >
              <circle cx="50%" cy="50%" r="50%" />
              <circle cx="50%" cy="50%" r="50%" style={progressStyle} />
            </svg>
          </Progress>
          <Bars isPlaying={isPlaying}>{bars}</Bars>
          <Close type="button" onClick={togglePlayer}>
            <img src={CloseIcon} alt="" />
          </Close>
        </Container>
      </Tooltip>
    </Wrapper>
  );
};

ReducedPlayer.propTypes = {
  playerStore: PropTypes.shape({
    togglePlayer: PropTypes.func,
    isPlaying: PropTypes.bool,
    trackPlayedPart: PropTypes.number,
    activeTrack: PropTypes.object,
  }).isRequired,
};

export default inject('playerStore')(observer(ReducedPlayer));
