import { clamp } from 'lodash-es';

export default event => {
  const { top, left, width, height } = event.target.getBoundingClientRect();
  const { clientX, clientY } = event;
  const px = {
    x: clientX - left,
    y: clientY - top,
  };
  const percent = {
    x: clamp(px.x / width, 0, 1),
    y: clamp(px.y / height, 0, 1),
  };
  return { px, percent };
};
