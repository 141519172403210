import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from '@components/CrewItem/style';
import Avatar from '@components/Avatar';

const CrewItem = ({ firstName, lastName, image, altImage, role }) => (
  <Avatar image={image.url} altImage={altImage?.url}>
    <Styled.Name>{`${firstName} ${lastName}`}</Styled.Name>
    {role && <Styled.Role>{role}</Styled.Role>}
  </Avatar>
);

CrewItem.defaultProps = {
  role: undefined,
  altImage: undefined,
};

CrewItem.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  altImage: PropTypes.object,
  role: PropTypes.string,
};

export default CrewItem;
