import styled from '@emotion/styled';
import { Flex } from 'rebass';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled.div`
  padding: 40px;
  min-height: 300px;
  display: flex;
  flex-direction: column;

  @media ${breakpoint.md} {
    min-height: unset;
    padding: 0;
  }
`;

export const SectionTitle = styled.h3`
  font-size: 13px;
  font-family: ${({ theme }) => theme.fonts.secondary.regular};
  margin-bottom: 20px;
`;

export const FiltersFormWrapper = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 6px;
  position: relative;
`;

export const ButtonsRow = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  padding-right: 7px;
`;

export const FilterRow = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
`;

export const FilterType = styled.div`
  width: 40%;
`;

export const FilterInput = styled.div`
  width: 60%;
  padding-left: 15px;
`;

export const Indicator = styled.img`
  transition: transform 0.3s ease;
  transform: rotate(${({ menuIsOpen }) => (menuIsOpen ? 180 : 0)}deg);
`;

export const RemoveWrapper = styled.div`
  width: 25px;
`;

export const RemoveFilterButton = styled.button`
  width: 10px;
  height: 10px;
  color: ${({ theme }) => theme.colors.textLight};
  font-size: 20px;
  margin: -3% 10px 0 5px;
  font-weight: 300;

  > img {
    transform: rotate(45deg);
  }
`;
