import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';
import { NavLink } from 'react-router-dom';

export const Wrapper = styled.div`
  display: flex;
  @media ${breakpoint.md} {
    flex-direction: column;
  }
`;

export const AlbumInfo = styled.div`
  margin-right: 25px;
  max-width: 200px;
  @media ${breakpoint.md} {
    max-width: initial;
    text-align: center;
    margin-right: 0;
  }
`;

export const Title = styled(NavLink)`
  font-family: ${({ theme }) => theme.fonts.secondary.regular};
  color: ${({ theme }) => theme.colors.primaryVariant};
  font-size: 13px;
  line-height: 17px;
  display: block;
  margin-bottom: 10px;

  @media ${breakpoint.md} {
    margin-bottom: 5px;
  }
`;

export const AlbumComposerTitle = styled.p`
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 12px;
  line-height: 16px;
`;

export const ImageWrapper = styled(NavLink)`
  position: relative;
  padding: 5px;
  box-shadow: 0 0 12px 0 rgba(13, 13, 13, 0.15);
  border: 1px solid rgb(208, 208, 207);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-bottom: 23px;
  background-color: rgba(255, 255, 255, 1);

  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0 0 5px 0 rgba(13, 13, 13, 0.15),
      0 0 12px 1px rgba(13, 13, 13, 0.25);
  }

  @media ${breakpoint.md} {
    margin-left: auto;
    margin-right: auto;
    max-width: 200px;
    margin-bottom: 9px;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  @media ${breakpoint.md} {
    max-width: 200px;
  }
`;

export const Scrollbar = styled.div`
  display: block !important;
  visibility: ${({ visibility }) => (visibility ? 'visible' : 'hidden')};
  background: ${props => props.theme.colors.quaternary};
  margin-left: 25px;
  cursor: pointer;
  width: 1px;
  max-height: 340px;
  @media ${breakpoint.md} {
    display: none !important;
  }

  .swiper-scrollbar-drag {
    &::after {
      position: absolute;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      background-color: ${props => props.theme.colors.primary};
      content: '';
      right: 50%;
      transform: translate(50%, -50%);
    }
  }
`;

export const AlbumInfoButtons = styled.div`
  display: flex;
  margin-top: 15px;
  @media ${breakpoint.md} {
    margin-top: 6px;
    margin-bottom: 32px;
    justify-content: center;
  }
`;

export const TooltipText = styled.div`
  padding: 12px;
  background: transparent;
`;
