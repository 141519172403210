import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from '@components/CrewItem/style';
import Avatar from '@components/Avatar';
import { Box } from 'rebass';

const ComposerItem = ({ firstName, lastName, image }) => (
  <Box
    sx={{
      cursor: 'pointer',
    }}
  >
    <Avatar image={image?.url}>
      <Styled.Name>{`${firstName} ${lastName}`}</Styled.Name>
    </Avatar>
  </Box>
);

ComposerItem.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
};

export default ComposerItem;
