import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const MenuItem = styled.button`
  font: inherit;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  padding: ${({ padding }) => padding || '8px 12px 8px 5px'};
  align-items: center;
  width: 100%;
  overflow: hidden;
  justify-content: ${({ justifyContent }) => justifyContent || 'initial'};
  white-space: nowrap;

  &:hover {
    background-color: #f2f2f2;
  }

  @media ${breakpoint.md} {
    padding: 12px 10px 12px 5px;
  }
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin: ${({ margin }) => margin || 0};
`;

export const Divider = styled.hr`
  border: none;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.strokeVariant};
  margin: 0;
  opacity: 0.15;
  width: 90%;
  margin: 0 auto;
`;
