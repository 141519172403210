import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const Paginator = styled.div`
  margin: 40px -10px;
  padding: 10px;
  overflow: hidden;

  .paginator {
    height: 48px;
    list-style: none;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    width: fit-content;
    margin: 0 auto;

    background: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    box-shadow: ${({ theme }) => theme.shadows.primary};
  }

  .paginator__inner {
    margin: 10px 0;
    display: inline-block;
    font-size: 16px;
    overflow: hidden;
    text-align: center;
    font-weight: 300;
    width: 50px;

    @media ${breakpoint.md} {
      width: 40px;
    }

    &__link {
      cursor: pointer;

      &--active {
        font-weight: 500;
        border-bottom: ${({ theme }) => `3px solid ${theme.colors.highlight}`};
      }
    }

    > a {
      display: inline-block;
      padding: 8px 12px;

      :focus {
        outline: none;
      }
    }

    &__break {
      @media ${breakpoint.md} {
        width: unset;
        > a {
          padding: 8px 0;
        }
      }
    }
  }

  li:first-of-type,
  li:last-of-type {
    a {
      height: 48px;
      display: inline-block;
    }
  }
`;

export const ArrowImage = styled.img`
  width: 50px;

  @media ${breakpoint.md} {
    width: 48px;
  }
`;
