import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Label } from '@components/Form/style';

export const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.primary};
  border-radius: 50%;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;

export const StyledInput = styled(Label)`
  position: relative;
  padding-left: 35px;
  padding-top: 5px;
  margin-bottom: 12px;
  margin-right: 36px;
  cursor: pointer;
  user-select: none;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &:hover input ~ ${Checkmark} {
    background-color: #cedfe3;
  }

  & input:checked ~ ${Checkmark} {
    // background-color: #2196f3;
  }

  & input:checked ~ ${Checkmark}:after {
    display: block;
  }

  & ${Checkmark}:after {
    top: 6px;
    left: 6px;
    bottom: 6px;
    right: 6px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;

const ERROR = theme => css`
  border-color: ${theme.colors.error};
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  ${({ withError, theme }) => withError && ERROR(theme)}
`;
