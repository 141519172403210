import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EyeIcon from '@assets/images/icons/eye-icon.svg';
import EyeIconShow from '@assets/images/icons/eye-icon-show.svg';
import TextField, { VARIANTS } from '@components/Form/TextField';
import { Icon } from '@components/Form/PasswordField/style';
import { MARGINS } from '@styles/globalStyles';

const PasswordField = ({
  name,
  label,
  register,
  required,
  style,
  errors,
  placeholder,
  fullWidth,
  mb,
  autoComplete,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <TextField
      type={isVisible ? 'text' : 'password'}
      variant={VARIANTS.PASSWORD}
      inputIndicator={() => (
        <button
          tabIndex="-1"
          type="button"
          onClick={() => setIsVisible(!isVisible)}
        >
          <Icon src={isVisible ? EyeIconShow : EyeIcon} alt="" />
        </button>
      )}
      {...{
        name,
        label,
        register,
        required,
        style,
        errors,
        placeholder,
        fullWidth,
        mb,
        autoComplete,
      }}
    />
  );
};

PasswordField.defaultProps = {
  required: false,
  style: {},
  errors: {},
  label: null,
  placeholder: null,
  fullWidth: true,
  mb: MARGINS.SMALL,
  autoComplete: 'off',
};

PasswordField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  register: PropTypes.func.isRequired,
  required: PropTypes.bool,
  style: PropTypes.object,
  errors: PropTypes.object,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  fullWidth: PropTypes.bool,
  mb: PropTypes.oneOf(Object.values(MARGINS)),
};

export default PasswordField;
